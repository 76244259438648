import React, { FC } from 'react';

const ArrowObjectIcon: FC<{ color: string; strokeWidth?: number }> = ({ color, strokeWidth = 7 }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" overflow="visible" width="100%" height="100%" viewBox="0 0 128 128">
      <path
        d="M124 4L4 124"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="M4 124L12 94L34 116L4 124Z"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
        vectorEffect="non-scaling-stroke"
        fill={color}
      />
    </svg>
  );
};

export default ArrowObjectIcon;
