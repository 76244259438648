import { FlatColors } from 'helpers/core-constants';
import React from 'react';
import { Popover } from '@headlessui/react';
import { HexColorPicker } from 'react-colorful';

const SolidColorComponent = ({
  backgroundColor,
  setBackgroundColor,
}: {
  backgroundColor: string;
  setBackgroundColor: any;
}) => {
  return (
    <div
      className="relative overflow-y-auto inline-block no-scrollbar text-left w-full my-3"
      style={{ maxHeight: 'calc(100vh - 200px)' }}
    >
      <>
        <h1
          className="text-sm font-medium text-black mt-5"
        >
          Solid Color
        </h1>
        <div
          className="mt-3"
        >
          <div
            className="relative gradient-item"
          >
            <Popover>
              {({ open }) => (
                <>
                  <Popover.Button
                    className="relative w-10 h-10 rounded-full cursor-pointer border-2 border-black"
                    style={{ backgroundColor: backgroundColor }}
                  ></Popover.Button>

                  <Popover.Panel>
                    <div className="absolute top-12 left-0">
                      <HexColorPicker
                        color={backgroundColor}
                        onChange={(color) => {
                          setBackgroundColor(color);
                        }}
                      />
                    </div>
                  </Popover.Panel>
                </>
              )}
            </Popover>
          </div>
        </div>
      </>
      <div
        className=""
      >
        <h2
          className="text-lg font-medium text-black mb-4"
        >
          Color Palettes
        </h2>
        <div
          className="grid grid-cols-3 lg:grid-cols-4  gap-1"
        >
          {FlatColors.map((color, index) => (
            <div
              key={index}
              className="  "
            >
              <div
                className="w-full h-20 rounded-lg overflow-hidden border-2 border-black  cursor-pointer "
                onClick={() => {
                  setBackgroundColor(color);
                }}
                style={{
                  background: `${color}`,
                }}
              ></div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SolidColorComponent;
