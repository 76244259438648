import { useRecoilCallback } from 'recoil';
import _ from 'lodash';
import { textListState } from '@/state/textState';
import { toolObjectListState } from '@/state/toolState';

export const useContainer = () => {
  const clear = useRecoilCallback(({ reset, snapshot, set }) => async () => {
    const textList = await snapshot.getLoadable(textListState).contents;
    const toolObjectList = [
      ...(await snapshot.getLoadable(toolObjectListState).contents),
    ];
    for (const [key, value] of Object.entries(textList)) {
      const index = _.findIndex(toolObjectList, ['id', `${key}`]);

      if (index < 0) return;
      toolObjectList.splice(index, 1, {
        ...toolObjectList[index],
        text: value,
      });
    }

    set(toolObjectListState, toolObjectList);
    reset(textListState);
  });

  return {
    clear,
  };
};
