import { useCallback } from 'react';
import _ from 'lodash';
import { useRecoilCallback, useRecoilState, useRecoilValue } from 'recoil';
import {
  ArrowIcon,
  BlurIcon,
  CircleIcon,
  DrawIcon,
  TextIcon,
  CursorTextIcon,
  CursorArrowIcon,
  CursorDrawIcon,
  CursorCircleIcon,
  CursorBlurIcon,
  CoursorIcon,
} from '@/components/icon/tool';
import ChatIcon from '@/components/icon/tool/ChatIcon';
import {
  currentToolObjectIndexState,
  currentToolObjectState,
  currentToolState,
  isToolBarShowState,
} from '@/state/toolState';

export const useTool = () => {
  const currentToolObject = useRecoilValue(currentToolObjectState);
  const [currentTool, setCurrentTool] = useRecoilState(currentToolState);
  const [isToolBarShow, setIsToolBarShow] = useRecoilState(isToolBarShowState);

  const getListTool = useCallback(
    (): {
      Icon: (props: { classname: string }) => JSX.Element;
      name: Tool.ObjectType;
      CursorIcon: () => JSX.Element;
    }[] => [
      {
        Icon: CoursorIcon,
        CursorIcon: CursorTextIcon,
        name: 'MOVE',
      },
      {
        Icon: ChatIcon,
        CursorIcon: CursorTextIcon,
        name: 'CHAT',
      },
      {
        Icon: TextIcon,
        CursorIcon: CursorTextIcon,
        name: 'TEXT',
      },
      {
        Icon: DrawIcon,
        CursorIcon: CursorDrawIcon,
        name: 'DRAW',
      },
      {
        Icon: ArrowIcon,
        CursorIcon: CursorArrowIcon,
        name: 'ARROW',
      },
      {
        Icon: CircleIcon,
        CursorIcon: CursorCircleIcon,
        name: 'CIRCLE',
      },
      {
        Icon: BlurIcon,
        CursorIcon: CursorBlurIcon,
        name: 'BLUR',
      },
    ],
    []
  );

  const setNextCurrentTool = useRecoilCallback(({ snapshot }) => async () => {
    const currentTool = await snapshot.getLoadable(currentToolState).contents;

    const list = getListTool();
    const ln = list.length - 1;
    const index = _.findIndex(list, ['name', currentTool]);
    if (index === ln) {
      setCurrentTool(list[0].name);
    } else {
      setCurrentTool(list[index + 1].name);
    }
  });

  const handleClickToolMenu = useRecoilCallback(
    ({ reset, set }) =>
      (toolName: Tool.ObjectType) => {
        set(currentToolState, toolName);
        reset(currentToolObjectState);
        reset(currentToolObjectIndexState);
      }
  );

  return {
    getListTool,
    setNextCurrentTool,
    handleClickToolMenu,
    currentTool,
    currentToolObject,
    isToolBarShow,
    setIsToolBarShow,
  };
};
