import { FC, MouseEvent } from 'react';
import { useRecoilState } from 'recoil';
import dayjs from 'dayjs';
import {
  currentCommentState,
  DEFAULT_CURRENT_COMMENT,
} from '@/state/commentState';
import Pencil from '../icon/Pecil';
import Check from '../icon/Check';

import { useComment } from '@/hooks/useComment';
// import DeleteModal from '../modal/DeleteModal';
import { isDeletedModelOpen } from '@/state/uiState';
import TrashMenu from '../icon/TrashMenu';

const list = [
  { text: 'Edit', Icon: Pencil, modifyType: 'EDIT' },
  { text: 'Delete', Icon: TrashMenu, modifyType: 'DELETE' },
  // { text: 'Resolve', Icon: Switch, modifyType: 'TOGGLE_RESOLVE' },
  // { text: 'UnResolve', Icon: Switch, modifyType: 'TOGGLE_RESOLVE' },
];
type Props = {
  onlyDelete?: boolean;
};

const CaptureCommentDropdown: FC<Props> = (props) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] =
    useRecoilState(isDeletedModelOpen);

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };
  let newList = list;
  if (props.onlyDelete && newList.length > 1) {
    newList = [list[1]];
  }

  const [currentComment, setCurrentComment] =
    useRecoilState(currentCommentState);
  // @ts-ignore
  const { setCaptureComment } = useComment();

  return (
    <div
      className="transform -translate-x-48 z-20 relative w-56 -mt-1 bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dropdown-upgrade "
      role="menu"
      tabIndex={0}
    >
      <div className="py-2 " role="none">
        {newList.map(({ text, Icon, modifyType }, index) => {
          if (!currentComment.isMain && modifyType === 'TOGGLE_RESOLVE') {
            return null;
          } else {
            return (
              <button
                type="button"
                key={index}
                className="text-gray-900 group flex rounded-md items-center w-full px-2 py-2 text-sm hover:bg-gray-800 hover:text-white"
                role="menuitem"
                onClick={(event: MouseEvent<HTMLButtonElement>) => {
                  event.stopPropagation();
                  event.preventDefault();
                  setCurrentComment((old) => ({
                    ...old,
                    modifyType: modifyType as Capture.CommentModifyType,
                    timestamp: dayjs().unix(),
                  }));
                }}
              >
                <Icon />
                {currentComment.isMain && modifyType === 'TOGGLE_RESOLVE' ? (
                  <Icon />
                ) : null}
                {modifyType === 'TOGGLE_RESOLVE'
                  ? !currentComment.isResolved
                    ? 'Resolve'
                    : 'Unresolve'
                  : text}

                {modifyType === 'TOGGLE_RESOLVE' &&
                currentComment.modifyType === 'TOGGLE_RESOLVE' ? (
                  <div className="flex w-full justify-end">
                    <div
                      className="flex items-center rounded-lg px-2 hover:text-pink"
                      onClick={(event: MouseEvent<HTMLDivElement>) => {
                        event.stopPropagation();
                        event.preventDefault();
                        setCurrentComment((old) => ({
                          ...DEFAULT_CURRENT_COMMENT,
                          isResolved: !old.isResolved,
                          isMain: old.isMain,
                          commentIndex: old.commentIndex,
                          timestamp: dayjs().unix(),
                        }));
                        // @ts-ignore
                        setCaptureComment({ ...DEFAULT_CURRENT_COMMENT });
                      }}
                    >
                      <Check />
                      OK
                    </div>
                  </div>
                ) : null}
              </button>
            );
          }
        })}
      </div>

      {/* <div className="px-1 py-1" role="none"></div> */}
    </div>
  );
};

export default CaptureCommentDropdown;
