import { OAuthCredential, User, UserCredential } from 'firebase/auth';
import {
  GoogleAccountsStorageType,
  LocalStorageItems,
} from 'utils/localStorage.types';

class LocalStorageService {
  addGoogleAccount(
    user: User,
    data: {
      userCredential: UserCredential;
      authUserCredential: OAuthCredential | null;
    }
  ) {
    const { authUserCredential, userCredential } = data;

    const localGoogleAccounts_string = localStorage.getItem(
      LocalStorageItems.GOOGLE_ACCOUNTS
    );

    if (localGoogleAccounts_string) {
      const localGoogleAccounts: GoogleAccountsStorageType[] = JSON.parse(
        localGoogleAccounts_string
      );

      if (Array.isArray(localGoogleAccounts)) {
        const newAccount = { userCredential, authUserCredential };
        const accountIndex = localGoogleAccounts.findIndex(
          (account) =>
            account.userCredential.user.uid === userCredential.user.uid
        );

        // Update account credentials in LocalStorage
        if (accountIndex >= 0) {
          localGoogleAccounts[accountIndex] = newAccount;
        } else {
          localGoogleAccounts.push(data);
        }

        localStorage.setItem(
          LocalStorageItems.GOOGLE_ACCOUNTS,
          JSON.stringify(localGoogleAccounts)
        );
      } else {
        // LocalStorage doesn't contains AccountsList
        localStorage.setItem(
          LocalStorageItems.GOOGLE_ACCOUNTS,
          JSON.stringify([data])
        );
      }
    } else {
      // LocalStorage doesn't contains AccountsList
      localStorage.setItem(
        LocalStorageItems.GOOGLE_ACCOUNTS,
        JSON.stringify([data])
      );
    }
  }
}

export default new LocalStorageService();
