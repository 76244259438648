import { useRecoilCallback, useSetRecoilState } from 'recoil';
import { useNavigate } from 'react-router';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';

import _ from 'lodash';
import * as firebase from '@/lib/firebase/firestore';
import * as storage from '@/lib/firebase/storage';
import { isAppLoadingState, pathState } from '@/state/appState';
import { currentUserIDState } from '@/state/userState';
import {
  currentCaptureInfoState,
  captureListState,
  currentCaptureInfoIndexState,
  captureListAtHostState,
  isDownloadState,
  captureRefState,
} from '@/state/captureState';
import {
  deletedCaptureInfo,
  deletedProject,
  isDashBoardState,
} from '@/state/uiState';
import { ImgSize } from '@/components/capture/types';
import { toPng } from 'html-to-image';
import { useMarker } from '@/hooks/useMarker';
import { copyImageToClipboard } from 'copy-image-clipboard';
import { useOrganization } from '@clerk/clerk-react';
import { RootState } from 'store';
import { useSelector } from 'react-redux';

export const useFunction = () => {
  const navigate = useNavigate();
  const { imageInfo } = useSelector((state: RootState) => state.editor);

  const { resetTempMarker } = useMarker();
  const setDownload = useSetRecoilState(isDownloadState);
  const { organization } = useOrganization();

  const copyLinkToClipboard = useRecoilCallback(
    ({ snapshot }) =>
      async () => {
        try {
          const currentCaptureInfo = (await snapshot.getLoadable(
            currentCaptureInfoState
          ).contents) as Capture.Info;
          const pathInfo = await snapshot.getLoadable(pathState).contents;
          const urlInfo = new URL(window.location.href);
          const url = `${urlInfo.origin}/${pathInfo.uid}/${pathInfo.cid}`;
          await navigator.clipboard.writeText(url);
          toast.success('Capture Link Copied!');
        } catch (error) {
          console.log(error);
        }
      },
    []
  );

  const copyGroupLinkToClipboard = useRecoilCallback(
    ({ snapshot }) =>
      async () => {
        try {
          const currentCaptureInfo = (await snapshot.getLoadable(
            currentCaptureInfoState
          ).contents) as Capture.Info;
          const pathInfo = await snapshot.getLoadable(pathState).contents;
          const urlInfo = new URL(window.location.href);
          const groupId = await firebase.setGId(
            pathInfo.uid,
            currentCaptureInfo.group
          );
          const url = `${urlInfo.origin}/${pathInfo.uid}/group/${groupId}/${pathInfo.cid}`;
          await navigator.clipboard.writeText(url);
          toast.success('Project link copied');
        } catch (error) {
          console.log(error);
        }
      },
    []
  );

  const copyToClipboard = useRecoilCallback(({ snapshot }) => async () => {
    const currentCaptureInfo = await snapshot.getLoadable(
      currentCaptureInfoState
    ).contents;

    try {
      copyImageToClipboard(currentCaptureInfo.url);
      // await navigator.clipboard.writeText(currentCaptureInfo.url);
      toast.success('Image Copied!');
    } catch (error) {
      console.log(error);
    }
  });

  const downloadImage = useRecoilCallback(
    ({ snapshot }) =>
      async () => {
        await setDownload(true);
        await resetTempMarker();

        const borderRef = await snapshot.getLoadable(captureRefState).contents;
        if (borderRef && borderRef !== null) {
          // borderRef.classList.add('download-capture');
          // borderRef.classList.remove('no-download-capture');
          toPng(borderRef, {
            cacheBust: true,
          })
            .then((dataUrl) => {
              const link = document.createElement('a');
              link.download = 'my-image-name.png';
              link.href = dataUrl;
              link.click();
              // borderRef.classList.remove('download-capture');
              // borderRef.classList.add('no-download-capture');
              setDownload(false);
            })
            .catch((err) => {
              console.log(err);
            })
            .finally(() => console.log("finish download"));
        } else {
          return;
        }
      },
    [setDownload]
  );
  const { uid, cid } = useParams();

  const deleteImage = useRecoilCallback(
    ({ set, reset, snapshot }) =>
      async (deleteDefinitively: boolean = false) => {
        // if (!uid || !cid) return;
        const path = await snapshot.getLoadable(pathState).contents;
        let currentUserID = await snapshot.getLoadable(currentUserIDState)
          .contents;

        if (organization) {
          currentUserID = organization.id
        }

        const captureList = await snapshot.getLoadable(captureListState)
          .contents;
        const currentCaptureInfo = await snapshot.getLoadable(
          currentCaptureInfoState
        ).contents;

        const captureListAtHost = await snapshot.getLoadable(
          captureListAtHostState
        ).contents;
        const isDashBoard = await snapshot.getLoadable(isDashBoardState)
          .contents;
        if (deleteDefinitively) {
          storage.remove(
            `${currentUserID}/${currentCaptureInfo.group}/${currentCaptureInfo.cid}`
          );
        }
        await firebase.deleteCapture(currentUserID, path.cid, deleteDefinitively);
        set(deletedCaptureInfo, { isDeleted: true, cid: path.cid });
        reset(currentCaptureInfoState);
        reset(currentCaptureInfoIndexState);
        const cloneCaptureList = [...captureList];
        _(cloneCaptureList)
          .remove(({ cid }) => cid === path.cid)
          .value();
        set(captureListState, cloneCaptureList);
        toast.success('Capture Successfully Deleted');

        const list = _.remove(
          [...captureListAtHost],
          ({ cid }) => cid !== path.cid
        );
        reset(pathState);
        // console.log(`IsDashBoard`,isDashBoard)
        const nextCid =
          Array.isArray(list) && list.length > 0 ? list[0].cid : null;
        if (!nextCid || nextCid === path.cid) {
          !isDashBoard && navigate('/');
        } else {
          !isDashBoard && navigate(`/${path.uid}/${nextCid}`);
        }
        window.location.reload();
      }
  );

  const replaceImage = useRecoilCallback(
    ({ snapshot }) =>
      async (
        file: Blob,
        imgSize: ImgSize,
        progress: Function,
        cb: Function
      ) => {
        const currentUserID = await snapshot.getLoadable(currentUserIDState)
          .contents;
        const currentCaptureInfo = await snapshot.getLoadable(
          currentCaptureInfoState
        ).contents;
        const path = `${currentUserID}/${currentCaptureInfo.group}/${currentCaptureInfo.cid}`;
        await storage.replace(
          currentUserID,
          currentCaptureInfo.cid,
          imgSize,
          file,
          path,
          progress,
          cb
        );
      }
  );

  const deleteProjectCapture = useRecoilCallback(
    ({ set, reset, snapshot }) =>
      async (group: string) => {
        const path = await snapshot.getLoadable(pathState).contents;
        let currentUserID = await snapshot.getLoadable(currentUserIDState)
          .contents;

        if (organization) {
          currentUserID = organization.id
        }

        const captureList = await snapshot.getLoadable(captureListState)
          .contents;
        const currentCaptureInfo = await snapshot.getLoadable(
          currentCaptureInfoState
        ).contents;
        const cloneCaptureList = [...captureList];
        set(isAppLoadingState, true);
        let cids = await firebase.deleteProjectCapture(
          currentUserID,
          group,
          (error: any) => {
            if (error) {
              console.log(`Error While Deleting Capture Project ${error}`);
            }
          }
        );
        await firebase.deleteProject(currentUserID, group);
        if (cids && cids?.length > 0) {
          for await (const value of cids) {
            _(cloneCaptureList)
              .remove(({ cid }) => cid === value)
              .value();
          }
          set(captureListState, cloneCaptureList);
          reset(pathState);
          set(deletedCaptureInfo, { isDeleted: true, cid: path.cid });
          set(deletedProject, { isDeleted: true, gid: group });
          toast.success('Project is deleted successfully', { duration: 2000 });
          // console.log(`Navigating`);
          // console.log(currentCaptureInfo.group, group);

          if (currentCaptureInfo?.group === group) {
            reset(currentCaptureInfoState);
            reset(currentCaptureInfoIndexState);
            navigate(`/`);
          }
        }
      }
  );
  return {
    copyLinkToClipboard,
    copyToClipboard,
    downloadImage,
    deleteImage,
    replaceImage,
    copyGroupLinkToClipboard,
    deleteProjectCapture,
  };
};
