import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
import { getFirestore, collection, doc, setDoc } from 'firebase/firestore';
import { nanoid } from 'nanoid';
import { getUser } from './user';
import { firebaseConfig } from '../config';

const DB_PREFIX = 'instacap/email/post';

type UserInfoForPost = {
  email: string;
  name: string;
  uid: string;
};

export const sendPostForComment = async (
  receiver: UserInfoForPost,
  sender: UserInfoForPost,
  comment: any,
  commentIdex: Number,
  capInfo: Capture.Info
) => {
  try {
    const db = getFirestore();
    const colRef = collection(db, DB_PREFIX);
    const docRef = doc(colRef, nanoid());
    const emailHtml = await emailBody(sender, comment, commentIdex);
    await setDoc(docRef, {
      to: [receiver.email],
      from: `"${sender.name}(Instacap)" <${firebaseConfig.emailSender}>`,
      message: {
        // prettier-ignore
        subject: `💬 New comment on your capture “${
          capInfo?.name ? capInfo?.name: capInfo.group
        }” `,
        // prettier-ignore
        // html: `A new comment from ${sender.email || sender.name} was just added to your capture:<br /><br /><a href='${window.location.href}'>View Comment</a>`,
        html: emailHtml,
      },
    });
  } catch (error) {
    console.error('Error set post: ', error);
  }
};

export const sendPostForReply = async (
  receiver: UserInfoForPost,
  sender: UserInfoForPost,
  capInfo: Capture.Info,
  comment: any,
  commentIndex: Number
) => {
  try {
    const db = getFirestore();
    const colRef = collection(db, DB_PREFIX);
    const docRef = doc(colRef, nanoid());
    // console.log(comment, '------------------comment-----');
    const emailHtml = await emailBody(sender, comment, commentIndex);
    await setDoc(docRef, {
      to: [receiver.email],
      from: `"${sender.name}(Instacap)" <${firebaseConfig.emailSender}>`,
      message: {
        // prettier-ignore
        subject: `💬 New reply to your comment on the Capture "${capInfo.name}"`,
        // prettier-ignore
        // html: `A new reply from ${sender.email || sender.name} was just added to your comment:<br /><br /><a href='${window.location.href}'>View Reply</a>`,
        html: emailHtml,
      },
    });
  } catch (error) {
    console.error('Error set post: ', error);
  }
};

export const sendPostForInvite = async (
  emails: string[],
  sender: UserInfoForPost,
  message?: string,
  role?: string,
  cap?: {
    projectName: string;
    capName: string;
    isRequestAccepted: boolean;
    gotToUrl: string;
    isGroupShare: boolean;
  }
) => {
  try {
    const db = getFirestore();
    const colRef = collection(db, DB_PREFIX);
    const docRef = doc(colRef, nanoid());
    const emailBody = await inviteEmailBody(sender, message, role, cap);
    // console.log(`Emails=================:`, emails);
    // console.log(`Sending Post For Invite==================`);
    setDoc(docRef, {
      to: emails,
      from: `"${sender.name}(Instacap)" <${firebaseConfig.emailSender}>`,
      message: {
        subject: `💬 Your comments are needed in ${
          cap?.isGroupShare
            ? `Project "${cap.projectName}"`
            : `Capture "${cap?.capName}"`
        }`,
        html: emailBody,
      },
    });
  } catch (error) {
    console.error('Error set post:', error);
  }
};

const getUserAvatar = async (uid: string) => {
  // const { getUserInfo, getStoredUserInfo } = useAuth();
  const user = await getUser(uid);
  // @ts-ignore
  const url = user?.avatar
    ? // @ts-ignore
      user?.avatar
    : `https://cdn0.iconfinder.com/data/icons/set-ui-app-android/32/8-512.png`;
  return url;
  // return `<img src='${url}' alt="UserAvatar" width="36" height="36">`;
};

const emailBody = async (
  sender: UserInfoForPost,
  comment: any,
  commentIdex?: any
) => {
  // console.log('-----------comment-------', comment);
  let userAvatars: any = [];
  if (comment.replies.length > 0) {
    for await (const reply of comment.replies) {
      // let url =
      userAvatars.push(await getUserAvatar(reply.creator.uid));
    }
  }
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  dayjs.extend(utc);
  dayjs.extend(tz);

  // comment.createdAt
  // console.log(timeZone);

  const loc = `${window.location.protocol}//${window.location.hostname}/Logo.png`;

  return `
  <html>
    <link href="https://fonts.googleapis.com/css?family=Poppins:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&amp;display=swap" rel="stylesheet">
    <style>

    </style>
    <body>

<table border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout:fixed;" id="bodyTable">
  <tbody>
  <tr>
                <td style="text-align: center">
                    <img src="${loc}" alt="" style="padding-bottom: 6px">
                </td>
            </tr>
      <tr>
        <td style="padding-right:10px;padding-left:10px;margin-top: 12px ;" align="center" valign="top" id="bodyCell">
          <table border="0" cellpadding="0" cellspacing="0" width="100%" class="wrapperBody" style="max-width:600px">
            <tbody style="width: 307px;height: 176px;background: #FFFFFF;border: 1px solid #000000;border-radius: 8px;">
              <tr>
                <td align="center" valign="top">
                  <table border="0" cellpadding="0" cellspacing="0" width="100%" class="tableCard" style="background-color: #fff;border-color: #e5e5e5;margin-top: 10%;border-style: solid;border: 1px solid #000000;border-radius: 8px;">
              <tbody>
               <tr>
                        <td style="font-size:1px;line-height:1px" height="20">&nbsp; </td>
              </tr>
              <tr>

                        <td style="font-family: 'Poppins';padding: 16px;border-radius: 9999px; padding-bottom:8px;font-style: normal;font-weight: 400;font-size: 14px;line-height: 150%;color: #808080;">
                          <img style="border-radius: 9999%;" src='${await getUserAvatar(
                            comment.creator.uid
                          )}' alt="UserAvatar" width="24" height="24">

                          <span style="position: relative;top: -14px;left: 7px;color: black;"> ${
                            comment.creator.displayName
                              ? comment.creator.displayName
                              : comment.creator.email
                          }</span>
                          <span style="font-family: 'Poppins';padding-left: 16px;padding-right:16px;font-style: normal;font-weight: 400;font-size: 14px;line-height: 150%;color: #808080;">
                         #${commentIdex + 1}
                         </span>
                        </td>
                          <td style="padding: 0px 23px 0px 23px;">
                                                <p style="text-align: justify">${dayjs(
                                                  comment.createdAt * 1000
                                                ).format('hh:mm A')}
                                                  ${timeZone}
                                                </p>
                                              </td>
                      </tr>
                      <tr>
                        <tr>

                            <tr>
                            </tr>

                            <td>
                            </td>
                        </tr>
                        <td>
                        </td>
                        <td>
                        </td>
                                          </tr>
                                          <tr>
                                              <td style="padding: 0px 23px 0px 23px;">
                                                <p style="white-space: pre-wrap; text-align: justify">${
                                                  comment.content
                                                }</p>
                                              </td>
                                          </tr>

                                          <tr>
                                              <td style="padding-left:22px;padding-right:22px;padding-top:12px;padding-bottom:12px">
                                              <hr style="border: 1px solid #E6E6E6;">
                                          </td>
                                          </tr>
                                          ${
                                            comment.replies.length > 0
                                              ? `<tr>
                                               <td style="padding-left:22px;padding-right:22px;padding-top:12px;padding-bottom:12px">
                                               Replies
                                           </td>
                                           </tr>`
                                              : ''
                                          }
                                          ${comment.replies.map(
                                            (reply: any, index: any) => {
                                              return `
                                              <tr>
                                              <td style="font-family: 'Poppins';padding: 16px;border-radius: 9999px; padding-bottom:8px;font-style: normal;font-weight: 400;font-size: 14px;line-height: 150%;color: #808080;">
                          <img style="border-radius: 9999%;" src='${
                            userAvatars[index]
                          }' alt="UserAvatar" width="24" height="24">

                          <span style="position: relative;top: -14px;left: 7px;color: black;">
                          ${
                            reply.creator.displayName
                              ? reply.creator.displayName
                              : reply.creator.email
                          } ${reply.creator.isAnonymous ? '(Guest)' : ''}
                          </span>

                        </td>
                          <td style="padding: 0px 23px 0px 23px;">
                                                <p style="text-align: justify">${dayjs(
                                                  reply.createdAt * 1000
                                                ).format(
                                                  'hh:mm  A'
                                                )} ${timeZone}
                                                </p>
                                              </td>
                           </tr>
                           <tr>
                                              <td style="padding: 0px 23px 0px 23px;">
                                                <p style="white-space: pre-wrap;text-align: justify">${
                                                  reply.content
                                                }
                                                </p>
                                              </td>
                                          </tr>`;
                                            }
                                          )}

                                         ${
                                           comment.replies.length > 0
                                             ? `<tr>
                                              <td style="padding-left:22px;padding-right:22px;padding-top:12px;padding-bottom:12px">
                                              <hr style="border: 1px solid #E6E6E6;">
                                          </td>
                                          </tr>`
                                             : ''
                                         }
                                          <tr>
                                              <td style="padding:0px 31px 17px 15px; text-align: right; cursor: pointer;">
                                                      <span style="position: relative;top: 8px;left: 8px;">
                                                      <a href='${
                                                        window.location.href
                                                      }/isEmail=1'>
                                                      <button type="button"  style="background: #abff57;padding: 7px 17px;border: 1px solid;border-radius: 35px;position: relative;bottom: 7px;">Reply!</button>
                                                      </a>
                                                </span>
                                              </td>
                                          </tr>
                    </tbody>
                  </table>
                  <table border="0" cellpadding="0" cellspacing="0" width="100%" class="space">
                    <tbody>
                      <tr>
                        <td style="font-size:1px;line-height:1px;" height="30">&nbsp;</td>

                        <td>  </td>
                        </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>

        </td>
      </tr>
  </tbody>
</table>
    </body>
</html>
  `;
};

const inviteEmailBody = async (
  sender: UserInfoForPost,
  message?: string,
  role?: string,
  cap?: {
    projectName: string;
    capName: string;
    isRequestAccepted: boolean;
    gotToUrl: string;
    isGroupShare: boolean;
  }
) => {
  let location =
    cap?.isRequestAccepted === true
      ? window.location.href.replace('/grantAccess', '')
      : window.location.href;
  if (cap?.isRequestAccepted === true) {
    let arr = location.split('/');
    arr.pop();
    location = arr.join('/');
  }
  if (cap?.gotToUrl !== undefined && cap?.gotToUrl !== '') {
    location = cap.gotToUrl;
  }

  const loc = `${window.location.protocol}//${window.location.hostname}/Logo.png`;

  // console.log(`Location${location}`);
  return `
   <div style="height:100%;display:flex">
 <table width="100%" border="0" align="center" cellpadding="0" cellspacing="0" >

    <!-- START HEADER/BANNER -->

            <tbody>
            <tr>
                <td style="text-align: center">
                    <img src="${loc}" alt="" style="padding-bottom: 6px">
                </td>
            </tr>
                <tr>
                    <td align="center">
                        <table class="col-900" width="900" height="554" border="0" align="center" cellpadding="0" cellspacing="0">
                        <tbody style="z-index: 99;">
                            <tr>
                            <td align="center" valign="top" style="background-image:url(https://dev.instacap.co/Background_Hud.png);background-size:cover; background-position:top;height: 400px">
                                <table class="col-600" width="600" height="400" border="0" align="center" cellx padding="0" cellspacing="0">

                                    <tbody>

                                        <tr>                                        <td height="196"></td>
                                    </tr>

                                    <tr>
                                        <td align="center" style=" left: 0%;font-family: 'Poppins';font-style: normal;font-weight: 600;font-size: 24px; line-height: 150%;display: flex;align-items: center;text-align: center; color: #000000;;">
                                            ${
                                              sender.name
                                            } has invited you to  ${role?.toLowerCase()}
                                            ${
                                              cap?.isGroupShare
                                                ? `the <span style="display: contents;color: #FF38A4;">“${cap?.projectName}”</span> Project.`
                                                : ` the <br> Capture “<span style="color: #FF38A4;display: contents;"> ${cap?.capName}” </span> in <span style="display: contents;color: #FF38A4;">“${cap?.projectName}”</span> Project.`
                                            }
                                        </td>
                                    </tr>

                                    <tr>
                                        <td align="center" style="font-family: 'Poppins';font-style: normal;font-weight: 400;font-size: 17px;line-height: 150%;margin-top: 30px;margin-bottom:32px;display: flex;align-items: center;text-align: center;color: #808080;">
                                            ${message ? `“${message}”` : ''}
                                        </td>
                                    </tr>
                                    <tr>
                                    <td><a href='${location}' style="cursor: pointer;" >
                                        <button style="cursor: pointer; margin: 0 auto;font-family: 'Poppins';font-style: normal;font-weight: 600;margin-top: 30;font-size: 16px;line-height: 154%;display: flex;align-items: center;color: #000000;height: 44px;background: #FFFFFF;border: 1px solid #000000;box-shadow: 1px 1px 0px #000000;border-radius: 100px;padding-left: 24px;padding-right: 24px;padding-top: 12px;padding-bottom: 12px;">Go to Instacap</button>
                                        </a>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td height="50"></td>
                                        <td height="50"></td>
                                    </tr>

                                </tbody></table>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div class="footer">

                </div>
                </td>
            </tr>
    <!-- END HEADER/BANNER -->
                <tr>
                 <td height="5"></td>
                </tr>
    <!-- END 3 BOX SHOWCASE -->
                    </tbody>
                </table>

            </div>


  `;
};

// <!--Green Background-->
//             <div style="">
//   <table width="100%" border="0" align="center" cellpadding="0" cellspacing="0" style="height: 455px;padding-left: 0px;   margin-left: 0px; background: #abff57;" >
//       <tbody>
//       <!-- END 3 BOX SHOWCASE -->
//       </tbody>
//   </table>
// </div>
export const sendPostForRequestAccess = async (
  emails: string[],
  sender: UserInfoForPost,
  message?: string,
  cap?: {
    projectName: string;
    capName: string;
    uid: string;
    cid: string;
    email: string;
    gid: string;
  }
) => {
  try {
    const db = getFirestore();
    const colRef = collection(db, DB_PREFIX);
    let id = nanoid();
    const docRef = doc(colRef, id);
    const emailBody = await reuqestEmailBody(sender, message, cap);
    // console.log(`EMAIL BODY==============${emailBody} IDDDDDD${id}`);
    await setDoc(docRef, {
      to: emails,
      from: `"${sender.name}(Instacap)" <${firebaseConfig.emailSender}>`,
      message: {
        subject: `View Access of  Capture ”${cap?.capName}” is requested by ${sender.email} `,
        html: emailBody,
        //     html: `You have been invited to by ${
        //     sender.email
        //   } to collaborate on a Capture board.<br /><br />They are waiting for your feedback!<br /><br />No registration required.<br /><br />Please click here to view the <a href='${
        //     window.location.href
        //   }'>Capture board. <br /> </a>
        //   <span style="font-family: 'Poppins';
        //   font-style: normal;
        //   font-weight: 400;
        //   font-size: 14px;
        //   line-height: 150%";
        //   color: #808080;> ${
        //     message != '' ? `You have Message from Invite:${message}.` : ''
        //   }</span>`,
        // },
      },
    });
  } catch (error) {
    console.error('Error set post:', error);
  }
};
const reuqestEmailBody = async (
  sender: UserInfoForPost,
  message?: string,
  cap?: {
    projectName: string;
    capName: string;
    uid: string;
    cid: string;
    email: string;
    gid: string;
  }
) => {
  // console.log(
  //   `Sending URL localhost:3000/grantAccess/${cap?.uid}/${cap?.cid}/${cap?.email}GID:${cap?.gid}`
  // );
  // console.log(`EnvironMent:${window.location.host}`);
  let location = window.location.host;
  let dest = `${window.location.protocol}//${location}/grantAccess/${cap?.uid}/${cap?.cid}/${cap?.email}`;

  const loc = `${window.location.protocol}//${window.location.hostname}/Logo.png`;

  // console.log(`Protocol ${window.location.protocol}`);
  return `
  <div style="height:100%;display:flex">
  <table width="100%" border="0" align="center" cellpadding="0" cellspacing="0" >
             <tbody>
                 <tr>
                     <td align="center">
                         <table class="col-900" width="900" height="554" border="0" align="center" cellpadding="0" cellspacing="0">
                         <tbody style="z-index: 99; position: relative">
                             <tr>
                             <td align="center" valign="top" style="background-image:url(https://dev.instacap.co/Background_Hud.png);background-size:cover; background-position:top;height: 400px">
                                 <table class="col-600" width="600" height="400" border="0" align="center" cellx padding="0" cellspacing="0">
                                     <tbody>
                                    <tr>
                                     <td height="60"></td>
                                    </tr>
                                      <tr>
                                      <td style="text-align: center">
                                      <img src="${loc}" alt="" style="padding-bottom: 6px">
                                          </td>
                                         </tr>
                                         <tr>
                                         <td height="40"></td>
                                        </tr>
                                     <tr>
                                         <td align="center" style=" left: 0%;font-family: 'Poppins';font-style: normal;font-weight: 100;font-size: 24px; line-height: 150%;display: flex;align-items: center;text-align: center; color: #000000;;">
                                         <span style="display: contents;font-weight: 600;"> ${
                                           sender.name
                                         } </span> has requested you for
                                             ${
                                               cap?.gid === '' ? '<br>' : ''
                                             } view access of  Capture
                                             ${cap?.gid !== '' ? '`s' : ''}
                                             ${
                                               cap?.gid === ''
                                                 ? `<span style="color: #FF38A4;display: contents;font-weight: 600;"> “${cap?.capName}”</span> in`
                                                 : ' of'
                                             } <span style="display: contents;color: #FF38A4;font-weight: 600;">
                                             “${
                                               cap?.projectName
                                             }” </span> Project
                                         </td>
                                     </tr>
                                     <tr>
                                         <td height="20"></td>
                                        </tr>

                                     <tr>
                                         <td align="center" style="font-family: 'Poppins';font-style: normal;font-weight: 100;font-size: 17px; line-height: 150%;margin-top: 30;display: flex;align-items: center;text-align: center;color: #808080;">
                                             ${message}
                                         </td>
                                     </tr>
                                     <tr>
                                         <td height="20"></td>
                                        </tr>
                                     <tr>
                                     <td>
                                         <a href="${
                                           window.location.protocol
                                         }//${location}">
                                         <button style="cursor: pointer; margin: 0 auto;font-family: 'Poppins';font-style: normal;font-weight: 600;margin-top: 30;font-size: 16px;line-height: 154%;display: flex;align-items: center;color: #000000;height: 44px;background: #FFFFFF;border: 1px solid #000000;box-shadow: 1px 1px 0px #000000;border-radius: 100px;padding-left: 24px;padding-right: 24px;padding-top: 12px;padding-bottom: 12px;">Go to Instacap</button>
                                         </td>
                                     </tr>
                                     <tr>
                                         <td height="20"></td>
                                        </tr>
                                     <tr>
                                     <tr>
                                     <td>
                                         <a href="${dest}">
                                         <button style="cursor: pointer; margin: 0 auto;font-family: 'Poppins';font-style: normal;font-weight: 600;margin-top: 30;font-size: 16px;line-height: 154%;display: flex;align-items: center;color: #000000;height: 44px;background: #FFFFFF;border: 1px solid #000000;box-shadow: 1px 1px 0px #000000;border-radius: 100px;padding-left: 24px;padding-right: 24px;padding-top: 12px;padding-bottom: 12px;">GRANT ACCESS</button>
                                         </a>    </td>
                                     </tr>
                                     <tr>
                                         <td height="50"></td>
                                         <td height="50"></td>
                                     </tr>

                                 </tbody></table>
                             </td>
                         </tr>
                     </tbody>
                 </table>
                 <table style="width:100%;left:0%;position:absolute;background-color: #ABFF57;  top: 40%;height: 60%;">
                     <tr style="">
                     </tr>
                 </table>
                 <div class="footer">

                 </div>
                 </td>
             </tr>
                 <tr>
                  <td height="5"></td>
                 </tr>
                     </tbody>
                 </table>

             </div>
  `;
};
