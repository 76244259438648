import { MouseEvent, PointerEvent } from 'react';

export const getBoundingBoxDefaultRect = () => {
  const DEFAULT_WIDTH = 740;
  const DEFAULT_HEIGHT = 480;
  let left = (window.innerWidth - DEFAULT_WIDTH) / 2;
  if (left < 0) left = 0;
  return { width: DEFAULT_WIDTH, height: DEFAULT_HEIGHT, left, top: 90 };
};

export const getCoordinates = (
  pointerEvent: PointerEvent<HTMLDivElement>
): Rect.Point => {
  const boundingArea = pointerEvent.currentTarget.getBoundingClientRect();

  const scrollLeft = window.scrollX ?? 0;
  const scrollTop = window.scrollY ?? 0;

  if (!boundingArea) {
    return { x: 0, y: 0 };
  }

  const point: Rect.Point = {
    x: pointerEvent.pageX - boundingArea.left - scrollLeft,
    y: pointerEvent.pageY - boundingArea.top - scrollTop + 25, // cursor draw icon height = 25
  };

  return point;
};

export const rectInfoToStyle = ({ rotate = 0, ...rest }: Rect.Info, imgEl: any, currentCaptureInfo: any) => {
  const styleObject = {
    ...rest,
    transform: `rotate(${rotate}deg)`,
  }

  if(imgEl && currentCaptureInfo){
    const adjustedValues = ['left', 'top', 'width', 'height'].reduce((acc, prop) => {
      const adjustedValue = ((imgEl?.current?.offsetWidth) / currentCaptureInfo.width) * (rest as any)[prop];
      return { ...acc, [prop]: adjustedValue };
    }, {});
  
    Object.assign(styleObject, adjustedValues);
  }

  return styleObject
};

export const getYPosition = (event: MouseEvent) => {
  return event.clientY + document.documentElement.scrollTop;
};

export const getDefaultRectByObjectTypeAndClientXY = ({
  type,
  clientX,
  clientY,
  toolWidth,
  toolHeight,
}: {
  type: Tool.ObjectType;
  clientX: number;
  clientY: number;
  toolWidth: number;
  toolHeight: number;
}) => {
  switch (type) {
    case 'TEXT':
      return {
        left: clientX,
        top: clientY,
        width: toolWidth,
        height: toolHeight,
        rotate: 0,
      };
    case 'ARROW':
      return {
        left: clientX,
        top: clientY,
        width: toolWidth,
        height: toolWidth,
        rotate: 0,
      };
    case 'CIRCLE':
      return {
        left: clientX,
        top: clientY,
        width: toolWidth,
        height: toolHeight,
        rotate: 0,
      };
    case 'BLUR':
      return {
        left: clientX,
        top: clientY,
        width: toolWidth,
        height: toolHeight,
        rotate: 0,
      };
    default:
      break;
  }
};
