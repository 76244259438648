import { getFirestore, setDoc, collection, doc, getDocs, updateDoc } from 'firebase/firestore';
import type { DocumentReference, DocumentData } from 'firebase/firestore';

export const save = async (uid: string, group: string, cid: string, data: { [key: string]: any }) => {
  try {
    const db = getFirestore();
    const colRef = collection(db, 'instacap', 'captures', uid);
    const docRef = doc(colRef, cid);
    await setDoc(docRef, data);
    return docRef;
  } catch (error) {
    console.error('Error adding document: ', error);
  }
};

export const update = async (docRef: DocumentReference<DocumentData>, data: { [key: string]: any }) => {
  await updateDoc(docRef, data);
};

export const getCount = async (uid: string) => {
  const db = getFirestore();
  const colRef = collection(db, 'instacap', 'captures', uid);
  const snapshot = await getDocs(colRef);
  return snapshot.size;
};
