import { useNavigate } from 'react-router-dom';
import { useRecoilCallback } from 'recoil';
import { nanoid } from 'nanoid';
import dayjs from 'dayjs';
import * as firestore from '@/lib/firebase/firestore';
import * as firestorage from '@/lib/firebase/firestore';
import { currentUserInfoState } from '@/state/userState';
import { pathState } from '@/state/appState';
import {
  currentToolState,
  currentToolObjectColorState,
  currentToolObjectState,
} from '@/state/toolState';

export const useCapture = () => {
  const navigate = useNavigate();

  const uploadCapture = useRecoilCallback(
    ({ snapshot }) =>
      async (blob: Blob) => {
        const { uid, isAnonymous } = await snapshot.getLoadable(
          currentUserInfoState
        ).contents;

        const number = await firestore.getCount(uid);
        if (isAnonymous && number >= 3) {
          navigate(`${uid}/limit`);
        }
        const cid = nanoid();
        const { hostname, port } = new URL(window.location.href);
        const group = hostname;
        const now = dayjs().unix();
        const docRef = await firestore.save(uid, group, cid, {
          cid,
          url: '',
          group,
          capturedUrl: window.location.href,
          port,
          createdAt: now,
          creator: {
            uid,
          },
          view: {
            count: 0,
            viewer: [],
          },
          isDone: false,
        });

        const url = await firestorage.upload(uid, cid, group, blob);
        // @ts-ignore
        await firestore.update(docRef, {
          url,
          isDone: true,
        });
        navigate(`${uid}/${cid}`);
      }
  );

  const saveCreateTool = useRecoilCallback(({ snapshot }) => async () => {
    const path = await snapshot.getLoadable(pathState).contents;
    const createTool = await snapshot.getPromise(currentToolObjectState);
    // console.log('createTool', createTool);
    // console.log('saveCreateTool');

    const { uid, cid } = path;
    if (createTool && uid && cid) {
      await firestore.saveCaptureCreateTool(uid, cid, createTool);
    } else {
      console.error('Error: there is no data to save the tool');
    }
  });

  const setCaptureTools = useRecoilCallback(
    ({ snapshot, reset }) =>
      async ({ tool }) => {
        const path = await snapshot.getLoadable(pathState).contents;
        const currentTool = await snapshot.getLoadable(currentToolState)
          .contents;

        const { uid, cid } = path;
        if (currentTool && uid && cid) {
          await firestore.setCaptureTool(uid, cid, tool);
          reset(currentToolObjectColorState);
        } else {
          console.error('Error: there is no data to save the tool');
        }
      }
  );

  const deleteCaptureTool = useRecoilCallback(
    ({ snapshot }) =>
      async ({ tool }) => {
        const path = await snapshot.getLoadable(pathState).contents;
        const currentTool = await snapshot.getLoadable(currentToolState)
          .contents;

        if (currentTool) {
          await firestore.deleteCaptureTool(path.uid, path.cid, { tool });
        }
      }
  );

  return {
    uploadCapture,
    setCaptureTools,
    deleteCaptureTool,
    saveCreateTool,
  };
};
