import { useRef } from 'react';
import ToolMenu from '@/components/tool/ToolMenu';
import { useRecoilValue } from 'recoil';
import {
  canAddNewCommentState,
  getCurrentUserAccessType,
} from '@/state/captureState';
import { currentUserInfoState } from '@/state/userState';
import { User } from 'firebase/auth';

const ToolBar = () => {
  const menuRef = useRef<HTMLDivElement | null>(null);
  const currentAccessType = useRecoilValue(getCurrentUserAccessType);
  const currentUserInfo = useRecoilValue(currentUserInfoState) as User;
  let allowedAnnotation =
    currentAccessType !== 'VIEW-CAPUTRE-ACCESS' &&
    currentAccessType !== 'VIEW-PROJECT-ACCESS';
  const canCommentAnonymous = useRecoilValue(canAddNewCommentState);
  if (currentUserInfo.isAnonymous) {
    allowedAnnotation = canCommentAnonymous;
  }

  return (
    <div
      ref={menuRef}
      className={`relative z-40 flex flex-row items-center cursor-pointer`}
    >
      <ToolMenu />
    </div>
  );
};

export default ToolBar;
