import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Popover } from '@headlessui/react';
import { useUpload } from '@/hooks/useUpload';
import { isAnonymousState } from '@/state/userState';
import type { ProcessServerConfigFunction } from 'filepond';
import FileUploadModal from '../modal/FileUploadModal';
import { ImgSize } from '../capture/types';
import {
  addingNewCaptureState,
  countCaptureListState,
  currentCaptureShareInvitListState,
  isProjectOpen,
} from '@/state/captureState';
import Plus from '../icon/Plus';

const AddCapture: FC<{
  group?: string;
  customClassName?: string;
  projectName?: string;
  toolTip: string;
}> = ({ group, customClassName, projectName, toolTip }) => {
  const url = window.URL || window.webkitURL;
  const navigate = useNavigate();
  const currentCaptureShareInviteList = useRecoilValue(
    currentCaptureShareInvitListState
  );
  const { uploadImage, uploadPDF, saveShareInviteOnNewCapture } = useUpload(
    group,
    projectName,
    currentCaptureShareInviteList
  );
  const isAnonymous = useRecoilValue(isAnonymousState);
  const count = useRecoilValue(countCaptureListState);
  const [isOpen, setIsOpen] = useState(false);
  const [tip, setTip] = useState('');
  const setAddingNew = useSetRecoilState(addingNewCaptureState);
  const setProjectOpen = useSetRecoilState(isProjectOpen);
  const [progress, setProgress] = useState<Number | null>(null);

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleProcess: ProcessServerConfigFunction = async (
    _fieldName,
    file,
    _metadata,
    load,
    error,
    progress,
    _abort
  ) => {
    if (file.type === 'application/pdf') {
      try {
        await uploadPDF(file, file.name, (path: string) => {
          // setProjectOpen((prev) => ({
          //   ...prev,
          //   index: 0,
          // }));
          //   navigate(path);
          load('done');
          closeModal();
          setAddingNew(true);
          setProgress(null);
        }, (progress: number) => {
          setProgress(progress);
        });
      } catch (err) {
        error('UPLOAD IS FAILED');
        console.log(err);
      }
    } else if (file.type.match(/^image/)) {
      try {
        const img = new Image();
        img.onload = async function () {
          const imgSize: ImgSize = {
            width: img.width,
            height: img.height,
          };
          await uploadImage(
            file,
            file.name,
            imgSize,
            progress,
            (path: string) => {
              // setProjectOpen((prev) => ({
              //   ...prev,
              //   index: 0,
              // }));
              navigate(path);
              load('done');
              closeModal();
              setAddingNew(true);
            }
          );
        };
        img.src = url.createObjectURL(file);
      } catch (err) {
        error('UPLOAD IS FAILED');
        console.log(err);
      }
    } else {
      error('This file type is not allowed here.');
    }
  };

  const handleClick = () => {
    if (isAnonymous && count > 2) {
      setTip('limit Exceeded!');
    } else {
      setIsOpen(!isOpen);
    }
  };
  return (
    <div className="flex items-center">
      <Popover
        className={`sm:order-first ${customClassName !== '' ? 'w-full' : 'w-fit'
          }`}
        onClick={handleClick}
      >
        <Popover.Button
          // onClick={handleClick}
          className={`${customClassName !== '' ? 'sidebar-upload' : 'thumbnail-upload'
            } ${isAnonymous && count > 2 ? 'cursor-not-allowed' : ''
            } has-tooltip disabled relative flex justify-center items-center w-full h-10  border-2 border-dashed rounded-lg hover:opacity-100 sm:h-full  sm:px-3 sm:py-3`}
        >
          <Plus />
          <span className={`sdjkas ${customClassName}`}>
            {customClassName !== '' ? toolTip : null}
          </span>
        </Popover.Button>

        <div className="w-full h-full">
          {isOpen && (
            <FileUploadModal
              isReplaceImage={false}
              isOpen={isOpen}
              closeModal={closeModal}
              onProcess={handleProcess}
              group={group}
              updateShareInviteList={saveShareInviteOnNewCapture}
              progress={progress}
            />
          )}
        </div>
      </Popover>
      &nbsp;
      {isAnonymous && count > 2 && (
        <Popover
          className={`sm:order-first ${customClassName !== '' ? '' : 'w-2'}`}
        >
          <Popover.Button
            className={`has-tooltip disabled relative flex justify-center items-center px-2 border-black text-black bg-white text-sm leading-6 border-2 rounded hover:opacity-100 sm:h-full  sm:px-3 sm:py-3`}
          >
            ?
            <span className="tooltip w-96 mt-24 tool-tip-txt shadow-2xl">
              {
                'Please Sign-in to keep adding more than 3 Captures. As a Guest User you have reached the maximum number limit'
              }
            </span>
          </Popover.Button>
        </Popover>
      )}
    </div>
  );
};

export default AddCapture;
