import { FC, useEffect, useState } from 'react';
import FolderIcon from '../icon/FolderIcon';
import { Project } from 'types/project';
import DropdownProject from '../sidebar/DropdownProject';
import { isAppLoadingState } from '@/state/appState';
import { useRecoilValue } from 'recoil';
import { useInvitation } from '@/hooks/useInvitation';
import { showMyProjectState } from '@/state/uiState';

type FolderProps = {
  data: Project;
  isExpired?: boolean;
  isDeleted?: boolean;
};

const Folder: FC<FolderProps> = ({ data, isExpired, isDeleted }) => {
  const [isDropdownShow, setIsDropdownShow] = useState(false);
  const [isEditProjectAccess, setIsEditProjectAccess] = useState<
    boolean | undefined
  >();

  const appLoading = useRecoilValue(isAppLoadingState);
  const showMyProject = useRecoilValue(showMyProjectState);
  const { isProjectEditAccess } = useInvitation();

  const handleClickTripleDots = (event: any) => {
    event.stopPropagation();
    event.preventDefault();

    setIsDropdownShow((isDropdownShow) => !isDropdownShow);
  };

  useEffect(() => {
    isProjectEditAccess(data.uid, data.gid, showMyProject).then((ok) => {
      setIsEditProjectAccess(ok);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <div className="flex items-center justify-between py-4 pl-6 pr-4 rounded-lg border border-[#D9D9D9] cursor-pointer hover:shadow-sm">
      <div className="flex items-center gap-4 overflow-hidden">
        <div>
          {!isDeleted && <FolderIcon />}

          {isDeleted && (
            <svg
              width="24"
              height="22"
              viewBox="0 0 24 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M21 21.5H3C1.3125 21.5 0 20.1875 0 18.5V3.5C0 1.85938 1.3125 0.5 3 0.5H9C9.9375 0.5 10.8281 0.96875 11.3906 1.71875L12.2812 2.9375C12.5625 3.3125 12.9844 3.5 13.5 3.5H21C22.6406 3.5 24 4.85938 24 6.5V18.5C24 20.1875 22.6406 21.5 21 21.5ZM8.20312 8.70312C7.73438 9.17188 7.73438 9.875 8.20312 10.2969L10.4062 12.5L8.20312 14.7031C7.73438 15.1719 7.73438 15.875 8.20312 16.2969C8.625 16.7656 9.32812 16.7656 9.75 16.2969L11.9531 14.0938L14.1562 16.2969C14.625 16.7656 15.3281 16.7656 15.75 16.2969C16.2188 15.875 16.2188 15.1719 15.75 14.7031L13.5469 12.5L15.75 10.2969C16.2188 9.875 16.2188 9.17188 15.75 8.70312C15.3281 8.28125 14.625 8.28125 14.1562 8.70312L11.9531 10.9062L9.75 8.70312C9.32812 8.28125 8.625 8.28125 8.20312 8.70312Z"
                fill="black"
              />
            </svg>
          )}
        </div>
        <span className="text-sm font-semibold truncate">{data.name}</span>
      </div>
      <div className="flex items-center gap-2">
        <div className="px-2 rounded-lg border border-black text-xxxxs">
          {data.numItems}
        </div>
        {!appLoading && isEditProjectAccess && (
          <div
            className={`ml-auto text-bold relative ${
              isDropdownShow ? 'three-dot-sidebar-hover' : ''
            }`}
            tabIndex={0}
            onClick={handleClickTripleDots}
          >
            {!isExpired && !isDeleted && (
              <svg
                className="three-dot-sidebar w-4 h-4"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16 0L0 0L0 16L16 16L16 0Z"
                  fill="white"
                  fillOpacity="0.01"
                />
                <path
                  d="M8 5C8.55228 5 9 4.55228 9 4C9 3.44772 8.55228 3 8 3C7.44772 3 7 3.44772 7 4C7 4.55228 7.44772 5 8 5Z"
                  fill="black"
                />
                <path
                  d="M8 9C8.55228 9 9 8.55228 9 8C9 7.44772 8.55228 7 8 7C7.44772 7 7 7.44772 7 8C7 8.55228 7.44772 9 8 9Z"
                  fill="black"
                />
                <path
                  d="M8 12.6665C8.55228 12.6665 9 12.2188 9 11.6665C9 11.1142 8.55228 10.6665 8 10.6665C7.44772 10.6665 7 11.1142 7 11.6665C7 12.2188 7.44772 12.6665 8 12.6665Z"
                  fill="black"
                />
              </svg>
            )}

            {!isExpired && !isDeleted && (
              <DropdownProject
                isDropdownShow={isDropdownShow}
                group={data.gid}
                setDropDownShow={setIsDropdownShow}
                data={data}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Folder;
