
type Props ={
  width ?:number,
  height ?:number
}
const TrashMenu =(props:Props) => (
  <svg
    width={props.width || '14'}
    className="mr-2 cursor-pointer"
    height={props.width || '14'}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M16 0H0V16H16V0Z" fill="white" fillOpacity="0.01" />
    <path
      d="M3 3.33325V14.6666H13V3.33325H3Z"
      stroke="black"
      strokeWidth="1.33333"
      strokeLinejoin="round"
    />
    <path
      d="M6.66675 6.66675V11.0001"
      stroke="black"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.33325 6.66675V11.0001"
      stroke="black"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.33325 3.33325H14.6666"
      stroke="black"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.33325 3.33325L6.42959 1.33325H9.59229L10.6666 3.33325H5.33325Z"
      stroke="black"
      strokeWidth="1.33333"
      strokeLinejoin="round"
    />
  </svg>
);
export default TrashMenu;
