import ReactDOMServer from 'react-dom/server';

import _ from 'lodash';
import {
  memo,
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import BoundingBox from '../tool/BoundingBox';

import { useMarker } from '@/hooks/useMarker';
import { isShowAddCommentState } from '@/state//commentState';
import {
  canAddNewCommentState,
  captureListAtHostState,
  captureListSharedAtHostState,
  captureRefState,
  currentCaptureInfoState,
  getCurrentUserAccessType,
  isDownloadState,
  isParamCaptureImageState,
} from '@/state/captureState';
import { hoverMarkerState, tempMarkerState } from '@/state/markerState';
import {
  captureBoardScrollYState,
  emojiPickerState,
  imageLoadedState,
  showCommentsTabState,
  showMyProjectState,
  sidebarState,
} from '@/state/uiState';
import Loading from '../Loading';
import CaptureMarker from './CaptureMarker';

import { boundingRectState } from '@/state/boundingBoxState';
import {
  currentToolObjectIndexState,
  currentToolState,
  isCurrentScaleState,
  isParamCurrentToolState,
} from '@/state/toolState';

import { responsiveDesignState } from '@/state/responsiveState';

import ToolObjectCommon from '@/components/tool/ToolObjectCommon';
import { useBoundingBox } from '@/hooks/tool/useBoundingBox';
import { useDrag } from '@/hooks/tool/useDrag';
import { useToolObject } from '@/hooks/tool/useToolObject';
import { currentUserInfoState } from '@/state/userState';
import { ImgSize } from './types';

import { useInvitation } from '@/hooks/useInvitation';
import { User } from 'firebase/auth';
import ImageCursordefault from '../icon/image-cursor-default';
import ZoomSlider from '../ZoomSlider';

import {
  ReactZoomPanPinchRef,
  TransformComponent,
  TransformWrapper,
} from 'react-zoom-pan-pinch';
import CaptureAddCommentMarker from './CaptureAddCommentMarker';

const getCoords = (elem: HTMLElement) => {
  return elem.getBoundingClientRect();
};

const CaptureImage = () => {
  const borderRef = useRef<HTMLDivElement | null>(null);
  const imgRef = useRef<HTMLImageElement | null>(null);
  const imgContainerRef = useRef<HTMLDivElement | null>(null);
  const transformComponentRef = useRef<ReactZoomPanPinchRef>(null!);
  const [isImageLoaded, setIsLoaded] = useState(false);
  const [isShowAddComment, setIsShowAddComment] = useRecoilState(
    isShowAddCommentState
  );

  const { position } = useDrag();
  const [imgSize, setImgSize] = useState<ImgSize>({ width: 0, height: 0 });
  const [paramCaptureImage, setParamCaptureImage] = useRecoilState(
    isParamCaptureImageState
  );
  const [showEmojiPicker, setShowEmojiPicker] =
    useRecoilState(emojiPickerState);
  const { responsive } = useRecoilValue(responsiveDesignState);

  const [currentScale, setScale] = useRecoilState(isCurrentScaleState);
  const currentCaptureInfo = useRecoilValue(currentCaptureInfoState);
  const hoverMarker = useRecoilValue(hoverMarkerState);
  const [captureBoardScrollY, setCaptureBoardScrollY] = useRecoilState(
    captureBoardScrollYState
  );
  const currentToolObjectIndex = useRecoilValue(currentToolObjectIndexState);
  const showMyProject = useRecoilValue(showMyProjectState);
  const captureListAtHost = useRecoilValue(
    !showMyProject ? captureListSharedAtHostState : captureListAtHostState
  );

  let group = '';
  let projectName;
  let publicAccess = true;
  if (captureListAtHost.length > 0) {
    group = captureListAtHost[0].group;
    projectName = captureListAtHost[0].projectName || '';
    publicAccess = captureListAtHost[0]?.publicAccess === true ? true : false;
  }

  const [cordCapture, setCordCapture] = useRecoilState(boundingRectState);
  const { isBoundingBoxShow } = useBoundingBox();
  const { setTempMarker } = useMarker();
  const { setToolState, setToolStateAfterInsert } = useToolObject();

  const canCommentUser = useRecoilValue(canAddNewCommentState);
  const showCommentsTab = useRecoilValue(showCommentsTabState);
  const setImageLoaded = useSetRecoilState(imageLoadedState);
  const canAddAnnotation = useRecoilValue(canAddNewCommentState);
  const currentAccessType = useRecoilValue(getCurrentUserAccessType);
  const currentUserInfo = useRecoilValue(currentUserInfoState) as Capture.Creator;;
  const { setCurrentCaptureShareInviteList } = useInvitation();
  const sidebar = useRecoilValue(sidebarState);
  const currentTool = useRecoilValue(currentToolState);

  const [paramCurrentTool, setParamCurrentTool] = useRecoilState(
    isParamCurrentToolState
  );
  const setCaptureRef = useSetRecoilState(captureRefState);

  const download = useRecoilValue(isDownloadState);
  const [tempMarker, setTempMarkerValue] = useRecoilState(tempMarkerState);
  const [zoomValue, setZoomValue] = useState<number>(1);
  const [isPanningEnabled, setIsPanningEnabled] = useState<boolean>(false);
  const [cursorCoordinates, setCursorCoordinates] = useState<{
    x: string;
    y: string;
  }>({ x: '0', y: '0' });

  useEffect(() => {
    if (borderRef.current && isImageLoaded) {
      setCaptureRef && setCaptureRef(borderRef.current);
    }
  }, [borderRef?.current, setCaptureRef, isImageLoaded]);

  const calcScale = useCallback(
    _.throttle(() => {
      if (
        imgRef.current?.width &&
        currentCaptureInfo?.width &&
        currentCaptureInfo?.height
      ) {
        const imageW = imgRef.current.width;
        const imageH = imgRef.current.height;
        const calcX = Number(imageW / currentCaptureInfo.width);
        const calcY = Number(imageH / currentCaptureInfo.height);

        setScale(() => ({ x: calcX, y: calcY }));
      }
    }, 1000 / 60),
    [setScale, imgSize, imgRef]
  );

  useEffect(() => {
    calcScale();
  }, [sidebar, showCommentsTab, calcScale]);

  const updateArrayTools = useMemo(() => {
    if (currentCaptureInfo?.tools) {
      return currentCaptureInfo?.tools.map((tool: Tool.Object) => ({
        ...tool,
        top: tool.top,
        left: tool.left,
        width: tool.width,
        height: tool.height,
      }));
    }
  }, [currentCaptureInfo]);

  useEffect(() => {
    calcScale();

    const resizehandler = calcScale;
    window.addEventListener('resize', resizehandler);
    return () => {
      window.removeEventListener('resize', resizehandler);
    };
  }, [calcScale]);

  useEffect(() => {
    if (imgRef.current && borderRef.current && isImageLoaded) {
      let cordCapture = getCoords(borderRef.current);
      setCordCapture(() => {
        return {
          width: cordCapture.width,
          height: cordCapture.height,
          top: cordCapture.top,
          left: cordCapture.left,
        };
      });
    }
  }, [isBoundingBoxShow, isImageLoaded, imgRef, showCommentsTab]);

  useEffect(() => {
    if (imgRef.current) {
      const cordCaptureImage = getCoords(imgRef.current);
      setParamCaptureImage(() => cordCaptureImage);
    }
  }, [paramCurrentTool, position]);

  useEffect(() => {
    return () => {
      setCaptureBoardScrollY(0);
      borderRef.current && borderRef.current.scrollTo(0, 0);
    };
  }, [setCaptureBoardScrollY, borderRef]);

  useEffect(() => {
    if (currentCaptureInfo?.cid)
      setCurrentCaptureShareInviteList(currentCaptureInfo?.cid);

    setIsLoaded(false);
    const loadingTimer = setTimeout(() => {
      setIsLoaded(true);
    }, 5000);

    return () => {
      clearTimeout(loadingTimer);
    };
  }, [currentCaptureInfo?.cid]);

  useEffect(() => {
    if (tempMarker) {
      setTempMarkerValue(null);
    }

    let timer: NodeJS.Timeout | undefined;
    if (currentCaptureInfo?.url) {
      if (!isImageLoaded) {
        timer = setTimeout(() => {
          setIsLoaded(true);
        }, 5000);
      }
    }

    return () => {
      timer && clearTimeout(timer);
    };
  }, [currentCaptureInfo?.url]);

  useEffect(() => {
    if (isImageLoaded && imgRef.current && currentCaptureInfo) {
      if (currentCaptureInfo?.comments?.length > 0) return;

      if (responsive === false) {
        const coords = getCoords(imgRef.current);
        // setTempMarker({
        //   x: 60,
        //   y: 100,
        //   box: coords.toJSON(),
        // });
      }
    }

    setImageLoaded(isImageLoaded);
    if (isImageLoaded) handleImageOnload();
  }, [isImageLoaded, currentCaptureInfo?.comments]);

  function handleImageClick(event: MouseEvent<HTMLImageElement>) {
    if (!canCommentUser) return false;
    if (currentTool !== 'CHAT') return false;

    const el = event.currentTarget;
    const coords = getCoords(el);

    setTempMarker({
      x: event.pageX - coords.left,
      y: event.pageY - coords.top,
      box: coords.toJSON(),
    });

    setToolState();
    setToolStateAfterInsert();
  }

  const handleMousemove = (event: MouseEvent<HTMLImageElement>) => {
    setCursorCoordinates({
      x: event.clientX + 15 + 'px',
      y: event.clientY + 15 + 'px',
    });
  };

  const handleZoomChange = (zoomValue: number, transitionTime = 100) => {
    const newZoom = zoomValue / 100;

    if (transformComponentRef.current) {
      let centerPositionX = 20;
      const centerPositionY = 20;

      transformComponentRef.current.setTransform(
        centerPositionX,
        centerPositionY,
        newZoom,
        transitionTime,
        'linear'
      );
    }

    return newZoom;
  };

  const handleImageOnload = () => {
    if (imgRef && imgRef.current?.width) {
      const imgSize = {
        width: imgRef.current?.width,
        height: imgRef.current?.height,
      };
      setImgSize(imgSize);
    }

    if (transformComponentRef.current) {
      setZoomValue(handleZoomChange(100, 0));
    }

    setIsLoaded(true);
  };

  return (
    <>
      <TransformWrapper
        ref={transformComponentRef}
        initialScale={1}
        minScale={0.5}
        maxScale={2}
        initialPositionY={20}
        initialPositionX={20}
        limitToBounds={true}
        centerZoomedOut
        wheel={{
          step: 0.1,
          disabled: true,
        }}
        panning={{
          activationKeys: ['Control'],
          velocityDisabled: true,
        }}
        doubleClick={{
          disabled: true,
          mode: 'reset',
        }}
        onZoom={(ref) => setZoomValue(ref.state.scale)}
        onPanningStart={() => {
          setIsPanningEnabled(true);
          if (borderRef.current) {
            borderRef.current.style.cursor = 'grabbing';
          }
        }}
        onPanningStop={() => {
          setIsPanningEnabled(false);
          if (borderRef.current) {
            if (currentTool === 'CHAT') {
              borderRef.current.style.cursor = `url(data:image/svg+xml,${escape(
                ReactDOMServer.renderToStaticMarkup(
                  <ImageCursordefault width={24} height={24} customClass="" />
                )
              )}), auto`;
            } else {
              borderRef.current.style.cursor = 'default';
            }
          }
        }}
      >
        <TransformComponent
          wrapperStyle={{
            overflow: 'auto',
          }}
          contentStyle={{
            paddingRight: '20px',
            paddingBottom: '20px',
          }}
          wrapperClass={`zoom-container-wrapper ${isPanningEnabled ? 'active' : ''
            }`}
          contentClass={`zoom-container-content ${isPanningEnabled ? 'active' : ''
            }`}
        >
          <div
            ref={borderRef}
            id="capture_board"
            className={`flex-initial ${!isImageLoaded && 'h-96'} ${download ? 'download-capture' : 'no-download-capture'
              } bg-white rounded-lg overflow-y-visible sm:min-h-60 sm:top-0 sm:z-10 sm:max-h-80`}
            onClick={() => {
              if (showEmojiPicker) {
                setShowEmojiPicker({
                  show: false,
                  positioning: {
                    targetElementRect: {
                      height: 0,
                      width: 0,
                      x: 0,
                      y: 0,
                    } as DOMRect,
                    offset: {
                      x: 0,
                      y: 0,
                    },
                  },
                  handleAddIcon: () => { },
                  handleHide: () => { },
                });
              }
            }}
            onMouseDown={(e) => e.button === 1 && setIsPanningEnabled(true)}
            onMouseUp={(e) => e.button === 1 && setIsPanningEnabled(false)}
          >
            {!isImageLoaded && <Loading />}

            <div
              ref={imgContainerRef}
              className="capture-image-container"
              onMouseLeave={() => {
                if (!canCommentUser) return false;
                setIsShowAddComment(false);
                if (borderRef && borderRef.current) {
                  borderRef.current.style.cursor = 'inherit';
                }
              }}
              onMouseEnter={() => {
                if (!canCommentUser) return false;
                setIsShowAddComment(true);

                if (borderRef && borderRef.current && !isPanningEnabled) {
                  if (currentTool === 'CHAT') {
                    borderRef.current.style.cursor = `url(data:image/svg+xml,${escape(
                      ReactDOMServer.renderToStaticMarkup(
                        <ImageCursordefault
                          width={24}
                          height={24}
                          customClass=""
                        />
                      )
                    )}), auto`;
                  } else {
                    borderRef.current.style.cursor = 'default';
                  }
                }
              }}
              onMouseMove={() => {
                if (borderRef && borderRef.current && !isPanningEnabled) {
                  if (currentTool === 'CHAT') {
                    borderRef.current.style.cursor = `url(data:image/svg+xml,${escape(
                      ReactDOMServer.renderToStaticMarkup(
                        <ImageCursordefault
                          width={24}
                          height={24}
                          customClass=""
                        />
                      )
                    )}), auto`;
                  } else {
                    borderRef.current.style.cursor = 'default';
                  }
                }
              }}
            >
              {/* {isImageLoaded &&
              } */}
              <img
                crossOrigin="anonymous"
                ref={imgRef}
                // style={{
                //   minWidth: imgSize.width,
                //   minHeight: imgSize.height,
                // }}
                className={`2xl:h-full bg-transparent captcha-capture object-scale-down ${isImageLoaded ? 'visible' : 'invisible'
                  }`}
                style={{ display: isImageLoaded ? "block" : "none" }}
                src={currentCaptureInfo?.url}
                key={currentCaptureInfo?.url}
                alt="no data"
                loading="lazy"
                onClick={handleImageClick}
                onMouseMove={canCommentUser ? handleMousemove : () => { }}
                onLoad={handleImageOnload}
              />

              {isImageLoaded && currentCaptureInfo && (
                <>
                  <CaptureMarker imgEl={imgRef} style={{ display: tempMarker ? "block" : "none" }} />
                  {!isPanningEnabled &&
                    currentToolObjectIndex === -1 &&
                    currentTool === 'CHAT' && (
                      <CaptureAddCommentMarker
                        show
                        left={cursorCoordinates.x}
                        top={cursorCoordinates.y}
                      />
                    )
                  }

                  {currentCaptureInfo?.tools?.length > 0 &&
                    // @ts-ignore
                    updateArrayTools.map((obj, index) => {
                      return (
                        <ToolObjectCommon
                          key={`${obj.id}`}
                          isImageLoaded={isImageLoaded}
                          imgEl={imgRef}
                          index={index}
                          isEditingAccess={
                            currentAccessType === 'CAPTURE-OWNER' ||
                            currentAccessType === 'EDIT-CAPUTRE-ACCESS' ||
                            currentAccessType === 'EDIT-PROJECT-ACCESS'
                          }
                          zoomValue={zoomValue}
                          {...obj}
                        />
                      );
                    })}

                  {canAddAnnotation && !currentUserInfo.isAnonymous && (
                    <BoundingBox
                      imgSize={imgSize}
                      isEditAble={canAddAnnotation}
                      borderEl={borderRef}
                      imgEl={imgRef}
                      zoomValue={zoomValue}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        </TransformComponent>
      </TransformWrapper>

      <div className="pl-5 pt-3 mob-slider w-full absolute bottom-32">
        <div className="flex flex-row">
          <div className="flex items-center">
            <ZoomSlider
              zoomValue={Math.ceil(zoomValue * 100)}
              onZooming={(zoomValue) => {
                setZoomValue(() => handleZoomChange(zoomValue));
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(CaptureImage);