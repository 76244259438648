import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';

import { httpsCallable } from 'firebase/functions';
import { functions } from '@/lib/firebase/auth';

import { useRecoilValue } from 'recoil';
import { currentUserInfoState } from '@/state/userState';

export const useUpgradeTeamUser = () => {
  const location = useLocation();
  const currentUserInfo = useRecoilValue(
    currentUserInfoState
  ) as Capture.Creator;
  const [isLoading, setIsLoading] = useState(false);

  const upgradePlan = async (plan: string) => {
    try {
      setIsLoading(true);
      const {
        email,
        uid,
        stripeCustomerId,
        planTeam,
        stripeSubscriptionIdTeam,
      } = currentUserInfo;
      const payload = {
        email,
        uid,
        stripeCustomer: stripeCustomerId,
        planTeam: 'addedOrRemoved',
        stripeSubscriptionIdTeam: stripeSubscriptionIdTeam,
        redirectPathname: location.pathname,
      };
      //   console.log('payload ::', payload);

      const { data }: any = await httpsCallable(
        functions,
        'getNewCheckoutSessionIdTeamUser'
      )(payload);

      const { sessionId, publicKey } = data;

      if (sessionId && publicKey) {
        const stripe = await loadStripe(publicKey);
        if (stripe) {
          const { error } = await stripe.redirectToCheckout({ sessionId });
          if (error) {
            console.error('Error redirecting to checkout:', error);
            setIsLoading(false);
          }
        }
      }
    } catch (error) {
      console.error('Error upgrading plan:', error);
      setIsLoading(false);
    }
  };

  return { isLoading, upgradePlan };
};
