import React from 'react';

const UploadModelDrag = () => {
  return `<svg width="97" class="mt-8" height="84" viewBox="0 0 97 84" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M92.0688 49.8283C93.3976 47.3142 94.1585 44.4558 94.1585 41.4167C94.1585 33.6922 89.2895 27.1168 82.45 24.5257C82.45 24.5175 82.4512 24.5093 82.4512 24.5C82.4512 10.969 71.444 0 57.8659 0C46.4114 0 36.8149 7.81783 34.0848 18.3832C32.3568 17.8173 30.5152 17.5 28.5976 17.5C19.5923 17.5 12.1804 24.2585 11.1654 32.9607C5.07288 34.2452 0.5 39.6305 0.5 46.0833C0.5 53.4928 6.5281 59.5 13.9634 59.5C14.1706 59.5 14.3755 59.486 14.5792 59.4743C14.5745 59.6785 14.5488 59.878 14.5488 60.0833C14.5488 73.2923 25.2938 84 38.5488 84C46.0848 84 52.8024 80.5327 57.2032 75.117C59.7531 77.7128 63.298 79.3333 67.2317 79.3333C72.6756 79.3333 77.3866 76.2417 79.7187 71.729C80.9503 72.1198 82.2604 72.3333 83.6219 72.3333C90.7341 72.3333 96.5 66.5875 96.5 59.5C96.5 55.6372 94.779 52.1815 92.0688 49.8283Z" fill="#F7F5EE"/>
  <path d="M79.5244 54.2503V54.8337H83.0366V54.2503C83.0366 54.2503 88.0028 54.2503 89.4756 54.2503C92.3849 54.2503 94.7439 51.8995 94.7439 49.0003C94.7439 46.2785 92.6576 44.0665 89.9966 43.8028C90.0223 43.5917 90.061 43.3852 90.061 43.167C90.061 40.2678 87.7019 37.917 84.7927 37.917C83.1092 37.917 81.6282 38.7173 80.6635 39.9423C80.4996 36.867 77.9685 34.417 74.8414 34.417C71.6091 34.417 68.9878 37.0292 68.9878 40.2503C68.9878 40.7707 69.0779 41.2653 69.2067 41.746C68.4551 40.8395 67.3324 40.2503 66.061 40.2503C63.9759 40.2503 62.2748 41.8078 62.0161 43.8145C61.8042 43.7888 61.597 43.7503 61.378 43.7503C58.4688 43.7503 56.1097 46.1012 56.1097 49.0003C56.1097 51.8995 58.4688 54.2503 61.378 54.2503C64.2873 54.2503 72.5 54.2503 72.5 54.2503H79.5244Z" fill="white"/>
  <path d="M74.8415 33.833C71.2907 33.833 68.4025 36.7112 68.4025 40.2497C68.4025 40.2683 68.4025 40.2858 68.4025 40.3045C67.6977 39.8903 66.8922 39.6663 66.061 39.6663C63.9174 39.6663 62.0676 41.1375 61.5443 43.1722C61.4893 43.1687 61.4343 43.1663 61.3781 43.1663C58.1504 43.1663 55.5244 45.7832 55.5244 48.9997C55.5244 52.2162 58.1504 54.833 61.3781 54.833H79.5244C79.8475 54.833 80.1098 54.5717 80.1098 54.2497C80.1098 53.9277 79.8475 53.6663 79.5244 53.6663H61.3781C58.7954 53.6663 56.6951 51.5733 56.6951 48.9997C56.6951 46.426 58.7954 44.333 61.3781 44.333C61.515 44.333 61.6473 44.3528 61.7796 44.3703L61.9447 44.3925C61.9693 44.396 61.9927 44.3972 62.0173 44.3972C62.3053 44.3972 62.5582 44.1813 62.5968 43.8873C62.8204 42.1455 64.3096 40.833 66.061 40.833C67.0971 40.833 68.0782 41.3008 68.7549 42.1163C68.8696 42.254 69.037 42.3287 69.2068 42.3287C69.2981 42.3287 69.3894 42.3077 69.4737 42.2633C69.7184 42.1385 69.8413 41.8597 69.771 41.5948C69.6364 41.0873 69.5732 40.6603 69.5732 40.2497C69.5732 37.3552 71.9369 34.9997 74.8415 34.9997C77.629 34.9997 79.9307 37.1837 80.0793 39.9732C80.0922 40.2158 80.2549 40.4247 80.4879 40.4982C80.5465 40.5168 80.6062 40.5262 80.6659 40.5262C80.8415 40.5262 81.0124 40.4457 81.1248 40.3022C82.0274 39.1565 83.3632 38.4997 84.7927 38.4997C87.3753 38.4997 89.4756 40.5927 89.4756 43.1663C89.4756 43.3028 89.4557 43.4347 89.4382 43.5665L89.4159 43.731C89.3972 43.8873 89.4417 44.0437 89.54 44.1662C89.6384 44.2887 89.7824 44.3668 89.9381 44.382C92.3451 44.6212 94.1586 46.6068 94.1586 48.9997C94.1586 51.5733 92.0583 53.6663 89.4756 53.6663H73.6708C73.3476 53.6663 73.0854 53.9277 73.0854 54.2497C73.0854 54.5717 73.3476 54.833 73.6708 54.833H89.4756C92.7033 54.833 95.3293 52.2162 95.3293 48.9997C95.3293 46.2032 93.3519 43.8512 90.6429 43.3098C90.6452 43.262 90.6464 43.2142 90.6464 43.1663C90.6464 39.9498 88.0204 37.333 84.7927 37.333C83.4159 37.333 82.1082 37.8183 81.0663 38.691C80.3662 35.8863 77.8327 33.833 74.8415 33.833Z" fill="black"/>
  <path d="M73.0851 42C71.4426 42 70.0307 43.1655 69.6748 44.7312C69.2451 44.4722 68.7546 44.3333 68.2559 44.3333C66.8486 44.3333 65.6826 45.3798 65.5011 46.746C65.2635 46.6923 65.0375 46.6667 64.8162 46.6667C63.0262 46.6667 61.5253 48.0667 61.4 49.854C61.3895 50.015 61.5113 50.155 61.6717 50.1667C61.6787 50.1667 61.6857 50.1667 61.6927 50.1667C61.8449 50.1667 61.9737 50.0488 61.9842 49.896C62.0884 48.4132 63.3318 47.25 64.8162 47.25C65.0843 47.25 65.3665 47.299 65.6802 47.3982C65.706 47.4063 65.7329 47.4098 65.7587 47.4098C65.8231 47.4098 65.8875 47.3865 65.9413 47.3468C66.0162 47.2932 66.0607 47.1975 66.0607 47.1042C66.0607 45.8978 67.0453 44.9167 68.2559 44.9167C68.7792 44.9167 69.292 45.1127 69.697 45.4685C69.7521 45.5163 69.8211 45.542 69.8902 45.542C69.9277 45.542 69.9639 45.535 69.9991 45.5198C70.1021 45.479 70.1723 45.3833 70.1805 45.2725C70.3023 43.764 71.5772 42.5833 73.0851 42.5833C73.3286 42.5833 73.5827 42.623 73.8836 42.7082C73.9105 42.7163 73.9386 42.7198 73.9655 42.7198C74.0931 42.7198 74.2079 42.6347 74.2453 42.5075C74.2898 42.3523 74.1997 42.1913 74.044 42.147C73.6904 42.0467 73.3848 42 73.0851 42ZM89.3384 43.75C87.786 43.75 86.4022 44.7965 85.9737 46.2957C85.9292 46.4508 86.0194 46.6118 86.1751 46.6562C86.2032 46.6632 86.2301 46.6667 86.2559 46.6667C86.3835 46.6667 86.5005 46.5827 86.5368 46.4543C86.8939 45.206 88.0459 44.3333 89.3384 44.3333C89.4754 44.3333 89.6077 44.3497 89.7388 44.3672C89.7528 44.3695 89.7657 44.3707 89.7798 44.3707C89.9214 44.3707 90.0479 44.2633 90.0678 44.1175C90.09 43.9577 89.9776 43.8107 89.8172 43.7897C89.6603 43.7687 89.5011 43.75 89.3384 43.75Z" fill="black"/>
  <path d="M50.8417 14.874C43.6226 14.874 36.6993 17.7318 31.5946 22.8188C26.49 27.9057 23.6222 34.805 23.6222 41.999C23.6222 49.193 26.49 56.0924 31.5946 61.1793C36.6993 66.2662 43.6226 69.124 50.8417 69.124C58.0608 69.124 64.9842 66.2662 70.0888 61.1793C75.1935 56.0924 78.0612 49.193 78.0612 41.999C78.0612 34.805 75.1935 27.9057 70.0888 22.8188C64.9842 17.7318 58.0608 14.874 50.8417 14.874Z" fill="#ABFF57"/>
  <path d="M50.8418 69.9409C35.3811 69.9409 22.8027 57.4063 22.8027 41.9993C22.8027 26.5923 35.3811 14.0576 50.8418 14.0576C66.3024 14.0576 78.8808 26.5923 78.8808 41.9993C78.8808 57.4063 66.3024 69.9409 50.8418 69.9409ZM50.8418 15.691C36.2837 15.691 24.4418 27.493 24.4418 41.9993C24.4418 56.5056 36.2837 68.3076 50.8418 68.3076C65.3998 68.3076 77.2418 56.5056 77.2418 41.9993C77.2418 27.493 65.3998 15.691 50.8418 15.691Z" fill="black"/>
  <path d="M32.6951 69.4167C32.6951 69.4167 34.5297 69.4167 36.7927 69.4167C39.0557 69.4167 40.8902 67.5885 40.8902 65.3333C40.8902 63.2555 39.3273 61.5603 37.3137 61.3025C37.3464 61.0937 37.378 60.8848 37.378 60.6667C37.378 58.4115 35.5435 56.5833 33.2805 56.5833C32.0723 56.5833 30.9976 57.1142 30.2483 57.9425C29.7987 55.8332 27.922 54.25 25.6707 54.25C23.0846 54.25 20.9878 56.3395 20.9878 58.9167C20.9878 59.1395 21.0229 59.353 21.0534 59.5677C20.5523 59.1663 19.9248 58.9167 19.2317 58.9167C17.794 58.9167 16.6034 59.9515 16.3576 61.3142C16.148 61.2815 15.9384 61.25 15.7195 61.25C13.4565 61.25 11.6219 63.0782 11.6219 65.3333C11.6219 67.5885 13.4565 69.4167 15.7195 69.4167C17.9825 69.4167 24.5 69.4167 24.5 69.4167V70H32.6951V69.4167Z" fill="white"/>
  <path d="M34.7439 64.1674C34.5823 64.1674 34.4512 64.0368 34.4512 63.8758C34.4512 62.4489 35.616 61.2881 37.0479 61.2881C37.0877 61.2986 37.9107 61.2869 38.5042 61.4468C38.66 61.4888 38.7524 61.6486 38.7103 61.8038C38.6681 61.9601 38.5078 62.0523 38.3521 62.0091C37.8322 61.8691 37.0642 61.8691 37.049 61.8714C35.9392 61.8714 35.0365 62.7709 35.0365 63.8758C35.0365 64.0368 34.9054 64.1674 34.7439 64.1674Z" fill="black"/>
  <path d="M36.7927 70.0003H32.6952C32.372 70.0003 32.1098 69.739 32.1098 69.417C32.1098 69.095 32.372 68.8337 32.6952 68.8337H36.7927C38.7291 68.8337 40.3049 67.2633 40.3049 65.3337C40.3049 63.5883 38.9878 62.1043 37.2399 61.8815C37.0831 61.8605 36.9414 61.7788 36.8466 61.6528C36.7517 61.5268 36.7119 61.3682 36.7365 61.213C36.7634 61.0333 36.7927 60.8537 36.7927 60.667C36.7927 58.7373 35.2169 57.167 33.2805 57.167C32.2854 57.167 31.3629 57.5812 30.6827 58.3337C30.5363 58.4958 30.3069 58.5647 30.0973 58.5063C29.8842 58.4492 29.7215 58.2788 29.6758 58.0642C29.2766 56.1928 27.5919 54.8337 25.6708 54.8337C23.4113 54.8337 21.5732 56.6653 21.5732 58.917C21.5732 59.0838 21.5978 59.2437 21.6212 59.4047C21.6552 59.6415 21.5475 59.9157 21.3379 60.0347C21.1283 60.1537 20.8743 60.1723 20.687 60.023C20.2596 59.6812 19.7574 59.5003 19.2317 59.5003C18.1008 59.5003 17.1338 60.3065 16.9336 61.4183C16.8774 61.7298 16.5871 61.9387 16.2675 61.8908C16.0872 61.8628 15.9069 61.8337 15.7195 61.8337C13.7832 61.8337 12.2074 63.404 12.2074 65.3337C12.2074 67.2633 13.7832 68.8337 15.7195 68.8337H33.8659C34.189 68.8337 34.4513 69.095 34.4513 69.417C34.4513 69.739 34.189 70.0003 33.8659 70.0003H15.7195C13.1369 70.0003 11.0366 67.9073 11.0366 65.3337C11.0366 62.76 13.1369 60.667 15.7195 60.667C15.7886 60.667 15.8554 60.6693 15.9233 60.674C16.4079 59.2903 17.7227 58.3337 19.2317 58.3337C19.6403 58.3337 20.0384 58.4048 20.4154 58.5437C20.6085 55.8218 22.8914 53.667 25.6708 53.667C27.8144 53.667 29.7285 54.9818 30.5328 56.9033C31.3289 56.3223 32.2913 56.0003 33.2805 56.0003C35.8632 56.0003 37.9634 58.0933 37.9634 60.667C37.9634 60.723 37.9623 60.7778 37.9588 60.8327C39.9993 61.3553 41.4756 63.1975 41.4756 65.3337C41.4756 67.9073 39.3754 70.0003 36.7927 70.0003Z" fill="black"/>
  <path d="M61.186 40.2867H52.6315V31.0198C52.6315 29.9955 51.7991 29.166 50.7712 29.166C49.7433 29.166 48.9109 29.9955 48.9109 31.0198V40.2867H40.3564C39.3285 40.2867 38.4961 41.1162 38.4961 42.1405C38.4961 43.1648 39.3285 43.9943 40.3564 43.9943H48.9109V53.2612C48.9109 54.2855 49.7433 55.115 50.7712 55.115C51.7991 55.115 52.6315 54.2855 52.6315 53.2612V43.9943H61.186C62.2139 43.9943 63.0463 43.1648 63.0463 42.1405C63.0463 41.1162 62.2128 40.2867 61.186 40.2867Z" fill="white"/>
  <path d="M50.7711 55.6971C49.4236 55.6971 48.3255 54.604 48.3255 53.2611V44.5776H40.3551C39.0076 44.5776 37.9106 43.4845 37.9106 42.1405C37.9106 40.7976 39.0076 39.7045 40.3551 39.7045H48.3255V31.021C48.3255 29.6781 49.4236 28.585 50.7711 28.585C52.1186 28.585 53.2156 29.6781 53.2156 31.021V39.7045H61.186C62.5335 39.7045 63.6316 40.7976 63.6316 42.1405C63.6316 43.4845 62.5335 44.5776 61.186 44.5776H53.2156V53.2611C53.2156 54.604 52.1198 55.6971 50.7711 55.6971ZM40.3563 40.87C39.6539 40.87 39.0825 41.4393 39.0825 42.1393C39.0825 42.8405 39.6539 43.4098 40.3563 43.4098H49.4974V53.26C49.4974 53.96 50.0687 54.5293 50.7723 54.5293C51.4747 54.5293 52.0461 53.96 52.0461 53.26V43.4098H61.1871C61.8907 43.4098 62.4621 42.8405 62.4621 42.1393C62.4621 41.4393 61.8907 40.87 61.1871 40.87H52.0461V31.0198C52.0461 30.3198 51.4747 29.7505 50.7723 29.7505C50.0687 29.7505 49.4974 30.3198 49.4974 31.0198V40.87H40.3563Z" fill="black"/>
  </svg>`;
};
export default UploadModelDrag;
