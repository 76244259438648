export const firstCharToUpper = (value: string) => {
  return `${value.charAt(0).toUpperCase()}${value.slice(1)}`;
};
export const utils = {
  special: {},
  navigational: {},
  isSpecial(e: any) {
    //@ts-ignore
    return typeof this.special[e.keyCode] !== 'undefined';
  },
  isNavigational(e: any) {
    //@ts-ignore
    return typeof this.navigational[e.keyCode] !== 'undefined';
  },
};
export const keys = {
  backspace: 8,
  shift: 16,
  ctrl: 17,
  alt: 18,
  delete: 46,
  leftArrow: 37,
  upArrow: 38,
  rightArrow: 39,
  downArrow: 40,
};
