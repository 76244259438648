const Trash = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M16 0H0V16H16V0Z" fill="white" fillOpacity="0.01" />
    <path
      d="M3 3.33301V14.6663H13V3.33301H3Z"
      stroke="#FF0000"
      strokeWidth="1.33333"
      strokeLinejoin="round"
    />
    <path
      d="M6.66675 6.66699V11.0003"
      stroke="#FF0000"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.33325 6.66699V11.0003"
      stroke="#FF0000"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.33325 3.33301H14.6666"
      stroke="#FF0000"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.33325 3.33301L6.42959 1.33301H9.59229L10.6666 3.33301H5.33325Z"
      stroke="#FF0000"
      strokeWidth="1.33333"
      strokeLinejoin="round"
    />
  </svg>
);

export default Trash;
