import { useState } from 'react';
import Profile from './Profile';
import User from '../../assets/icons/Profile.svg';
import BillingCard from '../../assets/icons/BillingCard.svg';

import Logout from '../../assets/icons/Logout.svg';
import { useAuth } from '@/hooks/useAuth';
import Billing from './Billing';

const SettingContent = () => {
  const { signOut } = useAuth();
  const [currentTab, setCurrentTab] = useState(0);
  const listItems = [
    { title: 'Profile', img: User },
    // { title: 'Billing', img: BillingCard },
    // { title: 'Notifications', img: Notification },
    // { title: 'Slack Integration', img: Slack },
  ];

  return (
    <div className="flex setting-content py-0 my-0">
      <div className="border-right flex settings-sidebar">
        <ul
          className="my-4 text-sm cursor-pointer flex flex-col justify-between"
          style={{ minWidth: '192px' }}
        >
          <div>
            {listItems.map((el: any, ind: number) => {
              return (
                <li
                  key={ind}
                  onClick={() => {
                    setCurrentTab(ind);
                  }}
                  className={`${
                    ind == currentTab && 'bg-green'
                  } py-3 px-6 flex`}
                >
                  <img src={el.img} alt={el.title} />
                  &nbsp;&nbsp;{el.title}
                </li>
              );
            })}
          </div>
          <div className="">
            <li
              className="py-3 px-6 flex text-red-600"
              onClick={() => signOut()}
            >
              <img src={Logout} alt={'logout-icons'} />
              &nbsp;&nbsp;Log Out
            </li>
          </div>
        </ul>
      </div>
      <hr className="border h-full" />
      {currentTab == 0 && <Profile />}
      {currentTab == 1 && <Billing />}
      {/* {currentTab == 1 && <Notifications />} */}
      {/* {currentTab == 1 && <SlackIntegration />} */}
    </div>
  );
};

export default SettingContent;
