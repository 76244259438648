import { FC, useState, useEffect, MouseEvent } from 'react';
import cn from 'classnames';

import Circle from '@uiw/react-color-circle';

import { useToolObject } from '@/hooks/tool/useToolObject';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { toolObjectListState } from '@/state/toolState';
import { currentCaptureInfoState } from '@/state/captureState';

const ToolObjectColorPicker: FC<{
  isShow: boolean;
  color?: string;
  type: Tool.ObjectType;
  currentRotation: string;
  setChangeToolColor: (color: string) => void;
  onMouseEnter?: (e: MouseEvent<HTMLDivElement>) => void;
}> = ({
  isShow,
  color,
  setChangeToolColor,
  onMouseEnter,
  type,
  currentRotation,
}) => {
  const { updateColorOrText } = useToolObject();

  const [open, isOpen] = useState(false);

  const currentCaptureInfo = useRecoilValue(currentCaptureInfoState);

  const setToolList = useSetRecoilState(toolObjectListState);

  useEffect(() => {
    return () => {
      isOpen(false);
    };
  }, []);

  useEffect(() => {
    if (!isShow) {
      isOpen(false);
    }
  }, [isShow]);

  const handleUpdateColor = async (color: string) => {
    if (currentCaptureInfo?.tools && currentCaptureInfo.tools.length) {
      setChangeToolColor(color);
      await updateColorOrText('color', color);

      setToolList(() => []);
    }
  };

  const rotationAngle = Number(
    currentRotation.split('rotate(')[1].slice(0, -4)
  );
  return (
    <>
      <div
        className={cn(
          isShow ? 'visible' : 'invisible',
          'w-6 h-6 rounded-full border-2 border-white absolute cursor-pointer bottom-0 hover:cursor-pointer hover:border-gray-500',
          type === 'ARROW' && 'top-2',
          type === 'DRAW' && 'top-0'
        )}
        style={{ backgroundColor: color, right: type === 'DRAW' ? -32 : -25 }}
        onMouseEnter={(e: MouseEvent<HTMLDivElement>) => {
          onMouseEnter && onMouseEnter(e);
        }}
        onClick={() => {
          isOpen((old) => !old);
        }}
      />
      <div
        tabIndex={-1}
        style={{
          ...(type === 'ARROW' && {
            transform: `rotate(-${rotationAngle}deg)`,
          }),
        }}
        className={cn(
          'pt-3 max-w-56 min-w-56 float-right -mr-10 outline-none',
          type === 'ARROW' &&
            `absolute top-0 -right-${
              rotationAngle > 285 || rotationAngle < 21 ? '52' : '40'
            }`,
          type === 'DRAW' && 'absolute top-6 -right-48'
        )}
      >
        {open && (
          <Circle
            colors={[
              '#EE53A2',
              '#ABFF57',
              '#FF4B33',
              '#54FFEA',
              '#FBFF1F',
              '#45BCFF',
              '#9B51E0',
              '#ffb100',
              '#6d6d6d',
              '#000000',
            ]}
            color={color}
            onChange={(color) => handleUpdateColor(color.hex)}
          />
        )}
      </div>
    </>
  );
};

export default ToolObjectColorPicker;
