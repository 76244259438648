import React, { ReactNode } from "react";

const BasicFrame: React.FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <div className="relative">
      <div
        className=" shadow-md rounded-md pointer-events-none"
        style={{
          border: '20px solid #ffffffc7',
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default BasicFrame;
