import FileUpload from '../FileUpload';
import { useUpload } from '@/hooks/useUpload';
import type { ProcessServerConfigFunction } from 'filepond';
import { ImgSize } from '../capture/types';
import { useState } from 'react';
import DashFileUpload from './DashFileUpload';
import { withSignInModal } from '../hoc/withSignInModal';
import { useRecoilValue } from 'recoil';
import { isAnonymousState } from '@/state/userState';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

type FileUploadPopupT = {
  openSignInModal?: () => void;
  maxFileSize?: string;
};

const FileUploadPopup = ({ openSignInModal,
  maxFileSize,
}: FileUploadPopupT) => {
  const [isDragging, setIsDragging] = useState(false);
  const { uploadImage, uploadPDF } = useUpload();
  const url = window.URL || window.webkitURL;
  const isAnonymous = useRecoilValue(isAnonymousState);
  const [progress, setProgress] = useState<Number | null>(null);

  const handleProcess: ProcessServerConfigFunction = async (
    _fieldName,
    file,
    _metadata,
    load,
    error,
    progress,
    _abort
  ) => {
    if (isAnonymous) {
      openSignInModal && openSignInModal();
      return;
    }

    if (file.type === 'application/pdf') {
      try {
        await uploadPDF(file, file.name, (path: string) => {
          // navigate(path);
          load('done');
          setProgress(null);
        }, (progress: number) => {
          setProgress(progress);
        });
      } catch (err) {
        error('UPLOAD IS FAILED');
        console.log(err);
      }
    } else if (file.type.match(/^image/)) {
      try {
        const img = new Image();
        img.onload = async function () {
          const imgSize: ImgSize = {
            width: img.width,
            height: img.height,
          };
          await uploadImage(
            file,
            file.name,
            imgSize,
            progress,
            (path: string) => {
              // navigate(path);
              load('done');
            }
          );
        };
        img.src = url.createObjectURL(file);
      } catch (err) {
        error('UPLOAD IS FAILED');
        console.log(err);
      }
    } else {
      error('This file type is not allowed here.');
    }
  };

  return (
    <div className="m-auto w-full h-full items-center rounded-xl text-sm font-bold">
      <div
        onDragOver={(event) => {
          event.preventDefault();
          event.stopPropagation();
          document
            .getElementsByClassName('filepond--drop-label')[0]
            .classList.add('update-border');
          setIsDragging(true);
        }}
        onDragLeave={(event) => {
          event.preventDefault();
          event.stopPropagation();
          document
            .getElementsByClassName('filepond--drop-label')[0]
            .classList.remove('update-border');
        }}
        onDrop={(event) => {
          event.preventDefault();
          event.stopPropagation();
          document
            .getElementsByClassName('filepond--drop-label')[0]
            .classList.remove('update-border');
          setIsDragging(false);
        }}
      >
        <div
          className={`inline-block w-full transition-all transform rounded-xl bg-transparent  sm:mt-6 `}
        >
          <div className="flex">
            <div className="w-full">
              <div className="file-upload-div" tabIndex={0}>
                <div className="dashboard-fileupload-dropdown h-72">
                  <div className="container-upload mx-auto">
                    <DashFileUpload
                      process={handleProcess}
                      isDragging={isDragging}
                      {...(maxFileSize && { maxFileSize: maxFileSize })}
                    />
                    {progress &&
                      <div className='absolute w-full flex flex-col justify-center items-center mt-8'>
                        <div className='w-24 h-24'>
                          <CircularProgressbar
                            value={Number(progress ?? 0)}
                            text={`${progress}%`}
                            background
                            backgroundPadding={6}
                            styles={buildStyles({
                              backgroundColor: "#abff57",
                              textColor: "#ff4bad",
                              pathColor: "#ff4bad",
                              trailColor: "transparent"
                            })}
                          />
                        </div>
                        {progress == 100 && <div>{"Larger files can take up to a minute to load or longer"}</div>}
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withSignInModal(FileUploadPopup);
