import {
  MouseEvent,
  FC,
  useEffect,
  createRef,
  useState,
  RefObject,
} from 'react';
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil';
import { rectInfoToStyle } from '@/lib/rect';
import cn from 'classnames';
import _ from 'lodash';

import DragResizeRotate from './DragResizeRotate';
import ToolObjectDraw from './ToolObjectDraw';
import ToolObjectCircle from './ToolObjectCircle';
import ToolObjectText from './ToolObjectText';
import ToolObjectArrow from './ToolObjectArrow';
import ToolObjectColorPicker from './ToolObjectColorPicker';
import CanvasBlur from '@/components/tool/CanvasBlur';

import { useToolObject } from '@/hooks/tool/useToolObject';
import { useCapture } from '@/hooks/tool/useCapture';

import {
  currentToolObjectIndexState,
  currentToolObjectState,
  currentToolState,
  isCurrentScaleState,
  toolObjectListOrderState,
} from '@/state/toolState';
import { toolObjectListState } from '@/state/toolState';
import { currentCaptureInfoState } from '@/state/captureState';
import { isShowAddCommentState } from '@/state/commentState';

import MinusFont from '../../assets/icons/tool/minusFont.png';
import PlusFont from '../../assets/icons/tool/plusFont.png';
import { useClickOutside } from '@/hooks/useClickOutside';

type ImgEl = RefObject<HTMLImageElement>;

const ToolObjectCommon: FC<
  Tool.Object & {
    className: string;
    index: number;
    isImageLoaded: boolean;
    imgEl?: ImgEl;
    isEditingAccess?: boolean;
    zoomValue: number;
  }
> = ({
  className,
  index,
  type,
  stroke,
  color,
  text,
  id,
  isImageLoaded,
  imgEl,
  isEditingAccess,
  zoomValue,
  ...propsRect
}) => {
  const ref = createRef<HTMLDivElement>();
  const { setToolState } = useToolObject();
  const [isFocus, setIsFocus] = useState(false);
  const [rect, setRect] = useState(propsRect);
  const [changeToolColor, setChangeToolColor] = useState(color);
  const { updateRectOfToolObject, selectToolObject } = useToolObject();
  const { setCaptureTools } = useCapture();
  const currentToolObjectIndex = useRecoilValue(currentToolObjectIndexState);
  const toolObjectListOrder = useRecoilValue(toolObjectListOrderState);
  const [toolList, setToolList] = useRecoilState(toolObjectListState);
  const currentTool = useRecoilValue(currentToolState);

  const [textUpdate, setTextUpdate] = useState(text);
  const [currentObjectTool, setCurrentObjectTool] = useRecoilState(
    currentToolObjectState
  );
  const currentScale = useRecoilValue(isCurrentScaleState);

  const setIsShowAddComment = useSetRecoilState(isShowAddCommentState);
  const currentCaptureInfo = useRecoilValue(currentCaptureInfoState);
  const [currentFont, setCurrentFont] = useState<number>(propsRect.fontSize);
  const [currentStrokeWidth, setCurrentStrokeWidth] = useState<number>(
    stroke?.strokeWidth ?? 0
  );

  const { deleteCaptureTool } = useCapture();

  useClickOutside(ref, async () => {
    console.log('Click outside of bounding box');
    await setToolState();
  });

  useEffect(() => {
    setRect((prev) => {
      return { ...prev, ...propsRect };
    });
  }, [currentScale]);

  useEffect(() => {
    if (!ref) return;
    if (index === currentToolObjectIndex) {
      setIsFocus(true);
    } else {
      setIsFocus(false);
    }
  }, [currentToolObjectIndex, ref]);

  const handleIncrementFont = () => {
    if (currentFont === 12) {
      setCurrentFont((prevState) => prevState);
    } else {
      setCurrentFont((prevState) => prevState - 1);
    }
  };

  const handleDecrementFont = () => {
    if (currentFont === 60) {
      setCurrentFont((prevState) => prevState);
    } else {
      setCurrentFont((prevState) => prevState + 1);
    }
  };

  const handleIncrementStrokeWidth = () => {
    if (currentStrokeWidth === 1) {
      setCurrentStrokeWidth((prevState) => prevState);
    } else {
      setCurrentStrokeWidth((prevState) => prevState - 1);
    }
  };

  const handleDecrementStrokeWidth = () => {
    if (currentStrokeWidth === 20) {
      setCurrentStrokeWidth((prevState) => prevState);
    } else {
      setCurrentStrokeWidth((prevState) => prevState + 1);
    }
  };

  const getText = (text: string) => {
    setTextUpdate(text);
  };

  const handleClick = async (e: MouseEvent<HTMLDivElement>) => {
    console.log('Tool click');

    if (currentTool === 'MOVE') {
      e.stopPropagation();
    }
    !isFocus && selectToolObject(index);
    await setSaveTool();
  };

  const handleMouseDown = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (currentTool === 'MOVE') {
      !isFocus && selectToolObject(index);
    }

    // handleMouseEnter(id);
  };

  const handleMouseEnter = (id: string) => {
    setIsShowAddComment(false);

    if (currentCaptureInfo?.tools && currentCaptureInfo.tools.length) {
      const obj = currentCaptureInfo.tools.find(
        (tool: Tool.Object) => tool.id === id
      );

      if (obj) {
        setCurrentObjectTool(obj);
      }
    }
  };

  const setSaveTool = async () => {
    if (currentObjectTool) {
      const scaledRect = {
        width: Math.round(rect.width),
        height: Math.round(rect.height),
        top: Math.round(rect.top),
        left: Math.round(rect.left),
      };

      const obj = {
        tool: {
          ...currentObjectTool,
          text: textUpdate,
          fontSize: currentFont,
          ...scaledRect,
        },
      };

      const stroke = currentObjectTool.stroke
        ? {
            ...currentObjectTool.stroke,
            strokeWidth: currentStrokeWidth,
          }
        : undefined;

      if (stroke) {
        obj.stroke = stroke;
      }

      if (
        obj.tool.type !== 'TEXT' ||
        (obj.tool.type === 'TEXT' && obj.tool.text?.length !== 0)
      ) {
        await setCaptureTools(obj);
        setToolList(() => []);
      }
    }
    // await setToolState();
    setIsShowAddComment(true);
  };

  const handleUpdate = (updatedRect: Rect.Info) => {
    setRect((old) => ({
      ...old,
      ...updatedRect,
    }));

    if (!toolList) {
      setTimeout(() => {
        setToolList(() => []);
      }, 5);
    }
  };

  const handleDone = async () => {
    handleMouseEnter(id)
    !_.isEqual(propsRect, rect) &&
      (await updateRectOfToolObject({
        ...rect,
      }));

    setToolList(() => []);
  };

  const handleDeleteTool = async () => {
    if (!isFocus) return;

    const removeToolState = currentCaptureInfo?.tools.find(
      (tool: Tool.Object) => tool.id === id
    );

    if (removeToolState) {
      await deleteCaptureTool({ tool: id });
      selectToolObject(-1);
    }
  };

  const getObject = (type: Tool.ObjectType) => {
    switch (type) {
      case 'TEXT':
        return (
          // @ts-ignore
          <ToolObjectText
            color={changeToolColor}
            getText={getText}
            text={textUpdate}
            rect={rect}
            id={id}
            currentFont={currentFont}
            onRectUpdate={handleUpdate}
          />
        );
      case 'ARROW':
        // @ts-ignore
        return <ToolObjectArrow color={changeToolColor} />;
      case 'CIRCLE':
        // @ts-ignore
        return (
          <ToolObjectCircle
            color={changeToolColor ?? ''}
            isActive={currentToolObjectIndex === index}
          />
        );
      case 'DRAW':
        return (
          <ToolObjectDraw
            style={{
              width: '100%',
              height: '100%',
            }}
            width={Math.round(rect.width)}
            height={Math.round(rect.height)}
            stroke={{ ...stroke, strokeWidth: currentStrokeWidth }}
            color={changeToolColor}
            active={currentToolObjectIndex === index}
          />
        );
      case 'BLUR':
        return null;
      default:
        return null;
    }
  };

  const zIndex = 20 + _.indexOf(toolObjectListOrder, index);

  let style = { ...rectInfoToStyle(rect, imgEl, currentCaptureInfo), zIndex };

  useEffect(() => {
    style = { ...rectInfoToStyle(rect, imgEl, currentCaptureInfo), zIndex }
  }, [currentObjectTool])

  return (
    <>
      <DragResizeRotate
        key={id}
        id={id}
        ref={ref}
        className={cn(
          className,
          'tool-elem box-content absolute overflow-visible outline-none',
          'border border-solid',
          {
            active: currentToolObjectIndex === index,
            'border-transparent': currentToolObjectIndex !== index,
            'cursor-move': currentTool === 'MOVE',
            [zIndex]: type !== 'BLUR',
            'z-30': type === 'BLUR',
            arrow: type === 'ARROW' || type === 'DRAW' || type === 'CIRCLE',
          }
        )}
        style={style}
        rect={rect}
        isResizable={type !== 'DRAW' && isFocus && isEditingAccess}
        isRotatable={
          type !== 'DRAW' && type !== 'CIRCLE' && isFocus && isEditingAccess
        }
        isDraggable={currentTool === 'MOVE' && isEditingAccess}
        isEditAble={isEditingAccess}
        onClick={handleClick}
        onMouseDown={handleMouseDown}
        onUpdate={handleUpdate}
        onDone={handleDone}
        onMouseLeave={setSaveTool}
        onMouseEnter={() => {
          handleMouseEnter(id);
          if (ref.current) ref.current.style.cursor = 'default';
        }}
        onDelete={handleDeleteTool}
        type={type}
        zoomValue={zoomValue}
      >
        {getObject(type)}
        {type !== 'BLUR' && isEditingAccess && (
          <ToolObjectColorPicker
            onMouseEnter={() => handleMouseEnter(id)}
            setChangeToolColor={setChangeToolColor}
            isShow={currentTool === 'MOVE' && isFocus}
            color={changeToolColor}
            type={type}
            currentRotation={style.transform}
          />
        )}
        {(type === 'TEXT' || type === 'DRAW') && isEditingAccess && (
          <div className="font-size-text absolute top-0 -left-10 invisible">
            <img
              src={PlusFont}
              className="cursor-pointer rounded-3xl bg-white mb-1 shadow-lg hover:shadow-xl"
              alt="PlusFont"
              onClick={
                type === 'TEXT'
                  ? handleDecrementFont
                  : handleDecrementStrokeWidth
              }
              width="25"
              height="25"
            />
            <img
              src={MinusFont}
              className="cursor-pointer rounded-3xl bg-white mb-0.5 shadow-lg hover:shadow-xl"
              alt="MinusFont"
              onClick={
                type === 'TEXT'
                  ? handleIncrementFont
                  : handleIncrementStrokeWidth
              }
              width="25"
              height="25"
            />
            <span className="result-font-size inline-block mr-3 text-xs">{`${Math.round(
              type === 'TEXT' ? currentFont : currentStrokeWidth
            )} px`}</span>
          </div>
        )}
      </DragResizeRotate>

      {type === 'BLUR' && <CanvasBlur rect={rect} imgEl={imgEl} />}
    </>
  );
};

export default ToolObjectCommon;
