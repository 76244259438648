import closeIcon from '@/assets/icons/close-24.png';
import React, { FC } from 'react';
import Template from './Template';
import { Dialog } from '@headlessui/react';
import CircleClose from "@/components/icon/CircleClose";
import ShareInputViewDropdown from "@/components/header/ShareInputViewDropdown";
import SendBigButton from "@/components/icon/SendBigButton";
import UserAvatar from "@/components/UserAvatar";
import CopyIcon from "@/components/icon/CopyIcon";
import ArrowUpShare from "@/components/icon/ArrowUpShare";
import ArrowDownShare from "@/components/icon/ArrowDownShare";
import CopyLinkShareDropdown from "@/components/header/CopyLinkShareDropdown";
import LeftMove from "@/components/icon/leftMove";
import RightMove from "@/components/icon/rightMove";
import { useRecoilValue } from 'recoil';
import { currentUserInfoState } from '@/state/userState';

type Props = {
  isOpen: boolean;
  closeModal: () => void;
};

const Profile: FC<Props> = ({ isOpen, closeModal }) => {
  const data: any = useRecoilValue(currentUserInfoState)
  // console.log(data);

  return (
    <Template isOpen={isOpen} closeModal={closeModal}>
      <div className="profile-modal inline-block w-full max-w-4xl overflow-hidden text-left align-middle transition-all transform rounded-xl bg-white  sm:mt-6  ">
        <Dialog.Title
          as="h3"
          className="text-5xl font-bold sm:text-2xl text-black leading-8"
        >
          <div className="flex py-6 pl-8 pr-6">
            <div className="w-3/4">
              <h4 className="New-modal-heading">Profile owner</h4>
            </div>
            <div className="w-1/4 float-right">
              <div className="float-right">
                <div
                  className="flex items-center cursor-pointer"
                  onClick={closeModal}
                >
                  <CircleClose />
                </div>
              </div>
            </div>
          </div>
        </Dialog.Title>
        <div className="">
          <div className="share-content">
            <div className="flex mt-5">
              <svg width="122" height="122" viewBox="0 0 122 122" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 61C1 27.8629 27.8629 1 61 1V1C94.1371 1 121 27.8629 121 61V61C121 94.1371 94.1371 121 61 121V121C27.8629 121 1 94.1371 1 61V61Z" fill="#D06BFF" stroke="black" strokeWidth="2" />
                <path fillRule="evenodd" clipRule="evenodd" d="M70.5635 70.3941C70.2394 70.9016 69.9638 71.5151 69.7664 72.0855C69.6123 72.5307 69.1264 72.7667 68.6812 72.6126C68.236 72.4585 68 71.9726 68.1541 71.5274C68.3832 70.8655 68.7119 70.1236 69.1254 69.4759C69.5247 68.8505 70.0715 68.204 70.7788 67.8861C71.1505 67.7191 71.5718 67.6412 72.0194 67.716C72.4629 67.79 72.8697 68.0035 73.2352 68.3177C73.9431 68.9262 74.5742 69.9788 75.1593 71.5002C75.3284 71.9399 75.1091 72.4335 74.6693 72.6026C74.2296 72.7718 73.736 72.5524 73.5669 72.1127C73.0146 70.6767 72.5082 69.9426 72.123 69.6115C71.9421 69.456 71.8135 69.4114 71.7383 69.3988C71.6671 69.3869 71.5863 69.3938 71.4782 69.4424C71.2258 69.5558 70.9017 69.8643 70.5635 70.3941Z" fill="black" />
                <path fillRule="evenodd" clipRule="evenodd" d="M71.8693 68.6811C72.3392 68.7159 72.6918 69.125 72.657 69.5948C72.4272 72.6972 71.9913 74.9292 71.1194 76.6769C70.2307 78.4583 68.9295 79.6581 67.1399 80.7766C62.8283 83.4713 58.4696 83.9767 54.8925 83.3967C51.3622 82.8243 48.4914 81.1723 47.2273 79.4136C46.9523 79.031 47.0396 78.498 47.4222 78.223C47.8047 77.948 48.3378 78.0353 48.6128 78.4178C49.5288 79.6923 51.9186 81.1861 55.1656 81.7126C58.3658 82.2315 62.3008 81.789 66.2357 79.3297C67.8585 78.3155 68.889 77.3258 69.5927 75.9152C70.3132 74.471 70.7304 72.5086 70.9555 69.4688C70.9903 68.999 71.3995 68.6463 71.8693 68.6811Z" fill="black" />
                <path d="M53.8902 51.6165C53.8902 58.2125 48.543 63.5596 41.947 63.5596C35.351 63.5596 30.0039 58.2125 30.0039 51.6165C30.0039 45.0205 35.351 39.6733 41.947 39.6733C48.543 39.6733 53.8902 45.0205 53.8902 51.6165Z" fill="#FEFFFF" />
                <path fillRule="evenodd" clipRule="evenodd" d="M55.5959 51.6161C55.5959 59.1544 49.4849 65.2654 41.9467 65.2654C34.4084 65.2654 28.2974 59.1544 28.2974 51.6161C28.2974 44.0778 34.4084 37.9668 41.9467 37.9668C49.4849 37.9668 55.5959 44.0778 55.5959 51.6161ZM41.9467 63.5592C48.5427 63.5592 53.8898 58.2121 53.8898 51.6161C53.8898 45.0201 48.5427 39.673 41.9467 39.673C35.3506 39.673 30.0035 45.0201 30.0035 51.6161C30.0035 58.2121 35.3506 63.5592 41.9467 63.5592Z" fill="black" />
                <path d="M92.231 60.1043C92.4471 59.2057 92.5612 58.2682 92.5612 57.3035C92.5612 50.7077 87.2142 45.3604 80.6181 45.3604C74.0695 45.3604 68.7521 50.631 68.6758 57.1613C71.2833 54.1155 75.1566 52.185 79.4806 52.185C85.0712 52.185 89.9084 55.4118 92.231 60.1043Z" fill="#9026B3" />
                <path fillRule="evenodd" clipRule="evenodd" d="M92.4235 61.7999C91.7099 61.8801 91.0225 61.5048 90.704 60.8613C88.658 56.7278 84.3999 53.8913 79.4827 53.8913C75.6781 53.8913 72.2712 55.5877 69.9739 58.271C69.5071 58.8163 68.7489 59.0111 68.077 58.7583C67.4052 58.5055 66.9634 57.8592 66.9718 57.1415C67.0591 49.6779 73.1358 43.6543 80.6201 43.6543C88.1586 43.6543 94.2694 49.7655 94.2694 57.3036C94.2694 58.4034 94.1393 59.4747 93.892 60.5033C93.7241 61.2014 93.1371 61.7198 92.4235 61.7999ZM92.5633 57.3192C92.5633 57.314 92.5633 57.3088 92.5633 57.3036C92.5633 50.7078 87.2162 45.3605 80.6201 45.3605C75.0312 45.3605 70.3391 49.1995 69.0365 54.3842C68.8128 55.2745 68.689 56.2044 68.6778 57.1614C69.2518 56.491 69.8871 55.8746 70.575 55.3209C73.0124 53.3593 76.1105 52.1851 79.4827 52.1851C84.125 52.1851 88.2478 54.4101 90.8428 57.8517C91.3728 58.5547 91.8391 59.3085 92.2331 60.1044C92.448 59.2107 92.562 58.2784 92.5633 57.3192Z" fill="black" />
                <path d="M54.1128 52.7551C54.3392 51.8886 54.4588 50.9845 54.4588 50.0542C54.4588 43.6937 48.8569 38.5371 41.9465 38.5371C35.0858 38.5371 29.5151 43.6198 29.4351 49.9171C32.1669 46.98 36.2248 45.1183 40.7548 45.1183C46.6118 45.1183 51.6795 48.23 54.1128 52.7551Z" fill="#9026B3" />
                <path fillRule="evenodd" clipRule="evenodd" d="M30.5698 47.7203C33.3209 45.561 36.8797 44.2648 40.7559 44.2648C46.0387 44.2648 50.7378 46.6737 53.6007 50.3988C53.6047 50.2843 53.6067 50.1692 53.6067 50.0538C53.6067 44.2295 48.4547 39.3898 41.9475 39.3898C36.3341 39.3898 31.7284 42.9921 30.5698 47.7203ZM28.5831 49.9058C28.6698 43.0782 34.6859 37.6836 41.9475 37.6836C49.2612 37.6836 55.3129 43.1569 55.3129 50.0538C55.3129 51.0576 55.1838 52.0341 54.9393 52.9703C54.8506 53.3096 54.5633 53.5601 54.2151 53.6017C53.8669 53.6434 53.5286 53.4676 53.3625 53.1587C51.0845 48.9224 46.313 45.9709 40.7559 45.9709C36.4602 45.9709 32.6294 47.7359 30.0607 50.4976C29.8206 50.7559 29.4463 50.8397 29.1189 50.7086C28.7915 50.5775 28.5786 50.2584 28.5831 49.9058Z" fill="black" />
                <path d="M48.2019 54.744C48.2019 58.042 45.5284 60.7156 42.2304 60.7156C38.9323 60.7156 36.2588 58.042 36.2588 54.744C36.2588 51.446 38.9323 48.7725 42.2304 48.7725C45.5284 48.7725 48.2019 51.446 48.2019 54.744Z" fill="black" />
                <path d="M43.0839 52.7539C43.0839 54.0103 42.0654 55.0288 40.8091 55.0288C39.5527 55.0288 38.5342 54.0103 38.5342 52.7539C38.5342 51.4975 39.5527 50.479 40.8091 50.479C42.0654 50.479 43.0839 51.4975 43.0839 52.7539Z" fill="#FFFFFE" />
              </svg>

              <div className="flex-1 w-32 pl-6">
                <h4 className="profile-name-modal py-1 ml-2">{data.displayName}</h4>
                <h4 className="profile-name-modal py-1 ml-2">{data.email}</h4>
                <h6 className="profile-date-modal ml-2">last seen 11 minutes ago</h6>
                <h6 className="ml-2 flex twitter-link"><span><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M16 0H0V16H16V0Z" fill="white" fillOpacity="0.01" />
                  <path d="M1.66675 11.9209C2.3097 12.2764 6.96388 14.9593 10.2734 12.8915C13.5828 10.8237 13.4003 7.26126 13.4003 5.62883C13.7001 5.00076 14.3334 4.68146 14.3334 2.98143C13.7113 3.5561 13.093 3.75163 12.4784 3.56803C11.8763 2.65002 11.0479 2.24399 9.99321 2.34994C8.41118 2.50888 7.83238 4.061 8.00271 6.06906C5.56338 7.30263 3.65058 5.17483 2.66481 3.56803C2.33544 4.83346 2.01785 6.3527 2.66481 8.03333C3.09611 9.15373 4.13291 10.101 5.77521 10.875C4.11085 11.7771 2.74135 12.1257 1.66675 11.9209Z" fill="#FF38A4" stroke="#FF38A4" strokeWidth="1.33333" strokeLinejoin="round" />
                </svg>
                </span>bobby_bob</h6>
                <div className="flex">
                  <div className="flex-none w-32 h-14">
                    <h6><span className="mr-2">278</span>Captures</h6>
                  </div>
                  <div className="flex-initial w-64 ...">
                    <div className="ml-auto">
                      <h6>1648 <span className="mr-2">Comments</span></h6>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <hr />
            <div className="flex pt-5">
              <div className="flex-none w-64 h-14">
                Projects
              </div>
              <div className="flex-initial w-80 ...">

              </div>
              <div className="flex-initial w-32 user-arrows">
                <LeftMove /><RightMove />
              </div>
            </div>
            <div className="mb-3">
            </div>

          </div>
        </div>
      </div>
    </Template>
  );
};

export default Profile;
