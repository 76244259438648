type Props = {
  classname: string;
};

const ChatIcon = ({ classname }: Props) => {
  return (
    <svg
      width="20"
      height="18"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classname}
    >
      <path
        d="M18.3333 2.5H1.66663V15H5.41663V17.0833L9.58329 15H18.3333V2.5Z"
        strokeWidth="2.08333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ChatIcon;
