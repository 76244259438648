type Props = {
  classname: string;
};

const TextIcon = ({ classname }: Props) => {
  return (
    <svg
      className={'rounded-3xl hover:bg-green hover:shadow-icon ' + classname}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 4.46387V16.3686"
        strokeWidth="2.08333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.83331 4.46387H9.99998H14.1666"
        strokeWidth="2.08333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default TextIcon;
