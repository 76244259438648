import { SignInButton, SignedOut } from '@clerk/clerk-react';
import React, { useRef } from 'react';

export const withSignInModal = (WrappedComponent: React.ComponentType<any>) => {
  const WithSignInModal = (props: any) => {
    const signInButtonRef = useRef<HTMLDivElement>(null);

    const openSignInModal = () => {
      if (!signInButtonRef.current) return;

      const signInButton = signInButtonRef.current.querySelector(
        '.sign-in-ref button'
      );
      if (signInButton) {
        (signInButton as HTMLButtonElement).click();
      }
    };

    return (
      <>
        <div
          className="sign-in-ref"
          style={{ display: 'none' }}
          ref={signInButtonRef}
        >
          <SignedOut>
            <SignInButton mode="modal" redirectUrl="" />
          </SignedOut>
        </div>
        <WrappedComponent {...props} openSignInModal={openSignInModal} />
      </>
    );
  };

  return WithSignInModal;
};
