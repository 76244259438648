import React, {
  FC,
  memo,
  MutableRefObject,
  useEffect,
  useMemo,
  useState,
} from 'react';

import ClockIcon from '@/components/icon/ClockIcon';
import SmileIcon from '@/components/icon/SmileIcon';
import SunIcon from '@/components/icon/SunIcon';
import BoxIcon from '@/components/icon/BoxIcon';
import AppleIcon from '@/components/icon/AppleIcon';
import PlaneIcon from '@/components/icon/PlaneIcon';
import MuscleIcon from '@/components/icon/MuscleIcon';

interface EmojiHeaderProps {
  activeGroupNumber: number;
  groupsRefs: MutableRefObject<(HTMLDivElement | null)[]>;
}

const EmojiHeader: FC<EmojiHeaderProps> = ({
  activeGroupNumber,
  groupsRefs,
}) => {
  const [activeGroup, setActiveGroup] = useState<number>(activeGroupNumber);
  const [showGroupTooltip, setShowGroupTooltip] = useState<number>(-1);

  const activeColor = useMemo(() => '#e73c7e', []);
  const widnowWidth = useMemo(() => window.innerWidth, []);

  const iconSize = widnowWidth <= 595 ? 22 : 18;

  useEffect(() => {
    setActiveGroup(activeGroupNumber);
  }, [activeGroupNumber]);

  const iconsData = useMemo(
    () => [
      {
        groupName: 'Frequently Used',
        icon: (
          <ClockIcon
            strokeColor={activeGroup === 0 ? activeColor : undefined}
            width={iconSize}
            height={iconSize}
          />
        ),
      },
      {
        groupName: 'Smiles & People',
        icon: (
          <SmileIcon
            strokeColor={activeGroup === 1 ? activeColor : undefined}
            width={iconSize}
            height={iconSize}
          />
        ),
      },
      {
        groupName: 'Animals & Nature',
        icon: (
          <SunIcon
            strokeColor={activeGroup === 2 ? activeColor : undefined}
            width={iconSize}
            height={iconSize}
          />
        ),
      },
      {
        groupName: 'Food & Drink',
        icon: (
          <AppleIcon
            strokeColor={activeGroup === 3 ? activeColor : undefined}
            width={iconSize}
            height={iconSize}
          />
        ),
      },
      {
        groupName: 'Activities',
        icon: (
          <MuscleIcon
            strokeColor={activeGroup === 4 ? activeColor : undefined}
            width={iconSize}
            height={iconSize}
          />
        ),
      },
      {
        groupName: 'Travel & Places',
        icon: (
          <PlaneIcon
            strokeColor={activeGroup === 5 ? activeColor : undefined}
            width={iconSize}
            height={iconSize}
          />
        ),
      },
      {
        groupName: 'Objects',
        icon: (
          <BoxIcon
            strokeColor={activeGroup === 6 ? activeColor : undefined}
            width={iconSize}
            height={iconSize}
          />
        ),
      },
    ],
    [activeGroup, activeGroupNumber]
  );

  return (
    <div className="flex flex-row justify-between p-0">
      {iconsData.map((iconData, index) => {
        return (
          <div key={`icon-header ${index}`} className="relative">
            <button
              key={`emoji header icon ${index}`}
              className={activeGroup === index ? 'emoji-active' : ''}
              onClick={() => {
                groupsRefs.current[index]?.scrollIntoView();
                setActiveGroup(index);
              }}
              onMouseEnter={() => setShowGroupTooltip(index)}
              onMouseLeave={() => setShowGroupTooltip(-1)}
            >
              {iconData.icon}
            </button>

            <div
              className="tooltip-box top-7 border-2 border-white absolute whitespace-nowrap shadow-2xl font-normal tracking-wider text-white bg-black bg-opacity-70 px-2 py-1 text-xxxxs rounded-tl-md rounded-tr-2xl rounded-br-2xl rounded-bl-2xl"
              style={{ display: showGroupTooltip === index ? '' : 'none' }}
            >
              {iconData.groupName}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default memo(EmojiHeader);
