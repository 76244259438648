import React, {
  ForwardRefRenderFunction,
  forwardRef,
  useState,
  useEffect,
  MouseEvent,
} from 'react';
import cn from 'classnames';

import { useDrag } from '@/hooks/tool/useDrag';
import { useResize } from '@/hooks/tool/useResize';
import { useRotate } from '@/hooks/tool/useRotate';

type Props = {
  id?: string;
  children?: React.ReactNode;
  isDraggable?: boolean;
  isRotatable?: boolean;
  isResizable?: boolean;
  isdragByParent?: boolean;
  className?: string;
  onMouseDown?: (e: MouseEvent<HTMLDivElement>) => void;
  onClick?: (e: MouseEvent<HTMLDivElement>) => void;
  onUpdate?: (rect: Rect.Info) => void;
  onDone?: (e: MouseEvent<HTMLDivElement>) => void;
  onDelete?: () => void;
  rect?: Rect.Info;
  style?: unknown;
  onMouseLeave?: (e: MouseEvent<HTMLDivElement>) => void;
  onMouseEnter?: (e: MouseEvent<HTMLDivElement>) => void;
  isEditAble?: boolean; // For showing delete icon
  type?: Tool.ObjectType;
  zoomValue: number;
};

const DragResizeRotate: ForwardRefRenderFunction<HTMLDivElement, Props> = (
  {
    id,
    children,
    isDraggable = true,
    isRotatable = true,
    isdragByParent = false,
    className,
    isResizable = true,
    onClick,
    onMouseDown,
    onUpdate,
    onDone,
    rect: propsRect,
    style,
    onMouseLeave,
    onMouseEnter,
    onDelete,
    isEditAble,
    type,
    zoomValue,
  },
  ref
) => {
  const [angle, setAngle] = useState(propsRect?.rotate);
  const [rect, setRect] = useState(propsRect);
  const { position, onDrag } = useDrag();
  const { onRotate, resetRotate } = useRotate(setAngle);
  // @ts-ignore
  const { getResizer } = useResize(setRect, type);

  useEffect(() => {
    if (!position) return;

    // @ts-ignore
    setRect(() => ({ ...propsRect, ...position }));
  }, [position]);

  useEffect(() => {
    // @ts-ignore
    onUpdate({ ...rect, rotate: angle });
  }, [rect, angle]);

  useEffect(() => {}, [isDraggable, isResizable, isRotatable]);

  return (
    <div
      ref={ref}
      className={`${className}`}
      // @ts-ignore
      style={style ? style : {}}
      tabIndex={-1}
      data-angle={angle}
      data-rect={JSON.stringify(propsRect)}
      data-is-draggable={isDraggable}
      data-is-rotatable={isRotatable}
      data-drag-by-parent={isdragByParent}
      onMouseDown={(e: MouseEvent<HTMLDivElement>) => {
        onDrag(e, zoomValue);
        onMouseDown && onMouseDown(e);
      }}
      onMouseUp={(e) => {
        onDone && onDone(e);
      }}
      onClick={(e: MouseEvent<HTMLDivElement>) => {
        onClick && onClick(e);
      }}
      onMouseLeave={(e: MouseEvent<HTMLDivElement>) => {
        onMouseLeave && onMouseLeave(e);
      }}
      onMouseEnter={(e: MouseEvent<HTMLDivElement>) => {
        onMouseEnter && onMouseEnter(e);
      }}
      onKeyDown={(e) => {
        const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
        if((isMac && e.key === 'Backspace') || e.key === 'Delete') {
           onDelete && onDelete();
        }
      }}
    >
      {children}
      {isRotatable && (
        <div
          className={cn(
            'w-3 h-3',
            'flex',
            'bg-white cursor-pointer',
            'absolute -top-10',
            'border-solid border-black border-1 rounded-full transform',
            type === 'ARROW'
              ? '-right-10 rotate-45'
              : 'left-1/2 -translate-x-1/2'
          )}
          // style={{
          //   transform: 'translateX(-50%)',
          // }}
          onMouseDown={onRotate}
          onDoubleClick={resetRotate}
        >
          <div
            className={cn(
              'w-full h-7',
              'relative left-1/2 mt-3',
              'border-l-1 border-solid border-blue-400'
            )}
          ></div>
        </div>
      )}
      {isResizable &&
        // @ts-ignore
        getResizer(angle).map(({ key, cursor, className1, onResize, type }) => (
          <div
            key={`${key}-1`}
            style={{ cursor }}
            // @ts-ignore
            className={className1}
            onMouseDown={(e) => onResize(e, cursor, type)}
          />
        ))}
      {isResizable &&
        // @ts-ignore
        getResizer(angle).map(({ key, className2 }) => (
          <div key={`${key}-2`} className={className2} />
        ))}
    </div>
  );
};

export default forwardRef(DragResizeRotate);
