import { useEffect } from 'react';
import { useRecoilValue, useRecoilCallback, useRecoilState } from 'recoil';
import { currentUserIDState, currentUserInfoState } from '@/state/userState';
import { captureListState, capturesDeletedState } from '@/state/captureState';

import * as firebase from '@/lib/firebase/firestore';
import { isAppLoadingState } from '@/state/appState';
import { useGetUserSubscription } from '@/hooks/useGetUserSubscription';
import { useOrganization } from '@clerk/clerk-react';
import { map } from 'lodash';

let unsub: Function | null = null;

const GetCaptureList = () => {
  const currentUserID = useRecoilValue(currentUserIDState);
  const currentUserInfo = useRecoilValue(currentUserInfoState);
  const [isAppLoading, setIsAppLoading] = useRecoilState(isAppLoadingState);
  const { subscription } = useGetUserSubscription(currentUserID);
  const { organization } = useOrganization();

  const getCaptureList = useRecoilCallback(
    ({ set }) =>
      () => {
        const funcPram = organization ? organization.id : currentUserID;
        // const funcPram = currentUserID;

        firebase.getList(funcPram, (list: Capture.Info[]) => {
          list = map(list, capture => ({ ...capture, expiresInXDays: capture?.expiredAt ? Math.round((capture.expiredAt - Math.floor(new Date().getTime() / 1000)) / 60 / 60 / 24) : null }));

          const capturesNotDeleted = list.filter(
            (capture) => !capture.isDeleted || (subscription ? ((subscription === "free" && currentUserInfo?.tier !== "1") ? capture.expiredAt && (capture.expiredAt < Math.floor(new Date().getTime() / 1000)) : true) : true)
          );
          set(captureListState, capturesNotDeleted);

          const capturesDeleted = list.filter((capture) => capture.isDeleted && (subscription ? ((subscription === "free" && currentUserInfo?.tier !== "1") ? capture.expiredAt && (capture.expiredAt > Math.floor(new Date().getTime() / 1000)) : true) : true));
          set(capturesDeletedState, capturesDeleted);

          if (isAppLoading) setIsAppLoading(false);
        });

        return firebase.getSub(funcPram, (data: Capture.Info) => {
          set(captureListState, (old) => [data, ...old]);
        });
      },
    [currentUserID, subscription]
  );

  useEffect(() => {
    if (!currentUserID) return;
    if (unsub) {
      unsub();
    }
    unsub = getCaptureList();

    return () => {
      unsub && unsub();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUserID]);
  return null;
};

export default GetCaptureList;
