import { FC } from 'react';
import CaptureHeader from './CaptureHeader';
import CaptureBody from './CaptureBody';

type Props = {
  isExtension: boolean;
  isProjectPage?: boolean;
  isExpired?: boolean;
  isDeleted?: boolean;
};

const NewCapture: FC<Props> = ({
  isExtension,
  isProjectPage,
  isExpired,
  isDeleted,
}) => {
  return (
    <div className="w-full flex flex-col bg-white px-10 sm:px-4 py-10 sm:py-4">
      <CaptureHeader
        isExtension={isExtension}
        isProjectPage={isProjectPage}
        isExpired={isExpired}
        isDeleted={isDeleted}
      />
      <CaptureBody isExpired={isExpired} isDeleted={isDeleted} />
    </div>
  );
};

export default NewCapture;
