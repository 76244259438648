const ArrowRestore = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.33325 11C3.33325 8.56703 4.70092 6.47227 6.66659 5.53063C7.37815 5.18977 8.16812 5 8.99992 5C12.1295 5 14.6666 7.6863 14.6666 11" stroke="black" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M5.99992 9.33331L3.33325 11L1.33325 8.33331" stroke="black" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

    )
}

export default ArrowRestore;
