const ForCaptureIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13 2H3C2.44772 2 2 2.44772 2 3V13C2 13.5523 2.44772 14 3 14H13C13.5523 14 14 13.5523 14 13V3C14 2.44772 13.5523 2 13 2Z"
      stroke="#333333"
      strokeWidth="1.33333"
      strokeLinejoin="round"
    />
    <path
      d="M2 9.33319L5.56437 6.06586C5.81297 5.83796 6.19263 5.83163 6.4487 6.05113L10.6667 9.66653"
      stroke="#333333"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.33301 8.00028L10.9242 6.40911C11.1587 6.17455 11.5301 6.14815 11.7956 6.34721L13.9997 8.00028"
      stroke="#333333"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2 6.6665V9.99984"
      stroke="#333333"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14 6.6665V9.99984"
      stroke="#333333"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ForCaptureIcon;
