import { useRecoilValue } from 'recoil';

import { currentCaptureInfoState } from '@/state/captureState';
import UserAvatar from '../UserAvatar';
import Pencil from '../icon/Pecil';
import { useNavigate } from 'react-router-dom';

const EditorCircle = () => {
  const navigate = useNavigate();
  const currentCaptureInfo = useRecoilValue(currentCaptureInfoState);

  return (
    <div
      className="flex flex-row cursor-default ml-3 cursor-pointer"
      onClick={() => {
        navigate(
          `/editor/${currentCaptureInfo?.creator?.uid}/${currentCaptureInfo?.cid}`
        );
      }}
    >
      <span className="owner-avatar inline-block h-9 w-9 rounded-full black-border items-center justify-center flex">
        <Pencil width={22} height={20} className={'ml-1'} />
      </span>
    </div>
  );
};

export default EditorCircle;
