import { useRecoilState } from 'recoil';
import { Dialog } from '@headlessui/react';
import { syncWithExtensionState } from '@/state/uiState';
import { useAuth } from '@/hooks/useAuth';
import Template from './Template';
import { FC } from 'react';

interface CloseWithVideoProps {
  show: boolean;
  closeModal: () => void;
  handleAgreement: () => void;
}

const CloseWithVideo: FC<CloseWithVideoProps> = ({
  show,
  closeModal,
  handleAgreement
}) => {
  return (
    <Template isOpen={show} closeModal={closeModal}>
      <div className="inline-block DeleteModal w-full py-12 px-16 max-w-2xl  overflow-hidden text-left align-middle transition-all transform rounded-xl bg-white sm:mt-6 default-modal">
        <Dialog.Title as="h4" className="text-2xl text-gray-900 leading-8">
          You have not-posted comment
        </Dialog.Title>

        <div className="pt-6">
          <div className="flex space-x-3  flex-wrap justify-center items-baseline">
            <button className="cancel-modal-btn" onClick={closeModal}>
              Stay
            </button>
            <button className="delete-modal-btn" onClick={handleAgreement}>
              Leave
            </button>
          </div>
        </div>
      </div>
    </Template>
  );
};

export default CloseWithVideo;
