import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { keys, utils } from '@/lib';

import { useCaptureInfo } from '@/hooks/useCaptureInfo';

import { currentCaptureInfoState } from '@/state/captureState';
import { pathState } from '@/state/appState';
import {
  canUpdateCaptureNameState,
  currentUserInfoState,
} from '@/state/userState';

import { User } from 'firebase/auth';
import { useClickOutside } from '@/hooks/useClickOutside';
import { useOrganization } from '@clerk/clerk-react';

const Title = () => {
  //@ts-ignore
  utils.special[keys['backspace']] = true;
  //@ts-ignore
  utils.special[keys['shift']] = true;
  //@ts-ignore
  utils.special[keys['ctrl']] = true;
  //@ts-ignore
  utils.special[keys['alt']] = true;
  //@ts-ignore
  utils.special[keys['delete']] = true;
  //@ts-ignore
  utils.navigational[keys['upArrow']] = true;
  //@ts-ignore
  utils.navigational[keys['downArrow']] = true;
  //@ts-ignore
  utils.navigational[keys['leftArrow']] = true;
  //@ts-ignore
  utils.navigational[keys['rightArrow']] = true;

  const { updateCaptureInfo, getCaptureInfoSub } = useCaptureInfo();

  const [isTitleEditing, setIsTitleEditing] = useState(false);
  const [isTooltip, setIsTooltip] = useState(false);
  const [currentTitle, setCurrentTitle] = useState('');

  const { uid, cid } = useRecoilValue(pathState);
  const { organization } = useOrganization();
  const currentCaptureInfo = useRecoilValue(currentCaptureInfoState);
  const currentUserInfo = useRecoilValue(currentUserInfoState) as User;
  const canUpdateCaptureName = useRecoilValue(canUpdateCaptureNameState);

  const titleContainerRef = useRef<HTMLParagraphElement>(null);
  const titleRef = useRef<HTMLInputElement>(null);

  let unsub: Function | null = null;

  useEffect(() => {
    setCurrentTitle(
      currentCaptureInfo?.name ? currentCaptureInfo.name : 'Untitled Capture'
    );
  }, [currentCaptureInfo?.name]);

  useEffect(() => {
    const handleChangingTitleApprove = (e: KeyboardEvent) => {
      if (e.key === 'Enter' && isTitleEditing) {
        setIsTitleEditing(false);
        changeTitle(currentTitle);
        titleContainerRef.current?.blur();
      } else if (currentTitle !== currentCaptureInfo?.name && !isTitleEditing) {
        setCurrentTitle(
          currentCaptureInfo?.name
            ? currentCaptureInfo.name
            : 'Untitled Capture'
        );
      }
    };

    window.addEventListener('keydown', handleChangingTitleApprove);

    return () => {
      window.removeEventListener('keydown', handleChangingTitleApprove);
    };
  }, [currentTitle]);

  useClickOutside(titleContainerRef, () => {
    if (isTitleEditing) {
      changeTitle(currentTitle);
      titleContainerRef.current?.blur();
    } else if (currentTitle !== currentCaptureInfo?.name) {
      setCurrentTitle(
        currentCaptureInfo?.name ? currentCaptureInfo.name : 'Untitled Capture'
      );
    }
  });

  const changeTitle = async (title: string) => {
    if (title === '') {
      setCurrentTitle('Untitled Capture');
    }
    const userId = organization ? organization.id : uid
    // const userId = uid
    unsub && unsub();
    await updateCaptureInfo(userId, cid, { name: title });

    unsub = getCaptureInfoSub(userId, cid);
    return () => {
      unsub && unsub();
    };
  };

  const handleTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCurrentTitle(e.currentTarget.value);
  };

  useEffect(() => {
    document.title = currentTitle + ' - ' + 'Instacap';
  }, [currentTitle]);

  return (
    <div
      className=""
      onMouseEnter={() => {
        if (!canUpdateCaptureName && !organization) return false;
        // if (!canUpdateCaptureName) return false;
        setIsTooltip(true);
      }}
      onMouseLeave={() => {
        if (!canUpdateCaptureName && !organization) return false;
        // if (!canUpdateCaptureName) return false;
        setIsTooltip(false);
      }}
    >
      <p
        className={`has-tooltip ${(canUpdateCaptureName && !organization) ? 'header-title cursor-auto' : 'cursor-default'}`}
        // className={`has-tooltip ${(canUpdateCaptureName) ? 'header-title cursor-auto' : 'cursor-default'}`}
        ref={titleContainerRef}
        onClick={() => {
          if (!canUpdateCaptureName && !organization) return false;
          // if (!canUpdateCaptureName) return false;
          setIsTitleEditing(true);
        }}
      >
        <input
          className={`title-field ${document.activeElement === titleRef.current &&
            'border-red-span-input'
            }
                      rounded relative  
                      px-0 py-1 outline-none 
                      text-sm 
                      text-gray-700 placeholder-gray-400 
                      focus:outline-none focus:shadow-outline`}
          type="text"
          value={currentTitle}
          onChange={handleTitleChange}
        />
      </p>
      <div
        className="tooltip-edit tooltip-box border-2 border-white absolute whitespace-nowrap shadow-2xl font-normal tracking-wider text-white bg-black bg-opacity-70 px-2 py-1 text-xxxxs rounded-tl-md rounded-tr-2xl rounded-br-2xl rounded-bl-2xl"
        style={{
          display: !currentUserInfo.isAnonymous && isTooltip ? '' : 'none',
        }}
      >
        Rename
      </div>
    </div>
  );
};

export default Title;
