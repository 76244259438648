type Props = {
  classname: string;
};

const DrawIcon = ({ classname }: Props) => {
  return (
    <svg
      className={'rounded-3xl hover:bg-green ' + classname}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.19326 15.4625C2.98846 16.0001 3.25825 16.602 3.79586 16.8068C4.33347 17.0116 4.93531 16.7418 5.14011 16.2042L3.19326 15.4625ZM10.8334 11.6667L9.96663 12.2445L10.8334 11.6667ZM16.0329 3.88538C16.1076 3.31496 15.7058 2.79193 15.1354 2.71717C14.565 2.6424 14.042 3.04421 13.9672 3.61462L16.0329 3.88538ZM5.14011 16.2042L7.43564 10.1784L5.48879 9.43674L3.19326 15.4625L5.14011 16.2042ZM8.41498 10.0199C8.89515 10.681 9.43145 11.4417 9.96663 12.2445L11.7001 11.0889C11.1461 10.258 10.5933 9.47395 10.1006 8.7956L8.41498 10.0199ZM9.96663 12.2445C10.3429 12.8088 10.8622 13.3514 11.5821 13.4784C12.3752 13.6184 12.9831 13.1934 13.3545 12.7893C13.7266 12.3845 14.0198 11.8437 14.2559 11.3012C14.4985 10.7439 14.7119 10.1113 14.8979 9.46883C15.2699 8.18343 15.5531 6.78547 15.7423 5.7208C15.8374 5.18583 15.9097 4.72943 15.9583 4.40598C15.9827 4.24417 16.0011 4.11538 16.0136 4.02641C16.0198 3.98191 16.0245 3.94735 16.0277 3.92357C16.0294 3.91168 16.0306 3.90248 16.0314 3.89608C16.0319 3.89288 16.0322 3.89038 16.0324 3.88859C16.0325 3.8877 16.0326 3.88698 16.0327 3.88644C16.0327 3.88618 16.0328 3.88591 16.0328 3.88578C16.0328 3.88555 16.0329 3.88538 15 3.75C13.9672 3.61462 13.9672 3.61454 13.9672 3.6145C13.9672 3.61454 13.9672 3.61454 13.9672 3.61464C13.9672 3.61482 13.9671 3.61518 13.967 3.61572C13.9669 3.6168 13.9667 3.61858 13.9663 3.62105C13.9657 3.626 13.9646 3.63373 13.9632 3.64412C13.9604 3.6649 13.9561 3.69633 13.9503 3.73759C13.9388 3.82012 13.9214 3.94186 13.8982 4.09611C13.8517 4.40479 13.7824 4.84266 13.6911 5.35631C13.5076 6.38888 13.2394 7.70552 12.8967 8.88957C12.7252 9.48182 12.5403 10.0226 12.3457 10.4697C12.1447 10.9316 11.9617 11.226 11.8206 11.3795C11.6788 11.5338 11.7365 11.3902 11.9442 11.4268C12.0787 11.4506 11.9776 11.5051 11.7001 11.0889L9.96663 12.2445ZM7.43564 10.1784C7.61329 9.71207 8.16544 9.67627 8.41498 10.0199L10.1006 8.7956C8.83489 7.05283 6.2291 7.49343 5.48879 9.43674L7.43564 10.1784Z"
        fill="#808080"
      />
    </svg>
  );
};

export default DrawIcon;
