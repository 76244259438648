import { UserButton } from '@clerk/clerk-react';
import Billing from '../Settings/Billing';
import { useLocation } from 'react-router-dom';

export const CustomUserButton = () => {

  const location = useLocation();

  return (
    <UserButton userProfileMode="modal" afterSignOutUrl={location.pathname} afterMultiSessionSingleSignOutUrl={location.pathname}>
      <UserButton.UserProfilePage
        label="Billing"
        url="user-billing"
        labelIcon={<BillingCardIcon />}
      >
        <Billing></Billing>
      </UserButton.UserProfilePage>
    </UserButton>
  );
};

export const BillingCardIcon = () => {
  return (
    <svg
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.2222 2.11117C14.4667 2.11117 14.6667 2.31117 14.6667 2.55561V3.4445H1.33333V2.55561C1.33333 2.31117 1.53333 2.11117 1.77778 2.11117H14.2222ZM14.6667 6.11117V11.4445C14.6667 11.6889 14.4667 11.8889 14.2222 11.8889H1.77778C1.53333 11.8889 1.33333 11.6889 1.33333 11.4445V6.11117H14.6667ZM1.77778 0.777832C0.797222 0.777832 0 1.57505 0 2.55561V11.4445C0 12.4251 0.797222 13.2223 1.77778 13.2223H14.2222C15.2028 13.2223 16 12.4251 16 11.4445V2.55561C16 1.57505 15.2028 0.777832 14.2222 0.777832H1.77778ZM3.33333 9.22228C2.96389 9.22228 2.66667 9.5195 2.66667 9.88894C2.66667 10.2584 2.96389 10.5556 3.33333 10.5556H4.66667C5.03611 10.5556 5.33333 10.2584 5.33333 9.88894C5.33333 9.5195 5.03611 9.22228 4.66667 9.22228H3.33333ZM6.88889 9.22228C6.51944 9.22228 6.22222 9.5195 6.22222 9.88894C6.22222 10.2584 6.51944 10.5556 6.88889 10.5556H10C10.3694 10.5556 10.6667 10.2584 10.6667 9.88894C10.6667 9.5195 10.3694 9.22228 10 9.22228H6.88889Z"
        fill="black"
      />
    </svg>
  );
};

export const ProfileIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.00009 6.66732C9.47285 6.66732 10.6668 5.47341 10.6668 4.00065C10.6668 2.52789 9.47285 1.33398 8.00009 1.33398C6.52733 1.33398 5.33342 2.52789 5.33342 4.00065C5.33342 5.47341 6.52733 6.66732 8.00009 6.66732Z"
        stroke="black"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 14.666C14 11.3523 11.3137 8.66602 8 8.66602C4.6863 8.66602 2 11.3523 2 14.666"
        stroke="black"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};