import { FC, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useNavigate } from 'react-router-dom';

import {
  groupedByHostCaptureListState,
  currentCaptureInfoState,
  groupedByHostCaptureListSharedState,
} from '@/state/captureState';
import { projectListStateWithCapturesState } from '@/state/projectState';
import { filter, isEqual, map, size } from 'lodash';
import { showMyProjectState } from '@/state/uiState';
import { checkSharedDocumentExists } from '@/lib/firebase/firestore/group';
import { currentUserInfoState } from '@/state/userState';

interface ShowProjectDropDownsProps {
  isShow: boolean;
}

const ProjectsDropdown: FC<ShowProjectDropDownsProps> = ({ isShow }) => {
  const navigate = useNavigate();
  const [showMyProjects, setMyProjects] = useRecoilState(showMyProjectState)

  const groupedByHostCaptureList = useRecoilValue(
    showMyProjects
    ? groupedByHostCaptureListState
    : groupedByHostCaptureListSharedState
  );
  const currentCaptureInfo = useRecoilValue(currentCaptureInfoState);
  const currentUserInfo = useRecoilValue(currentUserInfoState)
  
  const [projectListStateWithCaptures, setProjectListStateWithCaptures] = useRecoilState(
    projectListStateWithCapturesState
  );
  const [projectListWasChanged, setProjectListWasChanged] = useState(false)

  // console.log("projectListStateWithCaptures ::", projectListStateWithCaptures)

  // useLayoutEffect(() => {
  //   const localShowMyProjects = localStorage.getItem("showMyProjects");
  //   const localProjectList = localStorage.getItem("projectList");

  //   if(localShowMyProjects){
  //    const storedData = JSON.parse(localShowMyProjects)
  //    setMyProjects(storedData)
  //   }else {
  //     // localStorage.removeItem("showMyProjects");
  //   };

  //   if(localProjectList){
  //     const storedData = JSON.parse(localProjectList)
  //     setProjectListStateWithCaptures(storedData)
  //   }else{
  //     // localStorage.removeItem("showMyProjects");

  //   }
  // },[])


  const projectsListAsCaptures = useMemo(() => {
    let projects = groupedByHostCaptureList.map(
      ([_, project]) =>
        [...project].sort((a, b) => a?.order! - b?.order! ?? false)[0]
    );

    const nonProjectCaptures = projects.filter(
      (project) => project.group === null
    );

    if (nonProjectCaptures.length > 0) {
      let nonCapture = { ...nonProjectCaptures[0] };
      nonCapture.group = 'Non Project Captures';
      nonCapture.projectName = 'Non Project Captures';
      projects.unshift(nonCapture);
    }

    return projects.filter((project) => project.projectName && project.group);
  }, [groupedByHostCaptureList]);
  
  useEffect(() => {
    // console.log("projectListStateWithCaptures", projectListStateWithCaptures)

    if(!showMyProjects && size(projectListStateWithCaptures) > 0){
      const checkProjectsExistence = async () => {
        const checks = await Promise.all(
          projectListStateWithCaptures.map(async (project: any) => ({
            exists: project.project.name === "Non Project Captures" ? true : await checkSharedDocumentExists(project.project, currentUserInfo.email),
            project
          }))
        );
  
        const existingProjects = checks.filter(c => c.exists).map(c => c.project);
  
        if (JSON.stringify(existingProjects) !== JSON.stringify(projectListStateWithCaptures)) {
          setProjectListStateWithCaptures(existingProjects);
          localStorage.setItem("showMyProjects", JSON.stringify(showMyProjects));
          localStorage.setItem("projectList", JSON.stringify(existingProjects));

        }

      };
    
      checkProjectsExistence();
    }
  },[projectListStateWithCaptures])

 
  if (!isShow) return null;

  return (
    <div
      className="sidbar-dropdown-capture 
                 absolute left-0 
                 z-10 
                 bg-white 
                 divide-y divide-gray-100 
                 rounded-md shadow-lg 
                 ring-1 ring-black ring-opacity-5 
                 focus:outline-none"
      role="menu"
      tabIndex={1}
    >
      {filter(map(projectListStateWithCaptures, item => ({ ...item, captures: filter(item.captures, c => c.expiredAt && (c.expiredAt > Math.floor(new Date().getTime() / 1000))) })), e => size(e.captures) > 0).map((firstCapture: any, index: number) => {
        return (
          <button
            key={`sidebar-project-dropdown-${index}`}
            className={`dropdown-item sider-bar-text 
                        group px-2 
                        text-sm text-gray-900 
                        flex items-center 
                        w-full rounded-md 
                        hover:bg-green-tinted hover:text-black 
                        ${currentCaptureInfo?.group === firstCapture.group &&
              'bg-green cursor-default'
              }`}
            disabled={currentCaptureInfo?.group === firstCapture.group}
            onClick={() => {
              // const firstNonDeletedProject = firstCapture.captures.find((capture: any) => !capture.isDeleted)
              const firstNonDeletedProject = firstCapture.captures.find((capture: any) => 
                (!capture.isDeleted && groupedByHostCaptureList.some((project) => 
                  project[1].some((sharedCapture) => sharedCapture.cid === capture.cid)
                ))
              );

              if(showMyProjects){
                navigate(
                  `/${firstCapture?.project?.uid}/${firstNonDeletedProject?.cid ?? ''
                  }`
                );
              } else {
                navigate(
                  `/${firstNonDeletedProject?.creator?.uid}/${firstNonDeletedProject?.cid ?? ''
                  }`
                );
              }
            }}
          >
            <p className="truncate ... select-none">
              {firstCapture.project.name}
            </p>
          </button>
        );
      })}
    </div>
  );
};

export default ProjectsDropdown;
