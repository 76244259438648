import { Images } from 'helpers/core-constants';
import React from 'react';
const ImageComponent = ({ handleBackgroundImage }: any) => {
  return (
    <div
      className=" overflow-y-auto inline-block no-scrollbar text-left w-full my-3"
      style={{ maxHeight: 'calc(100vh - 200px)' }}
    >
      <>
        <h1
          className="text-sm font-medium mb-6 text-balck mt-5"
        >
          Images
        </h1>
      </>
      <div
        className=""
      >
        <div
          className="grid grid-cols-3 lg:grid-cols-4  gap-1"
        >
          {Images.map((image, index) => (
            <div
              key={index}
              className="  "
            >
              <div
                className="w-full h-20 rounded-lg overflow-hidden border-2 border-black  cursor-pointer "
                onClick={() => {
                  handleBackgroundImage(image);
                }}
                style={{
                  backgroundImage: `url(${image})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }}
              ></div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ImageComponent;
