import { FC } from 'react';

interface props {
  width?: number;
  height?: number;
  opacity?: number;
}

const AddProjectIcon: FC<props> = ({
  width = 16,
  height = 16,
  opacity = 1,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      opacity={opacity}
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M1.66675 2.66667C1.66675 2.29848 1.96522 2 2.33341 2H6.33341L8.00008 4H13.6667C14.0349 4 14.3334 4.29847 14.3334 4.66667V13.3333C14.3334 13.7015 14.0349 14 13.6667 14H2.33341C1.96522 14 1.66675 13.7015 1.66675 13.3333V2.66667Z"
        stroke="black"
        stroke-width="1.33333"
        stroke-linejoin="round"
      />
      <path
        d="M6 9H10"
        stroke="black"
        stroke-width="1.33333"
        stroke-linecap="round"
      />
      <path
        d="M8 7V11"
        stroke="black"
        stroke-width="1.33333"
        stroke-linecap="round"
      />
    </svg>
  );
};

export default AddProjectIcon;
