import { FC } from 'react';

interface props {
  width?: number;
  height?: number;
  opacity?: number;
}

const RecordingIcon: FC<props> = ({ height = 40, width = 40, opacity }) => {
  return (
    <svg
      width={width}
      height={height}
      opacity={opacity}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="40" height="40" rx="20" fill="#FF38A4" />
      <g clipPath="url(#clip0_3529_48299)">
        <path d="M28 12H12V28H28V12Z" fill="white" fillOpacity="0.01" />
        <path
          d="M25 15H15.6667C15.2985 15 15 15.3675 15 15.8208V24.8492C15 25.3025 15.2985 25.67 15.6667 25.67H25C25.3682 25.67 25.6667 25.3025 25.6667 24.8492V15.8208C25.6667 15.3675 25.3682 15 25 15Z"
          fill="white"
          stroke="white"
          strokeWidth="1.33333"
        />
      </g>
      <defs>
        <clipPath id="clip0_3529_48299">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(12 12)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};


export default RecordingIcon;