import React, { Fragment } from 'react';
import { Popover } from '@headlessui/react'; 
import { useDispatch, useSelector } from 'react-redux';
import {
  setCanvasTexts,
  setHeight,
  setScale,
  setWidth,
} from 'store/slice/editor.slice';
import { RootState } from 'store';
import { CheckIcon, ChevronDownIcon } from 'lucide-react';
import { socialMediaPostSizes } from 'helpers/core-constants';
import clsx from 'clsx';

const CanvasSize = () => {
  const { image } = useSelector((state: RootState) => state.editor);
  const dispatch = useDispatch();
  const [selectedSize, setSelectedSize] = React.useState(
    socialMediaPostSizes[0]
  );

  const handleCanvasSizeChange = (size: {
    width: number;
    height: number;
    title: string; // Change type to title
  }) => {
    const selectedSize = socialMediaPostSizes.find(
      (s) => s.title === size.title
    );

    if (selectedSize) {
      dispatch(setWidth(selectedSize.width));
      dispatch(setHeight(selectedSize.height));
      setSelectedSize(selectedSize);
    }
  };

  return (
    <div className="relative  rounded-3xl border border-gray-800 bg-white text-black w-full z-50">
      <Popover className="relative ">
        <Popover.Button
          className="flex items-center justify-start  px-4 w-full z-40 backdrop-blur-md bg-transparent bg-opacity-80 text-black"
          style={{
            padding: '13px 10px',
          }}
        >
          <img
            src={selectedSize.icon}
            alt={`${selectedSize.title} Icon`}
            className="w-3 h-3 ml-2"
          />
          <h1 className="text-xs ml-2 hidden md:block">
            {selectedSize.title} ({selectedSize.width} x {selectedSize.height})
          </h1>
          <ChevronDownIcon className="w-3 h-3 ml-2" />
        </Popover.Button>
        <Popover.Panel className="bg-deep-slate-900 mt-72 w-[500px] bg-opacity-80 backdrop-blur-lg border border-gray-800 rounded-md">
          <div className="overflow-hidden ring-1 ring-black ring-opacity-5">
            <div className="grid grid-cols-5 gap-2 p-2">
              {socialMediaPostSizes.map((size) => (
                <button
                  key={size.title} // Change key to title
                  onClick={() => handleCanvasSizeChange(size)}
                  className={clsx(
                    'flex flex-col items-center justify-center py-2 px-2 text-[10px] text-black cursor-pointer hover:border-slate-800 focus:outline-none border border-slate-700 rounded-md backdrop-blur-md bg-slate-800 bg-opacity-20',
                    {
                      'font-bold': selectedSize.title === size.title,
                    }
                  )}
                >
                  <div className="flex items-center justify-start">
                    <img
                      src={size.icon}
                      alt={`${size.title} Icon`}
                      className="w-3 h-3 mr-1"
                    />
                    <span>{size.title}</span>
                  </div>
                  <span className="">
                    ({size.width} x {size.height})
                  </span>
                </button>
              ))}
            </div>
          </div>
        </Popover.Panel>
      </Popover>
    </div>
  );
};

export default CanvasSize;
