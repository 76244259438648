import React, { FC, useEffect, useLayoutEffect, useMemo, useRef } from 'react';
import { createPortal } from 'react-dom';

import { useClickOutside } from '@/hooks/useClickOutside';

import Pencil from '../icon/Pecil';
import Trash from '../icon/Trash';
import { NotifyMessage } from '../notify';

interface DropdownProps {
  capture: Capture.Info;
  show: boolean;
  showRestore: boolean;
  onClose: () => void;
  onChangeNameClick?: (e: any) => void;
  onCaptureDelete?: () => void;
  onRestore?: () => void;
  onRestoreDelete?: () => void;
  showNotifyMsg?: boolean;
  styles: {
    position:
    | {
      x: number;
      y: number;
    }
    | DOMRect;
  };
}

const DropdownCapture: FC<DropdownProps> = ({
  capture,
  show,
  showRestore,
  onClose,
  onChangeNameClick = () => { },
  onCaptureDelete = () => { },
  onRestore,
  onRestoreDelete = () => { },
  showNotifyMsg = false,
  styles,
}) => {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const capturesContainer = document.getElementById('sidebar-capture-list');

  useClickOutside(dropdownRef, onClose);

  useEffect(() => {
    capturesContainer?.addEventListener('scroll', onClose);

    return () => {
      capturesContainer?.removeEventListener('scroll', onClose);
    };
  }, []);

  useLayoutEffect(() => {
    if (dropdownRef.current && styles.position?.x) {
      dropdownRef.current.style.left =
        styles.position.x - dropdownRef.current.clientWidth + 20 + 'px';
      dropdownRef.current.style.top = styles.position.y + 20 + 'px';
    }
  }, [dropdownRef, styles]);

  const menuItems = useMemo(
    () => [
      {
        Icon: Pencil,
        title: 'Change name',
        onClick: onChangeNameClick,
        useBottomDivider: true,
      },
      {
        Icon: Trash,
        title: 'Delete Capture',
        onClick: onCaptureDelete,
        classnames: 'text-danger',
      },
    ],
    []
  );

  const menuItemsRestore = useMemo(
    () => [
      {
        Icon: Pencil,
        title: 'Restore',
        onClick: onRestore,
        useBottomDivider: true,
        classnames: 'opacity-40',
      },
      {
        Icon: Trash,
        title: 'Delete now',
        onClick: onRestoreDelete,
        classnames: 'text-danger',
      },
    ],
    []
  );

  if (!show && !showRestore) return null;

  return createPortal(
    <div
      ref={dropdownRef}
      className="sidebar-dropdown
               absolute top-0
               w-56 
               py-2 mt-2 -ml-2 
               bg-white 
               rounded-md shadow-lg 
               ring-1 ring-black ring-opacity-5 
               focus:outline-none z-10"
    >
      <NotifyMessage
        title={"You can't restore the project if you are Free Plan member. "}
        show={showNotifyMsg}
        wrapperClassName={'absolute'}
        childClassName={
          'absolute w-max max-w- max-w-64 p-3 -bottom-1 left-10 bg-black bg-opacity-70 text-white rounded-md'
        }
      />
      {/* {(capture.isDeleted ? menuItemsRestore : menuItems).map( */}
      {menuItems.map(
        ({ Icon, title, onClick, classnames, useBottomDivider }, index) => (
          <React.Fragment key={"drop-elem=" + index}>
            <button
              type="button"
              key={index}
              className={`dropdown-item 
                      group w-full 
                      flex items-center 
                      rounded-md
                      px-2 
                      text-sm text-gray-900 
                    hover:bg-green hover:text-black
                      ${classnames && classnames}`}
              role="menuitem"
              onClick={onClick}
            >
              <div className="flex justify-center w-6 min-w-min min-h-max mr-2">
                <Icon width={16} height={16} />
              </div>
              <span>{title}</span>
            </button>
            {useBottomDivider && (
              <div className="container-dropdown px-4">
                <hr className="my-2" />
              </div>
            )}
          </React.Fragment>
        )
      )}
    </div>,
    document.getElementById('sidebar-capture-list')!
  );
};

export default DropdownCapture;
