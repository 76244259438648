import { FC, useEffect } from 'react';

import { useUpgradePlan } from '@/hooks/plan/useUpgradePlan';
import { useSetRecoilState } from 'recoil';
import { isAppLoadingState } from '@/state/appState';

type NotifyMessageProps = {
  show: boolean;
  title: string;
  wrapperClassName?: string;
  childClassName?: string;
};

export const NotifyMessage: FC<NotifyMessageProps> = ({
  show,
  title,
  wrapperClassName,
  childClassName,
}: NotifyMessageProps) => {
  const { isLoading: isLoadingUpgrade, upgradePlan } = useUpgradePlan();
  const setIsAppLoading = useSetRecoilState(isAppLoadingState);

  useEffect(() => {
    if (isLoadingUpgrade) setIsAppLoading(true);
  }, [isLoadingUpgrade])

  const redirectToBilling = (e: any) => {
    e.stopPropagation()
    upgradePlan && upgradePlan('pro');
  };

  if (!show) return <></>;

  return (
    <div id="notify-message-containter" className={`cursor-default ${wrapperClassName}`}>
      <div className={`cursor-default ${childClassName}`}>
        {title}
        <span className="text-pink cursor-pointer" onClick={redirectToBilling}>
          &nbsp;Upgrade now
        </span>
      </div>
    </div>
  );
};
