import { FC } from 'react';

interface props {
  width?: number;
  height?: number;
  opacity?: number;
}

const AddEmojiWithoutBorderIcon: FC<props> = ({
  width = 22,
  height = 22,
  opacity = 1,
}) => {
  return (
    <svg
      width={width}
      height={height}
      opacity={opacity}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10 2H0V12H10V2Z" fill="white" fillOpacity="0.01" />
      <path
        d="M4.99967 11.1668C7.30086 11.1668 9.16634 9.30135 9.16634 7.00016C9.16634 4.69898 7.30086 2.8335 4.99967 2.8335C2.69849 2.8335 0.833008 4.69898 0.833008 7.00016C0.833008 9.30135 2.69849 11.1668 4.99967 11.1668Z"
        stroke="black"
        strokeWidth="0.833333"
        strokeLinejoin="round"
      />
      <path
        d="M6.45866 8.4585C6.45866 8.4585 6.04199 9.29183 5.00033 9.29183C3.95866 9.29183 3.54199 8.4585 3.54199 8.4585"
        stroke="black"
        strokeWidth="0.833333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.45801 5.75V6.58333"
        stroke="black"
        strokeWidth="0.833333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.54199 5.75V6.58333"
        stroke="black"
        strokeWidth="0.833333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g clipPath="url(#clip0_3588_66765)">
        <rect x="6" width="6" height="6" rx="3" fill="white" />
        <path d="M12 0H6V6H12V0Z" fill="white" fillOpacity="0.01" />
        <path
          d="M9.00752 1.25L9.00293 4.75"
          stroke="black"
          strokeWidth="0.83"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.25 3H10.75"
          stroke="black"
          strokeWidth="0.83"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3588_66765">
          <rect x="6" width="6" height="6" rx="3" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AddEmojiWithoutBorderIcon;
