const extensionId = process.env.REACT_APP_EXTENSION_ID || '';
export let withExtension = false;
export let isExtensionAnonymous = false;

export const sendMessage = (data: any, cb?: Function, errorCB?: Function) => {
  try {
    if (!chrome || !chrome.runtime) {
      throw new Error('NOT_CHROME');
    } else {
      chrome.runtime.sendMessage(extensionId, data, (response) => {
        if (chrome.runtime.lastError) {
          console.log('Chrome runtime error:', chrome.runtime.lastError);
          errorCB && errorCB();
        }
        if (response) {
          cb && cb(response);
        }
      });
    }
  } catch (error) {
    errorCB && errorCB();
  }
};

const setLocalStorage = (data: { key: string; value: string }) => {
  localStorage.setItem(`${data.key}`, `${JSON.stringify(data.value)}`);
};

export const extensionSignOut = async () => {
  return new Promise((r) => {
    sendMessage(
      {
        type: 'signOut',
      },
      async (response: any) => {
        if (!response || !response.key || !response.value) return false;
        setLocalStorage(response);
        r(response);
      },
      () => { }
    );
  });
};

export const extensionSignIn = (data: any) => {
  sendMessage(
    {
      type: 'signIn',
      data,
    },
    () => { },
    () => { }
  );
};

export const init = (cb: Function) => {
  sendMessage(
    { type: 'instacap-web' },
    async (response: any) => {
      withExtension = true;
      console.log('With Extension:', withExtension);
      // if (response && response.key && response.value) {
      //   isExtensionAnonymous = response.value.isAnonymous;
      //   const savedValue = localStorage.getItem(`${response.key}`);
      //   if (savedValue) {
      //     const savedOauth = JSON.parse(savedValue);
      //     if (savedOauth.isAnonymous) {
      //       setLocalStorage(response);
      //     }
      //   } else {
      //     setLocalStorage(response);
      //   }
      // }
      cb(response);
    },
    cb
  );
};
