import closeIcon from '@/assets/icons/close-24.png';
import React, { FC, MouseEvent, useRef, useState } from 'react';
import Template from './Template';
import { Dialog } from '@headlessui/react';
import CircleClose from '@/components/icon/CircleClose';
import ShareInputViewDropdown from '@/components/header/ShareInputViewDropdown';
import SendBigButton from '@/components/icon/SendBigButton';
import UserAvatar from '@/components/UserAvatar';
import CopyIcon from '@/components/icon/CopyIcon';
import ArrowUpShare from '@/components/icon/ArrowUpShare';
import ArrowDownShare from '@/components/icon/ArrowDownShare';
import CopyLinkShareDropdown from '@/components/header/CopyLinkShareDropdown';
import LeftMove from '@/components/icon/leftMove';
import RightMove from '@/components/icon/rightMove';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  currentUserInfoState,
  isdisplayNameSettedState,
  nextState,
} from '@/state/userState';
import { useAuth } from '@/hooks/useAuth';
import { User } from 'firebase/auth';

type Props = {
  isOpen: boolean;
  title: string;
  submitText: string;
  defaultValue: string;
  closeModal: () => void;
};

const UpdateDisplayName: FC<Props> = ({
  isOpen,
  closeModal,
  title,
  submitText,
  defaultValue,
}) => {
  const data = useRecoilValue(currentUserInfoState) as User;
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);
  const setIsDisplayNameSetted = useSetRecoilState(isdisplayNameSettedState);
  const [updating, setIsUpdating] = useState(false);
  // console.log(data);

  const { setDisplayName } = useAuth();
  const next = useRecoilValue(nextState);
  const process = async () => {
    //Here We Update Name from the text area element\
    setIsUpdating(true);
    if (textAreaRef.current) {
      await setDisplayName(data.uid, textAreaRef.current.value);
      setIsDisplayNameSetted(true);
      next.next && next.next();

      setTimeout(() => {
        setIsUpdating(false);
        closeModal();
      }, 1000);
    }
  };
  return (
    <Template isOpen={isOpen} closeModal={closeModal}>
      <div className="inline-block DeleteModal w-full py-12  max-w-4xl  overflow-hidden align-middle transition-all transform rounded-xl bg-white sm:mt-6 ">
        <div className="text-center">
          <h4 className=" pb-3" style={{ fontSize: '30px' }}>
            {title}
          </h4>
          <p className="pt-3">
            you can edit the name later from profile settings.
          </p>

          <textarea
            onKeyPress={(event) => {
              if (event.key === 'Enter') {
                event.stopPropagation();
                event.preventDefault();
                return process();
              }
            }}
            ref={textAreaRef}
            id="textInput"
            defaultValue={defaultValue}
            maxLength={25}
            className=" text-center mt-5 pt-3 reply-input rounded relative py-1 px-2 pr-14 w-full bg-white shadow outline-none  text-gray-700 placeholder-gray-400 focus:outline-none focus:shadow-outline  overflow-hidden"
            style={{
              height: '52px',
              minHeight: '52px;',
              fontSize: '20px',
              width: '357px',
            }}
          ></textarea>
        </div>
        <div className="flex space-x-3 mt-5 pt-5 flex-wrap justify-center items-baseline">
          <button
            className="btn-green-outline border-100 custom-green-btn hover:opacity-100"
            onClick={process}
          >
            {updating ? (
              <div className="font-rubik  flex justify-center items-center">
                <div
                  style={{ borderTopColor: 'transparent' }}
                  className="w-4 h-4 border-1 border-black border-solid rounded-full animate-spin-fast"
                ></div>
              </div>
            ) : (
              submitText
            )}
          </button>
        </div>
      </div>
    </Template>
  );
};

export default UpdateDisplayName;
