import { getDefaultRectByObjectTypeAndClientXY } from '@/lib/rect';
import { atom, selector } from 'recoil';
import { nanoid } from 'nanoid';
import _ from 'lodash';

export const isCurrentScaleState = atom<Tool.Scale>({
  key: 'isCurrentScaleState',
  default: { x: 1, y: 1 },
});

export const isParamCurrentToolState = atom<any>({
  key: 'isParamCurrentToolState',
  default: {},
});

export const isDraggableStopState = atom<boolean>({
  key: 'isDraggableStop',
  default: true,
});

export const isCurrentScrollState = atom<Tool.ScrollCord>({
  key: 'isCurrentScrollState',
  default: { top: 0, left: 0 },
});

export const isToolBarShowState = atom<boolean>({
  key: 'IsToolBarShow',
  default: true,
});

export const currentToolObjectColorState = atom<string>({
  key: 'CurrentToolObjectColor',
  default: '#000000',
});

export const currentToolState = atom<Tool.ObjectType>({
  key: 'CurrentTool',
  default: 'MOVE',
});

export const currentToolObjectState = atom<Tool.Object | null>({
  key: 'CurrentToolObject',
  default: null,
});

export const currentToolObjectIndexState = atom<number>({
  key: 'CurrentToolObjectIndex',
  default: -1,
});

export const toolObjectListState = atom<Tool.Object[]>({
  key: 'ToolObjectListState',
  default: [],
});

export const toolObjectListOrderState = atom<number[]>({
  key: 'ToolObjectListOrder',
  default: [],
});

export const toolCordsState = atom({
  key: 'toolCordsState',
  default: {},
});

export const selectToolObjectState = selector<number>({
  key: 'SelectToolObject',
  // @ts-ignore
  get: () => null,
  set: ({ set, get }, index: number) => {
    const list = get(toolObjectListState);
    const currentTool = get(currentToolState);
    if (currentTool !== 'MOVE') return;
    set(currentToolObjectIndexState, index);
    set(currentToolObjectState, list[index]);

    const orderList = [...get(toolObjectListOrderState)];
    const orderListIndex = _.indexOf(orderList, index);
    orderList.splice(orderListIndex, 1);
    orderList.push(index);
    set(toolObjectListOrderState, orderList);
  },
});

export const addToolObjectState = selector<{
  position?: Rect.ClientPosition;
  rect?: Rect.Info;
  stroke?: Canvas.Path;
}>({
  key: 'AddToolObject',
  // @ts-ignore
  get: ({ get }) => get(currentToolObjectState),
  // @ts-ignore
  set: ({ set, get }, { position = {}, rect = {}, stroke = null }) => {
    const currentTool = get(currentToolState);
    const list = get(toolObjectListState);
    const color = get(currentToolObjectColorState);
    const ln = list.length;
    let toolObject: Tool.Object = {
      width: 0,
      height: 0,
      rotate: 0,
      top: 0,
      left: 0,
      type: currentTool as Tool.ObjectType,
      color,
      fontSize: 16,
      text: '',
      id: nanoid(),
    };

    // console.log('addToolObjectState');
    if (currentTool === 'DRAW') {
      toolObject = {
        ...toolObject,
        ...rect,
        stroke,
      };
    } else {
      toolObject = {
        ...toolObject,
        ...getDefaultRectByObjectTypeAndClientXY({
          type: currentTool,
          ...position,
        }),
      };
      set(currentToolObjectIndexState, ln);
      set(currentToolObjectState, toolObject);
    }

    set(toolObjectListState, (old) => [...old, toolObject]);

    const orderList = [...get(toolObjectListOrderState)];
    orderList.push(ln);
    set(toolObjectListOrderState, orderList);
  },
});

export const updateToolObjectState = selector<Tool.Object>({
  key: 'UpdateToolObject',
  // @ts-ignore
  get: ({ get }) => get(currentToolObjectState),
  set: ({ get, set }, toolObject: Tool.Object) => {
    const currentToolObjectIndex = get(currentToolObjectIndexState);
    const currentToolObject = get(currentToolObjectState);
    const toolObjectList = [...get(toolObjectListState)];
    toolObjectList.splice(currentToolObjectIndex, 1, {
      ...currentToolObject,
      ...toolObject,
    });
    set(currentToolObjectState, { ...currentToolObject, ...toolObject });
    set(toolObjectListState, toolObjectList);
  },
});
