import { useSession, useUser } from '@clerk/clerk-react';
import { useAuth } from './useAuth';
import { useEffect } from 'react';
import { useIntercom } from './useIntercom';
import { useRecoilState } from 'recoil';
import { isAppLoadingState } from '@/state/appState';

export const useClerkAuth = () => {
  const { isSignedIn, isLoaded } = useSession();
  const { user: clerkUser } = useUser();

  const { setAuth, createFirebaseUser } = useAuth();
  const { shutdown } = useIntercom();
  const [isAppLoading, setIsAppLoading] = useRecoilState(isAppLoadingState);

  useEffect(() => {
    const handleClerkUser = async () => {
      if (isLoaded) {
        if (isSignedIn) {
          if (clerkUser) {
            if (clerkUser?.unsafeMetadata?.uid) {
              setAuth({ uid: clerkUser.unsafeMetadata.uid, displayName: clerkUser?.fullName ?? "", photoURL: clerkUser.hasImage ? clerkUser.imageUrl : null, email: clerkUser?.emailAddresses[0].emailAddress, isAnonymous: false });
            } else {
              // create firebase user and save to clerk user unsafeMetadata
              const createResult: any = await createFirebaseUser({ email: clerkUser?.emailAddresses[0].emailAddress });
              if (createResult?.updateClerkMetaData == true) {
                const updateResult: any = await clerkUser.update({ unsafeMetadata: { uid: createResult.uid } });
              }
            }
          }
        } else {
          // setIsAppLoading(true);
          setAuth({ isAnonymous: true });
          await shutdown();
        }
      }
    }

    handleClerkUser();
  }, [isSignedIn, isLoaded, clerkUser]);

  return { isSignedIn: isSignedIn && isLoaded };
};
