'use client';

import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import store from 'store';
import toast, { Toaster } from 'react-hot-toast';
import 'highlight.js/styles/default.css';

const Layout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <Provider store={store}>
      {children}
      {/* <Toaster
        position="top-center"
        containerClassName=""
        toastOptions={{
          className: 'toaster-notification',
          duration: 2000,
          style: {
            fontFamily: 'poppins',
            fontSize: '16px',
            maxWidth: 'initial',
            padding: '20px',
            boxShadow: '0px 12px 30px rgba(0, 0, 0, 0.3)',
            backgroundColor: '#FFF',
            border: '1px solid #000',
            color: '#000',
            fontWeight: 600,
            borderRadius: '8px',
          },
        }}
      /> */}
    </Provider>
  );
};

export default Layout;
