import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { currentUserInfoState } from '@/state/userState';
import type { User } from 'firebase/auth';
import { useAuth } from '@/hooks/useAuth';

const SignInOut = () => {
  const currentUserInfo = useRecoilValue(currentUserInfoState) as User;
  const { linkToGoogle, signOut } = useAuth();
  useEffect(() => {}, [currentUserInfo.isAnonymous]);

  const handleClick = () => {
    linkToGoogle();
  };

  if (!currentUserInfo) return null;

  if (currentUserInfo.isAnonymous) {
    return (
      <div
        className="has-tooltip w-full h-full flex justify-center items-center cursor-pointer"
        onClick={handleClick}
      >
        Sign In
        <span className="tooltip-notuppercase mt-10 lowercase font-normal tracking-normal">
          to collaborate on captures, pdf's & more
        </span>
      </div>
    );
  }
  if (!currentUserInfo.isAnonymous) {
    return (
      <div
        className="w-full h-full flex justify-center items-center cursor-pointer"
        onClick={signOut}
      >
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M16 0H0V16H16V0Z" fill="white" fillOpacity="0.01" />
          <path
            d="M7.99723 2H2V14H8"
            stroke="black"
            strokeWidth="1.33333"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M11 11L14 8L11 5"
            stroke="black"
            strokeWidth="1.33333"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M5.33325 7.99724H13.9999"
            stroke="black"
            strokeWidth="1.33333"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    );
  }
  return null;
};

export default SignInOut;
