const CopyGroupIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2351_143299)">
      <path
        d="M5 4.1361V2.9165C5 2.22615 5.55963 1.6665 6.25 1.6665H17.0833C17.7737 1.6665 18.3333 2.22615 18.3333 2.9165V13.7498C18.3333 14.4402 17.7737 14.9998 17.0833 14.9998H15.8406"
        stroke="black"
        strokeWidth="1.66667"
      />
      <path
        d="M14.5832 4.1665H2.9165C2.22615 4.1665 1.6665 4.72615 1.6665 5.4165V17.0832C1.6665 17.7735 2.22615 18.3332 2.9165 18.3332H14.5832C15.2735 18.3332 15.8332 17.7735 15.8332 17.0832V5.4165C15.8332 4.72615 15.2735 4.1665 14.5832 4.1665Z"
        stroke="black"
        strokeWidth="1.66667"
        strokeLinejoin="round"
      />
      <path
        d="M7.68311 9.62938L9.88831 7.33375C10.4931 6.72888 11.4871 6.74221 12.1084 7.3635C12.7297 7.98479 12.7431 8.97879 12.1382 9.58363L11.3423 10.4265"
        stroke="black"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.61066 11.9785C5.39803 12.1911 4.95837 12.6157 4.95837 12.6157C4.35349 13.2206 4.33708 14.2986 4.95837 14.9199C5.57966 15.5412 6.57362 15.5545 7.17849 14.9497L9.33024 12.9962"
        stroke="black"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.77609 11.8036C7.4875 11.515 7.33009 11.146 7.30488 10.773C7.2758 10.3429 7.42242 9.90737 7.74634 9.5835"
        stroke="black"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.30054 10.7754C9.92183 11.3967 9.93516 12.3907 9.33029 12.9955"
        stroke="black"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2351_143299">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default CopyGroupIcon;
