import React, { useEffect, useState } from 'react';
import Button from '../Button';
import { useRecoilValue } from 'recoil';
import { currentUserInfoState } from '@/state/userState';
import Template from '../modal/Template';
import { Dialog } from '@headlessui/react';
import CircleClose from '../icon/CircleClose';
import Check from '../icon/Check';
import { useLocation, useSearchParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useUpgradePlanTeam } from '@/hooks/plan/useUpgradePlanTeam';

const UpgradePlanTeam = ({ isExpired, open = false, showButton = true }: any) => {
  const { isLoading: isLoadingUpgrade, upgradePlan } = useUpgradePlanTeam();

  const currentUserInfo = useRecoilValue(
    currentUserInfoState
  ) as Capture.Creator;
  const { planTeam, stripeSubscriptionId, stripeSubscriptionIdTeam } = currentUserInfo;

  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isOpen, setIsOpen] = useState(open);

  useEffect(() => {
    if (planTeam == "team" && location.search.includes('upgrade_to_pro_team=1')) {
      searchParams.delete('upgrade_to_pro_team');
      setSearchParams(searchParams);
      toast('You already have InstaCap Organization!', {
        icon: '🚀',
        duration: 3000,
        style: { maxWidth: 'initial' },
      });
    }
  }, [planTeam, searchParams])

  const openModal = () => setIsOpen(true);
  const closeModal = () => {
    setIsOpen(false);
    searchParams.delete('upgrade_to_pro_team');
    setSearchParams(searchParams);
  }

  const buyPlan = () => upgradePlan("team");

  return (
    <>
      {!stripeSubscriptionIdTeam && planTeam != 'team' &&
        <>
          {showButton &&
            <Button
              label={isExpired ? 'Upgrade to restore' : 'Upgrade to Pro'}
              style={{
                textWrap: 'nowrap',
                background:
                  'linear-gradient(90deg, #5B5BF2 -0.31%, #9155D8 45.14%, #B17AF1 76.96%, #854ACA 99.19%)',
                height: 24,
              }}
              textColor="white"
              className="text-xs font-semibold px-3 py-2.5 gap-2"
              childrenLeft
              onClick={openModal}
            >
              <svg
                width="10"
                height="13"
                viewBox="0 0 10 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.410004 6.00488L1.06625 0.661133C1.11313 0.286133 1.44125 0.00488281 1.81625 0.00488281H5.75375C6.10532 0.00488281 6.41 0.30957 6.41 0.661133C6.41 0.731445 6.38657 0.801758 6.36313 0.87207L5.285 3.75488H8.54282C9.01157 3.75488 9.41 4.15332 9.41 4.62207C9.41 4.80957 9.33969 4.97363 9.24594 5.11426L4.74594 11.7002C4.60532 11.8877 4.37094 12.0283 4.13657 12.0283H4.06625C3.69125 12.0283 3.41 11.7236 3.41 11.3486C3.41 11.3018 3.41 11.2314 3.43344 11.1846L4.535 6.75488H1.16C0.738129 6.75488 0.410004 6.42676 0.410004 6.00488Z"
                  fill="white"
                />
              </svg>
            </Button>
          }
          <Template isOpen={isOpen} closeModal={closeModal}>
            <div
              className={`inline-block w-full max-w-xl overflow-hidden text-left align-middle transition-all transform rounded-xl bg-white py-6 sm:mt-6 default-modal `}
              style={{ width: 460 }}
            >
              <Dialog.Title
                as="h3"
                className="text-2xl text-gray-900 leading-8 px-6"
              >
                <div className="flex mb-6">
                  <div className="w-3/4 flex flex-col">
                    <h4 className="fileupload-modal-heading">Upgrade to Organization</h4>
                    <span className="text-xs">
                      Get the most out of your visual collaboration.
                    </span>
                  </div>
                  <div className="w-1/4 float-right" onClick={closeModal}>
                    <div className="float-right mb-5">
                      <div className="flex items-center cursor-pointer">
                        <CircleClose />
                      </div>
                    </div>
                  </div>
                </div>
              </Dialog.Title>
              <div className="w-full h-px opacity-10 bg-black" />
              <div className="flex flex-col items-center mt-4 px-10">
                <img
                  src="/upgrade-to-pro.jpg"
                  style={{ height: 229, width: 276 }}
                />
                <div className="mt-6 flex items-center">
                  <span className="text-3xl font-normal">{'$25 '}</span>&nbsp;&nbsp;
                  <span className="text-base font-normal">{'/ mo. includes 5 users'}</span>
                </div>
                <div className="flex mt-4">
                  <h4 className="text-sm font-semibold uppercase">
                    {'Everything in'}
                    <a
                      href="https://www.instacap.co/pricing"
                      target="_blank"
                      className="pink-txt"
                    >
                      {' Pro Plan '}
                    </a>
                    {'plus:'}
                  </h4>
                </div>
                <div>
                  <div className="flex gap-2.5 mt-2">
                    <Check color={'#6AD500'} />
                    <p className="text-sm">{'Shared Workspaces'}</p>
                  </div>
                  <div className="flex gap-2.5 mt-2">
                    <Check color={'#6AD500'} />
                    <p className="text-sm">{'Enhanced Collaboration'}</p>
                  </div>
                  <div className="flex gap-2.5 mt-2">
                    <Check color={'#6AD500'} />
                    <p className="text-sm">{'Centralized Managements'}</p>
                  </div>
                  <div className="flex gap-2.5 mt-2">
                    <Check color={'#6AD500'} />
                    <p className="text-sm">{'Priority Support'}</p>
                  </div>
                </div>
                <Button
                  label={'Upgrade to Organization'}
                  backgroundColor="#ABFF57"
                  onClick={buyPlan}
                  isLoading={isLoadingUpgrade}
                  className="custom-btn px-6 mt-6 w-full uppercase justify-center"
                />
                <a href="https://www.instacap.co/pricing" target="_blank">
                  <h4 className="upload-url-txt mt-4 mb-1">{'Learn More'}</h4>
                </a>
              </div>
            </div>
          </Template>
        </>
      }
    </>
  );
};

export default UpgradePlanTeam;
