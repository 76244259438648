import { ProjectType } from '@/hooks/projects';
import { atom } from 'recoil';
import { Project } from 'types/project';

export const projectListState = atom<Project[]>({
  key: 'ProjectListState',
  default: [],
});

export const projectListStateWithCapturesState = atom<any>({
  key: 'ProjectListStateWithCaptures',
  default: [],
});

export const expiredProjectState = atom<any>({
  key: 'ExpiredProjectState',
  default: [],
});

export const currentProjectState = atom<ProjectType>({
  key: 'CurrentProjectState',
  default: { createdAt: 0, gid: '', name: '', numItems: 0, uid: '' },
});

export const currentProjectListState = atom<Project[]>({
  key: 'currentProjectList',
  default: [],
});
