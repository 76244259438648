const Settings = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_4047_13216)">
      <path
        d="M6.09452 14.3903C4.97749 14.0577 3.98318 13.4403 3.19588 12.6222C3.48959 12.2741 3.66659 11.8243 3.66659 11.3332C3.66659 10.2286 2.77116 9.3332 1.66659 9.3332C1.59977 9.3332 1.53372 9.33651 1.46858 9.34291C1.37985 8.90907 1.33325 8.45994 1.33325 7.99987C1.33325 7.303 1.44018 6.63111 1.63852 5.99967C1.64786 5.99981 1.65721 5.99987 1.66659 5.99987C2.77116 5.99987 3.66659 5.10444 3.66659 3.99987C3.66659 3.68277 3.59279 3.38294 3.46145 3.11654C4.23242 2.39967 5.17342 1.86318 6.21728 1.57422C6.54805 2.22257 7.22215 2.66655 7.99992 2.66655C8.77768 2.66655 9.45178 2.22257 9.78255 1.57422C10.8264 1.86318 11.7674 2.39967 12.5384 3.11654C12.4071 3.38294 12.3333 3.68277 12.3333 3.99987C12.3333 5.10444 13.2287 5.99987 14.3333 5.99987C14.3426 5.99987 14.352 5.99981 14.3613 5.99967C14.5597 6.63111 14.6666 7.303 14.6666 7.99987C14.6666 8.45994 14.62 8.90907 14.5313 9.34291C14.4661 9.33651 14.4001 9.3332 14.3333 9.3332C13.2287 9.3332 12.3333 10.2286 12.3333 11.3332C12.3333 11.8243 12.5103 12.2741 12.804 12.6222C12.0167 13.4403 11.0224 14.0577 9.90532 14.3903C9.64752 13.5838 8.89192 12.9999 7.99992 12.9999C7.10792 12.9999 6.35232 13.5838 6.09452 14.3903Z"
        stroke="black"
        strokeWidth="1.33333"
        strokeLinejoin="round"
      />
      <path
        d="M8.00008 10.3327C9.28875 10.3327 10.3334 9.28802 10.3334 7.99935C10.3334 6.71068 9.28875 5.66602 8.00008 5.66602C6.71141 5.66602 5.66675 6.71068 5.66675 7.99935C5.66675 9.28802 6.71141 10.3327 8.00008 10.3327Z"
        stroke="black"
        strokeWidth="1.33333"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_4047_13216">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default Settings;
