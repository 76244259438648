type Prop = {
  width?: number;
  height?: number;
};
const ArrowUpShare = (props?: Prop) => (
  <svg
    width={`${props?.width ? props.width : `20`}`}
    height={`${props?.height ? props.height : `20`}`}
    className="inline"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M20 0H0V20H20V0Z" fill="white" fillOpacity="0.01" />
    <path
      d="M5.41699 12.5L10.417 7.5L15.417 12.5"
      stroke="black"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default ArrowUpShare;
