import { FC, memo, useEffect, useRef } from 'react';
import { useRecoilValue } from 'recoil';
import { createPortal } from 'react-dom';
import clsx from 'clsx';

import { hoverMarkerState } from '@/state/markerState';
import { isShowAddCommentState } from '@/state/commentState';

interface CaptureAddCommentMarkerProps {
  show: boolean;
  left: string;
  top: string;
}

const CaptureAddCommentMarker: FC<CaptureAddCommentMarkerProps> = ({
  show,
  left,
  top,
}) => {
  const hoverMarker = useRecoilValue(hoverMarkerState);
  const isShowAddComment = useRecoilValue(isShowAddCommentState);

  const addCommentStyle = clsx({
    hidden: hoverMarker.index !== -1 || !isShowAddComment,
  });

  const markerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (markerRef.current) {
      markerRef.current.style.top = top;
      markerRef.current.style.left = left;
    }
  }, [left, top]);

  if (!show) return null;

  return createPortal(
    <div
      ref={markerRef}
      className={`${addCommentStyle} border-2 border-white absolute text-left whitespace-nowrap shadow-2xl font-normal tracking-wider text-white bg-black bg-opacity-70 px-2 py-1 text-xxxxs rounded-tl-md rounded-tr-2xl rounded-br-2xl rounded-bl-2xl `}
    >
      + ADD COMMENT
    </div>,
    document.body
  );
};

export default memo(CaptureAddCommentMarker);
