import { FC, useEffect, useState, useRef } from 'react';
import { Dialog } from '@headlessui/react';
import Template from './Template';
import FileUpload from '../FileUpload';
import type { ProcessServerConfigFunction } from 'filepond';
import { isDashBoardState, isFileUploadingState } from '@/state/uiState';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '@/hooks/useAuth';
import { User } from 'firebase/auth';
import axios from 'axios';
import {
  addingNewCaptureState,
  currentCaptureInfoState,
} from '@/state/captureState';
import CircleClose from '../icon/CircleClose';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { httpsCallable } from 'firebase/functions';
import { functions } from '@/lib/firebase/auth';

type Props = {
  isOpen: boolean;
  group?: string;
  closeModal: () => void;
  onProcess: ProcessServerConfigFunction;
  isReplaceImage: any;
  projectName?: string;
  updateShareInviteList?: Function;
  progress?: Number | null;
};

const FileUploadModal: FC<Props> = ({
  isOpen,
  closeModal,
  onProcess,
  group,
  isReplaceImage,
  projectName,
  updateShareInviteList,
  progress,
}) => {
  const setFileUploadingState = useSetRecoilState(isFileUploadingState);
  const navigate = useNavigate();

  const urlRef = useRef<null | HTMLInputElement>(null);
  const [urlErrorMessage, setUrlErrorMessage] = useState(
    'Invalid link. Please, try again!'
  );
  const [uploading, setUploading] = useState(false);
  const [valueEnter, setValueEnter] = useState(false);
  const [valideUrl, setValideUrl] = useState(true);
  const [isDragging, setIsDragging] = useState(false);
  const { getUserInfo } = useAuth();
  const user = getUserInfo() as User;
  const currentCap = useRecoilValue(currentCaptureInfoState);
  const setCurrentState = useSetRecoilState(currentCaptureInfoState);
  const setAddingNew = useSetRecoilState(addingNewCaptureState);
  let token = isReplaceImage ? '' : null;

  useEffect(() => {
    setFileUploadingState('NO-ACTION-PERFORM');
    if (!urlRef.current?.value) {
      setValideUrl(true);
      setValueEnter(false);
    }
  }, [isOpen]);

  const isDashBoard = useRecoilValue(isDashBoardState);

  const urlPatternValidation = () => {
    const expression =
      /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
    const regex = new RegExp(expression);
    return regex.test(urlRef.current?.value as string);
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (isReplaceImage && currentCap && currentCap?.url) {
      const url = new URL(currentCap?.url);
      token = url.searchParams.get('token');
    }

    if (!urlPatternValidation()) {
      setValideUrl(urlPatternValidation());
      return false;
    }
    setValideUrl(true);
    setUploading(true);

    const payload = {
      url: urlRef.current?.value,
      user:
        currentCap && currentCap?.creator.uid !== user.uid
          ? currentCap?.creator
          : user,
      group: isReplaceImage ? currentCap?.group : group,
      isReplaceImage: isReplaceImage,
      currentCap: isReplaceImage
        ? {
          cid: currentCap?.cid,
          uid: currentCap?.creator.uid,
          token: token,
          group: currentCap?.group,
        }
        : null,
      publicAccess: currentCap?.publicAccess,
      allowAnonymousCommenting: currentCap?.allowAnonymousCommenting,
      projectName: currentCap?.projectName,
    };

    try {
      const { data }: any = await httpsCallable(functions, 'uploadLiveurl')(payload);
      if (group && data.cid && updateShareInviteList) {
        await updateShareInviteList(data.cid);
      }
      setUploading(false);
      closeModal();
      setCurrentState(null);
      setCurrentState(currentCap);
      !isDashBoard && navigate(data.path, { replace: true });
      setAddingNew(true);

    } catch (error: any) {
      setUploading(false);
      setValideUrl(false);
      setUrlErrorMessage(error.message);
      console.log(error);
    }
  };

  return (
    <div
      onDragOver={(event) => {
        event.preventDefault();
        event.stopPropagation();
        document
          .getElementsByClassName('filepond--drop-label')[0]
          .classList.add('update-border');
        setIsDragging(true);
      }}
      onDragLeave={(event) => {
        event.preventDefault();
        event.stopPropagation();
        document
          .getElementsByClassName('filepond--drop-label')[0]
          .classList.remove('update-border');
      }}
      onDrop={(event) => {
        event.preventDefault();
        event.stopPropagation();
        document
          .getElementsByClassName('filepond--drop-label')[0]
          .classList.remove('update-border');
        setIsDragging(false);
      }}
    >
      <Template isOpen={isOpen} closeModal={closeModal}>
        <div
          className={`inline-block w-full max-w-xl  overflow-hidden text-left align-middle transition-all transform rounded-xl bg-white px-6  pt-6  ${!valideUrl && valueEnter ? 'pb-6' : 'pb-12'
            } sm:mt-6 default-modal `}
        >
          <Dialog.Title as="h3" className="text-2xl text-gray-900 leading-8">
            <div className="flex mb-4">
              <div className="w-3/4">
                <h4 className="fileupload-modal-heading">File Upload</h4>
              </div>
              <div className="w-1/4 float-right" onClick={closeModal}>
                <div className="float-right mb-5">
                  <div className="flex items-center cursor-pointer">
                    <CircleClose />
                  </div>
                </div>
              </div>
            </div>
          </Dialog.Title>
          <div className="flex">
            <div className="w-full">
              <div className="file-upload-div" tabIndex={0}>
                <div className="fileupload-dropdown">
                  <div className="container-upload mx-auto">
                    <FileUpload process={onProcess} isDragging={isDragging} />
                    {progress &&
                      <div className='absolute w-full flex flex-col justify-center items-center mt-8'>
                        <div className='w-24 h-24'>
                          <CircularProgressbar
                            value={Number(progress ?? 0)}
                            text={`${progress}%`}
                            background
                            backgroundPadding={6}
                            styles={buildStyles({
                              backgroundColor: "#abff57",
                              textColor: "#ff4bad",
                              pathColor: "#ff4bad",
                              trailColor: "transparent"
                            })}
                          />
                        </div>
                        {progress == 100 && <div>{"Larger files can take up to a minute to load or longer"}</div>}
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex">
            <div className="w-full relative">
              <h4 className="upload-url-txt mb-4">Or upload from a URL</h4>
              <form onSubmit={handleSubmit}>
                <input
                  type="text"
                  className=" upload-txtBox block p-2 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  autoFocus={false}
                  placeholder="Add the file URL"
                  ref={urlRef}
                  onChange={() => {
                    setValueEnter(urlRef.current?.value ? true : false);
                  }}
                />

                {/* add class border-danger if error shows  */}
                {/* green button upload-success default button upload-btn-default                 */}

                <button
                  disabled={uploading || !valueEnter}
                  type="submit"
                  className={`h-10 w-20 text-white rounded-lg upload-btn justify-center upload-default mx-auto mt-2 ${!valueEnter
                    ? 'upload-btn-default cursor-not-allowed'
                    : 'upload-success'
                    }   ${uploading ? 'cursor-not-allowed   ' : ''}`}
                >
                  {uploading ? (
                    <div className="font-rubik w-full h-full flex flex-col justify-center items-center">
                      <div
                        style={{ borderTopColor: 'transparent' }}
                        className="w-5 h-5 border-1 border-black border-solid rounded-full animate-spin-fast "
                      ></div>
                    </div>
                  ) : (
                    'Upload'
                  )}
                </button>
              </form>
            </div>
          </div>
          <div className="flex">
            {!valideUrl && valueEnter && (
              <div
                className=" text-center text-red-700 pt-2 rounded relative"
                role="alert"
              >
                <span className="block sm:inline upload-error">
                  {urlErrorMessage}
                </span>
                <span className="absolute top-0 bottom-0 right-0 px-4 py-3"></span>
              </div>
            )}
          </div>
        </div>
      </Template>
    </div>
  );
};

export default FileUploadModal;
