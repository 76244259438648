import { FC, useEffect, useState } from 'react';
import { useAuth } from '@/hooks/useAuth';
import Loading from './Loading';
import { Outlet } from 'react-router';
import SyncWithExtension from '@/components/modal/SyncWithExtension';
import AuthProblem from '@/components/modal/AuthProblem';
import * as chromeExtension from '@/lib/chromeExtension';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { isAppLoadingState, isCheckExtensionState, isExtensionActive } from '@/state/appState';
import { useMixpanel } from '@/hooks/useMixpanel';
import { User } from 'firebase/auth';
import { GetCaptureList } from '.';
import GetCaptureListSharedWithMe from './GetSharedWithMeCaputreList';
import { isdisplayNameSettedState } from '@/state/userState';
import { appState } from '@/state/appState';
import { useLocation, useSearchParams } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import { SignedIn, SignedOut, useClerk, useSession } from '@clerk/clerk-react';
import UpgradePlan from './dashboard/UpgradePlan';
import { httpsCallable } from 'firebase/functions';
import { functions } from '@/lib/firebase/auth';
import UpgradePlanTeam from './dashboard/UpgradePlanTeam';

const Auth: FC = () => {
  const mixpanel = useMixpanel();
  const { init, getStoredUserInfo, setAvatar, updatePlan, getUserInfo } = useAuth();

  const [isSetApp, setIsSetApp] = useState(false);

  const [isCheckExtension, setIsCheckExtension] = useRecoilState(
    isCheckExtensionState
  );
  const [isActive, setExtensionActive] = useRecoilState(isExtensionActive);
  const setIsDisplayNameSetted = useSetRecoilState(isdisplayNameSettedState);
  const location = useLocation();

  const [searchParams, setSearchParams] = useSearchParams();

  const { loaded, openSignIn, openSignUp } = useClerk();
  const { isSignedIn } = useSession();
  const [isAppLoading, setIsAppLoading] = useRecoilState(isAppLoadingState);
  // const setIsAppLoading = useSetRecoilState(isAppLoadingState);

  const [isReady, setIsReady] = useRecoilState(appState);
  const [openUpgrade, setOpenUpgrade] = useState(false);
  const [openUpgradeTeam, setOpenUpgradeTeam] = useState(false)

  useEffect(() => {
    if (loaded && !isSignedIn) {
      setIsAppLoading(false)
    }

    if (isReady && isSetApp && !isAppLoading) {
      if (location.search.includes('open_login=1')) {
        searchParams.delete('open_login');
        setSearchParams(searchParams);
        if (!isSignedIn) {
          openSignIn({ redirectUrl: location.pathname + location.search });
        }
      }
      if (location.search.includes('open_sign_up=1')) {
        searchParams.delete('open_sign_up');
        setSearchParams(searchParams);
        if (!isSignedIn) {
          openSignUp({ redirectUrl: location.pathname + location.search });
        }
      }

      if (location.search.includes('upgrade_to_pro=1')) {
        if (isSignedIn) {
          setOpenUpgrade(true);
        }
      }

      if (location.search.includes('upgrade_to_pro_team=1')) {
        if (isSignedIn) {
          // console.log("->>>",isSetApp)
          setOpenUpgradeTeam(true);
        }
      }
    }
  }, [loaded, isSignedIn, isReady, isSetApp, isAppLoading])

  useEffect(() => {
    if (location.search.includes('successSubscribe=1&session=')) {
      toast('Congratulations! You Just Leveled Up to InstaCap Pro!', {
        icon: '🚀',
        duration: 6000,
        style: { maxWidth: 'initial' },
      });
      searchParams.delete('successSubscribe');
      searchParams.delete('session');
      setSearchParams(searchParams);
      updatePlan();
    }

    if(location.search.includes('code=')) {
      setIsSetApp(true);
      
      const getAppSumoLicense = async (code: string) => {
        const { data }: any = await httpsCallable(
          functions,
          'getAppSumoAccessToken'
        )({code});
    
        // console.log("LICENSE data ::_>",data)
        window.location.replace(data?.redirect_url)
        searchParams.delete('open_sign_up');
      }

      const authorizationCode = searchParams.get('code')
      authorizationCode && getAppSumoLicense(authorizationCode)
    }
    
    if (location.search.includes('successSubscribeTeam=1&session=')) {
      toast('Congratulations! You Just Leveled Up to InstaCap Organization!', {
        icon: '🚀',
        duration: 6000,
        style: { maxWidth: 'initial' },
      });
      searchParams.delete('successSubscribeTeam');
      searchParams.delete('session');
      setSearchParams(searchParams);
      updatePlan();
    }

  }, []);

  useEffect(() => {
    console.log('Calling Use Affect from Chrome Extension Init');
    chromeExtension.init((response: any) => {
      if (!isActive && response) {
        console.log(`Chrome Extension Active is Setting true`);
        setExtensionActive(true);
      }
      console.log(
        `Chrome Extension Has Been Checked-After Checking Extension Next Use Affect to Init User Should Call`
      );
      setIsCheckExtension(true);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isCheckExtension) return;
    console.log(
      `Chrome Extension is Checked ${isCheckExtension} Now Checking User`
    );
    setIsSetApp(true);
    init();

    if (!isReady) setIsReady(true);

    // const unsub = init(async (user: User) => {
    //   // console.log(`Init Giving CallBack When User Resturned ${JSON.stringify(user)}`)
    //   if (user && !user.isAnonymous && user.email) {
    //     mixpanel.setUser(user.email, user.displayName || '', user.uid);
    //     user.providerData.forEach((profile) => {
    //       if (profile.photoURL) {
    //         mixpanel.setAvatar(profile.photoURL);
    //         setAvatar(user.uid, profile.photoURL);
    //       }
    //     });
    //   }
    //   if (user && user.isAnonymous && user.email) {
    //     mixpanel.setUser(user.email, user.displayName || '', user.uid);
    //   }
    //   const storedUserInfo = await getStoredUserInfo(user.uid);
    //   // console.log(`Setting is DisplayName:${storedUserInfo.isDisplayNameSetted}`)
    //   setIsDisplayNameSetted(
    //     storedUserInfo && storedUserInfo?.isDisplayNameSetted !== undefined
    //       ? storedUserInfo.isDisplayNameSetted
    //       : false
    //   );
    //   if (user && user.isAnonymous && !user.email) {
    //     if (storedUserInfo.email) {
    //       mixpanel.setUser(
    //         storedUserInfo.email,
    //         user.displayName || '',
    //         user.uid
    //       );
    //     }
    //   }

    //   console.log(
    //     `App is Setted True No Launching Application/[Actually After Get View] .....`
    //   );

    //   setIsSetApp(true);
    // });
    return () => {
      //@ts-ignore
      // unsub && unsub();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCheckExtension]);

  if (!isReady || !isSetApp) {
    return <Loading />;
  }

  return (
    <>
      <SignedIn>
        <GetCaptureList />
        <GetCaptureListSharedWithMe />
        {openUpgrade && <UpgradePlan open showButton={false} />}
        {openUpgradeTeam && <UpgradePlanTeam open showButton={false} />}
      </SignedIn>
      <Toaster
        position="top-center"
        containerClassName=""
        toastOptions={{
          className: 'toaster-notification',
          duration: 2000,
          style: {
            fontFamily: 'poppins',
            fontSize: '16px',
            maxWidth: 'initial',
            padding: '20px',
            boxShadow: '0px 12px 30px rgba(0, 0, 0, 0.3)',
            backgroundColor: '#FFF',
            border: '1px solid #000',
            color: '#000',
            fontWeight: 600,
            borderRadius: '8px',
          },
        }}
      />
      <SyncWithExtension />
      <AuthProblem />
      <Outlet />
    </>
  );
};

export default Auth;
