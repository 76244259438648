import { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import cn from 'classnames';

import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useTool } from '@/hooks/tool/useTool';
import { useBoundingBox } from '@/hooks/tool/useBoundingBox';

import { addTextState } from '@/state/textState';
import { currentToolState } from '@/state/toolState';

const ToolObjectText: FC<{
  rect: Rect.Info;
  onRectUpdate: (rect: Rect.Info) => void;
  color?: string;
  getText: (text: string) => void;
  text?: string;
  id: string;
  currentFont: number;
  textUpdate: string;
}> = ({
  rect,
  color,
  getText,
  text,
  id,
  currentFont,
  textUpdate,
  onRectUpdate,
}) => {
  const { handleClickToolMenu } = useTool();
  const { setIsBoundingBoxShow } = useBoundingBox();
  // const [text, setText] = useState<string>(propText);
  const el = useRef<HTMLTextAreaElement>(null);
  const currentTool = useRecoilValue(currentToolState);
  const addText = useSetRecoilState(addTextState);
  const [isFocused, setIsFocused] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (el?.current) {
      el.current.focus();
    }
  }, [el]);

  useEffect(() => {
    if (isFocused && currentTool !== 'TEXT') {
      handleClickToolMenu('TEXT');
      setIsBoundingBoxShow(true);
    }
  }, [currentTool, handleClickToolMenu, isFocused, setIsBoundingBoxShow]);

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    // setText(e.currentTarget.value);
    // @ts-ignore
    addText({ id, text: e.currentTarget.value });
    getText(e.currentTarget.value);

    const height = e.target.scrollHeight;
    contentRef!.current!.innerText = e.currentTarget.value;
    const width = contentRef.current?.clientWidth ?? 0;
    onRectUpdate({
      ...rect,
      height: height + 8,
      width: width + currentFont + 6,
    });
  };

  return (
    <div className="relative">
      {!isFocused && (
        <div
          className="absolute left-0 top-0 w-full h-full"
          onDoubleClick={() => {
            el.current?.focus();
            setIsFocused(true);
          }}
        />
      )}
      <span
        ref={contentRef}
        className="fixed overflow-auto w-min invisible"
        style={{
          fontSize: currentFont,
          fontFamily: 'Times New Roman',
          fontWeight: 700,
          padding: '0 5px',
          margin: '5px 7px',
          wordBreak: 'keep-all',
          whiteSpace: 'nowrap',
          lineHeight: 1.6,
        }}
      />
      <textarea
        ref={el}
        tabIndex={-1}
        className={cn('instacap-textarea', `text-2xl`, {
          '!cursor-text': currentTool === 'MOVE',
          'cursor-auto': currentTool !== 'MOVE',
        })}
        style={{
          width: rect.width,
          height: rect.height - 8,
          fontSize: currentFont,
          // lineHeight: `${
          //   Math.round(rect.width / 20) + Math.round(rect.height / 45) + 10
          // }px`,
          lineHeight: 1.6,
          color: color,
        }}
        cols={21}
        rows={1}
        wrap="hard"
        placeholder={
          document.activeElement !== el.current ? 'Enter text..' : ''
        }
        spellCheck={false}
        value={text}
        onChange={handleChange}
        onBlur={() => {
          if (!text) {
            onRectUpdate({ ...rect, height: currentFont + 22, width: 128 });
          }
          setIsFocused(false);
        }}
        name="textarea"
        onMouseDownCapture={(e) => {
          if (currentTool === 'MOVE' || currentTool === 'TEXT') {
            e.stopPropagation();
          }
        }}
        onMouseUpCapture={(e) => {
          if (currentTool === 'MOVE' || currentTool === 'TEXT') {
            e.stopPropagation();
          }
        }}
        onKeyDown={(e) => {
          e.stopPropagation();
        }}
        onKeyUp={(e) => {
          e.stopPropagation();
        }}
        onKeyPress={(e) => {
          e.stopPropagation();
        }}
      />
    </div>
  );
};

export default ToolObjectText;
