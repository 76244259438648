import React from 'react';
import { getTrackBackground, Range } from 'react-range';

interface CustomSliderProps {
  label: string;
  value: number;
  onChange: (value: number) => void;
  min?: number;
  max?: number;
  step?: number;
  className?: string;
}

const CustomSlider: React.FC<CustomSliderProps> = ({
  label,
  value,
  onChange,
  min = 0.5,
  max = 2,
  step = 0.1,
  className = '',
}) => {
  const [values, setValues] = React.useState([value]);

  React.useEffect(() => {
    setValues([value]);
  }, [value]);

  return (
    <div className={"flex w-full items-center  py-2" }>
      <label className='text-xs' style={{ flex: 2 }}>{label}</label>
      <div style={{ flex: 3 }}>
        <Range
          values={values}
          step={step}
          min={min}
          max={max}
          onChange={(values) => {
            setValues(values);
            onChange(values[0]);
          }}
          renderTrack={({ props, children }) => (
            <div
              onMouseDown={props.onMouseDown}
              onTouchStart={props.onTouchStart}
              style={{
                ...props.style,
                height: '32px',
                display: 'flex',
                width: '100%',
              }}
            >
              <div
                ref={props.ref}
                style={{
                  height: '6px',
                  width: '100%',
                  borderRadius: '100px',
                  backgroundColor: '#FFFFF',
                  border: '1px solid black',
                  background: getTrackBackground({
                    values: values,
                    colors: ['#000000', '#ffffff'],
                    min: min,
                    max: max,
                  }),
                  alignSelf: 'center',
                }}
              >
                {children}
              </div>
            </div>
          )}
          renderThumb={({ props, isDragged }) => (
            <div
              {...props}
              style={{
                ...props.style,
                height: '20px',
                width: '20px',
                borderRadius: '100px',
                backgroundColor: '#FFF',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                boxShadow: '1px 1px 0px #000000',
                background: '#FFFFFF',
                border: '1px solid #000000',
              }}
            >
              <div
                className="outline-none"
                style={{
                  height: '16px',
                  width: '30px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  position: 'absolute',
                  top: -20,
                  left: -6,
                  color: '#808080',
                  fontSize: '12px',
                }}
              >
                {values[0].toFixed(1)}
              </div>
            </div>
          )}
        />
      </div>
    </div>
  );
};

export default CustomSlider;