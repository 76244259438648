import { memo } from "react";

const ThreeVerticalDots = ({ width = 16, height = 16 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1_11084)">
        <path d="M16 0L0 0L0 16L16 16L16 0Z" fill="white" fillOpacity="0.01" />
        <path
          d="M8 5C8.55228 5 9 4.55228 9 4C9 3.44772 8.55228 3 8 3C7.44772 3 7 3.44772 7 4C7 4.55228 7.44772 5 8 5Z"
          fill="black"
        />
        <path
          d="M8 9C8.55228 9 9 8.55228 9 8C9 7.44772 8.55228 7 8 7C7.44772 7 7 7.44772 7 8C7 8.55228 7.44772 9 8 9Z"
          fill="black"
        />
        <path
          d="M8 12.6667C8.55228 12.6667 9 12.219 9 11.6667C9 11.1144 8.55228 10.6667 8 10.6667C7.44772 10.6667 7 11.1144 7 11.6667C7 12.219 7.44772 12.6667 8 12.6667Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_11084">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default memo(ThreeVerticalDots);
