import { atom, selector } from 'recoil';
import {
  canCommentState,
  currentCaptureInfoState,
  currentUserRoleForCaptureState,
} from './captureState';
import { currentCommentState } from './commentState';
import type { User } from 'firebase/auth';
import { slackUserInfo } from 'types/slackUserInfo';
import { getUser } from '@/lib/firebase/firestore';

export const currentUserIDState = atom<string>({
  key: 'CurrentUserID',
  default: '',
});

export const currentUserSubscriptionState = selector<string>({
  key: 'CurrentUserSubscription',
  get: async ({ get }) => {
    const currentUserID = get(currentUserIDState);
    if (!currentUserID) return null;

    const getCurrentUserSubscription = async (currentUserID: string) => {
      try {
        const meUser = await getUser(currentUserID);
        if (meUser) {
          if (meUser?.plan) {
            return meUser.plan;
          } else {
            return 'free';
          }
        }
        return null;
      } catch (error) {
        console.error('Eroare:', error);
        return null;
      }
    };

    try {
      const userSubscription = await getCurrentUserSubscription(currentUserID);
      return userSubscription;
    } catch (error) {
      console.error('Eroare:', error);
      return null;
    }
  },
});

export const userSubscriptionState = atom<string>({
  key: 'userSubscription',
  default: '',
});

export const currentUserInfoState = atom<any | {}>({
  key: 'CurrentUserInfo',
  default: {},
});

export const slackUserInfoState = atom<slackUserInfo>({
  key: 'SlackUserInfo',
  default: {
    isConnected: false,
  },
});

export const isAnonymousState = selector<boolean>({
  key: 'IsAnonymous',
  get: ({ get }) => {
    const currentUserInfo = get(currentUserInfoState) as User;
    return !!currentUserInfo.isAnonymous;
  },
});

export const isNeedEmailState = selector<boolean>({
  key: 'IsNeedEmail',
  get: ({ get }) => {
    const currentUserInfo = get(currentUserInfoState) as User;
    if (!currentUserInfo) return true;
    return !!currentUserInfo.isAnonymous && !currentUserInfo.email;
  },
});

export const isCaptureMineState = selector<boolean>({
  key: 'IsCaptureMine',
  get: ({ get }) => {
    const currentUserInfo = get(currentUserInfoState) as User;
    const currentCaptureInfo = get(currentCaptureInfoState) as Capture.Info;
    if (!currentCaptureInfo?.creator) return true;
    return currentUserInfo.uid !== currentCaptureInfo.creator.uid;
  },
});

export const newCommentUidsState = selector<
  { captureCreatorUid: string; userUid: string } | boolean
>({
  key: 'NewCommentUids',
  get: ({ get }) => {
    const currentUserInfo = get(currentUserInfoState) as User;
    const currentCaptureInfo = get(currentCaptureInfoState) as Capture.Info;
    if (!currentCaptureInfo?.creator) return false;
    if (currentUserInfo.uid !== currentCaptureInfo.creator.uid) {
      return {
        captureCreatorUid: currentCaptureInfo.creator.uid,
        userUid: currentUserInfo.uid,
      };
    } else {
      return false;
    }
  },
});

export const isCommentMineState = selector<boolean>({
  key: 'IsCommentMine',
  get: ({ get }) => {
    const currentUserInfo = get(currentUserInfoState) as User;
    const currentCaptureInfo = get(currentCaptureInfoState) as Capture.Info;
    const currentComment = get(currentCommentState) as Capture.CurrentComment;
    if (!currentCaptureInfo?.comments) return true;
    const comment = currentCaptureInfo.comments[currentComment.commentIndex];
    if (!comment) return true;
    return currentUserInfo.uid === comment.creator.uid;
  },
});

export const newReplyUidsState = selector<
  { replyerUids: string[]; userUid: string } | boolean
>({
  key: 'NewReplyUids',
  get: ({ get }) => {
    const currentUserInfo = get(currentUserInfoState) as User;
    const currentCaptureInfo = get(currentCaptureInfoState) as Capture.Info;
    const currentComment = get(currentCommentState) as Capture.CurrentComment;
    if (!currentCaptureInfo?.comments) return false;
    const comment = currentCaptureInfo.comments[currentComment.commentIndex];
    if (!comment) return false;

    const replyerUids = Array.from(
      new Set([
        currentUserInfo.uid,
        comment.creator.uid,
        ...(comment.replies?.map(({ creator }) => creator.uid) || []),
      ])
    );

    return {
      userUid: replyerUids.shift() || '',
      replyerUids,
    };
  },
});
export const canUpdateProjectNameState = selector<boolean>({
  key: 'canUpdateProjectName',
  get: ({ get }) => {
    const currentUserRole = get(currentUserRoleForCaptureState);
    if (currentUserRole.Role === 'ADMIN') return true;
    if (currentUserRole.Role === 'VIEW' || currentUserRole.Role === 'ILLEGAL')
      return false;
    if (currentUserRole.Role === 'EDIT' && currentUserRole.isGroupShare)
      return true;

    return false;
  },
});
export const canUpdateCaptureNameState = selector<boolean>({
  key: 'canUpdateCaptureName',
  get: ({ get }) => {
    const currentUserRole = get(currentUserRoleForCaptureState);
    if (currentUserRole.Role === 'ADMIN') return true;
    if (currentUserRole.Role === 'EDIT') return true;
    if (currentUserRole.Role === 'VIEW' || currentUserRole.Role === 'ILLEGAL')
      return false;

    return false;
  },
});
export const canAddNewCommentState = selector<boolean>({
  key: 'canAddNewComment',
  get: ({ get }) => {
    const currentUserRole = get(currentUserRoleForCaptureState);
    const canCommentAnyOne = get(canCommentState);
    if (
      currentUserRole.Role === 'ADMIN' ||
      canCommentAnyOne ||
      currentUserRole.Role === 'EDIT'
    )
      return true;
    if (currentUserRole.Role === 'VIEW' || currentUserRole.Role === 'ILLEGAL')
      return false;

    return false;
  },
});
export const canReplyState = selector<boolean>({
  key: 'canReply',
  get: ({ get }) => {
    const currentUserRole = get(currentUserRoleForCaptureState);
    // const publicAccess = get(currentCaptureIsPrivateState);
    const canCommentAnyOne = get(canCommentState);

    if (
      currentUserRole.Role === 'ADMIN' ||
      canCommentAnyOne ||
      currentUserRole.Role === 'EDIT'
    )
      return true;
    if (currentUserRole.Role === 'VIEW' || currentUserRole.Role === 'ILLEGAL')
      return false;

    return false;
  },
});
export const isdisplayNameSettedState = atom<boolean | undefined>({
  key: 'DisplayNameSetted',
  default: false,
});
export const updateDisplayNameState = atom<{
  updateName: boolean;
  isMain: boolean;
}>({
  key: 'updateDisplayName',
  default: {
    updateName: false,
    isMain: false,
  },
});

export const profileModalState = atom<boolean | undefined>({
  key: 'profileModalState',
  default: false,
});

export const nextState = atom<{ next: Function; isUpdate: boolean }>({
  key: 'next',
  default: {
    next: () => {
      console.log(
        `Next Tick Process is Calling When Update functionality will get Completed`
      );
    },
    isUpdate: false,
  },
});
