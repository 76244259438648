type Props = {
  classname: string;
};

const ArrowIcon = ({ classname }: Props) => {
  return (
    <svg
      className={'rounded-3xl hover:bg-green hover:border-black ' + classname}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0833 15.417H4.58331V7.91699"
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.58337 15.4172L15.19 4.81055"
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ArrowIcon;
