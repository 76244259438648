import { FC, memo } from 'react';

interface props {
  width?: number;
  height?: number;
  opacity?: number;
  fill?: string;
  strokeColor?: string;
}

const MuscleIcon: FC<props> = ({
  width = 18,
  height = 18,
  opacity = 1,
  fill = 'none',
  strokeColor = '#808080',
}) => {
  return (
    <svg
      width={width}
      height={height}
      fill={fill}
      opacity={opacity}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3504_23214)">
        <path
          d="M7.12339 12.0002C7.60672 10.2502 9.29672 9.00016 11.2434 9.20683C13.0967 9.4035 14.5701 10.9668 14.6634 12.8268C14.6867 13.3168 14.6201 13.7868 14.4767 14.2235C14.3901 14.4902 14.1301 14.6668 13.8467 14.6668H3.91952C2.23673 14.6668 0.974624 13.1273 1.30464 11.4772L3.33337 1.3335H7.33335L8.66669 3.66683L5.81005 5.71016L5.00002 4.66683"
          stroke={strokeColor}
          strokeWidth="1.33333"
          strokeMiterlimit="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.81299 5.70996L7.33299 11.3333"
          stroke={strokeColor}
          strokeWidth="1.33333"
          strokeMiterlimit="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3504_23214">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default memo(MuscleIcon);
