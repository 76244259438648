import dayjs from 'dayjs';
import {
  getFirestore,
  collection,
  getDocs,
  onSnapshot,
  query,
  where,
  orderBy,
  getDoc,
  doc,
  updateDoc,
  arrayUnion,
  arrayRemove,
  deleteDoc,
  setDoc,
} from 'firebase/firestore';
import { nanoid } from 'nanoid';
import * as storage from '@/lib/firebase/storage';

const DB_PREFIX = 'instacap/captures';
const DB_INVITE_PREFIX = 'instacap/invite';

export const saveCapture = async (uid: string, cid: string, data: any) => {
  try {
    const db = getFirestore();
    const colRef = collection(db, 'instacap', 'captures', uid);
    const docRef = doc(colRef, cid);
    await setDoc(docRef, data);
    return () => {
      deleteDoc(docRef);
    };
  } catch (error) {
    console.error('Error adding document: ', error);
  }
};
//Here Updating or Inserting New Field if doesnt exits So it will Add New Name against UID and CID which will update
//New Field Name in Current Capture Information.

export const updateCapture = async (uid: string, cid: string, info: object) => {
  try {
    const db = getFirestore();
    const colRef = collection(db, 'instacap', 'captures', uid);
    await updateDoc(doc(colRef, cid), { updatedAt: dayjs().unix(), ...info });
  } catch (error) {
    console.log('Error update user: ', error);
  }
};

export const getList = async (uid: string, cb: Function) => {
  try {
    const db = getFirestore();
    const colRef = collection(db, `${DB_PREFIX}/${uid}`);

    const q = query(colRef, orderBy('createdAt', 'desc'));

    // Add real-time listener to query
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const result: Capture.Info[] = [];
      querySnapshot.forEach((docSnap) => {
        if (docSnap.exists()) {
          result.push(docSnap.data() as Capture.Info);
        }
      });

      result.forEach((capture) => {
        if (!capture.expiredAt) {
          let startDate = new Date();
          startDate.setDate(startDate.getDate() + 30);
          let timestamp = Math.floor(startDate.getTime() / 1000);

          updateCapture(uid, capture.cid, { expiredAt: timestamp });
        }

        if (capture.isDeleted === null || capture.isDeleted === undefined) {
          updateCapture(uid, capture.cid, { isDeleted: false });
        }
      });

      cb(result);
    });

    // Return unsubscribe function to clean up listener
    return unsubscribe;
  } catch (error) {
    console.log(error);
  }
};

export const getSub = (uid: string, cb: Function) => {
  try {
    const db = getFirestore();
    const colRef = collection(db, `${DB_PREFIX}/${uid}`);

    const q = query(colRef, where('createdAt', '>', dayjs().unix()));

    const unsub = onSnapshot(q, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.type === 'added') {
          cb(change.doc.data());
        }
      });
    });

    return () => {
      unsub();
    };
  } catch (error) {
    console.log(error);
    return () => { };
  }
};

export const setGId = async (uid: string, group: string) => {
  const db = getFirestore();
  const colRef = collection(db, 'instacap', 'publicCaptures', uid);
  // find gid
  const q = query(colRef, where('group', '==', group));
  const colSnap = await getDocs(q);
  if (colSnap.size > 0 && colSnap.docs[0].exists()) {
    return colSnap.docs[0].id;
  }
  // create gid
  const gid = nanoid();
  const docRef = doc(db, 'instacap', 'publicCaptures', uid, gid);
  await setDoc(docRef, { group });
  return gid;
};

export const getGroupByGId = async (
  uid: string,
  gid: string,
  successCb: Function,
  failCb: Function
) => {
  const db = getFirestore();
  const docRef = doc(db, 'instacap', 'publicCaptures', uid, gid);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    const { group } = docSnap.data() as { group: string };
    successCb(uid, group);
  } else {
    failCb();
  }
};

export const getListByGroup = async (
  uid: string,
  group: string,
  cb: Function
) => {
  try {
    const db = getFirestore();
    const colRef = collection(db, `${DB_PREFIX}/${uid}`);

    const q = query(colRef, where('group', '==', group));

    const colSnap = await getDocs(q);

    const result: Capture.Info[] = [];

    colSnap.forEach((docSnap) => {
      if (docSnap.exists()) {
        result.push(docSnap.data() as Capture.Info);
      }
    });
    cb(result);
  } catch (error) {
    console.log(error);
  }
};

export const getListByGroupSub = (uid: string, group: string, cb: Function) => {
  try {
    const db = getFirestore();
    const colRef = collection(db, `${DB_PREFIX}/${uid}`);

    const q = query(colRef, where('group', '==', group));

    const unsub = onSnapshot(q, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        cb(change.type, change.doc.data());
      });
    });

    return () => {
      unsub();
    };
  } catch (error) {
    console.log(error);
    return () => { };
  }
};

export const getPublicOne = async (uid: string, cid: string, cb: Function) => {
  try {
    const db = getFirestore();
    const docRef = doc(db, `${DB_PREFIX}/${uid}/${cid}`);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      cb(docSnap.data() as Capture.Info);
    } else {
      cb(null);
    }
  } catch (error) {
    console.log(error);
  }
};

export const getCaptureInfoSub = (uid: string, cid: string, cb: Function) => {
  try {
    const db = getFirestore();
    const docRef = doc(db, `${DB_PREFIX}/${uid}/${cid}`);

    const unsub = onSnapshot(
      docRef,
      (doc) => {
        cb(doc.data());
      },
      (err) => {
        console.log(err);
      }
    );

    return () => {
      unsub();
    };
  } catch (error) {
    console.error('getCaptureInfoSub', error);
    return () => { };
  }
};

export const setCaptureComment = async (
  uid: string,
  cid: string,
  comment: Capture.Comment
) => {
  const db = getFirestore();
  const docRef = doc(db, `${DB_PREFIX}/${uid}/${cid}`);

  await updateDoc(docRef, {
    comments: arrayUnion(comment),
  });
};

export const saveCaptureCreateTool = async (
  uid: string,
  cid: string,
  newTool: any
) => {
  const db = getFirestore();
  const docRef = doc(db, `${DB_PREFIX}/${uid}/${cid}`);
  // const docSnap = await getDoc(docRef);
  // const data: any = docSnap.data();

  await updateDoc(docRef, {
    tools: arrayUnion(newTool),
  });

  // console.log('DATA_ARRAY', newTool);
};

export const setCaptureTool = async (
  uid: string,
  cid: string,
  toolObg: Tool.Object
) => {
  const db = getFirestore();
  const docRef = doc(db, `${DB_PREFIX}/${uid}/${cid}`);
  const docSnap = await getDoc(docRef);
  const data: any = docSnap.data();

  if (data) {
    if (!data.tools) {
      await updateDoc(docRef, {
        tools: arrayUnion(toolObg),
      });
    } else {
      if (!data.tools.length) {
        await updateDoc(docRef, {
          tools: arrayUnion(toolObg),
        });
      } else {
        const toolIsExist = data.tools.some(
          (tool: any) => tool.id === toolObg.id
        );

        if (!toolIsExist) {
          await updateDoc(docRef, { tools: arrayUnion(toolObg) });
        } else {
          const newTools = data.tools.map((tool: any) => {
            if (tool.id === toolObg.id) {
              tool = {
                ...toolObg,
                ...{ text: toolObg.text },
              };
            }
            return tool;
          });

          await updateDoc(docRef, {
            tools: newTools,
          });
        }
      }
    }
  } else {
    console.error('Error: there is no data for the document');
  }
};

export const deleteCaptureTool = async (
  uid: string,
  cid: string,
  toolId: any
) => {
  const db = getFirestore();
  const docRef = doc(db, `${DB_PREFIX}/${uid}/${cid}`);
  const docSnap = await getDoc(docRef);

  const data: any = docSnap.data();
  const updateArrayTools = data.tools.find(
    (tool: any) => tool.id === toolId.tool
  );

  // console.log('updateArrayTools', updateArrayTools);
  await updateDoc(docRef, {
    tools: arrayRemove(updateArrayTools),
  });
};

export const setCaptureCommentReply = async (
  uid: string,
  cid: string,
  comments: Capture.Comment[]
) => {
  const db = getFirestore();
  const docRef = doc(db, `${DB_PREFIX}/${uid}/${cid}`);
  await updateDoc(docRef, {
    comments,
  });
};

export const deleteCapture = async (uid: string, cid: string, deleteDefinitively: boolean = false) => {
  try {
    const db = getFirestore();
    const docRef = doc(db, `${DB_PREFIX}/${uid}/${cid}`);
    if (deleteDefinitively) {
      await deleteDoc(docRef);
    } else {
      await updateDoc(docRef, {
        isDeleted: true,
      });
    }
  } catch (error) {
    console.error('deleteCapture', error);
  }
};

export const setCaptureInvite = async (
  uid: string,
  cid: string,
  data: Capture.InvitationData
) => {
  const { updatedAt, invitation } = data;

  try {
    const db = getFirestore();
    const docRef = doc(db, `${DB_INVITE_PREFIX}/${uid}/${cid}`);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      await updateDoc(docRef, {
        updatedAt,
        invitations: arrayUnion(invitation),
      });
    } else {
      await setDoc(docRef, {
        updatedAt,
        invitations: [invitation],
      });
    }
    return {
      // message: `Your invitation to ${invitation.emails.join()} has been successfully sent.`,
      message: 'Email sent and Access Updated Successfully',
    };
  } catch (err) {
    console.error('Setting capture invitetion error:', err);
  }
};

export const addViewer = async (
  uid: string,
  cid: string,
  currentUserID: string,
  noDataCb: Function
) => {
  try {
    const db = getFirestore();
    const docRef = doc(db, `${DB_PREFIX}/${uid}/${cid}`);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const data = docSnap.data() as Capture.Info;
      if (!data.view.viewer.includes(currentUserID)) {
        const viewer = [...data.view.viewer, currentUserID];
        await updateDoc(docRef, {
          view: {
            count: viewer.length,
            viewer,
          },
        });
      }
    } else {
      noDataCb();
    }
  } catch (error) {
    console.log(error);
  }
};
export const deleteProjectCapture = async (
  uid: string,
  group: string,
  cb: Function
) => {
  try {
    const db = getFirestore();
    const colRef = collection(db, `${DB_PREFIX}/${uid}`);

    const q = query(colRef, where('group', '==', group));

    const colSnap = await getDocs(q);
    let cids = [String];
    for await (const docSnap of colSnap.docs) {
      if (docSnap.exists()) {
        cids.push(docSnap.data().cid);
        storage.remove(`${uid}/${group}/${docSnap.data().cid}`);
        await deleteDoc(docSnap.ref);
      }
    }

    return cids;
  } catch (error) {
    console.log(`Error ${error}`);
  }
};
export const updateProjectCapture = async (
  uid: string,
  group: string,
  info: any,
  cb: Function
) => {
  try {
    const db = getFirestore();
    const colRef = collection(db, `${DB_PREFIX}/${uid}`);

    const q = query(colRef, where('group', '==', group));
    const colSnap = await getDocs(q);
    // console.log(`Founded Capture ${colSnap.docs.length}`);
    for await (const docSnap of colSnap.docs) {
      if (docSnap.exists()) {
        // let data = docSnap.data() as Capture.Info;
        await updateDoc(docSnap.ref, info);
      }
    }
    return cb();
  } catch (error) {
    console.log(`Error ${error}`);
  }
};
export const getCaputreInfo = async (
  uid: string,
  cid: string,
  isGroupShare?: boolean,
  group?: string,
  cb?: Function
) => {
  try {
    const db = getFirestore();
    // console.log(`Uid :${uid} and Cid:${cid}`);
    let data: any;
    if (!isGroupShare) {
      const docRef = doc(db, `${DB_PREFIX}/${uid}/${cid}`);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        // console.log(`Data Found Exists ${docSnap.data()}`);
        data = docSnap.data() as Capture.Info;
        cb && cb(data);
      }
    } else {
      if (!group) return console.log(`Invalid Data in Case of Group Share`);

      await getListByGroup(uid, group, (data: Capture.Info[]) => {
        // console.log(`Group Capture Found ${data.length}`);
        // return data;
        cb && cb(data);
      });
    }
    // return null;
  } catch (error) {
    console.log(`Get Capture Info Error ${error}`);
  }
};
