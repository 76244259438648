import { RootState } from 'store';
import { setHeight, setWidth } from 'store/slice/editor.slice';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-hot-toast';

const CanvasInputSize = () => {
  const dispatch = useDispatch();
  const { height, width } = useSelector((state: RootState) => state.editor);
  const timeoutRef = useRef<number | null>(null);

  const [showToast, setShowToast] = useState(false);
  
  const showToaster = () => {
    if (!showToast) {
      setShowToast(true);
      toast.error('Change of resolution leads to permutation of tools', {
        duration: 7000,
      });
  
      timeoutRef.current = window.setTimeout(() => {
        setShowToast(false);
        timeoutRef.current = null;
      }, 15000);
    }
  };
  
  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);
  

  return (
    <div
      className="flex  items-center border border-black   justify-start    rounded-3xl z-40 "
      style={{
        padding: '4px 10px',
      }}
    >
      <div className="max-w-44 flex items-center justify-center rounded-md px-3">
        <input
          type="number"
          value={width}
          onChange={(e) => {
            dispatch(setWidth(parseInt(e.target.value)));
            showToaster();
          }}
          className="bg-transparent text-sm w-12 border-none text-black rounded-md p-1 mr-1 focus:outline-none focus:border-primary"
          style={{ appearance: 'textfield' }}
        />
        <span className="text-sm text-black">x</span>
        <input
          type="number"
          value={height}
          onChange={(e) => {
            dispatch(setHeight(parseInt(e.target.value)));
            showToaster();
          }}
          className="bg-transparent text-sm w-12 border-none text-black rounded-md p-1 ml-1 focus:outline-none focus:border-primary"
          style={{ appearance: 'textfield' }}
        />
        <span className="text-sm text-black">px</span>
      </div>
    </div>
  );
};

export default CanvasInputSize;
