import { FC, memo } from 'react';

interface props {
  width?: number;
  height?: number;
  opacity?: number;
  fill?: string;
  strokeColor?: string;
}

const SunIcon: FC<props> = ({
  height = 18,
  width = 18,
  opacity = 1,
  fill = 'none',
  strokeColor = '#808080',
}) => {
  return (
    <svg
      width={width}
      height={height}
      fill={fill}
      opacity={opacity}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3504_23190)">
        <path d="M16 0H0V16H16V0Z" fill="white" fillOpacity="0.01" />
        <path
          d="M8 1V2.05"
          stroke={strokeColor}
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.95 3.05029L12.2075 3.79274L12.95 3.05029Z"
          fill="#808080"
        />
        <path
          d="M12.95 3.05029L12.2075 3.79274"
          stroke={strokeColor}
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.0002 8H13.9502"
          stroke={strokeColor}
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.95 12.95L12.2075 12.2075L12.95 12.95Z"
          fill="#808080"
        />
        <path
          d="M12.95 12.95L12.2075 12.2075"
          stroke={strokeColor}
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8 15V13.95"
          stroke={strokeColor}
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.05029 12.95L3.79274 12.2075L3.05029 12.95Z"
          fill="#808080"
        />
        <path
          d="M3.05029 12.95L3.79274 12.2075"
          stroke={strokeColor}
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1 8H2.05"
          stroke={strokeColor}
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.05029 3.05029L3.79274 3.79274L3.05029 3.05029Z"
          fill="#808080"
        />
        <path
          d="M3.05029 3.05029L3.79274 3.79274"
          stroke={strokeColor}
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8 12C10.2091 12 12 10.2091 12 8C12 5.79087 10.2091 4 8 4C5.79087 4 4 5.79087 4 8C4 10.2091 5.79087 12 8 12Z"
          stroke={strokeColor}
          strokeWidth="1.33333"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3504_23190">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default memo(SunIcon);
