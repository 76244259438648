import { useState } from 'react';

export const useShowNotifyMsg = () => {
  const [showDiv, setShowDiv] = useState(false);

  const onButtonClick = () => {
    setShowDiv(true);

    setTimeout(() => {
      setShowDiv(false);
    }, 5000);
  };

  return { onButtonClick, showDiv };
};
