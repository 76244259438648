import { FC, memo } from 'react';

interface props {
  width?: number;
  height?: number;
  opacity?: number;
  fill?: string;
  strokeColor?: string;
}

const AppleIcon: FC<props> = ({
  width = 18,
  height = 18,
  opacity = 1,
  fill = 'none',
  strokeColor = '#808080',
}) => {
  return (
    <svg
      width={width}
      height={height}
      opacity={opacity}
      fill={fill}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3504_23209)">
        <path d="M16 0H0V16H16V0Z" fill="white" fillOpacity="0.01" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 13.6737C8.81037 13.6737 9.1437 14.1104 9.57807 14.2074C10.0124 14.3045 10.3771 14.3315 10.7505 14.167C11.49 13.8411 11.9408 13.4529 12.4878 12.8732C13.5015 11.7988 14 10.3215 14 8.34319C14 6.36485 13.4665 5.34249 12.6445 4.67372C11.8224 4.00499 11.3457 3.87685 10.3516 4.00499C9.35757 4.13309 8.8163 5.20115 8.00277 5.20115C7.18927 5.20115 6.19777 4.14802 5.33517 4.00499C4.47257 3.86192 4 4.00499 3.25322 4.67372C2.50645 5.34249 2 6.38385 2 8.34319C2 10.3025 2.51718 11.7553 3.5309 12.8297C4.0779 13.4095 4.51 13.8411 5.2495 14.167C5.62293 14.3315 6.0089 14.3045 6.42813 14.2074C6.8474 14.1104 7.18073 13.6737 8 13.6737Z"
          stroke={strokeColor}
          strokeWidth="1.33333"
        />
        <path
          d="M8.00244 5.201C8.26407 2.62267 9.37547 1.3335 11.3366 1.3335C11.5165 1.87612 11.5629 2.40128 11.476 2.909C11.4347 3.15019 11.3066 3.49413 11.0916 3.9408"
          stroke={strokeColor}
          strokeWidth="1.33333"
          strokeLinecap="round"
        />
        <path
          d="M4.74295 8.6665C4.65079 9.11507 4.65079 9.57694 4.74295 10.052C4.83515 10.5271 5.03145 10.8938 5.33192 11.1519"
          stroke={strokeColor}
          strokeWidth="1.33333"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3504_23209">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default memo(AppleIcon);
