import { useState, FC, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import {
  captureIsPublicState,
  captureIsPublicGroupState,
  currentCaptureIsPdfState,
  getCurrentUserAccessType,
} from '@/state/captureState';
import { useFunction } from '@/hooks/useFunction';
import Trash from '../icon/Trash';
import FileUploadModal from '../modal/FileUploadModal';
import CopyIcon from '../icon/CopyIcon';
import SwitchIcon from '../icon/Switch';
import CopyGroupIcon from '../icon/CopyGroupIcon';
import CopyImageIcon from '../icon/CopyImageIcon';
import NewDownloadIcon from '../icon/NewDownloadIcon';
import ReplaceIcon from '../icon/ReplaceIcon';
import DeleteModal from '../modal/DeleteModal';
import { ProcessServerConfigFunction } from 'filepond';
import { ImgSize } from '@/components/capture/types';
import PushIcon from '../icon/PushIcon';
import Pencil from '../icon/Pecil';
import RenameCaptureModal from '../modal/RenameCaptureModal';
import MoveCaptureModal from '../modal/MoveCaptureModal';
import { pathState } from '@/state/appState';
import { removeImage } from 'store/slice/editor.slice';
import { useDispatch } from 'react-redux';

const NewEditorDropdown: FC<{
  isDropdownShow: boolean;
  isDashboard?: boolean;
  renameModal?: () => void;
  handleExportWithConfetti: () => void;
  handleCopyImageToClipboard: () => void;
  handleFileInputChange: (e: any) => void;
}> = ({
  isDropdownShow,
  isDashboard,
  renameModal,
  handleExportWithConfetti,
  handleCopyImageToClipboard,
  handleFileInputChange,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isMoveOpen, setIsMoveOpen] = useState(false);
  const url = window.URL || window.webkitURL;

  const captureIsPublic = useRecoilValue(captureIsPublicState);
  const captureIsPublicGroup = useRecoilValue(captureIsPublicGroupState);
  const currentCaptureIsPdf = useRecoilValue(currentCaptureIsPdfState);
  const currentAccessType = useRecoilValue(getCurrentUserAccessType);
  const path = useRecoilValue(pathState);

  const { copyGroupLinkToClipboard, deleteImage, replaceImage } = useFunction();
  const deleteImageWithPopup = (event: any) => {
    setIsDeleteModalOpen(true);
  };
  const dispatch = useDispatch();
  const handleRemoveImage = () => {
    dispatch(removeImage());
  };
  const list = useMemo(
    () => {
      const data = [
       
        {
          text: 'Copy Project Link',
          Icon: CopyGroupIcon,
          fn: copyGroupLinkToClipboard,
          needPermission: !(
            currentAccessType === 'VIEW-PROJECT-ACCESS' ||
            currentAccessType === 'EDIT-PROJECT-ACCESS' ||
            currentAccessType === 'CAPTURE-OWNER'
          ),
          classname: '',
          htmlContent: '',
          isShow: !isDashboard,
        },
        {
          text: 'Copy Image',
          Icon: CopyImageIcon,
          fn: handleCopyImageToClipboard,
          needPermission: false,
          classname: '',
          htmlContent: '',
          isShow: true,
        },
        {
          text: 'Download Image',
          Icon: NewDownloadIcon,
          fn: handleExportWithConfetti,
          needPermission: false,
          classname: '',
          htmlContent: '',
          isShow: !isDashboard,
        },
        {
          text: 'Rename',
          Icon: Pencil,
          fn: renameModal,
          needPermission: !(
            currentAccessType === 'EDIT-CAPUTRE-ACCESS' ||
            currentAccessType === 'EDIT-PROJECT-ACCESS' ||
            currentAccessType === 'CAPTURE-OWNER'
          ),
          classname: '',
          isShow: isDashboard,
        },
        // {
        //   text: 'Push to Slack',
        //   Icon: PushIcon,
        //   fn: () => {},
        //   needPermission: false,
        //   classname: '',
        //   htmlContent: '',
        // },
        {
          text: '',
          Icon: '',
          needPermission: true,
          classname: '',
          html: '<hr class="my-6">',
          isShow: true,
        },
        {
          text: 'Replace Image',
          Icon: ReplaceIcon,
          fn: () => {
            setIsOpen(true);
          },
          needPermission: !(
            currentAccessType === 'EDIT-CAPUTRE-ACCESS' ||
            currentAccessType === 'EDIT-PROJECT-ACCESS' ||
            currentAccessType === 'CAPTURE-OWNER'
          ),
          classname: '',
          isShow: !isDashboard,
        },

        {
          text: 'Delete',
          Icon: Trash,
          fn: deleteImageWithPopup,
          needPermission: true,
          classname: 'text-danger',
          isShow: true,
        },
      ];
      if (currentCaptureIsPdf) {
        data.shift();
      }
      return data;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [captureIsPublicGroup, currentCaptureIsPdf, currentAccessType]
  );

  const closeModal = () => {
    setIsOpen(false);
  };
  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  const handleProcess: ProcessServerConfigFunction = async (
    _fieldName,
    file,
    _metadata,
    load: Function,
    error,
    progress,
    _abort
  ) => {
    if (file.type === 'application/pdf') {
      try {
        error('ONLY IMAGES CAN BE REPLACE!');
      } catch (err) {
        error('UPLOAD IS FAILED');
        console.log(err);
      }
    } else if (file.type.match(/^image/)) {
      try {
        const image = new Image();
        image.onload = async function () {
          const imgSize: ImgSize = {
            width: image.width,
            height: image.height,
          };
          await replaceImage(file, imgSize, progress, () => {
            load();
            closeModal();
          });
        };
        image.src = url.createObjectURL(file);
      } catch (err) {
        error('UPLOAD IS FAILED');
        console.log(err);
      }
    } else {
      error('This file type is not allowed here.');
    }
  };
  return (
    <>
      {isDropdownShow && (
        <div
          className={`z-20 absolute top-10  w-56  ${
            isDashboard ? '-ml-12' : 'mt-4 -ml-2 '
          } bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:-ml-48 header-dropdown sm:top-0 sm:mt-0 sm:mt-0 header-dropdown copy_capture`}
          role="menu"
          tabIndex={0}
        >
          <input
            id="fileInput"
            type="file"
            accept="image/*"
            onChange={handleFileInputChange}
            className="hidden"
          />
          <ul className="py-2" role="none">
            {list.map(
              (
                { text, Icon, fn, needPermission, classname, html, isShow },
                index
              ) => {
                if (captureIsPublic && needPermission) return null;
                if (!isShow) return;
                if (currentCaptureIsPdf && text === 'Replace Image')
                  return null;
                return (
                  <li key={index}>
                    {!html ? (
                      <button
                        type="button"
                        key={index}
                        className={`px-3 text-gray-900 group flex rounded-md items-center w-full px-2  text-sm hover:bg-green hover:text-black  dropdown-item`}
                        role="menuitem"
                        onClick={fn}
                      >
                        <div className="flex justify-center w-6 min-w-min min-h-max mr-2">
                          <Icon />
                        </div>
                        <span className={`${classname}`}>{text}</span>
                      </button>
                    ) : (
                      <div className="container-dropdown px-4">
                        <hr className="my-2"></hr>
                      </div>
                    )}
                  </li>
                );
              }
            )}
          </ul>
        </div>
      )}

      <FileUploadModal
        isOpen={isOpen}
        isReplaceImage={currentCaptureIsPdfState}
        closeModal={closeModal}
        onProcess={handleProcess}
      />
      <DeleteModal
        isDeleteModalOpen={isDeleteModalOpen}
        closeModal={closeDeleteModal}
        Proceed={deleteImage}
        deleteType="Capture"
      />
      <MoveCaptureModal
        isOpen={isMoveOpen}
        closeModal={() => setIsMoveOpen(false)}
        cid={path.cid}
        gid={path.gid}
      />
    </>
  );
};

export default NewEditorDropdown;
