import React, { RefObject, useEffect, useRef } from 'react';
import { rectInfoToStyle } from '@/lib/rect';
import { useRecoilValue } from 'recoil';
import { isCurrentScaleState } from '@/state/toolState';
import { currentCaptureInfoState } from '@/state/captureState';

type ImgEl = RefObject<HTMLImageElement>;

interface Props {
  rect: Rect.Info;
  imgEl?: ImgEl;
}

const CanvasBlur = ({ rect, imgEl }: Props) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const pixelRef = useRef<HTMLCanvasElement | null>(null);
  const currentScale = useRecoilValue(isCurrentScaleState);
  const currentCaptureInfo = useRecoilValue(currentCaptureInfoState)

  function disableSmoothRendering(ctx: any) {
    ctx.webkitImageSmoothingEnabled = false;
    ctx.mozImageSmoothingEnabled = false;
    ctx.msImageSmoothingEnabled = false;
    ctx.imageSmoothingEnabled = false;
    return ctx;
  }

  useEffect(() => {
    const { current: canvas } = canvasRef;
    const amount = 1 / 100;
    if (!imgEl?.current) return;

    if (imgEl?.current?.width && imgEl?.current?.height) {
      const w = imgEl.current.width * (amount <= 0 ? 0.01 : amount);
      const h = imgEl.current.height * (amount <= 0 ? 0.01 : amount);

      if (!canvas) return;
      if (!pixelRef.current) return;
      if (!canvasRef.current) return;

      const pixelCtx = pixelRef.current.getContext('2d');
      if (!pixelCtx) return;

      const ctx = canvas.getContext('2d');
      if (!ctx) return;

      pixelRef.current.width = w;
      pixelRef.current.height = h;

      // canvasRef.current.width = imgEl.current.width;
      // canvasRef.current.height = imgEl.current.height;

      ctx.drawImage(
        imgEl.current,
        rect.left / currentScale.x,
        rect.top / currentScale.y,
        rect.width / currentScale.x,
        rect.height / currentScale.y,
        0,
        0,
        canvas.width,
        canvas.height
      );
      if (canvas) {
        pixelCtx.drawImage(canvas, 0, 0, w, h);
        const context = disableSmoothRendering(
          // @ts-ignore
          canvasRef.current.getContext('2d')
        );
        // stretch the smaller image
        context.drawImage(
          pixelRef.current,
          0,
          0,
          w,
          h,
          0,
          0,
          imgEl.current.width,
          imgEl.current.height
        );
      }
    }
  }, [canvasRef, rect]);

  return (
    <>
      <canvas
        ref={canvasRef}
        style={rectInfoToStyle(rect, imgEl, currentCaptureInfo)}
        className="absolute z-10 img"
      />
      <canvas
        ref={pixelRef}
        style={rectInfoToStyle(rect, imgEl, currentCaptureInfo)}
        className="absolute z-10 img"
        id="pixel"
      ></canvas>
    </>
  );
};

export default CanvasBlur;
