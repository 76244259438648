type Props = {
  classname: string;
};

const CoursorIcon = ({ classname }: Props) => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classname}
    >
      <path
        d="M15.0033 7.5019L9.91487 10.0295C9.75379 10.1095 9.62292 10.2395 9.54181 10.4L7.22542 14.9845C6.8928 15.6428 5.93191 15.5763 5.69317 14.8785L1.479 2.56013C1.25264 1.89847 1.89277 1.26908 2.55051 1.50659L14.9156 5.97177C15.6056 6.22092 15.6603 7.17556 15.0033 7.5019Z"
        strokeWidth="2.5"
      />
    </svg>
  );
};

export default CoursorIcon;
