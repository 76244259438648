import { useAuth } from '@/hooks/useAuth';
import { currentCaptureInfoState } from '@/state/captureState';
import axios from 'axios';
import { getAdditionalUserInfo, User } from 'firebase/auth';
import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import img from '../assets/images/urlImg.svg';
import Loading from './Loading';
import { withSignInModal } from './hoc/withSignInModal';
import { isAnonymousState } from '@/state/userState';
import { httpsCallable } from 'firebase/functions';
import { functions } from '@/lib/firebase/auth';

type UrlUploadT = {
  openSignInModal?: () => void;
};

const UrlUpload = ({ openSignInModal }: UrlUploadT) => {
  const navigate = useNavigate();
  const [uploading, setUploading] = useState(false);
  const [valueEnter, setValueEnter] = useState(false);
  const [valideUrl, setValideUrl] = useState(true);
  const [urlErrorMessage, setUrlErrorMessage] = useState(
    'Invalid link. Please, try again!'
  );
  const urlRef = useRef<null | HTMLInputElement>(null);
  const isReplaceImage = false;
  const currentCap = useRecoilValue(currentCaptureInfoState);
  const isAnonymous = useRecoilValue(isAnonymousState);
  const urlPatternValidation = () => {
    const expression =
      /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
    const regex = new RegExp(expression);
    return regex.test(urlRef.current?.value as string);
  };
  const { getUserInfo } = useAuth();
  let token = isReplaceImage ? '' : null;
  const user = getUserInfo() as User;
  // const setCurrentState = useSetRecoilState(currentCaptureInfoState);

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (isAnonymous) {
      openSignInModal && openSignInModal();
      return;
    }
    // if (isReplaceImage && currentCap && currentCap?.url) {
    //   const url = new URL(currentCap?.url);
    //   token = url.searchParams.get('token');
    // }

    if (!urlPatternValidation()) {
      setValideUrl(urlPatternValidation());
      return false;
    }
    setValideUrl(true);
    setUploading(true);

    const payload = {
      url: urlRef.current?.value,
      user: user,
      // group: isReplaceImage ? currentCap?.group : null,
      isReplaceImage: isReplaceImage,
      // currentCap: isReplaceImage
      //   ? {
      //       cid: currentCap?.cid,
      //       uid: currentCap?.creator.uid,
      //       token: token,
      //       group: currentCap?.group,
      //     }
      //   : null,
    };

    try {
      const { data }: any = await httpsCallable(functions, 'uploadLiveurl')(payload);
      Loading();
      setUploading(false);

      // setCurrentState(null);
      // setCurrentState(currentCap);
      // navigate(response.data.path, { replace: true });

    } catch (error: any) {
      setUploading(false);
      setValideUrl(false);
      setUrlErrorMessage(error.message);
      console.log(error);
    }
  };
  return (
    <div>
      <div className="flex align-center justify-center">
        <div
          className={`inline-block w-full max-w-xl text-lefttransition-all transform bg-transparent  ${!valideUrl && valueEnter ? 'pb-6' : 'pb-0'} sm:mt-0 `}
        >
          <div className="flex">
            <div className="w-full">
              <div className="file-upload-div" tabIndex={0}>
                <div className="fileupload-dropdown">
                  <div className="url-upload mx-auto">
                    <div className="mx-auto">
                      <div className="flex justify-center">
                        <img src={img} alt="add" />
                      </div>

                      <p className="upload-drag-txt">
                        Add a Capture via{' '}
                        <span className="pink-txt">Link </span>
                      </p>
                      <p className="upload-grey-txt mb-4">
                        Support Image and PDF files
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex">
            <div className="w-full relative">
              <h4 className="upload-url-txt mb-9"></h4>
              <form onSubmit={handleSubmit}>
                <input
                  type="text"
                  className="upload-txtBox block p-2 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  autoFocus={false}
                  placeholder="Add the file URL"
                  ref={urlRef}
                  onChange={() => {
                    setValueEnter(urlRef.current?.value ? true : false);
                  }}
                />

                {/* add class border-danger if error shows  */}
                {/* green button upload-success default button upload-btn-default                 */}

                <button
                  disabled={uploading || !valueEnter}
                  type="submit"
                  className={`px-6 h-10 text-white rounded-lg upload-btn justify-center upload-default mx-auto ${!valueEnter
                    ? 'upload-btn-default cursor-not-allowed'
                    : 'upload-success'
                    }   ${uploading ? 'cursor-not-allowed   ' : ''}`}
                >
                  {uploading ? (
                    <div className="font-rubik w-full h-full flex flex-col justify-center items-center">
                      <div
                        style={{ borderTopColor: 'transparent' }}
                        className="w-5 h-5 border-1 border-black border-solid rounded-full animate-spin-fast "
                      ></div>
                    </div>
                  ) : (
                    'Upload'
                  )}
                </button>
              </form>
            </div>
          </div>
          <div className="flex">
            {!valideUrl && valueEnter && (
              <div
                className=" text-center text-red-700 pt-2 rounded relative"
                role="alert"
              >
                <span className="block sm:inline upload-error">
                  {urlErrorMessage}
                </span>
                <span className="absolute top-0 bottom-0 right-0 px-4 py-3"></span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withSignInModal(UrlUpload);
