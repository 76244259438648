import { atom } from 'recoil';

export const responsiveDesignState = atom<any>({
  key: 'responsiveDesignState',
  default: {
    responsive: false,
    welcome: true,
    sidebar: false,
    comments: false,
  },
});
