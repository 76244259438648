import { FC } from 'react';

interface props {
  width?: number;
  height?: number;
  fill?: string;
  opacity?: number;
  strokeColor?: string;
}

const AddEmojiIcon: FC<props> = ({ width = 24, height = 24, opacity = 1 }) => {
  return (
    <svg
      width={width}
      height={height}
      opacity={opacity}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="1" y="1" width="22" height="22" rx="11" fill="white" />
      <path d="M17 7H7V17H17V7Z" fill="white" fillOpacity="0.01" />
      <path
        d="M12.0002 16.1666C14.3014 16.1666 16.1668 14.3011 16.1668 11.9999C16.1668 9.69873 14.3014 7.83325 12.0002 7.83325C9.69898 7.83325 7.8335 9.69873 7.8335 11.9999C7.8335 14.3011 9.69898 16.1666 12.0002 16.1666Z"
        stroke="black"
        strokeWidth="0.833333"
        strokeLinejoin="round"
      />
      <path
        d="M13.4582 13.4583C13.4582 13.4583 13.0415 14.2916 11.9998 14.2916C10.9582 14.2916 10.5415 13.4583 10.5415 13.4583"
        stroke="black"
        strokeWidth="0.833333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.4585 10.75V11.5833"
        stroke="black"
        strokeWidth="0.833333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5415 10.75V11.5833"
        stroke="black"
        strokeWidth="0.833333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g clipPath="url(#clip0_3543_44828)">
        <rect x="13" y="5" width="6" height="6" rx="3" fill="white" />
        <path d="M19 5H13V11H19V5Z" fill="white" fillOpacity="0.01" />
        <path
          d="M16.0075 6.25L16.0029 9.75"
          stroke="black"
          strokeWidth="0.83"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.25 8H17.75"
          stroke="black"
          strokeWidth="0.83"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <rect
        x="1"
        y="1"
        width="22"
        height="22"
        rx="11"
        stroke="black"
        strokeWidth="0.83"
      />
      <defs>
        <clipPath id="clip0_3543_44828">
          <rect x="13" y="5" width="6" height="6" rx="3" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AddEmojiIcon;
