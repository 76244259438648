import { useState, FC, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import {
  captureIsPublicState,
  captureIsPublicGroupState,
  currentCaptureIsPdfState,
  getCurrentUserAccessType,
  captureListAtHostState,
  captureListSharedAtHostState,
} from '@/state/captureState';
import { useFunction } from '@/hooks/useFunction';
import CopyIcon from '../icon/CopyIcon';
import ReplaceIcon from '../icon/ReplaceIcon';
import { updateShareInvite } from '@/lib/firebase/firestore';
import dayjs from 'dayjs';
import { useInvitation } from '@/hooks/useInvitation';
import { currentUserInfoState } from '@/state/userState';
import { User } from 'firebase/auth';
import toast from 'react-hot-toast';
import { isDashBoardState, showMyProjectState } from '@/state/uiState';
import {
  deleteSharingCaptureForUser,
  updateShareInviteRole,
} from '@/lib/firebase/firestore/share';

const EmailViewDropdown: FC<{
  isEmailViewDropdownShow: boolean;
  object: Capture.ShareModel;
  groupName: string;
  setIsProcessing: Function;
  isProcessing: boolean;
  onClose?: () => void;
}> = ({
  isEmailViewDropdownShow,
  object,
  groupName,
  setIsProcessing,
  isProcessing,
  onClose,
}) => {
  const captureIsPublic = useRecoilValue(captureIsPublicState);
  const captureIsPublicGroup = useRecoilValue(captureIsPublicGroupState);
  const currentCaptureIsPdf = useRecoilValue(currentCaptureIsPdfState);
  const { setCurrentCaptureShareInviteList } = useInvitation();
  const [roleSlected, setRoleSelected] = useState(object.Role);
  const [isGroupShare, setIsGroupShare] = useState(object.isGroupShare);
  const [showToolTip, setShowToolTip] = useState(false);
  const currentUserInfo = useRecoilValue(currentUserInfoState) as User;
  const currentAccessType = useRecoilValue(getCurrentUserAccessType);
  const showMyProject = useRecoilValue(showMyProjectState);
  const captureListAtHost = useRecoilValue(
    showMyProject ? captureListAtHostState : captureListSharedAtHostState
  );
  const isDashBoard = useRecoilValue(isDashBoardState);
  const { copyLinkToClipboard, copyGroupLinkToClipboard } = useFunction();
  // console.log(`Role Acces Type in functions:${currentAccessType}`)
  const list = useMemo(
    () => {
      const data = [
        {
          text: 'Can view',
          role: 'VIEW',
          Icon: CopyIcon,
          fn: copyLinkToClipboard,
          needPermission: false,
          classname: '',
          htmlContent: '',
          inputType: 'radio',
          toolTip: '',
        },
        {
          text: 'Can edit',
          role: 'EDIT',
          Icon: CopyIcon,
          fn: copyGroupLinkToClipboard,
          needPermission: currentAccessType === 'VIEW-PROJECT-ACCESS',
          classname: '',
          htmlContent: '',
          inputType: 'radio',
          toolTip: '',
        },
        {
          text: '',
          Icon: '',
          needPermission: !(
            currentAccessType === 'CAPTURE-OWNER' ||
            currentAccessType === 'EDIT-PROJECT-ACCESS' ||
            currentAccessType === 'VIEW-PROJECT-ACCESS'
          ),
          classname: '',
          html: '<hr class="my-2">',
        },
        {
          text: 'Entire project access',
          Icon: ReplaceIcon,
          inputType: 'checkbox',
          needPermission: !(
            currentAccessType === 'CAPTURE-OWNER' ||
            currentAccessType === 'EDIT-PROJECT-ACCESS' ||
            currentAccessType === 'VIEW-PROJECT-ACCESS'
          ),
          classname: '',
          toolTip: 'it Enable',
        },
        {
          text: '',
          Icon: '',
          needPermission: !(
            currentAccessType === 'CAPTURE-OWNER' ||
            currentAccessType === 'EDIT-PROJECT-ACCESS' ||
            currentAccessType === 'VIEW-PROJECT-ACCESS'
          ),
          classname: '',
          html: '<hr class="my-2">',
        },
      ];

      return data;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [captureIsPublicGroup, currentCaptureIsPdf, object]
  );

  const updateRole = async (roleSelected: Capture.RoleAssigned) => {
    try {
      if (isProcessing) return;
      setIsProcessing(true);
      if (currentUserInfo.uid === object.uid) return false;
      //Here Need to Update Roles in all other captures at Host in List thanks......
      //Mean Just need to find and add roles according to this

      console.log(`CaptureList At Host Creating Role if doesnt exist`);
      if (isGroupShare) {
        for await (let item of captureListAtHost) {
          await updateShareInvite(
            object.email,
            item.cid,
            {
              ...item,
              IniviteStatus: object.IniviteStatus,
              Role: roleSelected,
              updatedAt: dayjs().unix(),
              updatedBy: currentUserInfo.uid,
              isGroupShare: isGroupShare,
            },
            object.creator.uid
          );
        }
        await setCurrentCaptureShareInviteList(object.cid);

        toast.success(
          `Role Successfully Update to ${
            isGroupShare
              ? roleSelected === 'VIEW'
                ? 'Entire Project View Access'
                : 'Entire Project Edit Access'
              : roleSelected === 'VIEW'
              ? 'View Capture Access'
              : 'EDIT Capture Access'
          }`
        );
        setIsProcessing(false);
      } else {
        await updateShareInvite(
          object.email,
          object.cid,
          {
            ...object,
            IniviteStatus: object.IniviteStatus,
            Role: roleSelected,
            updatedAt: dayjs().unix(),
            updatedBy: currentUserInfo.uid,
            isGroupShare: isGroupShare,
          },
          object.creator.uid
        );
        await setCurrentCaptureShareInviteList(object.cid);
        toast.success(
          `Role Successfully Update to ${
            isGroupShare
              ? roleSelected === 'VIEW'
                ? 'Entire Project View Access'
                : 'Entire Project Edit Access'
              : roleSelected === 'VIEW'
              ? 'View Capture Access'
              : 'EDIT Capture Access'
          }`
        );
        setIsProcessing(false);
      }
    } catch (error) {
      setIsProcessing(false);
    }
  };

  const updateIsGroupShare = async () => {
    if (currentUserInfo.uid === object.uid || isDashBoard) return false;
    if (isProcessing) return;
    setIsProcessing(true);
    if (captureListAtHost.length === 0) {
      await updateShareInvite(
        object.email,
        object.cid,
        {
          ...object,
          isGroupShare: !object.isGroupShare,
          groupName: groupName,
          IniviteStatus: object.IniviteStatus,
          updatedAt: dayjs().unix(),
          updatedBy: currentUserInfo.uid,
        },
        object.creator.uid
      );
    } else {
      setIsProcessing(true);
      for await (let item of captureListAtHost) {
        await updateShareInviteRole(
          object.email,
          item.cid,
          {
            ...object,
            isGroupShare: !object.isGroupShare,
            updatedAt: dayjs().unix(),
            updatedBy: currentUserInfo.uid,
            email: object.email,
            cid: item.cid,
          },
          object.creator.uid
        );
      }
    }
    setIsGroupShare(!object.isGroupShare);
    await setCurrentCaptureShareInviteList(object.cid);
    toast.success(
      `Role Successfully Update to ${
        !isGroupShare
          ? roleSlected === 'VIEW'
            ? 'Entire Project View Access'
            : 'Entire Project Edit Access'
          : roleSlected === 'VIEW'
          ? 'View Capture Access'
          : 'EDIT Capture Access'
      }`
    );
    setIsProcessing(false);
  };

  const handleDeleteUserAccess = () => {
    deleteSharingCaptureForUser(object.email, object.cid);
    setCurrentCaptureShareInviteList(object.cid);
    onClose && onClose();
  };

  return (
    <>
      {isEmailViewDropdownShow && (
        <div
          className="z-20 absolute top-8 right-2   w-56 mt-2 -ml-2 bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:-ml-48 email-view-dropdown "
          role="menu"
          tabIndex={1}
        >
          <div className="py-2" role="none">
            {list.map(
              ({
                text,
                Icon,
                fn,
                needPermission,
                classname,
                html,
                inputType,
                role,
                toolTip,
              }) => {
                if (captureIsPublic && needPermission) return null;
                return (
                  <>
                    {!html ? (
                      inputType == 'radio' ? (
                        <div className="radio-custom">
                          <label className="container">
                            <input
                              type="radio"
                              name="radio"
                              onClick={async () => {
                                setRoleSelected(role as Capture.RoleAssigned);
                                updateRole(role as Capture.RoleAssigned);
                              }}
                              defaultChecked={roleSlected === role}
                            />

                            <span className="checkmark"></span>
                            <span className="ml-4">{text}</span>
                          </label>
                        </div>
                      ) : (
                        <div
                          onMouseEnter={() => {
                            setShowToolTip(true);
                          }}
                          onMouseLeave={() => {
                            setShowToolTip(false);
                          }}
                        >
                          <label className="container">
                            <input
                              type="checkbox"
                              disabled={isDashBoard}
                              onClick={async () => {
                                // setIsGroupShare(!object.isGroupShare)
                                await updateIsGroupShare();
                              }}
                              defaultChecked={isGroupShare}
                            />
                            <span
                              className={
                                isDashBoard
                                  ? 'checkmark checked-grey'
                                  : `checkmark`
                              }
                            ></span>
                            <span className="ml-4">{text}</span>
                          </label>
                          {toolTip !== '' ? (
                            <div
                              className={`custom-tooltip-box border-2 border-white absolute whitespace-nowrap shadow-2xl font-normal tracking-wider text-white bg-black bg-opacity-70 px-2 py-1 text-xxxxs rounded-tl-md rounded-tr-2xl rounded-br-2xl rounded-bl-2xl ${
                                isProcessing ? 'cursor-wait' : ''
                              }`}
                              style={{ display: showToolTip ? '' : 'none' }}
                            >
                              Will give access to all <br />
                              captures in the project.
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                      )
                    ) : (
                      <div className="px-4">
                        <hr className="my-3"></hr>
                      </div>
                    )}
                  </>
                );
              }
            )}

            <button className="pl-5" onClick={handleDeleteUserAccess}>
              Delete
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default EmailViewDropdown;
