import React, { FC } from 'react';
import CircleObjectIcon from '../icon/tool/CircleObjectIcon';

const ToolObjectCircle: FC<{ color: string; isActive: boolean }> = ({
  color,
  isActive,
}) => {
  return <CircleObjectIcon color={color} isActive={isActive} />;
};

export default ToolObjectCircle;
