type Props = {
  classname: string;
};

const CircleIcon = ({ classname }: Props) => {
  return (
    <svg
      className={
        'rounded-3xl hover:bg-green hover:border-2 hover:border-black ' +
        classname
      }
      width="22"
      height="22"
      viewBox="0 0 22 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse cx="11" cy="9" rx="9.16667" ry="7.5" strokeWidth="2.08333" />
    </svg>
  );
};

export default CircleIcon;
