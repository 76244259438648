import {
  FC,
  useRef,
  useEffect,
  useState,
  MouseEvent,
  PointerEvent,
  RefObject,
} from 'react';
import ReactDOMServer from 'react-dom/server';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { nanoid } from 'nanoid';
import _ from 'lodash';

import { useBoundingBox } from '@/hooks/tool/useBoundingBox';
import { useTool } from '@/hooks/tool/useTool';
import { useToolObject } from '@/hooks/tool/useToolObject';
import { useCapture } from '@/hooks/tool/useCapture';

import { getCoordinates } from '@/lib/rect';
import DragResizeRotate from './DragResizeRotate';
import ToolObjectDraw from './ToolObjectDraw';
import { ImgSize } from '@/components/capture/types';

import { isCurrentScaleState } from '@/state/toolState';
import { boundingRectState } from '@/state/boundingBoxState';
import {
  currentToolObjectColorState,
  currentToolState,
} from '@/state/toolState';
import { isShowAddCommentState } from '@/state/commentState';

type Props = {
  imgSize: ImgSize;
  imgEl: RefObject<HTMLImageElement>;
  isEditAble: boolean;
  borderEl: RefObject<HTMLDivElement>;
  zoomValue: number;
};

const BoundingBox: FC<Props> = ({
  imgSize,
  imgEl,
  borderEl,
  isEditAble,
  zoomValue,
}) => {
  const { rect, setRect, isBoundingBoxShow, setIsBoundingBoxShow } =
    useBoundingBox();
  const { setToolState, setToolStateAfterInsert } = useToolObject();
  const { getListTool } = useTool();
  const { setCaptureTools } = useCapture();

  const boundingBoxRef = useRef<HTMLDivElement | null>(null);

  const setIsShowAddComment = useSetRecoilState(isShowAddCommentState);

  const { top, left } = useRecoilValue(boundingRectState);
  const currentToolObjectColor = useRecoilValue(currentToolObjectColorState);
  const color = useRecoilValue(currentToolObjectColorState);
  const currentScale = useRecoilValue(isCurrentScaleState);
  const currentTool = useRecoilValue(currentToolState);

  const [isDrawing, setIsDrawing] = useState<boolean>(false);
  const [stroke, setStroke] = useState<Canvas.Path | null>(null);

  const [drawPosition, setDrawPosition] = useState<{
    minX: number;
    minY: number;
    maxX: number;
    maxY: number;
  }>({
    minX: 0,
    minY: 0,
    maxX: 0,
    maxY: 0,
  });

  useEffect(() => {
    // if (!boundingBoxRef) return;
    const el = boundingBoxRef.current;
    const list = getListTool();

    if (el) {
      if (currentTool === 'MOVE' || currentTool === 'CHAT') {
        el.style.cursor = '';
        return;
      } else {
        // @ts-ignore
        const { CursorIcon } = _.find(list, ['name', currentTool]);
        const icon = escape(ReactDOMServer.renderToStaticMarkup(CursorIcon()));
        el.style.cursor = `url(data:image/svg+xml,${icon}), auto`;
      }
    }
  }, [currentTool, boundingBoxRef.current]);

  const addObjects = async (e: MouseEvent<HTMLDivElement>): Promise<void> => {
    e.stopPropagation();
    if (
      imgEl.current &&
      borderEl.current &&
      currentTool !== 'DRAW' &&
      currentTool !== 'MOVE'
    ) {
      const widthTool = (imgEl.current?.width / 100) * 18 > 220 ? 260 : 220;
      const widthArrow = (imgEl.current?.width / 100) * 18 > 180 ? 210 : 180;
      const widthHeight = (imgEl.current?.height / 100) * 17;
      const rect = e.currentTarget.getBoundingClientRect();

      const width =
        currentTool === 'ARROW'
          ? widthArrow
          : currentTool === 'TEXT'
            ? 128
            : widthTool;

      const height =
        currentTool === 'ARROW'
          ? widthArrow
          : currentTool === 'TEXT'
            ? 46
            : widthHeight > 150
              ? 170
              : 150;
      await setCaptureTools({
        tool: {
          left: (e.clientX - rect.left) / zoomValue - Math.round(width / 2),
          top: (e.clientY - rect.top) / zoomValue - Math.round(height / 2),
          width,
          height,
          rotate: 0,
          text: '',
          type: currentTool as Tool.ObjectType,
          color,
          fontSize: 24,
          id: nanoid(),
        },
      });
      setToolStateAfterInsert();
    }

    // setIsBoundingBoxShow(false);
    // await setToolState();
  };

  const handlePointerDown = (event: PointerEvent<HTMLDivElement>) => {
    if (currentTool !== 'DRAW') return;
    if (isDrawing) return;
    if (event.pointerType === 'mouse' && event.button !== 0) return;
    const point = getCoordinates(event);
    setIsDrawing(true);

    const pointX = point.x / zoomValue;
    const pointY = point.y / zoomValue;

    const stroke: Canvas.Path = {
      drawMode: true,
      strokeColor: currentToolObjectColor,
      strokeWidth: 6,
      paths: [{ x: pointX, y: pointY }],
    };

    if (borderEl.current) {
      setDrawPosition({
        minX: pointX,
        minY: pointY + borderEl.current.scrollTop,
        maxX: pointX,
        maxY: pointY + borderEl.current.scrollTop,
      });
    }

    setStroke(stroke);
  };

  const handlePointerMove = (event: PointerEvent<HTMLDivElement>) => {
    if (!isDrawing) return;

    const point = getCoordinates(event);
    const pointX = point.x / zoomValue;
    const pointY = point.y / zoomValue;

    setDrawPosition((old) => {
      const minX = old.minX > pointX ? pointX : old.minX;
      const minY = old.minY > pointY ? pointY : old.minY;
      const maxX = old.maxX < pointX ? pointX : old.maxX;
      const maxY = old.maxY < pointY ? pointY : old.maxY;
      return { minX, minY, maxX, maxY };
    });
    // @ts-ignore
    setStroke((old) => {
      // @ts-ignore
      return { ...old, paths: [...old.paths, { x: pointX, y: pointY }] };
    });
  };

  const handlePointerUp = async (event: PointerEvent<HTMLDivElement>) => {
    if (event.pointerType === 'mouse' && event.button !== 0) return;
    if (!isDrawing) {
      return;
    }
    if (!borderEl.current) return;
    setIsDrawing(false);
    setStroke(null);

    const newStoke = {
      ...stroke,
      drawMode: false,
      paths: [
        // @ts-ignore
        ...stroke.paths.map(({ x, y }: { x: number; y: number }) => ({
          // @ts-ignore
          x: x - drawPosition.minX + 5 + borderEl.current?.scrollLeft,
          y: y - drawPosition.minY + 5, // document.documentElement.scrollTop + 5,
        })),
      ],
    };

    await setCaptureTools({
      tool: {
        left: drawPosition.minX - 4,
        top: drawPosition.minY - 4,
        width: drawPosition.maxX - drawPosition.minX + 10,
        height: drawPosition.maxY - drawPosition.minY + 10,
        rotate: 0,
        text: '',
        type: 'DRAW',
        color,
        fontSize: 16,
        stroke: newStoke,
        id: nanoid(),
      },
    });

    setToolStateAfterInsert();
    // setIsBoundingBoxShow(false);
    // await setToolState();
  };

  const hiddenAddComment = () => {
    setIsShowAddComment(false);
  };

  const handleUpdate = (rect: Rect.Info) => {
    setRect((old: Rect.Info) => ({ ...old, ...rect }));
  };

  return isBoundingBoxShow && rect ? (
    <>
      <div
        style={{
          top: 0,
          left: 0,
        }}
        className="flex fixed flex-col z-10"
        onClick={addObjects}
        onPointerDown={handlePointerDown}
        onPointerMove={handlePointerMove}
        onPointerUp={handlePointerUp}
        onMouseEnter={hiddenAddComment}
      >
        <DragResizeRotate
          ref={boundingBoxRef}
          className="instacap-boundingbox"
          isResizable={false}
          isRotatable={false}
          isdragByParent={true}
          isDraggable={currentTool === 'MOVE'}
          rect={rect}
          style={{
            width: imgEl.current?.width,
            height: imgEl.current?.height,
          }}
          onUpdate={handleUpdate}
          isEditAble={isEditAble}
          zoomValue={zoomValue}
        >
          {stroke && (
            <ToolObjectDraw
              stroke={stroke}
              style={{ width: '100%', height: '100%' }}
              color={currentToolObjectColor}
            />
          )}
        </DragResizeRotate>
      </div>
    </>
  ) : null;
};

export default BoundingBox;
