import billingDetailsSvg from '@/assets/icons/BillingDetails.svg';
import { currentUserInfoState } from '@/state/userState';
import { useRecoilValue } from 'recoil';
import Button from '../Button';
import Check from '../icon/Check';
import dayjs from 'dayjs';
import { useUpgradePlan } from '@/hooks/plan/useUpgradePlan';
import { useStripePortal } from '@/hooks/plan/useStripePortal';
import { useUpgradePlanTeam } from '@/hooks/plan/useUpgradePlanTeam';

const commonAppSumoFeaturesList = [
  'Private projects & captures',
  'Desktop app',
  'Indefinite days of storage',
  'Team management & billing',
  'Login-free Chrome extension',
  'Unlimited projects & captures',
  'Unlimited comments and annotations',
  'Full page captures',
  'Comment & reply on any capture link',
  'Ability to upload PDFs, images, and other files',
  'Reply by email',
  'Unlimited guests',
  'Audio/video comments',
];

const tiersFeatureList = {
  "2" : ["10 user(s)", "Collaboration"],
  "3" : ["50 user(s)", "Collaboration"]
}

const Billing = () => {
  const currentUserInfo = useRecoilValue(
    currentUserInfoState
  ) as Capture.Creator;
  const {
    plan,
    planTeam,
    stripeCustomerId,
    stripeSubscriptionId,
    expiresOn,
    stripeSubscriptionIdTeam,
    teamExpiresOn,
    appSumoLicenseKey,
    licenseStatus,
    tier,
  } = currentUserInfo;

  const { isLoading: isLoadingUpgrade, upgradePlan } = useUpgradePlan();
  const { isLoading: isLoadingUpgradeTeam, upgradePlan: upgradePlanTeam } =
    useUpgradePlanTeam();

  const { isLoading: isLoadingStripePortal, openStripePortal } =
    useStripePortal();

  const buyPlan = () => {
    if(appSumoLicenseKey) {
      window.location.href = 'https://appsumo.com/account/products/';
      return  
    }
    upgradePlan('pro')
  };

  const buyTeamPlan = () => {
    if(appSumoLicenseKey) {
      window.location.href = 'https://appsumo.com/account/products/';
      return  
    }  
    upgradePlanTeam('team')
  };

  return (
    <div className="w-full px-6">
      <div className="mx-6 mt-4">
        <h1 className="text-xl font-medium">Billing details</h1>

        {stripeCustomerId && !isLoadingUpgrade && !isLoadingUpgradeTeam && (
          <div className="flex mt-6">
            <Button
              label={'Billing & Invoices'}
              onClick={openStripePortal}
              isLoading={isLoadingStripePortal}
              className="custom-btn px-6"
            >
              <span className="ml-2.5">
                <img src={billingDetailsSvg} />
              </span>
            </Button>
          </div>
        )}

        {appSumoLicenseKey && tier && licenseStatus === 'active' && (
          <>
            <h2 className="text-xl font-medium flex mt-6">
              {`AppSumo Lifetime - Plan License Tier ${tier}`}
            </h2>
            {commonAppSumoFeaturesList.map((feature) => (
              <div className="flex gap-2.5 mt-2">
                <Check color={'#6AD500'} />
                <p className="text-sm">{feature}</p>
              </div>
            ))}
            {
              tier !== "1" && tiersFeatureList[tier as keyof typeof tiersFeatureList]?.map((feature, index) => (
                <div className="flex gap-2.5 mt-2" key={index}>
                  <Check color={'#6AD500'} />
                  <p className="text-sm">{feature}</p>
                </div>
              ))
              
            }
          </>
        )}


        {tier !== '1' && (
          <>
            <div
              className="mt-12"
              style={{ height: '1px', backgroundColor: 'black', width: '70%' }}
            ></div>

            <h2 className="text-xl font-medium flex mt-6">
              Personal account details
            </h2>

            {plan === "free" && !planTeam && !appSumoLicenseKey &&
              <div className="flex mt-2">
                <h4 className="text-lg font-medium">
                  {'Free Plan'}
                </h4>
              </div>    
            }

            {(plan === "pro" || tier === "1") &&
              <div className="flex mt-2">
                <h4 className="text-lg font-medium">
                  {'Pro Plan: $12 / mo'}
                </h4>
              </div>
            }

            {expiresOn && (
              <div className="text-xxs">{`Your plan is set to cancel on ${dayjs
                .unix(expiresOn)
                .format('D MMMM YYYY')}. Enjoy full access until then!`}</div>
            )}
            <div className="flex mt-2">
              <p className="text-base text-gray-600 text-sm leading-6">
                Thank you for being a customer.
              </p>
            </div>

            {stripeSubscriptionId == null &&
              plan == 'free' &&
              !isLoadingStripePortal && (
                <div className="mt-6">
                  <Button
                    label={`Upgrade to ${appSumoLicenseKey ? "Tier 1" : "Pro"}`}
                    onClick={buyPlan}
                    isLoading={isLoadingUpgrade}
                    className="custom-btn px-6"
                  />
                  <div className="flex mt-6">
                    <h4 className="text-sm font-semibold uppercase">
                      {'Everything in'}
                      <a
                        href="https://www.instacap.co/pricing"
                        target="_blank"
                        className="pink-txt"
                      >
                        {' Free Plan '}
                      </a>
                      {'plus:'}
                    </h4>
                  </div>
                  <div className="flex gap-2.5 mt-2">
                    <Check color={'#6AD500'} />
                    <p className="text-sm">{'Private Projects & Captures'}</p>
                  </div>
                  <div className="flex gap-2.5 mt-2">
                    <Check color={'#6AD500'} />
                    <p className="text-sm">{'Indefinite days of Storage'}</p>
                  </div>
                </div>
              )}
          </>
        )}

        {(tier !== '2' && tier !== '3') && (
          <>
            <div
              className="mt-12"
              style={{ height: '1px', backgroundColor: 'black', width: '70%' }}
            ></div>

            <h2 className="text-xl font-medium flex mt-12">
              Organization account details
            </h2>
            <div className="flex mt-2">
              <h4 className="text-lg font-medium">
                {stripeSubscriptionIdTeam
                  ? 'Organization Plan: $25 / mo. includes 5 users'
                  : 'Organization Plan: $25 / mo. includes 5 users'}
              </h4>
            </div>
            {teamExpiresOn && (
              <div className="text-xxs">{`Your plan is set to cancel on ${dayjs
                .unix(teamExpiresOn)
                .format('D MMMM YYYY')}. Enjoy full access until then!`}</div>
            )}
            {!stripeSubscriptionIdTeam && !planTeam && !isLoadingStripePortal && (
              <div className="mt-6">
                <Button
                  label={`Upgrade to ${appSumoLicenseKey ? "Tier 2/3" : "Organization"}`}
                  onClick={buyTeamPlan}
                  isLoading={isLoadingUpgradeTeam}
                  className="custom-btn px-6"
                />
                <div className="flex mt-6">
                  <h4 className="text-sm font-semibold uppercase">
                    {'Everything in'}
                    <a
                      href="https://www.instacap.co/pricing"
                      target="_blank"
                      className="pink-txt"
                    >
                      {' Pro Plan '}
                    </a>
                    {'plus:'}
                  </h4>
                </div>
                <div className="flex gap-2.5 mt-2">
                  <Check color={'#6AD500'} />
                  <p className="text-sm">{'Shared Workspaces'}</p>
                </div>
                <div className="flex gap-2.5 mt-2">
                  <Check color={'#6AD500'} />
                  <p className="text-sm">{'Enhanced Collaboration'}</p>
                </div>
                <div className="flex gap-2.5 mt-2">
                  <Check color={'#6AD500'} />
                  <p className="text-sm">{'Centralized Managements'}</p>
                </div>
                <div className="flex gap-2.5 mt-2">
                  <Check color={'#6AD500'} />
                  <p className="text-sm">{'Priority Support'}</p>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Billing;
