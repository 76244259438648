const ArrowForward = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="1" y="1" width="24" height="24" rx="12" fill="white" />
    <path d="M21 5H5V21H21V5Z" fill="white" fillOpacity="0.01" />
    <path
      d="M19 13H7"
      stroke="black"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15 9L19 13L15 17"
      stroke="black"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect x="1" y="1" width="24" height="24" rx="12" stroke="black" />
  </svg>
);
export default ArrowForward;
