import { useAuth } from '@/hooks/useAuth';
import { saveShareInvite, setCaptureInvite } from '@/lib/firebase/firestore';
import { sendPostForRequestAccess } from '@/lib/firebase/firestore/email';
import { currentCaptureInfoState } from '@/state/captureState';
import { currentUserInfoState } from '@/state/userState';
import dayjs from 'dayjs';
import { User } from 'firebase/auth';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import GrantAccess from './grantAccess';

import Logo from '../assets/images/newlogo-white.svg';

import Loading from './Loading';

const NoAccess = () => {
  const { linkToGoogle } = useAuth();
  const closeModal = () => {
    setIsOpen(false);
  };

  const { isReady } = useAuth();
  const [isOpen, setIsOpen] = useState(true);
  const [isRequested, setIsRequested] = useState(false);
  const { uid, cid, gid } = useParams();
  const currentCaptureInformation = useRecoilValue(currentCaptureInfoState);
  const handleClick = (event: any) => {
    event.preventDefault();
    linkToGoogle(closeModal);
  };

  const [isLoaded, setIsLoaded] = useState(false);

  const userInfo = useRecoilValue(currentUserInfoState) as User;

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    setIsOpen(false);
    setIsLoaded(true);
  }, [userInfo]);

  const setLoadin = () => {
    setIsLoaded(true);
  };
  useEffect(() => {
    let timer: NodeJS.Timeout | undefined;
    userInfo.email ? (timer = setTimeout(setLoadin, 1000)) : setLoadin();

    return () => {
      timer && clearTimeout(timer);
    };
  }, [userInfo]);
  const handleAccessClick = async (event: any) => {
    //Here i will Update Share Invite Status to REQUESTED/
    //Which link will be sended to Owner of Capture Email Then Owner of Email Will click on link to accept the capture
    try {
      if (!cid && !uid) return false;
      if (!userInfo.email) return false;
      if (cid && uid && currentCaptureInformation) {
        let shareModel: Capture.ShareModel = {
          email: userInfo.email || '',
          IniviteStatus: 'REQUESTED',
          Role: 'VIEW',
          cid: cid || '',
          creator: {
            uid: currentCaptureInformation?.creator.uid || '',
            displayName: currentCaptureInformation?.creator.displayName || '',
            email: currentCaptureInformation?.creator.email || '',
          },
          groupName: currentCaptureInformation?.group || '',
          isGroupShare: gid && gid !== '' ? true : false,
          updatedAt: dayjs().unix(),
          createdAt: dayjs().unix(),
          message: `${userInfo.displayName} email is "${userInfo.email}"`,
          displayName: userInfo.displayName || '',
          uid: userInfo.uid,
          updatedBy: userInfo.uid,
        };
        let emails = [];

        emails.push(currentCaptureInformation?.creator.email || '');
        const invitation: Capture.Invitation = {
          emails,
          creator: {
            uid: userInfo.uid,
            displayName: userInfo.displayName || '',
            avatar: userInfo.uid,
            email: userInfo.email || '',
            isAnonymous: false,
          },
          createdAt: dayjs().unix(),
        };
        const data: Capture.InvitationData = {
          invitation,
          updatedAt: dayjs().unix(),
        };
        sendPostForRequestAccess(
          emails,
          {
            name: userInfo.displayName || '',
            email: userInfo.email || '',
            uid: userInfo.uid,
          },
          shareModel != undefined ? shareModel.message : '',
          {
            projectName:
              currentCaptureInformation?.projectName ||
              currentCaptureInformation?.group ||
              'Untitled Project',
            capName: currentCaptureInformation?.name || 'Untitled Capture',
            uid: currentCaptureInformation?.creator.uid || uid || '',
            cid: currentCaptureInformation?.cid || cid || '',
            email: userInfo.email,
            gid: !gid ? '' : gid,
          }
        );
        await saveShareInvite(shareModel);
        // await setCurrentCaptureShareInvitList(currentCaptureInformation?.cid);
        setIsRequested(true);
        return await setCaptureInvite(
          uid || currentCaptureInformation?.creator.uid || '',
          cid || '',
          data
        );
      }
    } catch (err) {
      console.error('Sending invitation emails error:', err);
    }
  };
  if (!isLoaded || !isReady) return <Loading />;

  return (
    <>
      {isRequested ? (
        <GrantAccess
          heading="Request generated for Capture Access"
          message="We have notified Capture Owner of your Access Request We Will notify you using your email when Owner updates your Access,Thanks"
        ></GrantAccess>
      ) : (
        !isOpen &&
        currentCaptureInformation && (
          <>
            <link rel="stylesheet" type="text/css" href="/noAccess.css" />
            <div className="container desktop-container">
              <img src="/404-background.png" alt="404" className="" />
              <div className="top-logo">
                <a href="/" target="_parent">
                  <img src={Logo} alt="Instacap" />
                </a>
              </div>
              <div className="centered">
                <img src="/no-capture-img.png" alt="" className="four-img" />
              </div>
              <div className="not-found-heading">
                <h4 className="text-distance">No Access to Capture</h4>
                {userInfo.isAnonymous && (
                  <span>
                    The Capture is not Publicly accessible, please Sign-in
                    <br /> to Access the Capture and Ask the Capture owner
                    <br />
                    to verify the link and/or update permissions.
                  </span>
                )}
              </div>
              {userInfo.isAnonymous ? (
                <div className="buttons">
                  <a onClick={handleClick} className="request-btn">
                    Signin
                  </a>
                </div>
              ) : (
                <div>
                  <p className="not-found-txt">
                    Ask the Capture’s owner to grant you <br /> permission to
                    view this Capture. You are <br /> logged in as{' '}
                    <span className="email-content">{userInfo.email}</span>
                  </p>
                  {
                    <div className="buttons">
                      <a onClick={handleAccessClick} className="request-btn">
                        Request Access
                      </a>
                      <a href="/" className="cancel-btn">
                        Cancel
                      </a>
                    </div>
                  }
                  <p className="bottom-txt" onClick={handleClick}>
                    Log in to another account
                  </p>
                </div>
              )}

              {/* <div className="footer-logo-nf">
                <a target="_parent">
                  <img src="/image/instacap-logo.png" alt="instacap-logo" />
                </a>
              </div> */}
            </div>
            <div className="mobile-container">
              <div className="wrapper">
                <div className="first-acess">
                  <a href="/" target="_parent">
                    <img src={Logo} alt="Instacap" />
                  </a>
                </div>
                <div className="second-acess">
                  <img src="/no-capture-img.png" alt="" className="four-img" />
                  <h4 className="">No Access to Capture</h4>
                  {userInfo.isAnonymous && (
                    <span>
                      The Capture is not Publicly accessible, please Sign-in to
                      Access the Capture and Ask the Capture owner to verify the
                      link and/or update permissions.
                    </span>
                  )}
                </div>
                <div className="third-access">
                  {userInfo.isAnonymous ? (
                    <div className="">
                      <a onClick={handleClick} className="">
                        Signin
                      </a>
                    </div>
                  ) : (
                    <div className="third-access-wrapper">
                      <p className="third-inner">
                        Ask the Capture’s owner to grant you permission to view
                        this Capture. You are logged in as{' '}
                      </p>
                      <span className="">{userInfo.email}</span>
                      {
                        <div className="btn-container">
                          <a
                            onClick={handleAccessClick}
                            className="btn-request"
                          >
                            Request Access
                          </a>
                          <a href="/" className="btn-cancel">
                            Cancel
                          </a>
                        </div>
                      }
                      <p className="login-indicator" onClick={handleClick}>
                        Log in to another account
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
        )
      )}
    </>
  );
};

export default NoAccess;
