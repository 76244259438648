import {
  captureListAtHostState,
  // captureListState,
  currentCaptureInfoState,
  currentCaptureIsPrivateState,
  currentUserRoleForCaptureState,
} from '@/state/captureState';
import { responsiveDesignState } from '@/state/responsiveState';
import { Toaster } from 'react-hot-toast';
import { Outlet } from 'react-router';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  useRecoilCallback,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil';
import CaptureInfo from './capture/CaptureInfo';
import Header from './header/Header';
//@ts-ignore
import { usePath } from '@/hooks/usePath';
import { usePublic } from '@/hooks/usePublic';
import Empty from './Empty';
import Loading from './Loading';
import Sidebar from './sidebar/Sidebar';

// import { pathState } from '@/state/appState';
// import _ from 'lodash';
import {
  deletedCaptureInfo,
  exactMatchNotFound,
  isDashBoardState,
  showMyProjectState,
} from '@/state/uiState';
// import { NOTFOUND } from 'dns';
import { sidebarState } from '@/state/uiState';
// import clsx from 'clsx';

import { useSlack } from '@/hooks/useSlack';
import {
  isAppLoadingState,
  isLoomSupportedState,
  pathState,
} from '@/state/appState';
import {
  currentUserIDState,
  currentUserInfoState,
  profileModalState,
  slackUserInfoState,
  updateDisplayNameState,
} from '@/state/userState';
import { isSupported } from '@loomhq/record-sdk';
import { User } from 'firebase/auth';
import { useEffect, useRef, useState } from 'react';
import NotFound from './404';
import ComingSoon from './ComingSoon';
import NoAccess from './NoAccess';
import UpdateDisplayName from './modal/UpdateNameModal';

import LogoCollapse from '../assets/images/new-sidebar-logo-close.svg';
import Logo from '../assets/images/newlogo-sidebar.svg';
import SettingModal from './modal/SettingModal';
import { getProjectsWithCaptures } from '@/lib/firebase/firestore/group';
import { currentProjectListState, projectListState, projectListStateWithCapturesState } from '@/state/projectState';
import { useOrganization } from '@clerk/clerk-react';

const Layout = () => {
  const navigate = useNavigate();
  const { authUser } = useSlack();
  const [searchParams, setSearchParams] = useSearchParams();

  const [slackUserState, setSlackUserState] =
    useRecoilState(slackUserInfoState);
  const [isLoomSupported, setIsLoomSupported] =
    useRecoilState(isLoomSupportedState);

  const captureListAtHost = useRecoilValue(captureListAtHostState);
  const { welcome, sidebar } = useRecoilValue(responsiveDesignState);

  const [responsiveDesign, setResponsiveDesign] = useRecoilState(
    responsiveDesignState
  );
  const { uid, cid } = useRecoilValue(pathState);
  const currentUserRole = useRecoilValue(currentUserRoleForCaptureState);
  const currentCaptureIsPrivate = useRecoilValue(currentCaptureIsPrivateState);

  const isCaptureDeletedInfor = useRecoilValue(deletedCaptureInfo);
  const currentCaptureInfo = useRecoilValue(currentCaptureInfoState);
  const exactMatchNotFoundValue = useRecoilValue(exactMatchNotFound);

  const isAppLoading = useRecoilValue(isAppLoadingState);
  const isSidebarShow = useRecoilValue(sidebarState);
  const currPath = window.location.pathname;
  const isLimit = currPath.includes('limit') ? true : false;
  const currentUserInfo = useRecoilValue(currentUserInfoState) as User;
  const setiIsDashBoard = useSetRecoilState(isDashBoardState);
  const currentUserID = useRecoilValue(currentUserIDState);
  const showMyProjects = useRecoilValue(showMyProjectState);
  const currentProjectList = useRecoilValue(currentProjectListState)
  // console.log("currentProjectList ::", currentProjectList)

  const setResponsiveValues = useSetRecoilState(responsiveDesignState);

  const [display, setDisplay] = useState(
    window.innerWidth <= 860 ? 'none' : 'none'
  );

  const sidebarRef = useRef<HTMLDivElement | null>(null);
  const [sidebarWidth, setSidebarWidth] = useState(0);
  const [contentMaxWidth, setContentMaxWidth] = useState(0);
  const { organization } = useOrganization();

  const getProjectListWithCaptures = useRecoilCallback(
    ({ set }) =>
      async () => {
        const userId = organization ? organization.id : currentUserID
        // const userId = currentUserID;
        const list = await getProjectsWithCaptures(
          userId,
          showMyProjects
        );
        if (list) set(projectListStateWithCapturesState, list);
      },
    [currentUserID]
  );

  useEffect(() => {
    if (!currentUserID) return;
    getProjectListWithCaptures();
  }, [currentUserID]);

  useEffect(() => {
    if (window.innerWidth <= 860) {
      setResponsiveValues({
        responsive: true,
        welcome: true,
        sidebar: false,
        comments: false,
      });
    } else {
      setResponsiveValues({
        responsive: false,
        welcome: false,
        sidebar: false,
        comments: false,
      });
    }

    function handleResize() {
      if (sidebarRef.current) {
        setContentMaxWidth(window.innerWidth - sidebarRef.current.clientWidth);
      }
    }

    window.addEventListener('resize', handleResize);

    const observer = new ResizeObserver((entries) => {
      setSidebarWidth(entries[0].contentRect.width);
    });

    if (sidebarRef.current) {
      observer.observe(sidebarRef.current);
    }

    return () => {
      window.removeEventListener('resize', handleResize);
      sidebarRef.current && observer.unobserve(sidebarRef.current);
    };
  }, []);

  useEffect(() => {
    if (sidebarRef.current) {
      setContentMaxWidth(window.innerWidth - sidebarRef.current.clientWidth);
    }
  }, [sidebarWidth]);

  let group = '';
  if (captureListAtHost.length > 0) {
    group = captureListAtHost[0].group;
  }

  const setSideBarShow = useSetRecoilState(sidebarState);

  const handleWindowSizeChange = () => {
    if (window.innerWidth > 860) {
      setSideBarShow(true);
    } else if (window.innerWidth < 860) {
      setSideBarShow(false);
    }
    setDisplay(window.innerWidth <= 860 ? 'none' : 'none');
  };

  useEffect(() => {
    async function checkBrowserLoomSupport() {
      const { supported, error } = await isSupported();

      if (!supported) {
        console.warn(`Error setting up Loom: ${error}`);
        console.error('Loom unsupported');

        setIsLoomSupported(false);
      } else {
        setIsLoomSupported(true);
      }
    }

    checkBrowserLoomSupport();

    if (window.innerWidth > 860) {
      setSideBarShow(true);
    } else if (window.innerWidth < 860) {
      setSideBarShow(false);
    }
    setiIsDashBoard(false);

    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  usePath();
  usePublic();

  //Slack connection
  useEffect(() => {
    const slackCode = searchParams.get('code');
    const slackState = searchParams.get('state');

    if (slackCode !== null && slackState !== null) {
      authUser(slackCode)
        .then(() => {
          setSlackUserState({
            isConnected: true,
          });

          console.log('Slack connected');
        })
        .finally(() => {
          navigate('/');
        });
    } else if (!slackUserState.isConnected) {
      const slack_access_token = localStorage.getItem('slack_access_token');

      if (slack_access_token) {
        setSlackUserState({
          isConnected: true,
        });

        console.log('Slack connected');
      }
    }
  }, []);

  const [updateDisplayName, setUpdateDisplayName] = useRecoilState(
    updateDisplayNameState
  );
  const [profileModal, setProfileModal] = useRecoilState(profileModalState);

  if (exactMatchNotFoundValue && currentCaptureInfo?.isDeleted) {
    return <NotFound />;
  }

  if (
    cid != '' &&
    uid != '' &&
    currentUserInfo != null &&
    currentCaptureInfo !== null &&
    currentCaptureIsPrivate &&
    currentUserRole.Role === 'ILLEGAL'
    && !organization
  ) {
    return <NoAccess />;
  }

  return (
    <>
      <div className="comingSoong" style={{ display: `${display}` }}>
        <ComingSoon />
      </div>
      {profileModal && (
        <SettingModal isOpen={true} closeModal={() => setProfileModal(false)} />
      )}

      {updateDisplayName.updateName && (
        <UpdateDisplayName
          isOpen={updateDisplayName.updateName}
          closeModal={() => {
            setUpdateDisplayName({ updateName: false, isMain: false });
          }}
          title={`Display Name`}
          submitText={updateDisplayName.isMain ? 'Submit' : `Submit and send`}
          defaultValue={currentUserInfo.displayName || `Enter Name`}
        />
      )}

      <div style={{ display: `${display === 'none' ? 'block' : 'none'}` }}>
        <Outlet />
        <div
          className={`page-center-content w-screen h-screen flex flex-row bg-gray-100 overflow-y-auto font-sans sm:flex-col sm:overflow-x-hidden ${sidebar && 'sm:bg-none'
            }`}
        >
          <Sidebar ref={sidebarRef} />
          <div
            className={`w-full flex flex-row sm:flex-col sm:relative bg-grey sm:h-screen sm:overflow-hidden mrpx-0 landscape-land ${!welcome && 'sm:hidden'
              } sm:h-screen sm:pb-0`}
            style={{
              maxWidth: contentMaxWidth ? contentMaxWidth : 'inherit',
            }}
          >
            <div className="flex-auto flex-column h-full w-full max-h-full overflow-hidden sm:relative">
              {currentCaptureInfo === undefined || isAppLoading ? (
                <>{!isLimit && <Loading />}</>
              ) : currentCaptureInfo === null ? (
                <>
                  <span className="hidden sm:block absolute top-4 left-4">
                    <img
                      src="/hamburger.svg"
                      alt=""
                      onClick={() => {
                        setResponsiveDesign({
                          ...responsiveDesign,
                          responsive: true,
                          sidebar: true,
                          welcome: false,
                          comments: false,
                        });
                      }}
                      className="cursor-pointer hamburger-icon"
                    />
                  </span>
                  <Empty />;
                </>
              ) : (
                <>
                  <Header />
                  <CaptureInfo />
                </>
              )}
              {/* <div
                className={`fixed bottom-0 sm:left-3 sm:px-0 sm:hidden  ${isSidebarShow ? ' left-5 pb-2	' : ' left-0 p-5 pb-2'
                  } `}
              >
                <a href="/">
                  {!isSidebarShow ? (
                    <img src={LogoCollapse} alt="instacap-logo" />
                  ) : (
                    <img src={Logo} alt="instacap-logo" />
                  )}
                </a>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Layout;
