import { useAuth } from '@/hooks/useAuth';
import { User } from 'firebase/auth';
import { FC, useEffect, useState } from 'react';

import Wink from './icon/avatars/Wink.svg';
import Anonymous from './icon/avatars/Anonymous.svg';
import Smiling1 from './icon/avatars/Smiling1.svg';
import Died from './icon/avatars/Died.svg';
import Kiss from './icon/avatars/Kiss.svg';
import Love from './icon/avatars/Love.svg';
import Neutral from './icon/avatars/Neutral.svg';
import old1 from './icon/avatars/old1.svg';
import Old2 from './icon/avatars/Old2.svg';
import Old3 from './icon/avatars/Old3.svg';
import Outbreak from './icon/avatars/Outbreak.svg';
import Smiling2 from './icon/avatars/Smiling2.svg';
import Surprised from './icon/avatars/Surprised.svg';
import NoImage from './icon/avatars/NoImage.svg'
import { useRecoilValue } from 'recoil';
import { currentUserInfoState } from '@/state/userState';

const avatatLists = [
  Wink,
  // Anonymous,
  Smiling1,
  Died,
  Kiss,
  Love,
  Neutral,
  old1,
  Old2,
  Old3,
  Outbreak,
  Smiling2,
  Surprised,
];

const colors = [
  'FF38A4',
  'B0FF62',
  '54FFEA',
  '000000',
  '9B51E0',
  'FF5B37',
  'FFF8F4',
];
const variant = 'beam';
const host = 'https://source.boringavatars.com';
const size = '36';

const cacheAvatar: { [key: string]: string } = {};

const UserAvatar: FC<{
  uid: string;
  customClass?: string | undefined;
  height?: Number;
  width?: Number;
  useCache?: boolean
}> = ({ uid, customClass, height, width }) => {
  const [url, setUrl] = useState(
    avatatLists[Math.floor(Math.random() * avatatLists.length)]
  );
  const { getStoredUserInfo } = useAuth();
  const user = useRecoilValue(currentUserInfoState) as User;

  useEffect(() => {
    if (uid === "") return setUrl(avatatLists[Math.floor(Math.random() * avatatLists.length)]);
    if (user?.uid === uid && user?.photoURL) {
      setUrl(user.photoURL);
    }
  }, [user, uid]);

  useEffect(() => {
    if (uid === "") return setUrl(avatatLists[Math.floor(Math.random() * avatatLists.length)]);
    const fetch = async () => {

      if (uid) {
        const otherInfo = await getStoredUserInfo(uid);
        if (otherInfo?.avatar) {
          cacheAvatar[uid] = otherInfo.avatar;
          setUrl(otherInfo.avatar);
        }
      }
    };
    if (user.uid !== uid) {
      if (cacheAvatar[uid]) {
        setUrl(cacheAvatar[uid]);
      } else {
        fetch();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, uid]);
  return (
    <img
      src={url}
      width={36}
      loading={'lazy'}
      alt={``}
      className={` rounded-full user-avatar  ${customClass}`}
      style={{
        width: width ? width + 'px' : '36px',
        height: height ? height + 'px' : '36px',
      }}
    />
  );
};

export default UserAvatar;
