import { useCallback, MouseEvent, useMemo } from 'react';
import {
  centerToTL,
  degToRadian,
  getCursor,
  getLength,
  getNewStyle,
} from '@/lib/ddr/util';

const zoomableMap: { [key: string]: string } = {
  n: 't',
  s: 'b',
  e: 'r',
  w: 'l',
  ne: 'tr',
  nw: 'tl',
  se: 'br',
  sw: 'bl',
};

let isMouseDown = false;

const getRound = (num: number) => {
  const COUNT = 10 * 7;
  return Math.round((num + Number.EPSILON) * COUNT) / COUNT;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
// eslint-disable-next-line @typescript-eslint/ban-types
export const useResize = (
  setRect: (object: object) => void,
  toolType: Tool.ObjectType,
  aspectRatio = false
) => {
  const zoomable = useMemo(
    () => (toolType === 'ARROW' ? 'ne, sw' : 'nw, ne, se, sw'),
    [toolType]
  );

  const direction = useMemo(
    () =>
      zoomable
        .split(',')
        .map((d) => d.trim())
        .filter((d) => d),
    [zoomable]
  );

  const onResize = useCallback(
    (elementEvent: MouseEvent<HTMLElement>, cursor: string, type: string) => {
      if (elementEvent.button !== 0) return;
      elementEvent.preventDefault();
      elementEvent.stopPropagation();
      document.body.style.cursor = cursor;
      const element = elementEvent.currentTarget;
      // @ts-ignore
      const rect = JSON.parse(element.parentElement.dataset.rect);
      // @ts-ignore
      rect.angle = parseInt(element.parentElement.dataset.angle, 10);
      rect.centerX = rect.left + rect.width / 2;
      rect.centerY = rect.top + rect.height / 2;
      const { clientX: startX, clientY: startY } = elementEvent;

      isMouseDown = true;

      const onMove = (e: globalThis.MouseEvent) => {
        if (!isMouseDown) return;
        e.stopImmediatePropagation();
        const { clientX, clientY } = e;
        const deltaX = clientX - startX;

        const deltaY = clientY - startY;
        const alpha = getRound(Math.atan2(deltaY, deltaX));
        const length = getRound(getLength(deltaX, deltaY));
        const isShiftKey = e.shiftKey;

        const beta = getRound(alpha - degToRadian(rect.angle));
        const deltaW = getRound(length * Math.cos(beta));
        const deltaH = getRound(length * Math.sin(beta));
        const ratio =
          isShiftKey && !aspectRatio ? rect.width / rect.height : aspectRatio;
        const minWidth = 0;
        const minHeight = 0;

        const {
          position: { centerX, centerY },
          size,
        } = getNewStyle(
          type,
          { ...rect, rotateAngle: rect.angle },
          deltaW,
          deltaH,
          ratio,
          minWidth,
          minHeight
        );
        const style = centerToTL({
          centerX,
          centerY,
          width: size.width,
          height: size.height,
          rotateAngle: rect.angle,
        });

        let { top, left, width, height } = style;
        top = Math.round(top);
        left = Math.round(left);
        width = Math.round(width);
        height = Math.round(toolType === 'ARROW' ? width : height);

        setRect({
          top,
          left,
          width,
          height,
        });
      };

      const onUp = () => {
        document.body.style.cursor = 'auto';
        document.removeEventListener('mousemove', onMove);
        document.removeEventListener('mouseup', onUp);
        if (!isMouseDown) return;
        isMouseDown = false;
      };
      document.addEventListener('mousemove', onMove);
      document.addEventListener('mouseup', onUp);
    },
    [setRect, aspectRatio, toolType]
  );

  const getResizer = useCallback(
    (angle: number) => {
      return direction.map((d) => {
        const cursor = `${getCursor(angle + 0, d)}-resize`;

        return {
          key: d,
          type: zoomableMap[d],
          cursor,
          // @ts-ignore
          className1: `chrome-extension-resizer-${zoomableMap[d]} chrome-extension-resizer-resizable-handler`,
          // @ts-ignore
          className2: `chrome-extension-resizer-${zoomableMap[d]} chrome-extension-resizer-square`,
          onResize,
        };
      });
    },
    [direction, onResize]
  );
  return { getResizer };
};
