import {
  useExport,
  useRandomColorFromPallet,
  useSaveImage,
} from '@/hooks/useScreenshotEditor';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import EditorNavbar from './editor/navbar/editorNav';
import Sidebar from './editor/sidebar';
import BackgroundSection from './editor/LeftSidebarSection';
import ImageCanvas from './editor/canvas/imageCanvas';
import { setName } from 'store/slice/editor.slice';
import Loading from './Loading';
import toast from 'react-hot-toast';

const Editor = () => {
  const {
    handleExport,
    handlePaste,
    containerRef,
    handleFileInputChange,
    handleCopyImageToClipboard,
    image,
  } = useExport();
  useRandomColorFromPallet();
  const { height, width, name } = useSelector(
    (state: RootState) => state.editor
  );
  const fadeInAnimation = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
    exit: { opacity: 0 },
  };

  useEffect(() => {
    const handlePasteGlobal = (e: any) => {
      handlePaste(e);
    };

    document.addEventListener('paste', handlePasteGlobal);

    return () => {
      document.removeEventListener('paste', handlePasteGlobal);
    };
  }, [handlePaste]);

  const { handleSaveImage, loading } = useSaveImage();
  const handleSaveImageProgressAndExit = () => {
    handleSaveImage(width, height, containerRef);
    toast.success('Image saved successfully');
  };
  const handleSaveImageProgress = () => {
    handleSaveImage(width, height, containerRef, false);
    toast.success('Image saved successfully');
  };
  if (loading) return <Loading />;
  return (
    <div className="page-center-content">
      <EditorNavbar
        handleExport={handleExport}
        handleCopyImageToClipboard={handleCopyImageToClipboard}
        handleFileInputChange={handleFileInputChange}
        NewEditorDropdownStatus={true}
        name={name}
        handleSaveImageProgress={handleSaveImageProgress}
        handleSaveImageProgressAndExit={handleSaveImageProgressAndExit}
      />

      <div className="">
        <div className="editor-container">
          <main className="editor-main">
            <div
              className=""
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
                marginTop: '65px',
                marginLeft: '20px',
              }}
            >
              <ImageCanvas image={image} containerRef={containerRef} />
            </div>
          </main>

          <div className="background-section z-50 no-scrollbar">
            <BackgroundSection />
          </div>

          <div className="sidebar-section-editor">
            <Sidebar
              handleFileInputChange={handleFileInputChange}
              //@ts-ignore
              handleExport={handleExport}
              width={width}
              height={height}
              image={image}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Editor;
