import dayjs from 'dayjs';
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  updateDoc,
} from 'firebase/firestore';
import { find } from 'lodash';
import { nanoid } from 'nanoid';
import { Project } from 'types/project';

const DB_PREFIX = 'instacap/groups';

export const saveProject = async (uid: string, data: Project) => {
  try {
    if (uid && data?.gid) {
      const db = getFirestore();
      const colRef = collection(db, 'instacap', 'groups', uid);
      const docRef = doc(colRef, data.gid);
      await setDoc(docRef, data);
      return data;
    }
    return null;
  } catch (e) {
    console.error('Error adding project: ', e);
  }
};

export const updateProject = async (
  uid: string,
  gid: string,
  data: Partial<Project>
) => {
  try {
    const db = getFirestore();
    const colRef = collection(db, 'instacap', 'groups', uid);
    const docRef = doc(colRef, gid);
    await updateDoc(docRef, data);
    return data;
  } catch (e) {
    console.error('Error adding project: ', e);
  }
};
export const addProject = async (uid: string, name: string) => {
  const data: Project = {
    gid: nanoid(),
    name,
    numItems: 0,
    createdAt: dayjs().unix(),
    uid,
  };

  return saveProject(uid, data);
};

export const getProjects = async (uid: string) => {
  try {
    const db = getFirestore();
    const colRef = collection(db, `${DB_PREFIX}/${uid}`);

    const q = query(colRef, orderBy('createdAt', 'desc'));

    const colSnap = await getDocs(q);

    const result: Project[] = [];

    colSnap.forEach((docSnap) => {
      if (docSnap.exists()) {
        result.push(docSnap.data() as Project);
      }
    });

    return result;
  } catch (error) {
    console.log(error);
  }
};

export const getProjectSub = (uid: string, cb: Function) => {
  try {
    const db = getFirestore();
    const colRef = collection(db, `${DB_PREFIX}/${uid}`);
    const q = query(colRef, orderBy('createdAt', 'desc'));

    const unsub = onSnapshot(q, (snapshot) => {
      const result: Project[] = [];
      snapshot.forEach((doc) => {
        result.push(doc.data() as Project);
      });
      cb(result);
    });

    return () => {
      unsub();
    };
  } catch (error) {
    console.log(error);
    return () => {};
  }
};

export const getProjectsWithCaptures = async (
  uid: string,
  showMyProjects: boolean
) => {
  try {
    const db = getFirestore();
    const colRef = collection(db, `${DB_PREFIX}/${uid}`);
    const q = query(colRef, orderBy('createdAt', 'desc'));
    const colSnap = await getDocs(q);

    const colRef2 = collection(db, `instacap/captures/${uid}`);
    const q2 = query(colRef2, orderBy('createdAt', 'desc'));
    const colSnap2 = await getDocs(q2);

    let projects: any = [];
    let captures: any = [];

    colSnap.forEach((docSnap) => {
      if (docSnap.exists()) {
        projects.push(docSnap.data() as Project);
      }
    });

    colSnap2.forEach((docSnap) => {
      if (docSnap.exists()) {
        captures.push(docSnap.data() as Capture.Info);
      }
    });

    let projectsWithCaptures = projects.filter(
      (project: Project) => project.numItems !== 0
    );

    if (!showMyProjects) {
      const getCaptures = async () => {
        let allCapture: any = [];

        for (const project of projectsWithCaptures) {
          const colCaptures = collection(db, `instacap/captures/${project.uid}`);
          const capturesQuerry = query(colCaptures, orderBy('createdAt', 'desc'));
          const colSnapCaptures = await getDocs(capturesQuerry);

          colSnapCaptures.forEach((docSnap) => {
            if (docSnap.exists()) {
              allCapture.push(docSnap.data());
            }
          });
        }
        return allCapture;
      };

      const fetchCaptures = async () => {
        const captures = await getCaptures();
        return captures;
      };

      captures = await fetchCaptures();
    }

    projectsWithCaptures = projectsWithCaptures.map((project: Project) => {
      const projectCaptures = captures.filter(
        (capture: Capture.Info) => capture.projectName === project.name
      );

      return { project: project, captures: projectCaptures };
    });

    const capturesWithoutProject = captures.filter(
      (capture: Capture.Info) => capture.group === null
    );
    const nonProjectCaptures = {
      project: {
        createdAt: null,
        gid: null,
        name: 'Non Project Captures',
        numItems: capturesWithoutProject.length,
        uid: uid,
      },
      captures: capturesWithoutProject,
    };

    if (capturesWithoutProject.length > 0) {
      projectsWithCaptures.unshift(nonProjectCaptures);
    }

    return projectsWithCaptures;
  } catch (error) {
    console.log(error);
  }
};

export const deleteProject = async (uid: string, gid: string) => {
  try {
    const db = getFirestore();
    await deleteDoc(doc(db, `${DB_PREFIX}/${uid}`, gid));
  } catch (e) {
    console.log(e);
  }
};


export const checkDocumentExists = async (project:any, email : string) => {
  const db = getFirestore();
  const docRef = doc(db, 'instacap/groups', `${project.uid}/${project.gid}`);
  const docSnap = await getDoc(docRef);
  
  if (docSnap.exists() ) return true 
  return false
}

export const checkSharedDocumentExists = async (project:any, email : string) => {
  const db = getFirestore();
  const docRefShared = doc(db, 'instacap/shares/sharedWithMe', email);
  const docSnapShared = await getDoc(docRefShared);
  
  if (docSnapShared.exists()){
    const result = docSnapShared.data()
    const invitations = result.invitations;
    const projectFinded = find(invitations, invitation => invitation.uid == project.uid)
    return projectFinded
  }  
  return false
}