import React from 'react';

const CursorCircleIcon = () => {
  return (
    <svg
      width="37"
      height="43"
      viewBox="0 0 37 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_f)">
        <ellipse
          cx="18.2979"
          cy="31.5"
          rx="11"
          ry="4.5"
          fill="black"
          fillOpacity="0.5"
        />
      </g>
      <path
        d="M8.59492 16.1813C8.4853 14.2851 9.36415 12.7924 10.9492 11.6278C12.5965 10.4174 14.8647 9.70148 16.9164 9.58284C19.0773 9.4579 21.5003 9.79879 23.3268 10.7704C25.0204 11.6714 26.2109 13.0899 26.3512 15.5155C26.4555 17.3212 25.2844 19.2367 23.0691 20.8233C21.9871 21.5983 20.83 22.1649 19.7651 22.528C19.972 21.9646 19.9772 21.3252 19.7319 20.726C19.2106 19.453 17.7562 18.8436 16.4832 19.3648C16.0412 19.5458 15.2907 19.6805 14.3607 19.6695C13.4458 19.6587 12.4301 19.5074 11.4796 19.1789C9.58717 18.525 8.66979 17.4763 8.59492 16.1813ZM14.321 24.6506C14.2532 24.8423 14.208 25.0454 14.1887 25.2572C14.0641 26.6271 15.0735 27.8386 16.4434 27.9633C19.3991 28.2322 23.1142 26.9182 25.9696 24.873C28.8322 22.8227 31.5702 19.4847 31.3241 15.228C31.0701 10.8354 28.6786 7.97529 25.6662 6.37277C22.7847 4.83986 19.3721 4.4513 16.6289 4.60992C13.7765 4.77485 10.5433 5.74472 7.99973 7.61364C5.39686 9.52616 3.39286 12.5053 3.622 16.4688C3.86162 20.6138 6.99036 22.8978 9.8526 23.887C11.2662 24.3755 12.8259 24.6341 14.321 24.6506Z"
        fill="#000000"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <defs>
        <filter
          id="filter0_f"
          x="0.297852"
          y="20"
          width="36"
          height="23"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur stdDeviation="3.5" result="effect1_foregroundBlur" />
        </filter>
      </defs>
    </svg>
  );
};

export default CursorCircleIcon;
