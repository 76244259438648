import { countCaptureListState } from '@/state/captureState';
import { useRecoilValue } from 'recoil';
import HeaderTitleGroup from '../../assets/images/HeaderTitleGroup.svg';

const HeaderTitle = () => {
  const countCaptureList = useRecoilValue(countCaptureListState);

  return (
    <div>
      {countCaptureList < 1 && <p className="text-6xl py-8 font-bold leading-10 sm:leading-7 sm:w-full lg:text-5xl">
        Make your first <span className="leading-relaxed">capture.</span>
      </p>}
      {countCaptureList > 0 && <p className="text-6xl py-8 font-bold leading-10 sm:leading-7 sm:w-full lg:text-5xl">
        Manage Your  <span className="leading-relaxed"> own projects.</span>
      </p>}
    </div>
  );
};

export default HeaderTitle;
