const FolderIcon = () => (
  <svg
    width="24"
    height="22"
    viewBox="0 0 24 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.75 3.5H12.75L9.75 0.5H2.25C0.984375 0.5 0 1.53125 0 2.75V19.25C0 20.5156 0.984375 21.5 2.25 21.5H21.75C22.9688 21.5 24 20.5156 24 19.25V5.75C24 4.53125 22.9688 3.5 21.75 3.5ZM5.95312 8C6.75 8 7.45312 8.70312 7.45312 9.5C7.45312 10.3438 6.75 11 5.95312 11C5.10938 11 4.45312 10.3438 4.45312 9.5C4.45312 8.70312 5.15625 8 5.95312 8ZM19.4062 18.125C19.2656 18.3594 18.9844 18.5 18.75 18.5H5.25C4.92188 18.5 4.6875 18.3594 4.54688 18.125C4.45312 17.8438 4.45312 17.5625 4.64062 17.3281L7.92188 12.8281C8.0625 12.6406 8.25 12.5 8.48438 12.5C8.67188 12.5 8.95312 12.6406 9.09375 12.8281L10.1719 14.2812L13.0781 9.875C13.2188 9.64062 13.4531 9.5 13.7344 9.5C14.0156 9.5 14.2031 9.64062 14.3438 9.875L19.3594 17.375C19.5 17.5625 19.5 17.8906 19.4062 18.125Z"
      fill="black"
    />
  </svg>
);
export default FolderIcon;
