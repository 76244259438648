import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';

export const upload = async (
  uid: string,
  cid: string,
  group: string,
  blob: Blob
) => {
  try {
    const storage = getStorage();
    console.log('upload');

    const usersCollection = ref(storage, `captures/${uid}/${group}/${cid}`);

    await uploadBytes(usersCollection, blob, {
      customMetadata: { url: window.location.href },
      cacheControl: `public, max-age=${365 * 24 * 60 * 60 * 1000}`,
    });

    return await getDownloadURL(usersCollection);
  } catch (error) {
    console.log(error);
  }
};
