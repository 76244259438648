import { FC, useRef, useState, useEffect } from 'react';
import Img from '../../assets/icons/capture.svg';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  currentCaptureInfoState,
  currentCaptureShareInvitListState,
  currentUserRoleForCaptureState,
  getCurrentUserAccessType,
  groupedByHostCaptureListSharedState,
  groupedByHostCaptureListState,
  isProjectOpen,
} from '@/state/captureState';
import { currentUserInfoState, isAnonymousState } from '@/state/userState';
import ArrowDown from '../icon/ArrowDown';
import ArrowUpShare from '../icon/ArrowUpShare';
import { isAppLoadingState, pathState } from '@/state/appState';
import { showMyProjectState } from '@/state/uiState';
import { User } from 'firebase/auth';
import ProjectDropDown from './ProjectDropdown';
import AddProject from './AddProject';
import share from '../../assets/icons/shareIcon.svg';
import deleteIcon from '../../assets/icons/Delete.svg';
import AddCapture from './AddCapture';
import { useFunction } from '@/hooks/useFunction';
import DeleteModal from '../modal/DeleteModal';
import RenameProjectModal from '../modal/RenameProjectModal';
import DashboardShareModal from '../modal/DashboardShareModal';
import LockIcon from '../../assets/icons/privateLock.svg';
import { Popover } from '@headlessui/react';
import { useNavigate } from 'react-router-dom';
import SearchIcon from '../icon/SearchIcon';
import cn from 'classnames';
import { AiOutlineHome } from 'react-icons/ai';
import { projectListState } from '@/state/projectState';
import plus from '../../assets/icons/addNew.svg';
import MoreIcon from '../icon/MoreIcon';
import { Project } from 'types/project';
import DropdownProject from '../sidebar/DropdownProject';
import SignIn from './SignIn';
import UpgradePlan from './UpgradePlan';
import { useOrganization } from '@clerk/clerk-react';

type Props = {
  isExtension: boolean;
  isProjectPage?: boolean;
  isExpired?: boolean;
  isDeleted?: boolean;
};

const CaptureHeader: FC<Props> = ({
  isExtension,
  isProjectPage,
  isExpired,
  isDeleted,
}) => {
  const navigate = useNavigate();
  const isAnonymous = useRecoilValue(isAnonymousState);
  const appLoading = useRecoilValue(isAppLoadingState);
  const ref = useRef<HTMLDivElement | null>(null);
  const searchRef = useRef<HTMLInputElement | null>(null);
  const [showMyProject, setShowMyProjects] = useRecoilState(showMyProjectState);
  const currentRole = useRecoilValue(currentUserRoleForCaptureState);
  const [isDropdownShow, setIsDropdownShow] = useState(false);
  const currentUserInfo = useRecoilValue(currentUserInfoState) as User;
  const captureListAtHost = useRecoilValue(
    showMyProject
      ? groupedByHostCaptureListState
      : groupedByHostCaptureListSharedState
  );
  const projectList = useRecoilValue(projectListState);
  const captureListSharedWithMeAtHost = useRecoilValue(
    groupedByHostCaptureListSharedState
  );
  const currentCaptureInfo = useRecoilValue(currentCaptureInfoState);
  const currentProject = useRecoilValue(isProjectOpen);
  const groupedByHostCaptureList = useRecoilValue(
    groupedByHostCaptureListState
  );
  const currentAccessType = useRecoilValue(getCurrentUserAccessType);
  const setProjectOpen = useSetRecoilState(isProjectOpen);
  const { deleteProjectCapture } = useFunction();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const setIsAppLoading = useSetRecoilState(isAppLoadingState);
  const [renameOpen, setRenameOpen] = useState(false);
  const [projectName, setProjectName] = useState('');
  const [captureCount, setCaptureCount] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [isPrivate, setIsPrivate] = useState(false);
  const currentCaptureShareInviteList = useRecoilValue(
    currentCaptureShareInvitListState
  );
  const { organization } = useOrganization();

  const handleDeleteProject = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    setIsDeleteModalOpen(true);
  };

  const handleClickTripleDots = () => {
    if (currentUserInfo.isAnonymous) return false;
    setIsDropdownShow((isDropdownShow) => !isDropdownShow);
  };

  const [isEditProjectDropdownShow, setIsEditProjectDropdownShow] =
    useState(false);
  const [showShareModel, setShowShareModel] = useState(false);

  const handleChangeEditDropdownShow = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    if (currentUserInfo.isAnonymous) return false;
    setIsEditProjectDropdownShow((isDropdownShow) => !isDropdownShow);
  };

  const Proceed = async (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    setIsDeleteModalOpen(false);
    await deleteProjectCapture(currentProject.gid).then(() => {
      setProjectOpen({
        open: false,
        gid: '',
        projectInfo: {},
        captureCount: 0,
      });
      navigate('/');
    });
    setIsAppLoading(false);
  };

  // useEffect(() => {
  //   if (
  //     currentRole.Role === 'ADMIN' ||
  //     currentRole.Role === 'SUPERADMIN' ||
  //     currentRole.Role === 'ILLEGAL' ||
  //     currentCaptureInfo === null ||
  //     currentUserInfo.isAnonymous
  //   ) {
  //     console.log(
  //       'here',
  //       currentRole,
  //       currentCaptureInfo,
  //       currentUserInfo.isAnonymous
  //     );
  //     setShowMyProjects(true);
  //   } else {
  //     setShowMyProjects(false);
  //   }
  // }, [currentRole, captureListSharedWithMeAtHost, currentUserInfo]);

  useEffect(() => {
    document.addEventListener('mousedown', clickOutside);

    return () => {
      document.removeEventListener('mousedown', clickOutside);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDropdownShow]);

  useEffect(() => {
    setIsDropdownShow(false);
  }, [showMyProject]);

  const clickOutside = (e: Event) => {
    // inside clicku
    if (ref.current && ref?.current.contains(e.target as Node)) return false;

    // outside click && already opened
    if (isDropdownShow) return handleClickTripleDots();
  };

  useEffect(() => {
    if (currentProject.gid) {
      let projectName;
      if (currentProject.projectInfo.name) {
        projectName = currentProject.projectInfo.name;
      } else {
        projectName = 'Untitled Project';
      }

      setProjectName(projectName);
      setCaptureCount(currentProject.projectInfo?.numItems ?? 0);
      setIsPrivate(!!currentProject.projectInfo?.publicAccess);
    }
  }, [captureListAtHost, currentProject]);

  const setCurrentPath = useSetRecoilState(pathState);
  useEffect(() => {
    const projects = captureListAtHost.map(([_, group]) => {
      return {
        gid: group[0].group,
        items: group,
      };
    });
    const cid =
      projects.find((p) => p.gid === currentProject.gid)?.items?.[0]?.cid ?? '';
    isOpen
      ? setCurrentPath({
        gid: '',
        cid: cid,
        uid: currentProject.projectInfo?.uid ?? '',
      })
      : setCurrentPath({
        gid: '',
        cid: '',
        uid: '',
      });
  }, [isOpen]);

  const shareAccess =
    currentAccessType === 'EDIT-PROJECT-ACCESS' ||
    currentAccessType === 'CAPTURE-OWNER' ||
    currentAccessType === 'VIEW-PROJECT-ACCESS';
  const addCaptureAccess =
    currentAccessType === 'EDIT-PROJECT-ACCESS' ||
    currentAccessType === 'CAPTURE-OWNER' ||
    currentProject.projectInfo?.uid === currentUserInfo.uid;
  useEffect(() => {
    let obj = currentCaptureShareInviteList.find(
      (o) => o.email === currentUserInfo.email
    );
    setShowShareModel(obj?.isGroupShare == true);
  }, [currentCaptureShareInviteList]);

  return (
    <div className="w-full flex items-center xl:items-start container-width sm:flex-col mb-7">
      <div className="w-1/2 sm:w-full flex flex-col sm:pb-4">
        {isAnonymous && (
          <div className="hidden sm:block pb-8">
            <p>
              Sign in to{' '}
              <a href="#" className="underline font-bold">
                start a collaboration
              </a>{' '}
              in-context on pdf's, images, and more.
            </p>
          </div>
        )}
        {!currentProject.open && (
          <>
            {(isDeleted || isExpired) && (
              <div className="flex flex-col px-4">
                <span
                  className="cursor-pointer text-sm text-light-grey flex items-center w-fit"
                  onClick={() => navigate('/')}
                >
                  <AiOutlineHome className="mr-0.5" /> Dashboard
                </span>
              </div>
            )}
            <div
              className={cn(
                'flex items-center',
                currentUserInfo.isAnonymous && 'mb-4'
              )}
            >
              {currentUserInfo.isAnonymous && <img src={Img} alt="QA" />}
              <div
                className={`${(isDeleted || isExpired) && 'text-red-600'
                  } ml-4 text-2xl font-bold`}
              >
                {showMyProject ? (
                  currentUserInfo.isAnonymous ? (
                    `Your Captures (${captureListAtHost.length})`
                  ) : isExpired ? (
                    <div className="flex gap-2 items-center">
                      <span>Expired Captures</span>
                      <UpgradePlan isExpired />
                    </div>
                  ) : isDeleted ? (
                    <div className="flex gap-2">
                      <span>Deleted Captures</span>
                      <div
                        id="drop-btn"
                        className={`relative min-w-10 h-10 flex items-center justify-center border-1.5 border-black rounded-full cursor-pointer hover:bg-gray-100 shadow-xs`}
                        onClick={handleChangeEditDropdownShow}
                      >
                        <MoreIcon />
                        <DropdownProject
                          isDropdownShow={isEditProjectDropdownShow}
                          group={currentProject.gid}
                          setDropDownShow={setIsEditProjectDropdownShow}
                          data={currentProject.projectInfo as Project}
                          isExpired={isExpired}
                          isDeleted={isDeleted}
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="flex items-center">
                      Non-Project Captures
                      {<AddProject toolTip="Add Capture" />}
                    </div>
                  )
                ) : (
                  // `Shared with me (${captureListSharedWithMeAtHost.length})`
                  `Shared with me`
                )}
              </div>
              {showMyProject && currentUserInfo.isAnonymous && (
                <AddProject toolTip="Add Capture" />
              )}
            </div>
            {isAnonymous && (
              <div className="sm:hidden dashboard-inner-text">
                {!isExtension && (
                  <p>
                    Use{' '}
                    <a
                      href="https://chrome.google.com/webstore/detail/annotation-full-page-capt/jompclhalmbigbmjogfnfjkomponodhk"
                      target="blank"
                      className="underline font-bold"
                    >
                      Instacap for Chrome
                    </a>{' '}
                    to capture and annotate on webpages.
                  </p>
                )}
                {isAnonymous && (
                  <div className="flex">
                    {'Or'}&nbsp;
                    <SignIn mode="text" />
                    &nbsp;
                    {
                      "to start a collaboration in-context on pdf's, images, and more."
                    }
                  </div>
                )}
              </div>
            )}
          </>
        )}
        {currentProject.open && !isProjectPage && (
          <div>
            <div>
              <span
                onClick={() => {
                  setProjectOpen({
                    open: false,
                    gid: '',
                    projectInfo: {},
                    captureCount: 0,
                  });

                  // navigate('/')
                }}
                className="text-pink cursor-pointer"
              >
                {showMyProject
                  ? `My Projects (${projectList.length})`
                  // : `Shared with me (${captureListSharedWithMeAtHost.length})`}{' '}
                  : `Shared with me`}{' '}
                {'>'}{' '}
              </span>{' '}
              {projectName ? projectName : 'Untitled Capture'}
            </div>
            <div className="flex items-center">
              {isPrivate && <img src={LockIcon} alt="lock-icon" />}
              &nbsp;
              <p className="text-2xl font-semibold py-4">
                <Popover
                  className={`sm:order-first`}
                // onClick={handleClick}
                >
                  <Popover.Button
                    // onClick={handleClick}
                    className={
                      currentAccessType !== 'CAPTURE-OWNER' &&
                        currentAccessType !== 'EDIT-PROJECT-ACCESS'
                        ? ''
                        : `has-tooltip`
                    }
                  >
                    <span
                      className="cursor-pointer font-semibold"
                      onClick={() => {
                        if (
                          currentAccessType !== 'CAPTURE-OWNER' &&
                          currentAccessType !== 'EDIT-PROJECT-ACCESS'
                        ) {
                          return false;
                        }
                        setRenameOpen(true);
                      }}
                    >
                      <span className="truncate ... max-w-4">
                        {projectName
                          ? projectName.length > 15
                            ? `${projectName.slice(0, 20)}...`
                            : projectName
                          : 'Untitled Capture'}
                      </span>{' '}
                      ({captureCount})
                    </span>{' '}
                    <span className="tooltip w-30 m-auto tool-tip-txt shadow-2xl">
                      {'Rename Project'}
                    </span>
                  </Popover.Button>
                </Popover>
              </p>
              {addCaptureAccess && (
                <AddCapture
                  group={currentProject.projectInfo.gid}
                  toolTip="Add Capture"
                  customClassName=""
                  projectName={projectName}
                />
              )}
            </div>
          </div>
        )}
        {isProjectPage && (
          <div className="flex flex-col px-4">
            <span
              className="cursor-pointer text-sm text-light-grey flex items-center w-fit"
              onClick={() => navigate('/')}
            >
              <AiOutlineHome className="mr-0.5" /> Dashboard
            </span>
            <div className="flex items-center">
              <span className="text-2xl font-semibold truncate mr-2">
                {projectName}
              </span>
              {(addCaptureAccess || organization) && (
              // {addCaptureAccess && (
                <AddCapture
                  group={currentProject.projectInfo.gid}
                  toolTip="Add Capture"
                  customClassName=""
                  projectName={projectName}
                />
              )}
              {(addCaptureAccess || organization) && (
              // {addCaptureAccess && (
                <div
                  className={`relative min-w-10 h-10 flex items-center justify-center border-1.5 border-black rounded-full cursor-pointer hover:bg-gray-100 shadow-xs`}
                  onClick={handleChangeEditDropdownShow}
                >
                  <MoreIcon />
                  <DropdownProject
                    isDropdownShow={isEditProjectDropdownShow}
                    group={currentProject.gid}
                    setDropDownShow={setIsEditProjectDropdownShow}
                    data={currentProject.projectInfo as Project}
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <div
        className={` w-1/2 sm:w-full flex xl:flex-col xl:gap-4 flex-row justify-end sm:justify-between items-start xl:items-end`}
      >
        {/* {currentProject.open && isProjectPage && (
          <button
            className={` ${!showShareModel && !showMyProject ? 'cursor-not-allowed' : ''
              } border px-4 py-2 mx-1 rounded-3xl flex`}
            onClick={() => {
              if (showShareModel || showMyProject) {
                setIsOpen(true);
              } else {
                return false;
              }
            }}
          >
            <img src={share} alt="share-icon" className="px-3" />{' '}
            <span>Share Project</span>
          </button>
        )} */}

        {currentProject.open &&
          (currentAccessType === 'CAPTURE-OWNER' ||
            currentProject.projectInfo?.uid === currentUserInfo.uid) &&
          !isProjectPage && (
            <button
              onClick={handleDeleteProject}
              className="border px-4 py-2 mx-1 rounded-3xl flex text-red-500"
            >
              <img src={deleteIcon} alt="delete-icon" className="px-3" />{' '}
              <span>Delete Project</span>
            </button>
          )}
        {/* {currentProject.open && (
          <button className="border px-4 py-2 mx-1 rounded-3xl flex">
            <img src={slack} alt="slack-icon" className="px-3" />{' '}
            <span>Connect with Slack</span>
          </button>
        )} */}
        {/* {!currentProject.open && currentUserInfo.isAnonymous && (
          <div className="w-1/3 xl:w-1/2 xl:mr-0 h-11 flex items-center mr-6 sm:mr-4 px-4 rounded-3xl border-1 border-gray-300">
            <SearchIcon />
            <input
              ref={searchRef}
              type="text"
              className="w-4/5 h-8 pl-2 focus:outline-none text-base font-normal"
              placeholder="Search"
            />
          </div>
        )} */}
        {/* {(!currentProject.open || isProjectPage) && (
          <button className="w-1/3 xl:w-1/2 xl:mr-0 h-11 flex justify-between items-center mr-6 px-4 rounded-3xl border-1 border-gray-300 text-base font-normal">
            Sort by date
            <ArrowDown width={20} height={20} />
          </button>
        )} */}
        {/* <div className="w-1/3 sm:w-1/2 h-12 flex justify-between items-center px-2 rounded-3xl border-1 border-gray-300"> */}
        {/* {!appLoading && !currentProject.open && (
          <div ref={ref} tabIndex={0} className="xl:w-1/2 relative">
            <div
              onClick={handleClickTripleDots}
              className="border-2 h-11 flex rounded-3xl w-full"
            >
              {
                <p
                  className={
                    'xl:justify-between w-full relative flex flex-row justify-start items-center text-lg font-bold px-4 py-2 sidebar-Capture-title truncate ... max-w-56 xl:max-w-none'
                  }
                  tabIndex={0}
                >
                  <span className="flex items-center">
                    {showMyProject ? 'My Projects' : 'Shared with me'}
                    <span className="sidebar-Capture-title-count ml-1">
                      (
                      {showMyProject
                        ? captureListAtHost.length
                        : captureListSharedWithMeAtHost.length}
                      )
                    </span>
                  </span>

                  {!currentUserInfo.isAnonymous && (
                    <span
                      id={`ShareInputViewDropDown`}
                      className={'cursor-pointer pl-4'}
                    >
                      {isDropdownShow ? (
                        <ArrowUpShare width={20} height={20} />
                      ) : (
                        <ArrowDown width={20} height={20} />
                      )}
                    </span>
                  )}
                </p>
              }
            </div>
            <div className="absolute ml-2">
              <ProjectDropDown
                isDropdownShow={isDropdownShow}
                index={0}
                SharedWithMe={captureListSharedWithMeAtHost.length}
                myProject={groupedByHostCaptureList.length}
                setShowMyProject={setShowMyProjects}
                isShowingMyProject={showMyProject}
              />
            </div>
          </div>
        )} */}
      </div>
      {/* <DeleteModal
        isDeleteModalOpen={isDeleteModalOpen}
        closeModal={() => setIsDeleteModalOpen(false)}
        Proceed={Proceed}
        deleteType="Project"
      />
      <RenameProjectModal
        isOpen={renameOpen}
        closeModal={(e: boolean) => {
          setRenameOpen(false);
        }}
        data={currentProject.projectInfo as Project}
      /> */}

      {
        <DashboardShareModal
          isOpen={isOpen}
          closeModal={() => setIsOpen(!isOpen)}
          url={`${window.location.href + currentProject.projectInfo?.uid}`}
        />
      }
    </div>
  );
};

export default CaptureHeader;
