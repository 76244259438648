import { FC, memo } from 'react';

interface props {
  width?: number;
  height?: number;
  fill?: string;
  opacity?: number;
}

const SearchIcon: FC<props> = ({
  fill,
  height = 17,
  width = 17,
  opacity = 1,
}) => {
  return (
    <svg
      width={width}
      height={height}
      opacity={opacity}
      fill={fill}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title />
      <g
        fill="none"
        fillRule="evenodd"
        id="页面-1"
        stroke="none"
        strokeWidth="1"
      >
        <g id="导航图标" transform="translate(-327.000000, -142.000000)">
          <g id="编组" transform="translate(327.000000, 142.000000)">
            <rect
              fill="#FFFFFF"
              fillOpacity="0.01"
              fillRule="nonzero"
              height="24"
              id="矩形"
              width="24"
              x="0"
              y="0"
            />
            <path
              d="M10.5,19 C15.1944,19 19,15.1944 19,10.5 C19,5.8056 15.1944,2 10.5,2 C5.8056,2 2,5.8056 2,10.5 C2,15.1944 5.8056,19 10.5,19 Z"
              id="路径"
              stroke="#212121"
              strokeLinejoin="round"
              strokeWidth="1.5"
            />
            <path
              d="M13.3284,7.17155 C12.60455,6.4477 11.60455,6 10.5,6 C9.39545,6 8.39545,6.4477 7.67155,7.17155"
              id="路径"
              stroke="#212121"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
            />
            <line
              id="路径"
              stroke="#212121"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
              x1="16.6109"
              x2="20.85355"
              y1="16.6109"
              y2="20.85355"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default memo(SearchIcon);
