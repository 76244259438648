import { useEffect, useRef, useState } from 'react';
import ArrowDown from '../icon/ArrowDown';
import ArrowUpShare from '../icon/ArrowUpShare';
import { useRecoilState, useRecoilValue } from 'recoil';
import { showMyProjectState } from '@/state/uiState';
import {
  groupedByHostCaptureListSharedState,
  groupedByHostCaptureListState,
} from '@/state/captureState';
import { Link, useNavigate } from 'react-router-dom';
import { currentUserInfoState } from '@/state/userState';
import { User } from 'firebase/auth';
import cn from 'classnames';

const Nav = () => {
  const navigate = useNavigate();

  const [show, setShow] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);

  const currentUserInfo = useRecoilValue(currentUserInfoState) as User;

  const [showMyProject, setShowMyProjects] = useRecoilState(showMyProjectState);

  const captureListAtHost = useRecoilValue(
    showMyProject
      ? groupedByHostCaptureListState
      : groupedByHostCaptureListSharedState
  );

  const captureListSharedWithMeAtHost = useRecoilValue(
    groupedByHostCaptureListSharedState
  );

  const DROPDOWNS = [
    {
      title: 'Software QA',
      href: 'https://www.instacap.co/use-cases#qa',
    },
    {
      title: 'Development Prototypes',
      href: 'https://www.instacap.co/use-cases#dev',
    },
    {
      title: 'Presentation Feedback',
      href: 'https://www.instacap.co/use-cases#pdf',
    },
    {
      title: 'Market Approvals',
      href: 'https://www.instacap.co/use-cases#marketing',
    },
    {
      title: 'Instructional Guides',
      href: 'https://www.instacap.co/use-cases#howto',
    },
  ];
  const clickOutside = (e: Event) => {
    // inside click
    if (ref.current && ref?.current.contains(e.target as Node)) return false;

    // outside click && already opened
    if (show) return setShow(false);
  };
  useEffect(() => {
    document.addEventListener('mousedown', clickOutside);

    return () => {
      document.removeEventListener('mousedown', clickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const hasCapture =
    captureListAtHost.length > 0 || captureListSharedWithMeAtHost.length > 0;

  return (
    <div className="">
      <ul className="inline-flex sm:absolute sm:right-4 sm:w-1/2 sm:h-auto sm:z-999999 sm:py-8 sm:flex sm:flex-col rounded-xl sm:items-center sm:font-normal sm:text-gray-800 sm:bg-gray-100">
        {!hasCapture && currentUserInfo.isAnonymous ? (
          <>
            <li
              className="px-1 relative sm:pb-4 py-1 upload-grey-txt"
              onClick={() => setShow(!show)}
            >
              <span className="hover:text-3xl cursor-pointer">Use Cases</span>
            </li>
            <span
              onClick={() => setShow(!show)}
              className={'cursor-pointer mt-2 flex items-center'}
            >
              {show ? (
                <ArrowUpShare width={20} height={20} />
              ) : (
                <ArrowDown width={20} height={20} />
              )}
            </span>
            <li className="px-4 sm:pb-4 py-1 upload-grey-txt">
              <a
                href="https://www.instacap.co/pricing"
                target={'_blank'}
                rel="noreferrer"
              >
                Pricing
              </a>
            </li>
            <li className="px-4 py-1 upload-grey-txt">
              <a
                href="https://www.instacap.co/blog"
                target={'_blank'}
                rel="noreferrer"
              >
                Blog
              </a>
            </li>
            <li className="px-4 py-1 upload-grey-txt">
              <a
                href="https://chrome.google.com/webstore/detail/annotation-full-page-capt/jompclhalmbigbmjogfnfjkomponodhk"
                target={'_blank'}
                rel="noreferrer"
              >
                Get Chrome Extension
              </a>
            </li>
          </>
        ) : (
          <>
            <li
              className="px-4 sm:pb-4 py-1 upload-grey-txt"
              onClick={() => setShowMyProjects(true)}
            >
              <Link
                className={cn({
                  underline: showMyProject,
                })}
                to="/"
              >
                Dashboard
              </Link>
            </li>
            {currentUserInfo.isAnonymous && (
              <li className="px-4 sm:pb-4 py-1 upload-grey-txt">
                <Link to="/">My captures</Link>
              </li>
            )}
            {currentUserInfo.isAnonymous && (
              <li className="px-4 sm:pb-4 py-1 upload-grey-txt">My Guides</li>
            )}
          </>
        )}
        {!currentUserInfo.isAnonymous && (
          <li
            onClick={() => {
              setShowMyProjects(false);
              navigate('/');
            }}
            className={cn('px-4 sm:pb-4 py-1 upload-grey-txt cursor-pointer', {
              underline: !showMyProject,
            })}
          >
            Shared with me
          </li>
        )}
      </ul>
      {show && (
        <div
          className="absolute w-72 mt-2 -ml-16 bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none usecases-dashboard-dropdown"
          role="menu"
          tabIndex={0}
          ref={ref}
        >
          {DROPDOWNS.map((el, ind) => (
            <a
              href={el.href}
              target={'_blank'}
              className="w-full h-full flex justify-center items-center cursor-pointer relative avatar-inner-dropdown my-2"
              role="none"
              onClick={() => {
                setShow(false);
              }}
              rel="noreferrer"
            >
              {/* <a href={el.href} target={'_blank'}> */}
              {el.title}
              {/* </a> */}
              {/* <SignOut /> */}
            </a>
          ))}
        </div>
      )}
    </div>
  );
};

export default Nav;
