import { useRecoilCallback, useRecoilValue, useSetRecoilState } from 'recoil';
import { isAppLoadingState, isExtensionActive } from '@/state/appState';
import NewHeader from './NewHeader';
import NewCapture from './NewCapture';
import { Toaster } from 'react-hot-toast';
import Loading from '../Loading';
import { useEffect, useState } from 'react';
import ComingSoon from '../ComingSoon';
import { isDashBoardState } from '@/state/uiState';
import {
  groupedByHostCaptureListState,
  isProjectOpen,
} from '@/state/captureState';
import { useNavigate, useParams } from 'react-router-dom';
import { Project } from 'types/project';
import {
  getProjectSub,
  getProjects,
  saveProject,
  updateProject,
} from '@/lib/firebase/firestore';
import dayjs from 'dayjs';
import { projectListState } from '@/state/projectState';
import { currentUserIDState, currentUserInfoState } from '@/state/userState';
import { useOrganization } from '@clerk/clerk-react';

const ProjectPage = () => {
  const params = useParams();
  const navigate = useNavigate();
  const isExtension = useRecoilValue(isExtensionActive);
  const isAppLoading = useRecoilValue(isAppLoadingState);
  const [display, setDisplay] = useState(
    window.innerWidth <= 860 ? 'block' : 'none'
  );
  const setiIsDashBoard = useSetRecoilState(isDashBoardState);
  const projectList = useRecoilValue(projectListState);
  const currentUserID = useRecoilValue(currentUserIDState);

  const currentUserInfo = useRecoilValue(currentUserInfoState) as Capture.Creator;
  const { plan } = currentUserInfo;
  const { organization } = useOrganization();

  const groupedByHostCaptureList = useRecoilValue(
    groupedByHostCaptureListState
  );
  const setProjectOpen = useSetRecoilState(isProjectOpen);

  const handleWindowSizeChange = () => {
    setDisplay(window.innerWidth <= 860 ? 'block' : 'none');
  };

  useEffect(() => {
    const projects = groupedByHostCaptureList.map(([_, group]) => {
      let captures = [...group];
      if (plan === 'free' && currentUserInfo?.tier !== "1") {
        const currentDateUnix = Math.floor(new Date().getTime() / 1000);
        captures = captures.filter((item) => item.expiredAt && item.expiredAt > currentDateUnix);
      }
      return {
        name: group[0].projectName ?? '',
        gid: group[0].group,
        numItems: captures.length,
        uid: group[0].creator.uid,
      };
    });

    projects.forEach((p) => {
      const newProject = projectList.find((x) => x.gid === p.gid);
      if (!newProject) {
        return saveProject(currentUserID, { ...p, createdAt: dayjs().unix() });
      }
      if (p.numItems !== newProject.numItems) {
        updateProject(currentUserID, newProject.gid, { numItems: p.numItems });
      }
    });
  }, [currentUserID, groupedByHostCaptureList, projectList]);

  const updateCurrentProject = (data: Project[]) => {
    setProjectOpen((prev) => {
      const updated = data.find((d) => d.gid === prev.gid);
      if (updated) return { ...prev, projectInfo: updated };
      return prev;
    });
  };

  const getProjectList = useRecoilCallback(
    ({ set }) =>
      () => {
        const funcPram = organization ? organization.id : currentUserID;
        // const funcPram = currentUserID;

        getProjects(funcPram).then((list) => {
          if (list) set(projectListState, list);
        });

        return getProjectSub(funcPram, (data: Project[]) => {
          set(projectListState, data);
          updateCurrentProject(data);
        });
      },
    [currentUserID]
  );

  useEffect(() => {
    if (!currentUserID) return;

    const unsub = getProjectList();

    return () => {
      unsub && unsub();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUserID]);

  useEffect(() => {
    const { projectId } = params;

    if (projectList.length > 0) {
      const project = projectList.find(
        (data) => data.gid === projectId || data.gid === 'expired'
      );

      if (project) {
        setProjectOpen({
          open: true,
          captureCount: project.numItems,
          gid: projectId ?? '',
          projectInfo: project,
        });
      } else {
        navigate('/');
      }
    }
  }, [navigate, params, projectList, setProjectOpen]);

  useEffect(() => {
    setiIsDashBoard(true);
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
      setProjectOpen({
        open: false,
        gid: '',
        projectInfo: {},
        captureCount: 0,
      });
    };
  }, []);

  return (
    <>
      <div style={{ display: isAppLoading ? 'none' : 'block' }}>
        <div className="comingSoong" style={{ display: `${display}` }}>
          <ComingSoon />
        </div>
        <div
          className={`lex flex-col justify-between dashboard ${display === 'block' ? 'hidden' : ''
            } `}
        >
          <NewHeader isExtension={isExtension} />

          <NewCapture isExtension={isExtension} isProjectPage />

          {/* <Footer /> */}

        </div>
      </div>
      <div
        style={{
          display: !isAppLoading ? 'none' : 'block',
          height: !isAppLoading ? 0 : '100%',
        }}
      >
        <Loading />
      </div>
    </>
  );
};

export default ProjectPage;
