import { atom, selector } from 'recoil';

export const textListState = atom<{ [key: string]: string }>({
  key: 'TextList',
  default: {},
});

export const addTextState = selector({
  key: 'AddText',
  get: () => null,
  // @ts-ignore

  set: ({ set, get }, { id, text }) => {
    const textList = { ...get(textListState) };
    textList[id] = text;
    set(textListState, textList);
  },
});
