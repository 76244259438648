type Props = {
  classname: string;
};

const BlurIcon = ({ classname }: Props) => {
  return (
    <svg
      className={'rounded-3xl hover:bg-green ' + classname}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="6.66669" cy="6.66699" r="2.5" />
      <circle cx="6.66669" cy="13.333" r="2.5" />
      <circle cx="2.08331" cy="6.66699" r="1.25" />
      <circle cx="2.08331" cy="13.333" r="1.25" />
      <circle cx="6.66669" cy="1.25" r="1.25" />
      <circle cx="6.66669" cy="18.75" r="1.25" />
      <circle cx="13.3333" cy="1.25" r="1.25" />
      <circle cx="13.3333" cy="18.75" r="1.25" />
      <circle cx="17.9167" cy="6.66699" r="1.25" />
      <circle cx="17.9167" cy="13.333" r="1.25" />
      <circle cx="13.3333" cy="6.66699" r="2.5" />
      <circle cx="13.3333" cy="13.333" r="2.5" />
    </svg>
  );
};

export default BlurIcon;
