export const requiredEmojiesGroups: any = {
  'Frequently Used': ['Frequently Used'], //Custom group
  'Smiles & People': [
    'Smileys & Emotion (face-smiling)',
    'Smileys & Emotion (face-concerned)',
    'Smileys & Emotion (face-affection)',
    'Smileys & Emotion (face-neutral-skeptical)',
    'Smileys & Emotion (face-sleepy)',
    'Smileys & Emotion (face-glasses)',
    'Smileys & Emotion (face-unwell)',
    'Smileys & Emotion (face-tongue)',
    'Smileys & Emotion (face-hand)',
    'Smileys & Emotion (face-hat)',
    'Smileys & Emotion (cat-face)',
    'Smileys & Emotion (face-costume)',
    'Smileys & Emotion (monkey-face)',
    'Smileys & Emotion (emotion)',
    'People & Body (family)',
    'People & Body (hand-fingers-partial)',
    'People & Body (hand-fingers-closed)',
    'People & Body (hands)',
    'People & Body (body-parts)',
    'People & Body (person-gesture)',
    'People & Body (hand-fingers-open)',
    'People & Body (hand-single-finger)',
    'People & Body (person-activity)',
    'People & Body (hand-prop)',
    'People & Body (person-fantasy)',
    'People & Body (person-role)',
    'People & Body (person)',
    'People & Body (person-symbol)',
    'People & Body (person-sport)',
    'People & Body (person-resting)',
  ],
  'Animals & Nature': [
    'Animals & Nature (plant-flower)',
    'Animals & Nature (plant-other)',
    'Animals & Nature (animal-bird)',
    'Animals & Nature (animal-mammal)',
    'Animals & Nature (animal-bug)',
    'Animals & Nature (animal-reptile)',
    'Animals & Nature (animal-amphibian)',
    'Animals & Nature (animal-marine)',
  ],
  'Food & Drink': [
    'Food & Drink (food-prepared)',
    'Food & Drink (drink)',
    'Food & Drink (food-sweet)',
    'Food & Drink (dishware)',
    'Food & Drink (food-fruit)',
    'Food & Drink (food-vegetable)',
    'Food & Drink (food-asian)',
    'Food & Drink (food-marine)',
  ],
  Activities: [
    'Activities (game)',
    'Activities (event)',
    'Activities (sport)',
    'Activities (award-medal)',
    'Activities (arts & crafts)',
  ],
  'Travel & Places': [
    'Travel & Places (sky & weather)',
    'Travel & Places (transport-air)',
    'Travel & Places (place-map)',
    'Travel & Places (transport-water)',
    'Travel & Places (place-other)',
    'Travel & Places (transport-ground)',
    'Travel & Places (place-other)',
    'Travel & Places (transport-ground)',
    'Travel & Places (place-building)',
    'Travel & Places (time)',
    'Travel & Places (place-geographic)',
    'Travel & Places (place-religious)',
    'Travel & Places (hotel)',
  ],
  Objects: [
    'Objects (music)',
    'Objects (light & video)',
    'Objects (clothing)',
    'Objects (tool)',
    'Objects (money)',
    'Objects (phone)',
    'Objects (musical-instrument)',
    'Objects (other-object)',
    'Objects (book-paper)',
    'Objects (medical)',
    'Objects (computer)',
    'Objects (writing)',
    'Objects (sound)',
    'Objects (office)',
    'Objects (lock)',
    'Objects (mail)',
    'Objects (household)',
    'Objects (science)',
  ],
};
