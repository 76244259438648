import { useRecoilValue } from 'recoil';
import cn from 'classnames';

import { useTool } from '@/hooks/tool/useTool';
import { useBoundingBox } from '@/hooks/tool/useBoundingBox';

import { currentUserInfoState } from '@/state/userState';

import { User } from '@firebase/auth';
import toast from 'react-hot-toast';
import { canCommentState } from '@/state/captureState';

const ToolMenu = () => {
  const { handleClickToolMenu, getListTool, currentTool } = useTool();
  const { setIsBoundingBoxShow } = useBoundingBox();
  const currentUserInfo = useRecoilValue(currentUserInfoState) as User;
  const canComment = useRecoilValue(canCommentState);

  return (
    <ul className={`list-tool rounded-3xl bg-white`}>
      {getListTool().map(({ Icon, name }, index) => {
        return (
          <li
            className={`list-tool-item flex items-center p-3 hover:bg-green ${name === currentTool && 'bg-green border-black border-1'} ${currentUserInfo.isAnonymous && (canComment ? index > 1 : true) && 'opacity-30'}`}
            key={name}
            onClick={(e) => {
              e.stopPropagation();
              if (currentUserInfo.isAnonymous) {
                if (!(canComment && (name == "CHAT" || name == "MOVE"))) {
                  toast('You must be logged in to make edits to screen captures.');
                  return;
                }
              }

              handleClickToolMenu(name);

              if (index < 2) {
                setIsBoundingBoxShow(false);
              } else {
                setIsBoundingBoxShow(true);
              }
            }}
          >
            <div className="relative fill-black">
              <span className="tooltip-left-tooltip-text bg-black">
                {name.toLowerCase()}
              </span>
              <Icon
                classname={cn(
                  'stroke-current',
                  name === 'BLUR' && 'fill-current',
                  name === currentTool ? 'text-black' : 'text-gray-400'
                )}
              />
            </div>
          </li>
        );
      })}
    </ul>
  );
};

export default ToolMenu;
