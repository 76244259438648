import React, { FC } from 'react';

const CircleObjectIcon: FC<{
  color: string;
  strokeWidth?: number;
  isActive: boolean;
}> = ({ color, strokeWidth = 6, isActive }) => {
  return (
    <svg
      width="100%"
      height="100%"
      preserveAspectRatio="none"
      viewBox="0 0 22 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {isActive && (
        <ellipse
          cx="11"
          cy="9"
          rx="10"
          ry="8"
          stroke={'blue'}
          strokeWidth={strokeWidth + 3}
          vectorEffect="non-scaling-stroke"
          strokeLinecap="round"
          fill="none"
        />
      )}
      <ellipse
        cx="11"
        cy="9"
        rx="10"
        ry="8"
        stroke={color}
        strokeWidth={strokeWidth}
        vectorEffect="non-scaling-stroke"
        strokeLinecap="round"
        fill="none"
      />
    </svg>
  );
};

export default CircleObjectIcon;
