import { useRecoilCallback, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  canCommentState,
  captureListAtHostState,
  currentCaptureCreatorUpdatedInfoState,
  currentCaptureInfoState,
  currentCaptureIsPrivateState,
  setCurrentCaptureInfoState,
} from '@/state/captureState';
import * as firebase from '@/lib/firebase/firestore';
import { deletedCaptureInfo, exactMatchNotFound, showMyProjectState } from '@/state/uiState';
import { useInvitation } from './useInvitation';
import { useAuth } from './useAuth';

export const useCaptureInfo = () => {
  const setNotFound = useSetRecoilState(exactMatchNotFound);
  const isDeleted = useRecoilValue(deletedCaptureInfo);
  const captureListAtHost = useRecoilValue(captureListAtHostState);
  const currentCaptureInfo = useRecoilValue(currentCaptureInfoState);
  const { setCurrentCaptureShareInviteList } = useInvitation();
  const { getStoredUserInfo } = useAuth();

  const getCaptureInfoSub = useRecoilCallback(
    ({ set, reset }) =>
      (uid: string, cid: string) => {
        return firebase.getCaptureInfoSub(
          uid,
          cid,
          async (result: Capture.Info | null) => {
            if (
              (result === null || result === undefined) ||
              currentCaptureInfo?.isDeleted
            ) {
              return setNotFound(true);
            }
            if (cid) {
              setCurrentCaptureShareInviteList(cid);
            }
            set(
              currentCaptureIsPrivateState,
              result?.publicAccess !== undefined &&
                result?.publicAccess === true
                ? true
                : false
            );
            if (!result?.allowAnonymousCommenting && !result?.publicAccess) {
              set(canCommentState, true);
            } else {
              set(canCommentState, false);
            }
            if (result?.creator) {
              let user = await getStoredUserInfo(result?.creator.uid);

              set(currentCaptureCreatorUpdatedInfoState, user);
            }
            if (result) result.expiresInXDays = result?.expiredAt ? Math.round((result.expiredAt - Math.floor(new Date().getTime() / 1000)) / 60 / 60 / 24) : null;
            set(setCurrentCaptureInfoState, result);
          }
        );
      }
  );
  //Here Recoil Call To Update Name in the fireStore DataBase Which Will add new field in data name
  //Next Time title will display with the name if exists.Thanks @Author:Usman Sattar
  const updateCaptureInfo = useRecoilCallback(
    ({ set }) =>
      async (uid: string, cid: string, info: Object) => {
        return await firebase.updateCapture(uid, cid, info);
      }
  );

  const updatePorjectPublicAccess = useRecoilCallback(
    ({ set }) =>
      async (uid: string, group: string, info: Object) => {
        let result: any = {};

        for (const capture of captureListAtHost) {
          result = await firebase.updateCapture(uid, capture.cid, info);
        }
        return result;
      }
  );

  const updateCapturePublicAccess = useRecoilCallback(
    ({ set }) =>
      async (uid: string, group: string, info: Object) => {
        let result: any = {};

      if(currentCaptureInfo){
        result = await firebase.updateCapture(uid, currentCaptureInfo.cid, info);
      }
        return result;
      }
  );

  const updatePorjectName = useRecoilCallback(
    ({ set }) =>
      async (uid: string, group: string, info: Object) => {
        await firebase.updateProjectCapture(uid, group, info, () => {
          // console.log(`Sucess fully Updated Project`);
        });
      }
  );

  return {
    getCaptureInfoSub,
    updateCaptureInfo,
    updatePorjectPublicAccess,
    updateCapturePublicAccess,
    updatePorjectName,
  };
};
