const ResetOriginalIcon = ({ color = '' }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.66675 22C6.66675 17.1341 9.40208 12.9445 13.3334 11.0613C14.7565 10.3795 16.3365 10 18.0001 10C24.2593 10 29.3334 15.3726 29.3334 22"
      stroke="black"
      stroke-width="2.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.0001 18.6667L6.66675 22.0001L2.66675 16.6667"
      stroke="black"
      stroke-width="2.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default ResetOriginalIcon;
