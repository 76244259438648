const CopyIcon = () => (
  <svg
    width="20"
    height="20"
    className="inline"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.74658 5.45723L5.69991 2.41057C4.81278 1.52346 3.35495 1.543 2.44372 2.45422C1.5325 3.36543 1.51296 4.8233 2.40008 5.7104L5.04518 8.3555"
      stroke="black"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.9675 7.66797L13.6126 10.3131C14.4997 11.2002 14.4802 12.658 13.569 13.5692C12.6577 14.4805 11.1999 14.5 10.3128 13.6129L7.26611 10.5662"
      stroke="black"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.70312 8.71421C9.61432 7.80297 9.63389 6.34514 8.74676 5.45801"
      stroke="black"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.26622 7.2666C6.35502 8.17783 6.33546 9.63567 7.22259 10.5228"
      stroke="black"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default CopyIcon;
