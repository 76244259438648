import { FC } from 'react';
import { Dialog } from '@headlessui/react';
import Template from './Template';
import CircleClose from '../icon/CircleClose';

type Props = {
  isOpen: boolean;
  closeModal: (e: boolean) => void;
};

const LearnModal: FC<Props> = ({ isOpen, closeModal }) => {
  return (
    <Template isOpen={isOpen} closeModal={() => closeModal(false)}>
      <div
        className={`inline-block w-full max-w-3xl  overflow-hidden text-left align-middle transition-all transform rounded-xl bg-white px-6  pt-6 sm:mt-6 default-modal `}
      >
        <Dialog.Title as="h3" className="text-2xl text-gray-900 leading-8">
          <div className="flex">
            <div className="w-3/4">
              <h4 className="fileupload-modal-heading">Preview</h4>
            </div>
            <div
              className="w-1/4 float-right"
              onClick={() => closeModal(false)}
            >
              <div className="float-right mb-5">
                <div className="flex items-center cursor-pointer">
                  <CircleClose />
                </div>
              </div>
            </div>
          </div>
          <hr />
        </Dialog.Title>
        <div className="flex">
          <div className="w-full my-10 relative">
            <iframe
              width="100%"
              height="400"
              src="https://www.youtube.com/embed/NKpeEha4w-4"
            ></iframe>
          </div>
        </div>
      </div>
    </Template>
  );
};

export default LearnModal;
