import { FC } from 'react';
import { useRecoilValue } from 'recoil';
import {
  countCaptureListState,
  groupedByHostCaptureListSharedState,
} from '@/state/captureState';
import CaptureGrid from './CaptureGrid';
import { showMyProjectState } from '@/state/uiState';

const RecentCapture: FC = () => {
  const countCaptureList = useRecoilValue(countCaptureListState);
  const countSharedList = useRecoilValue(
    groupedByHostCaptureListSharedState
  ).length;
  const showMyProject = useRecoilValue(showMyProjectState);

  if (countCaptureList === 0 && countSharedList === 0) {
    return null;
  }

  return (
    <div className="w-full bg-headerYellow pt-8 pb-0.5">
      <div className="flex flex-col container-width mb-8 sm:w-full sm:flex-col">
        <h4 className="text-2xl font-semibold mb-6 ml-4">Recent Captures</h4>
        <CaptureGrid sharedWithMe={!showMyProject} hidePagination showRecent />
      </div>
    </div>
  );
};

export default RecentCapture;
