import { atom } from 'recoil';

export const isBoundingBoxMaxState = atom<boolean>({
  key: 'IsBoundingBoxMax',
  default: true,
});

export const isBoundingBoxShowState = atom<boolean>({
  key: 'IsBoundingBoxShow',
  default: false,
});

// const DEFAULT_WIDTH = 740;
// const DEFAULT_HEIGHT = 480;
// let left = (window.innerWidth - DEFAULT_WIDTH) / 2;
// if (left < 0) left = 0;
// let oldRect: Rect.Info = { width: DEFAULT_WIDTH, height: DEFAULT_HEIGHT, left, top: 90 };

export const boundingRectState = atom<Rect.Info>({
  key: 'BoundingRect',
  default: {
    width: 0,
    height: 0,
    top: 0,
    left: 0,
  },
});
