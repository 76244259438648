import { useCallback, MouseEvent } from 'react';
import { getAngle } from '@/lib/ddr/util';

let isMouseDown = false;

export const useRotate = (setAngle: (angle: number) => void) => {
  const onRotate = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      if (event.button !== 0) return;
      event.preventDefault();
      event.stopPropagation();
      const { clientX, clientY } = event;

      const element = event.currentTarget?.parentElement;
      // @ts-ignore
      if (element.dataset.isRotatable !== 'true') return;
      // @ts-ignore
      const startAngle = parseInt(element.dataset.angle, 10);

      // @ts-ignore
      const rect = element.getBoundingClientRect();

      const center = {
        x: rect.left + rect.width / 2,
        y: rect.top + rect.height / 2,
      };
      const startVector = {
        x: clientX - center.x,
        y: clientY - center.y,
      };

      isMouseDown = true;

      const onMove = (e: globalThis.MouseEvent) => {
        if (!isMouseDown) return;
        e.stopImmediatePropagation();
        const { clientX, clientY } = e;
        const rotateVector = {
          x: clientX - center.x,
          y: clientY - center.y,
        };
        const angle = getAngle(startVector, rotateVector);
        let rotateAngle = Math.round(startAngle + angle);
        if (rotateAngle >= 360) {
          rotateAngle -= 360;
        } else if (rotateAngle < 0) {
          rotateAngle += 360;
        }
        if (rotateAngle > 356 || rotateAngle < 4) {
          rotateAngle = 0;
        } else if (rotateAngle > 86 && rotateAngle < 94) {
          rotateAngle = 90;
        } else if (rotateAngle > 176 && rotateAngle < 184) {
          rotateAngle = 180;
        } else if (rotateAngle > 266 && rotateAngle < 274) {
          rotateAngle = 270;
        }
        setAngle(rotateAngle);
      };
      const onUp = () => {
        document.removeEventListener('mousemove', onMove);
        document.removeEventListener('mouseup', onUp);
        if (!isMouseDown) return;
        isMouseDown = false;
      };
      document.addEventListener('mousemove', onMove);
      document.addEventListener('mouseup', onUp);
    },
    [setAngle]
  );

  const resetRotate = () => {
    setAngle(0);
  };
  return { onRotate, resetRotate };
};
