type Props = {
  bg?: string
}
const Loading = (props?: Props) => (
  <div className={`font-rubik w-full h-full flex flex-col justify-center items-center  ${props?.bg ? props.bg : 'bg-gray-100'}`}>

    <img src="/loading.gif" alt="Instacap" className="w-" />

  </div>
);

export default Loading;
