import { useEffect, useRef, useState } from 'react';
import {
  OrganizationSwitcher,
  useOrganization,
  useUser,
} from '@clerk/clerk-react';
import { useRecoilValue } from 'recoil';
import { forEach } from 'lodash';

import styles from './../../styles/index.module.scss';

import { currentUserInfoState } from '@/state/userState';
import { httpsCallable } from 'firebase/functions';
import { functions } from '@/lib/firebase/auth';
import { useUpgradeTeamUser } from '@/hooks/plan/useUpgradeTeamUser';

const Teams = () => {
  const currentUserInfo = useRecoilValue(currentUserInfoState) as any;
  const switcherRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const { planTeam, tier, stripeSubscriptionIdTeam } = currentUserInfo;
  const { isLoading: isLoadingUpgradeTeam, upgradePlan: upgradePlanTeam } =
  useUpgradeTeamUser();

  const team = useOrganization();
  const { user } = useUser();
  const [haveOrganization, setHaveOrganization] = useState<boolean>();
  const [countUserMemberships, setCountUserMemberships] = useState<number>(0);
  const [orgsImgUrl, setOrgsImgUrl] = useState<string[]>([]);
  const [userIsOwner, setUserIsOwner] = useState<boolean>(false);
  const [hasPandingInvitations, setHasPandingInvitations] = useState<
    boolean | undefined
  >(false);

  const getOrganizationInvitations = async () => {
    const invitations = await user?.getOrganizationInvitations();
    const pendingInvite = invitations?.data.some(
      (invitation) => invitation.status === 'pending'
    );
    setHasPandingInvitations(pendingInvite);
  };
  
    useEffect(() => {
      
      
      getOrganizationInvitations();
    }, []);
  
  const userHasCreatedOrg = async () => {
    const payload = {
      id: user?.id,
      userMemberships: user?.organizationMemberships.map(
        (membership) => membership.organization.id
      ),
    };

    const { data }: any = await httpsCallable(
      functions,
      'getUserOrgsList'
    )(payload);

    if (!data) return setHaveOrganization(false);

    if(team.organization){
      const isOwner = data[0].id === team.organization.id
      setUserIsOwner(isOwner)
    }

    const imgUrls = data.map((org: any) => org.imageUrl);
    setOrgsImgUrl(imgUrls);
    setHaveOrganization(data.length > 0 ? true : false);
    return data.length > 0 ? true : false;
  };

  const getUserMemberships = async () => {
    const payload = {
      userId: user?.id,
    };

    const { data }: any = await httpsCallable(
      functions,
      'getUserMemberships'
    )(payload);

    if (!data) return setCountUserMemberships(0);

    const filteredUserMemberships = data.filter(
      (membership: any) => membership.organization.createdBy != user?.id
    );

    setCountUserMemberships(filteredUserMemberships.length);
  };

  useEffect(() => {
    userHasCreatedOrg();
    getUserMemberships();
    getOrganizationInvitations();
  }, [haveOrganization, isOpen]);

  useEffect(() => {

    if (!planTeam && tier !== "2" && tier !== "3") {
      // hide create new organization button
      const actionButton = document.querySelector(
        '.cl-organizationSwitcherPopoverActionButton__createOrganization'
      );
      if (actionButton instanceof HTMLElement) {
        actionButton.style.display = 'none';
      }
    }

    if (switcherRef?.current) {
      const div = document
        .getElementById('switcherRef')
        ?.getElementsByClassName(
          'cl-organizationPreviewSecondaryIdentifier cl-organizationPreviewSecondaryIdentifier__organizationSwitcher'
        );
      if (team?.organization?.membersCount) {
        forEach(div ?? [], (e) => {
          e.innerHTML =
            team?.organization?.membersCount +
            ' Member' +
            ((team?.organization?.membersCount ?? 0) > 1 ? 's' : '');
        });
      }

      if (haveOrganization) {
        // hide create new organization button
        const actionButton = document.querySelector(
          '.cl-organizationSwitcherPopoverActionButton__createOrganization'
        );
        if (actionButton instanceof HTMLElement) {
          actionButton.style.display = 'none';
        }
      }
    }
  }, [switcherRef, team, isOpen, planTeam]);

  useEffect(() => {
    if (planTeam || tier === "2" || tier === "3") return;

    const buttons = document.querySelectorAll('div[role="group"] button');
    buttons.forEach((button) => {
      const img = button.querySelector('img');

      const htmlButton = button as HTMLElement;

      if (img && orgsImgUrl.some((url) => img.src.includes(url))) {
        htmlButton.style.display = 'none';
      }
    });
  }, [haveOrganization, orgsImgUrl, isOpen, getOrganizationInvitations]);

  useEffect(() => {
    const actionButton = document.querySelector('.cl-organizationSwitcherPopoverActionButton__manageOrganization');

    const handleActionButtonClick = () => {
      // MutationObserver wait changes in DOM
      const observer = new MutationObserver((mutationsList, observer) => {
        for (let mutation of mutationsList) {
          if (mutation.type === 'childList') {
            const tabButton = document.getElementById('cl-tab-1');
            if (tabButton) {
              tabButton.addEventListener('click', handleTabButtonClick);
              // observer.disconnect();
            }
          }
        }
      });

      observer.observe(document.body, { childList: true, subtree: true });
    };

    const handleTabButtonClick = () => {

      //  MutationObserver wait buttoon "cl-membersPageInviteButton"
      const observer = new MutationObserver((mutationsList, observer) => {
        for (let mutation of mutationsList) {
          if (mutation.type === 'childList') {
            // "cl-membersPageInviteButton" added to DOM
            const inviteButton = document.querySelector('.cl-membersPageInviteButton') as HTMLElement;
            if (inviteButton) {
              // console.log('Tab button clicked, and found invite button:', inviteButton);

              if(!userIsOwner){
                inviteButton.style.display = 'none';
                return
              }

              const isConditionForTier2 = tier === "2" && team?.organization && team?.organization?.membersCount >= 11;
              const isConditionForTier3 = tier === "3" && team?.organization && team?.organization?.membersCount >= 51;

              if(team?.organization && !stripeSubscriptionIdTeam && userIsOwner && (isConditionForTier2 || isConditionForTier3)){
                inviteButton.textContent = 'Start Prorate';
              }

              const handleInviteButtonClick = (event: any) => {
                if(team?.organization && !stripeSubscriptionIdTeam && userIsOwner && (isConditionForTier2 || isConditionForTier3)){
                  event.preventDefault();
                  event.stopImmediatePropagation();
                  
                  upgradePlanTeam("teamUser");
                }
              };

              inviteButton.addEventListener('click', handleInviteButtonClick, true);
              // observer.disconnect();
            }
          }
        }
      });

      observer.observe(document.body, { childList: true, subtree: true });
    };

    if (actionButton) {
      actionButton.addEventListener('click', handleActionButtonClick);
    }

    return () => {
      if (actionButton) {
        actionButton.removeEventListener('click', handleActionButtonClick);
      }
      const tabButton = document.getElementById('cl-tab-1');
      if (tabButton) {
        tabButton.removeEventListener('click', handleTabButtonClick);
      }
    };
  }, [isOpen]);

  useEffect(() => {
    const inviteButton = document.querySelector('.cl-membersPageInviteButton') as HTMLElement;
    if (inviteButton) {

      if (isLoadingUpgradeTeam) {
        
        const loader = document.createElement('div');
        loader.className = 'loader'; 
        loader.innerText = 'Loading...'; 

        inviteButton.replaceWith(loader);
      }
    }
  }, [isLoadingUpgradeTeam])

  if (!currentUserInfo) return null;
  if (currentUserInfo?.isAnonymous) return null;

  return (
    <>
      {(countUserMemberships && countUserMemberships > 0) ||
      hasPandingInvitations || (planTeam || tier === "2" || tier === "3")  ? (
        <div
          id="switcherRef"
          ref={switcherRef}
          className={`border-100 px-2 ${styles.teamsBtn}`}
          style={{
            border: '1px solid #000000',
            boxShadow: '1px 1px 0px #000000',
          }}
          onClick={() => setIsOpen(!isOpen)}
        >
          <OrganizationSwitcher
            afterSelectOrganizationUrl={'/switchTeam'}
            afterSelectPersonalUrl={'/switchTeam'}
          />
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default Teams;
