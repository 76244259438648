import { useEffect, useRef, useState, FC } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { setup } from '@loomhq/record-sdk';
import { oembed } from '@loomhq/loom-embed';
import * as yup from 'yup';

import { User } from 'firebase/auth';
import { isLoomSupportedState } from '@/state/appState';
import { useComment } from '@/hooks/useComment';
import { currentCommentState } from '@/state/commentState';
import { sortedCaptureCommentState } from '@/state/captureState';

import { responsiveDesignState } from '@/state/responsiveState';

import {
  currentUserInfoState,
  isdisplayNameSettedState,
  nextState,
  updateDisplayNameState,
} from '@/state/userState';
import {
  closeWithVideoState,
  loomRecordingState,
  isVideoUploadedState,
  emojiPickerState,
} from '@/state/uiState';
import { browserTabOnCloseHandler } from 'utils/utils';

import CaptureCommentEmailInput from './CaptureCommentEmailInput';
import ReplyButtonNew from '../icon/ReplyButtonNew';
import SendBigButton from '../icon/SendBigButton';
import CloseWithVideo from '../modal/CloseWithVideo';

import addEmoji_IMAGE from '../../assets/images/addEmoji.png';
import RecordingIcon from '../icon/RecordingIcon';
import RecordingDotIcon from '../icon/RecordingDotIcon';
import CameraIcon from '../icon/CameraIcon';
import VideoFileIcon from '../icon/VideoFileIcon';
import X from '../icon/X';
import CloseInCircleIcon from '../icon/CloseInCircleIcon';

// Dealing with Textarea Height
function calcHeight(value: string) {
  let numberOfLineBreaks = (value.match(/\n/g) || []).length;
  let newHeight = 20 + numberOfLineBreaks * 20 + 12 + 4;
  return newHeight;
}

const schema = yup.object().shape({ email: yup.string().required() });

const CaptureCommentTextarea: FC<{ isNew: Boolean }> = ({ isNew }) => {
  const { setCaptureComment } = useComment();

  const [emojiPickerConfig, setEmojiPickerConfig] =
    useRecoilState(emojiPickerState);
  const [closeWithVideo, setCloseWithVideo] =
    useRecoilState(closeWithVideoState);

  const isLoomSupported = useRecoilValue(isLoomSupportedState);
  const currentComment = useRecoilValue(currentCommentState);
  const captureComment = useRecoilValue(sortedCaptureCommentState);
  const currentUserInfo = useRecoilValue(currentUserInfoState) as User;
  const nameIsSetted = useRecoilValue(isdisplayNameSettedState);

  const [commentValue, setCommentValue] = useState(
    currentComment.content.trim()
  );
  const setDisplayName = useSetRecoilState(updateDisplayNameState);
  const [next, setNextFunction] = useRecoilState(nextState);
  const [isVideoUploaded, setIsVideoUploaded] =
    useRecoilState(isVideoUploadedState);
  const [isLoomRecording, setIsLoomRecording] =
    useRecoilState(loomRecordingState);

  const [responsiveDesign, setResponsiveDesign] = useRecoilState(
    responsiveDesignState
  );

  const inputEl = useRef<HTMLInputElement | null>(null);
  const textareaEl = useRef<HTMLTextAreaElement | null>(null);
  const textareaEl2 = useRef<HTMLTextAreaElement | null>(null);
  const loomButton = useRef<HTMLButtonElement | null>(null);
  const recordingInProgressButton = useRef<HTMLButtonElement | null>(null);
  const emojiPickerRef = useRef<HTMLDivElement | null>(null);

  const [textareaValue, setTextareaText] = useState<string>('');
  const [isClicked, setIsClicked] = useState(false);
  const [showInputError, setShowInputError] = useState(false);
  const [showClosingWithVideoModal, setShowClosingWithVideoModal] =
    useState<boolean>(false);
  const [placePinError, setPlacePinError] = useState(false);
  const [enterCommentError, setEnterCommentError] = useState(false);

  // Loom states
  const [loomVideoHTML, setLoomVideoHTML] = useState<string>('');
  const [recordingStartingTimeLocal, setRecordingStartingTimeLocal] =
    useState<number>(0);
  const [videoDuration, setVideoDuration] = useState<string>('');
  const [videoTimer, setVideoTimer] = useState<NodeJS.Timer>();

  useEffect(() => {
    setShowClosingWithVideoModal(closeWithVideo.show);
  }, [closeWithVideo]);

  useEffect(() => {
    async function setupLoom() {
      const button = loomButton.current;

      if (!button) {
        return;
      }

      const { configureButton, status } = await setup({
        publicAppId: process.env.REACT_APP_LOOM_APP_ID,
      });

      const sdkButton = configureButton({ element: button });

      sdkButton.on('insert-click', async (video) => {
        const { html } = await oembed(video.sharedUrl, { width: 270 });

        const iframeCloseIndex = html.indexOf('>');
        const styledVideoThumbnail =
          html.substring(0, iframeCloseIndex) +
          ' style="border-radius: 10px;"' +
          html.substring(iframeCloseIndex, html.length);

        setLoomVideoHTML(styledVideoThumbnail);
        setIsVideoUploaded(true);
      });

      sdkButton.addListener('recording-start', () => {
        setIsLoomRecording(true);

        setRecordingStartingTimeLocal(Date.now());

        window.addEventListener('beforeunload', browserTabOnCloseHandler);
      });

      sdkButton.addListener('recording-complete', () =>
        setIsLoomRecording(false)
      );

      sdkButton.addListener('cancel', () => {
        setIsLoomRecording(false);

        window.removeEventListener('beforeunload', browserTabOnCloseHandler);
      });

      recordingInProgressButton.current?.addEventListener('click', () => {
        sdkButton.endRecording();
      });
    }

    if (isLoomSupported) {
      setupLoom();
    }
  }, [loomButton, isLoomSupported, recordingInProgressButton]);

  useEffect(() => {
    if (isLoomRecording) {
      const timerID = setInterval(() => {
        if (recordingStartingTimeLocal) {
          const seconds = (Date.now() - recordingStartingTimeLocal) / 1000;
          const minutes = Math.floor(seconds / 60);
          const secondsString = (seconds - +minutes * 60).toFixed(2);

          setVideoDuration(
            `${+minutes <= 0 ? '00' : +minutes < 10 ? '0' + minutes : minutes
            }:${+secondsString < 10
              ? '0' + secondsString.replace('.', ',')
              : secondsString.replace('.', ',')
            }`
          );
        }
      }, 10);
      setVideoTimer(timerID);
    } else {
      if (videoTimer) {
        clearInterval(videoTimer);
      }
    }
  }, [isLoomRecording, recordingStartingTimeLocal]);

  useEffect(() => {
    if (textareaEl.current) {
      textareaEl.current.focus();

      if (currentComment.content === '') {
        textareaEl.current.style.height = '32px';
      } else {
        textareaEl.current.style.height = `${calcHeight(
          (textareaEl.current.value = currentComment.content
            ? currentComment.content
            : '')
        )}px`;
      }
      // textareaEl.current.rows = textareaEl.current.rows + 1;
      textareaEl.current.value = currentComment.content
        ? currentComment.content
        : '';
      textareaEl.current.setSelectionRange(
        currentComment.content.length,
        currentComment.content.length
      );
      textareaEl.current.classList.add(`overflow-hidden`);
      textareaEl.current.style.minHeight = `32px`;
      textareaEl.current.focus();
    }

    if (textareaEl2.current) {
      textareaEl2.current.focus();
      textareaEl2.current.style.height = loomVideoHTML !== '' ? '43px' : '40px';
      // `${calcHeight(
      // (textareaEl2.current.value = currentComment.content
      //   ? currentComment.content
      //   : '')
      // )}px`;
      // textareaEl2.current.value = ''
      textareaEl2.current.style.minHeight = `40px`;
      textareaEl2.current.classList.add(`overflow-hidden`);
    }
    if (
      currentComment.commentIndex !== -1 &&
      captureComment[currentComment.commentIndex]?.isTemp === true
    ) {
      setPlacePinError(false);
    }
  }, [currentComment.timestamp]);

  useEffect(() => {
    if (textareaEl.current) {
      handleKeyUp({
        currentTarget: textareaEl.current,
      });
    }
  }, [textareaEl.current?.scrollHeight]);

  const handleReplyButtonClick = async (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    const el = textareaEl.current as HTMLTextAreaElement;
    let value = el.value;
    if (!value.trimEnd()) {
      textareaEl.current?.focus();
      return false;
    }

    // if (inputEl && inputEl.current) {
    //   const email = inputEl.current.value;
    //   const result = await schema.isValid({ email });

    //   if (!result || email === '') {
    //     setShowInputError(true);
    //     inputEl.current?.focus();
    //     return false;
    //   }
    //   setShowInputError(false);
    //   !isClicked && setIsClicked(true);
    //   // return;
    //   // if (!nameIsSetted) {
    //   if (String(currentUserInfo.displayName).length == 0) {
    //     // console.log(`Name is Setted True`)
    //     setNextFunction({
    //       next: () => {
    //         setCaptureComment({
    //           content: value,
    //           videoHTML: loomVideoHTML,
    //           email: '',
    //           displayName: email,
    //         });
    //         el.classList.remove('input-radius');
    //         el.value = '';
    //         setCommentValue('');
    //         el.style.height = `32px`;

    //         setLoomVideoHTML('');
    //         setIsVideoUploaded(false);
    //       },
    //       isUpdate: true,
    //     });
    //     setDisplayName({ updateName: true, isMain: false });
    //   } else {
    //     setCaptureComment({
    //       content: value,
    //       videoHTML: loomVideoHTML,
    //       email: '',
    //       displayName: email,
    //     });
    //     el.classList.remove('input-radius');
    //     el.value = '';
    //     setCommentValue('');
    //     el.style.height = `32px`;

    //     setLoomVideoHTML('');
    //     setIsVideoUploaded(false);
    //   }
    // } else {
    //   // if (!nameIsSetted && !currentUserInfo.isAnonymous) {
    //   if (String(currentUserInfo.displayName).length == 0 && !currentUserInfo.isAnonymous) {
    //     setNextFunction({
    //       next: () => {
    //         setCaptureComment({ content: value, videoHTML: loomVideoHTML });
    //         el.classList.remove('input-radius');
    //         el.value = '';
    //         el.style.height = `32px`;
    //         setLoomVideoHTML('');
    //         setCommentValue('');
    //         setIsVideoUploaded(false);
    //       },
    //       isUpdate: true,
    //     });
    //     setDisplayName({ updateName: true, isMain: false });
    //   } else {
    //     setCaptureComment({ content: value, videoHTML: loomVideoHTML });
    //     el.classList.remove('input-radius');
    //     el.value = '';
    //     el.style.height = `32px`;
    //     setLoomVideoHTML('');
    //     setCommentValue('');
    //     setIsVideoUploaded(false);
    //   }
    // }

    if (inputEl && inputEl.current) {
      const email = inputEl.current.value;
      const result = await schema.isValid({ email });

      if (!result || email === '') {
        setShowInputError(true);
        inputEl.current?.focus();
        return false;
      }
      setShowInputError(false);
      !isClicked && setIsClicked(true);
      if (currentUserInfo?.isAnonymous) {
        setCaptureComment({
          content: value,
          videoHTML: loomVideoHTML,
          email: '',
          displayName: email,
        });
        el.classList.remove('input-radius');
        el.value = '';
        setCommentValue('');
        el.style.height = `32px`;

        setLoomVideoHTML('');
        setIsVideoUploaded(false);
      } else {
        setCaptureComment({ content: value, videoHTML: loomVideoHTML });
        el.classList.remove('input-radius');
        el.value = '';
        el.style.height = `32px`;
        setLoomVideoHTML('');
        setCommentValue('');
        setIsVideoUploaded(false);
      }

      // if (String(currentUserInfo.displayName).length == 0) {
      //   setNextFunction({
      //     next: () => {
      //       setCaptureComment({
      //         content: value,
      //         videoHTML: loomVideoHTML,
      //         email: '',
      //         displayName: email,
      //       });
      //       el.classList.remove('input-radius');
      //       el.value = '';
      //       setCommentValue('');
      //       el.style.height = `32px`;

      //       setLoomVideoHTML('');
      //       setIsVideoUploaded(false);
      //     },
      //     isUpdate: true,
      //   });
      //   setDisplayName({ updateName: true, isMain: false });
      // } else {
      //   setCaptureComment({
      //     content: value,
      //     videoHTML: loomVideoHTML,
      //     email: '',
      //     displayName: email,
      //   });
      //   el.classList.remove('input-radius');
      //   el.value = '';
      //   setCommentValue('');
      //   el.style.height = `32px`;

      //   setLoomVideoHTML('');
      //   setIsVideoUploaded(false);
      // }
    } else {
      setCaptureComment({ content: value, videoHTML: loomVideoHTML });
      el.classList.remove('input-radius');
      el.value = '';
      el.style.height = `32px`;
      setLoomVideoHTML('');
      setCommentValue('');
      setIsVideoUploaded(false);

      // // if (!nameIsSetted && !currentUserInfo.isAnonymous) {
      // if (String(currentUserInfo.displayName).length == 0 && !currentUserInfo.isAnonymous) {
      //   setNextFunction({
      //     next: () => {
      //       setCaptureComment({ content: value, videoHTML: loomVideoHTML });
      //       el.classList.remove('input-radius');
      //       el.value = '';
      //       el.style.height = `32px`;
      //       setLoomVideoHTML('');
      //       setCommentValue('');
      //       setIsVideoUploaded(false);
      //     },
      //     isUpdate: true,
      //   });
      //   setDisplayName({ updateName: true, isMain: false });
      // } else {
      //   setCaptureComment({ content: value, videoHTML: loomVideoHTML });
      //   el.classList.remove('input-radius');
      //   el.value = '';
      //   el.style.height = `32px`;
      //   setLoomVideoHTML('');
      //   setCommentValue('');
      //   setIsVideoUploaded(false);
      // }
    }
  };

  const handleClickMainComment = async (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    const el = textareaEl2.current as HTMLTextAreaElement;
    let value = el.value;

    if (captureComment[currentComment.commentIndex]?.isTemp !== true) {
      if (textareaEl2.current) {
        textareaEl2.current.focus();
        textareaEl2.current.style.height = '43px';
      }
      setPlacePinError(true);
      return false;
    } else {
      setPlacePinError(false);
    }
    if (!value.trimEnd() && loomVideoHTML === '') {
      textareaEl.current?.focus();
      setEnterCommentError(true);

      return false;
    } else {
      setEnterCommentError(false);
    }

    //Case when only video uploaded
    if (loomVideoHTML.trimEnd() && !value.trimEnd()) {
      value = ' ';
    }

    if (inputEl && inputEl.current) {
      const email = inputEl.current.value;
      const result = await schema.isValid({ email });
      if (!result) {
        setShowInputError(true);
        inputEl.current?.focus();
        return false;
      }
      setShowInputError(false);

      !isClicked && setIsClicked(true);
      if (currentUserInfo?.isAnonymous) {
        setCaptureComment({
          content: value,
          videoHTML: loomVideoHTML,
          email: '',
          displayName: email,
        });
        el.classList.remove('input-radius');
        el.value = '';
        el.style.height = `40px`;
      } else {
        setCaptureComment({ content: value, videoHTML: loomVideoHTML });
        el.classList.remove('input-radius');
        el.value = '';
        el.style.height = `40px`;
      }
    } else {
      setCaptureComment({ content: value, videoHTML: loomVideoHTML });
      el.classList.remove('input-radius');
      el.value = '';
      el.style.height = `40px`;
    }
    //   // if (!nameIsSetted && !currentUserInfo.isAnonymous) {
    //   if (String(currentUserInfo.displayName).length == 0 && !currentUserInfo.isAnonymous) {
    //     // console.log(`Name is Setted True`)

    //     setNextFunction({
    //       next: () => {
    //         setCaptureComment({
    //           content: value,
    //           videoHTML: loomVideoHTML,
    //           email: '',
    //           displayName: email,
    //         });
    //         el.classList.remove('input-radius');
    //         el.value = '';
    //         el.style.height = `40px`;
    //       },
    //       isUpdate: true,
    //     });

    //     // setDisplayName({ updateName: true, isMain: false });
    //   } else {
    //     setCaptureComment({
    //       content: value,
    //       videoHTML: loomVideoHTML,
    //       email: '',
    //       displayName: email,
    //     });
    //     el.classList.remove('input-radius');
    //     el.value = '';
    //     el.style.height = `40px`;
    //   }
    // } else {
    //   // if (!nameIsSetted && !currentUserInfo.isAnonymous) {
    //   if (String(currentUserInfo.displayName).length == 0 && !currentUserInfo.isAnonymous) {
    //     setNextFunction({
    //       next: () => {
    //         setCaptureComment({ content: value, videoHTML: loomVideoHTML });
    //         el.classList.remove('input-radius');
    //         el.value = '';
    //         el.style.height = `40px`;
    //       },
    //       isUpdate: true,
    //     });
    //     // setDisplayName({ updateName: true, isMain: false });
    //   } else {
    //     // console.log('clicked');
    //     setCaptureComment({ content: value, videoHTML: loomVideoHTML });
    //     el.classList.remove('input-radius');
    //     el.value = '';
    //     el.style.height = `40px`;
    //   }
    // }

    setTextareaText('');
    setLoomVideoHTML('');
    setIsVideoUploaded(false);
    setCloseWithVideo({
      show: false,
      handleAgreement: () => { },
    });
    setResponsiveDesign({
      ...responsiveDesign,
      welcome: true,
      sidebar: false,
      comments: true,
    });
    window.removeEventListener('beforeunload', browserTabOnCloseHandler);
  };

  const handleKeyUp = (event: any) => {
    const el = event.currentTarget;
    // console.log(`Scroll Height ${el.scrollHeight}`);
    if (el.scrollHeight > 80) {
      el.style.height = `80px`;
      el.classList.remove(`overflow-hidden`);
      el.classList.add('input-radius');
    } else {
      el.style.height = `${el.scrollHeight}px`;
      if (el.value === '') {
        el.style.height = `${32}px`;
      }
      el.classList.add(`overflow-hidden`);
      el.classList.remove('input-radius');
    }
  };

  const handleKeyUpMain = (event: any) => {
    const el = event.currentTarget;
    setEnterCommentError(false);

    if (el.scrollHeight > 88) {
      el.style.height = `88px`;
      el.classList.remove(`overflow-hidden`);
      el.classList.add('input-radius');
    } else {
      el.style.height = `${el.scrollHeight}px`;
      if (el.value === '') {
        el.style.height = `${40}px`;
        if (loomVideoHTML !== '' || enterCommentError) {
          el.style.height = `${43}px`;
        }
      }
      el.classList.add(`overflow-hidden`);
      el.classList.remove('input-radius');
    }
  };

  return (
    <>
      {!isNew ? (
        <div>
          {currentUserInfo.isAnonymous && (
            <div className="">
              <CaptureCommentEmailInput
                isClicked={isClicked}
                inputEl={inputEl}
                isComment={false}
                height={32}
                setshowError={setShowInputError}
                showError={showInputError}
              />
              {showInputError && (
                <div className="w-3/3 px-4  pt-2">
                  <p className="name-error pl-0">Enter the name</p>
                </div>
              )}
            </div>
          )}
          <div className="flex justify-between items-center">
            <div
              className={`relative z-50 mb-1 ${!showInputError ? ' mt-3 ' : 'mt-2'
                } w-full flex flex-wrap items-stretch`}
            >
              <textarea
                ref={textareaEl}
                name="comment"
                id="textInput"
                placeholder={
                  currentComment.type === 'POST' &&
                    currentComment.replyIndex === -1
                    ? 'Enter comment'
                    : 'Enter reply'
                }
                autoFocus={true}
                value={commentValue}
                onKeyUp={handleKeyUp}
                onKeyDown={(event) => {
                  if (event.key === 'Enter' && !event.shiftKey) {
                    return handleReplyButtonClick(event);
                  }
                }}
                onChange={(e) => {
                  setTextareaText(e.target.value);
                  setCommentValue(e.target.value);
                }}
                className="text-xs
          	 reply-input rounded relative py-1 px-2 pr-14 w-full bg-white rounded shadow outline-none text-sm text-gray-700 placeholder-gray-400 focus:outline-none focus:shadow-outline replyTextArea"
              ></textarea>

              <div className="absolute right-0 z-10 flex flex-row items-center">
                <span
                  className="py-2 pr-2 cursor-pointer"
                  onClick={() => {
                    if (emojiPickerConfig.show) {
                      setEmojiPickerConfig({
                        show: false,
                        positioning: {
                          targetElementRect: {
                            height: 0,
                            width: 0,
                            x: 0,
                            y: 0,
                          } as DOMRect,
                          offset: {
                            x: 0,
                            y: 0,
                          },
                        },
                        handleAddIcon: () => { },
                        handleHide: () => { },
                      });
                    } else {
                      setEmojiPickerConfig({
                        show: true,
                        positioning: {
                          targetElementRect: textareaEl.current
                            ? textareaEl.current.getBoundingClientRect()
                            : ({
                              height: 0,
                              width: 0,
                              x: 0,
                              y: 0,
                            } as DOMRect),
                          offset: {
                            x: 0,
                            y: currentComment.commentIndex === 0 ? -40 : 410,
                          },
                        },
                        handleAddIcon: (emoji: string | number) => {
                          if (typeof emoji === 'string') {
                            setCommentValue((prev) => prev + emoji);
                          } else if (typeof emoji === 'number') {
                            setCommentValue(
                              (prev) => prev + String.fromCodePoint(emoji)
                            );
                          }

                          handleKeyUp({
                            currentTarget: textareaEl.current,
                          });
                        },
                        handleHide: () => {
                          setEmojiPickerConfig({
                            show: false,
                            positioning: {
                              targetElementRect: {
                                height: 0,
                                width: 0,
                                x: 0,
                                y: 0,
                              } as DOMRect,
                              offset: {
                                x: 0,
                                y: 0,
                              },
                            },
                            handleAddIcon: () => { },
                            handleHide: () => { },
                          });
                          textareaEl.current?.focus();
                        },
                      });
                    }
                  }}
                >
                  <img
                    width={16}
                    height={16}
                    src={addEmoji_IMAGE}
                    alt="add emoji"
                  />
                </span>

                <span
                  id="sendButton"
                  className="py-2 pr-2 w-8 h-full leading-snug bg-transparent rounded text-base font-normal text-gray-400 text-center flex items-center justify-center cursor-pointer"
                  onClick={handleReplyButtonClick}
                >
                  <ReplyButtonNew></ReplyButtonNew>
                  {/* {currentComment.isBeingModify ? 'SAVE' : currentComment.type} */}
                </span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="bottom-card">
          {currentUserInfo.isAnonymous && (
            <div>
              <CaptureCommentEmailInput
                isClicked={isClicked}
                inputEl={inputEl}
                isComment={true}
                setshowError={setShowInputError}
                showError={showInputError}
              />

              {showInputError && (
                <div className="w-3/3 px-4 pb-2 pt-1">
                  <p className="name-error pl-3">Enter the name</p>
                </div>
              )}
            </div>
          )}

          <div
            className={`flex flex-col ${!showInputError ? ' py-3 ' : ' pb-3 pt-0 '
              } pl-5 pr-4`}
          >
            {loomVideoHTML != '' && (
              <div className="mb-2 flex flex-row justify-between items-center text-pink">
                <div className="video-uploaded">
                  <VideoFileIcon />
                  <p className="pt-0.5 ml-1">Video uploaded</p>
                </div>
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    setLoomVideoHTML('');
                    setIsVideoUploaded(false);
                  }}
                >
                  <CloseInCircleIcon />
                </div>
              </div>
            )}

            <div
              className={`loom-recrding-duration ${!isLoomRecording && 'hidden'
                } mb-1 flex flex-row items-center`}
            >
              <RecordingDotIcon />
              <p className="ml-2">{videoDuration}</p>
            </div>

            <div className="w-full flex flex-row justify-end items-center pr-0.5">
              <textarea
                ref={textareaEl2}
                className={`input-reply-field w-full relative p-2.5 pl-5 pr-3 bg-white border-black shadow outline-none text-sm text-gray-700 placeholder-gray-400 focus:outline-none focus:shadow-outline border rounded-full block resize-none  ${placePinError ? 'border-red ' : ''
                  }`}
                name="comment"
                disabled={
                  currentComment.commentIndex === -1 ||
                  !captureComment[currentComment.commentIndex]?.isTemp
                }
                placeholder={
                  currentComment.commentIndex !== -1 &&
                    captureComment[currentComment.commentIndex]?.isTemp === true
                    ? 'Enter New comment'
                    : 'Place pin on Capture'
                }
                autoFocus={true}
                // defaultValue={currentComment.content}
                onKeyUp={handleKeyUpMain}
                onKeyDown={(event) => {
                  if (event.key === 'Enter' && !event.shiftKey) {
                    handleClickMainComment(event);
                  }
                }}
                onChange={(e) => setTextareaText(e.target.value)}
                rows={1}
              ></textarea>

              {currentUserInfo.isAnonymous || !isLoomSupported ? (
                <div className="pl-2">
                  <button
                    className={`send-button py-2 px-4 cursor-pointer text-white font-bold rounded-full ${placePinError
                      ? 'cursor-not-allowed upload-btn-default'
                      : ''
                      }`}
                    onClick={handleClickMainComment}
                  >
                    <SendBigButton />
                  </button>
                </div>
              ) : (
                <>
                  <div
                    className={
                      textareaValue === '' && loomVideoHTML === ''
                        ? 'flex items-center pl-2 sm:hidden'
                        : 'hidden'
                    }
                  >
                    <button
                      ref={loomButton}
                      className={`record-button cursor-pointer ${isLoomRecording ? 'hidden' : 'flex'
                        }`}
                    >
                      <CameraIcon opacity={1} />
                    </button>

                    <button
                      ref={recordingInProgressButton}
                      className={`cursor-pointer ${isLoomRecording ? '' : 'hidden'
                        }`}
                    >
                      <RecordingIcon />
                    </button>
                  </div>

                  <div
                    className={
                      textareaValue === '' && loomVideoHTML === ''
                        ? 'hidden'
                        : 'pl-2'
                    }
                  >
                    <button
                      className={`send-button py-2 px-4 cursor-pointer text-white font-bold rounded-full ${placePinError
                        ? 'cursor-not-allowed upload-btn-default'
                        : ''
                        }`}
                      onClick={handleClickMainComment}
                    >
                      <SendBigButton />
                    </button>
                  </div>
                </>
              )}
            </div>

            {(placePinError || enterCommentError) && (
              <div className=" px-6  pt-2">
                <p className="name-error ">
                  {placePinError
                    ? `Please place pin on Capture`
                    : enterCommentError
                      ? 'Please Enter a comment'
                      : ''}{' '}
                </p>
              </div>
            )}
          </div>
        </div>
      )}
      {/* <CaptureCommentEmailInput isClicked={isClicked} inputEl={inputEl} /> */}
      <CloseWithVideo
        show={showClosingWithVideoModal}
        closeModal={() => setShowClosingWithVideoModal(false)}
        handleAgreement={() => {
          setLoomVideoHTML('');
          setIsVideoUploaded(false);
          setShowClosingWithVideoModal(false);

          closeWithVideo.handleAgreement();
          setCloseWithVideo({
            show: false,
            handleAgreement: () => { },
          });
        }}
      />
    </>
  );
};

export default CaptureCommentTextarea;
