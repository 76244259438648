import { FC } from 'react';

interface props {
  width?: number;
  height?: number;
  opacity?: number;
}

const CameraIcon: FC<props> = ({ height = 40, width = 40, opacity = 1 }) => {
  return (
    <>
      <svg
        width={height}
        height={width}
        opacity={opacity}
        viewBox="0 0 42 42"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="1" y="1" width="40" height="40" rx="20" fill="white" />
        <g clipPath="url(#clip0_3535_56043)">
          <path d="M29 13H13V29H29V13Z" fill="white" fillOpacity="0.01" />
          <path
            d="M23.375 16H14.625C14.2798 16 14 16.3444 14 16.7692V25.2308C14 25.6556 14.2798 26 14.625 26H23.375C23.7202 26 24 25.6556 24 25.2308V16.7692C24 16.3444 23.7202 16 23.375 16Z"
            stroke="black"
            strokeWidth="1.33333"
          />
          <path
            d="M24 22.6667L28 24V18L24 19.3333"
            stroke="black"
            strokeWidth="1.33333"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <rect x="1" y="1" width="40" height="40" rx="20" stroke="black" />
        <defs>
          <clipPath id="clip0_3535_56043">
            <rect
              width="16"
              height="16"
              fill="white"
              transform="translate(13 13)"
            />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};

export default CameraIcon;
