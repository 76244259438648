import React from 'react';

const CursorDrawIcon = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="path-1-outside-1_1471_10757"
        maskUnits="userSpaceOnUse"
        x="-2.3518"
        y="-0.828427"
        width="28.2843"
        height="28.2843"
        fill="black"
      >
        <rect
          fill="white"
          x="-2.3518"
          y="-0.828427"
          width="28.2843"
          height="28.2843"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.5685 3.41421C18.7875 2.63317 17.5211 2.63317 16.7401 3.41421L6.13348 14.0208C5.35243 14.8019 5.35243 16.0682 6.13348 16.8492L8.2548 18.9706C9.03585 19.7516 10.3022 19.7516 11.0832 18.9706L21.6898 8.36396C22.4709 7.58291 22.4709 6.31658 21.6898 5.53553L19.5685 3.41421ZM3.38339 18.1355L2.41191 19.8356C2.18817 20.2272 2.25417 20.72 2.57304 21.0389L3.49287 21.9587C3.81175 22.2776 4.30458 22.3436 4.69612 22.1198L6.39621 21.1483C6.96871 20.8212 7.07343 20.0392 6.60718 19.573L4.95874 17.9246C4.49249 17.4583 3.71053 17.563 3.38339 18.1355Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.5685 3.41421C18.7875 2.63317 17.5211 2.63317 16.7401 3.41421L6.13348 14.0208C5.35243 14.8019 5.35243 16.0682 6.13348 16.8492L8.2548 18.9706C9.03585 19.7516 10.3022 19.7516 11.0832 18.9706L21.6898 8.36396C22.4709 7.58291 22.4709 6.31658 21.6898 5.53553L19.5685 3.41421ZM3.38339 18.1355L2.41191 19.8356C2.18817 20.2272 2.25417 20.72 2.57304 21.0389L3.49287 21.9587C3.81175 22.2776 4.30458 22.3436 4.69612 22.1198L6.39621 21.1483C6.96871 20.8212 7.07343 20.0392 6.60718 19.573L4.95874 17.9246C4.49249 17.4583 3.71053 17.563 3.38339 18.1355Z"
        fill="#000000"
      />
      <path
        d="M2.41191 19.8356L4.14839 20.8279L4.14839 20.8279L2.41191 19.8356ZM3.38339 18.1355L5.11988 19.1278L5.11988 19.1278L3.38339 18.1355ZM4.69612 22.1198L3.70384 20.3833L3.70384 20.3833L4.69612 22.1198ZM6.39621 21.1483L5.40394 19.4119L5.40393 19.4119L6.39621 21.1483ZM18.1543 4.82843L18.1543 4.82843L20.9827 2C19.4206 0.437903 16.888 0.437903 15.3259 2L18.1543 4.82843ZM7.5477 15.435L18.1543 4.82843L15.3259 2L4.71927 12.6066L7.5477 15.435ZM7.5477 15.435L7.5477 15.435L4.71927 12.6066C3.15717 14.1687 3.15717 16.7014 4.71927 18.2635L7.5477 15.435ZM9.66902 17.5563L7.5477 15.435L4.71927 18.2635L6.84059 20.3848L9.66902 17.5563ZM9.66902 17.5563L6.84059 20.3848C8.40269 21.9469 10.9353 21.9469 12.4974 20.3848L9.66902 17.5563ZM20.2756 6.94975L9.66902 17.5563L12.4974 20.3848L23.104 9.77817L20.2756 6.94975ZM20.2756 6.94975L23.104 9.77817C24.6661 8.21608 24.6661 5.68342 23.104 4.12132L20.2756 6.94975ZM18.1543 4.82843L20.2756 6.94975L23.104 4.12132L20.9827 2L18.1543 4.82843ZM4.14839 20.8279L5.11988 19.1278L1.6469 17.1432L0.675423 18.8433L4.14839 20.8279ZM3.98726 19.6247C4.30613 19.9435 4.37213 20.4364 4.14839 20.8279L0.675423 18.8433C0.00421817 20.0179 0.202219 21.4965 1.15883 22.4531L3.98726 19.6247ZM4.90709 20.5445L3.98726 19.6247L1.15883 22.4531L2.07866 23.3729L4.90709 20.5445ZM3.70384 20.3833C4.09538 20.1596 4.58822 20.2256 4.90709 20.5445L2.07866 23.3729C3.03527 24.3295 4.51379 24.5275 5.6884 23.8563L3.70384 20.3833ZM5.40393 19.4119L3.70384 20.3833L5.6884 23.8563L7.38849 22.8848L5.40393 19.4119ZM5.19297 20.9872C4.72672 20.521 4.83144 19.739 5.40394 19.4119L7.38849 22.8848C9.10598 21.9034 9.42013 19.5575 8.02139 18.1588L5.19297 20.9872ZM3.54453 19.3388L5.19297 20.9872L8.02139 18.1588L6.37295 16.5103L3.54453 19.3388ZM5.11988 19.1278C4.79274 19.7003 4.01077 19.805 3.54453 19.3388L6.37295 16.5103C4.97421 15.1116 2.62832 15.4258 1.6469 17.1432L5.11988 19.1278Z"
        fill="white"
        mask="url(#path-1-outside-1_1471_10757)"
      />
    </svg>
  );
};

export default CursorDrawIcon;
