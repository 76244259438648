export const loadSessionStorage = (key: string | null) => {
  try {
    // @ts-ignore
    const serializedState = sessionStorage.getItem(key);
    return serializedState === null ? [] : JSON.parse(serializedState);
  } catch (err) {
    console.error('Get state error: ', err);
  }
};

export const saveSessionStorage = (key: string | null, value: any) => {
  try {
    const serializedState = JSON.stringify(value);
    sessionStorage.setItem(key as string, serializedState);
  } catch (err) {
    console.error('Set state error: ', err);
  }
};

/**
 * Ask user for confirmig closing browser tab
 * 
 * @param e - BeforeUnloadEvent
 * @returns - Confirmation message for closing tab
 */
export const browserTabOnCloseHandler = (e: BeforeUnloadEvent) => {
  var confirmationMessage = 'Recording in progress';

  (e || window.event).returnValue = confirmationMessage; //Gecko + IE
  return confirmationMessage; //Webkit, Safari, Chrome
}

export const fromBytesToMB = (bytes: number) => {
  return bytes / (1024 ** 2);
}