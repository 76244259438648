import { FC, useEffect, useRef, useState } from 'react';

import UserAvatar from '@/components/UserAvatar';

interface EmojiCounterProps {
  emoji: string;
  count: number;
  creators: Capture.Creator[];
  emojiClickHandler: () => void;
}

const EmojiCounter: FC<EmojiCounterProps> = ({
  emoji,
  count,
  creators,
  emojiClickHandler,
}) => {
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const [startIndex, setStartIndex] = useState<number>(0);
  const [endIndex, setEndIndex] = useState<number>(4);
  const [hidingTimer, setHidingTimer] = useState<NodeJS.Timeout>(
    setTimeout(() => {})
  );

  const tooltipRef = useRef<HTMLDivElement | null>(null);
  const badgeRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    return () => {
      clearTimeout(hidingTimer);
    };
  }, []);

  useEffect(() => {
    if (tooltipRef.current && badgeRef.current) {
      tooltipRef.current.style.left =
        badgeRef.current.getBoundingClientRect().x + 'px';
      tooltipRef.current.style.top =
        badgeRef.current.getBoundingClientRect().y + 35 + 'px';
    }
  }, [badgeRef.current?.getBoundingClientRect()]);

  return (
    <div
      onMouseEnter={() => {
        setShowTooltip(true);
        clearTimeout(hidingTimer);
      }}
      onMouseLeave={() => {
        const timer = setTimeout(() => {
          setShowTooltip(false);
        }, 30);

        setHidingTimer(timer);
      }}
    >
      <div
        ref={badgeRef}
        className="emoji-badge cursor-pointer"
        onClick={emojiClickHandler}
      >
        <p>
          {emoji} <span className='count'>{count}</span>
        </p>
      </div>

      <div
        ref={tooltipRef}
        className="emoji-badge-tooltip"
        style={{ display: showTooltip ? '' : 'none' }}
      >
        {startIndex != 0 && (
          <div
            className="w-full flex flex-col items-center justify-center mb-1.5 cursor-pointer"
            onClick={() => {
              setStartIndex((prev) => prev - 5);
              setEndIndex((prev) => prev - 5);
            }}
          >
            <p className="mb-1.5">And {startIndex} less</p>
            <hr className="w-full" />
          </div>
        )}

        {creators.map((creator, index) => {
          if (index <= endIndex && index >= startIndex) {
            return (
              <div
                key={`Emoji Counter ${index}`}
                className="flex flex-row items-center p-1"
              >
                <UserAvatar
                  customClass="user-avatar-custom"
                  uid={creator.uid}
                  width={16}
                  height={16}
                />
                <p className="ml-1">{creator.displayName}</p>
              </div>
            );
          }
        })}

        {creators.length - 1 > endIndex && (
          <div
            className="w-full flex flex-col items-center justify-center mt-1.5 cursor-pointer"
            onClick={() => {
              setStartIndex((prev) => prev + 5);
              setEndIndex((prev) => prev + 5);
            }}
          >
            <hr className="w-full" />
            <p className="mt-1.5">And {creators.length - endIndex - 1} more</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default EmojiCounter;
