const ReplyButton = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="cursor-pointer"
  >
    <rect x="1" y="1" width="22" height="22" rx="11" fill="white" />
    <path
      d="M11.5833 7.8335L7.83333 11.5835L11.5833 15.1252V12.8335C14.5 12.8335 15.75 15.9585 15.75 15.9585C15.75 12.4168 14.7083 10.1252 11.5833 10.1252V7.8335Z"
      stroke="black"
      strokeWidth="0.833333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect x="1" y="1" width="22" height="22" rx="11" stroke="black" />
  </svg>
);
export default ReplyButton;
