import { useState } from 'react';
import { RecoilRoot } from 'recoil';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import { MixpanelProvider } from 'react-mixpanel-browser';
import RootLayout from '@/components/editor/layout';
import {
  Auth,
  Capture,
  Welcome,
  Goodbye,
  Layout,
  Limit,
  NotMatch,
  PublicGroup,
  GrantAccess,
  Dashboard,
  ProjectPage,
  // Feedback
} from '@/components';
import NotFound from './components/404';
import ExpiredProjectPage from './components/dashboard/ExpiredProjectPage';
import DeletedProjectPage from './components/dashboard/DeletedProjectPage';
import Editor from './components/editor';
import CodeToImage from './components/code-to-image';

const App = () => {
  const [display, setDisplay] = useState(
    window.innerWidth <= 860 ? true : false
  );

  return (
    <RootLayout>
      <RecoilRoot>
        <BrowserRouter>
          <MixpanelProvider>
            <Routes>
              <Route path="/welcome" element={<Welcome />} />
              <Route path="/notFound" element={<NotFound />} />
              {/* <Route path="/comingSoon" element={<MobileSupport />} /> */}

              <Route element={<Auth />}>
                {!display && <Route path="/" element={<Dashboard />} />}
                <Route path="/project/:projectId" element={<ProjectPage />} />
                <Route path="/goodbye" element={<Goodbye />} />
                <Route path="/expired" element={<ExpiredProjectPage />} />
                <Route path="/deleted" element={<DeletedProjectPage />} />
                <Route path="/switchTeam" element={<Navigate to="/" />} />
                <Route path="/editor/:uid/:cid" element={<Editor />} />
                <Route path="/code-to-image" element={<CodeToImage />} />
                <Route
                  path="/grantAccess/:uid/:cid/:email"
                  element={<GrantAccess message="" heading="" body="" css="" />}
                />
                {/* <Route path="/feedback" element={<Feedback />} /> */}
                <Route element={<Layout />}>
                  <Route path="/:uid/limit" element={<Limit />} />
                  <Route path="/:uid/:cid" element={<Capture />} />
                  <Route
                    path="/:uid/group/:gid/:cid"
                    element={<PublicGroup />}
                  />
                  {/* <Route path="/:uid/group/:gid" element={<Capture />} /> */}

                  <Route path="*" element={<NotMatch />} />
                </Route>
              </Route>
            </Routes>
          </MixpanelProvider>
        </BrowserRouter>
      </RecoilRoot>
    </RootLayout>
  );
};

export default App;
