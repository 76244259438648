type Props = {
    width?: number,
    height?: number
    customClass?: string
}
const HelpIcon = (props: Props) => (
    <svg
        className={`${props.customClass}`}
        width={`${props.width ? props.width : `16`}`}
        height={`${props.height ? props.height : `16`}`} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_604_14139)">
            <path d="M10.0001 18.3334C12.3012 18.3334 14.3846 17.4007 15.8926 15.8926C17.4007 14.3846 18.3334 12.3012 18.3334 10.0001C18.3334 7.69891 17.4007 5.61558 15.8926 4.10752C14.3846 2.59949 12.3012 1.66675 10.0001 1.66675C7.69891 1.66675 5.61558 2.59949 4.10752 4.10752C2.59949 5.61558 1.66675 7.69891 1.66675 10.0001C1.66675 12.3012 2.59949 14.3846 4.10752 15.8926C5.61558 17.4007 7.69891 18.3334 10.0001 18.3334Z" stroke="black" strokeWidth="1.66667" strokeLinejoin="round" />
            <path d="M10 11.9269V10.2603C11.3807 10.2603 12.5 9.14096 12.5 7.76025C12.5 6.37955 11.3807 5.26025 10 5.26025C8.61929 5.26025 7.5 6.37955 7.5 7.76025" stroke="black" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
            <path fillRule="evenodd" clipRule="evenodd" d="M9.99992 15.6771C10.5752 15.6771 11.0416 15.2107 11.0416 14.6354C11.0416 14.0601 10.5752 13.5938 9.99992 13.5938C9.42463 13.5938 8.95825 14.0601 8.95825 14.6354C8.95825 15.2107 9.42463 15.6771 9.99992 15.6771Z" fill="black" />
        </g>
        <defs>
            <clipPath id="clip0_604_14139">
                <rect width="20" height="20" fill="white" />
            </clipPath>
        </defs>
    </svg>

);

export default HelpIcon;