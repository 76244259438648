import { FC } from 'react';
import Paths from './Paths';
import { useRecoilValue } from 'recoil';
import { isCurrentScaleState } from '@/state/toolState';

const ToolObjectDraw: FC<{
  width?: number;
  height?: number;
  currentScale?: Tool.Scale;
  className?: string;
  style?: object;
  stroke: Canvas.Path;
  color?: string;
  active?: boolean;
}> = ({ width, height, className = '', style = {}, stroke, color, active }) => {
  const currentScale = useRecoilValue(isCurrentScaleState);
  if (!stroke) return null;

  // console.log('width', width / currentScale.x);
  // console.log('height', height);
  // console.log('path', stroke);
  // console.log('currentScale', currentScale);
  const adjustedHeight = height;
  const adjustedWidth = width;

  return (
    <svg
      className={className}
      overflow="visible"
      width={adjustedWidth}
      height={adjustedHeight}
      viewBox={currentScale ? `0 0 ${adjustedWidth} ${adjustedHeight}` : ''}
      preserveAspectRatio="xMinYMin slice"
      style={style}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        {active && (
          <Paths
            paths={[{ ...stroke, strokeWidth: stroke.strokeWidth + 3 }]}
            color={'blue'}
          />
        )}
        <Paths paths={[stroke]} color={color} />
      </g>
    </svg>
  );
};

export default ToolObjectDraw;
