const ReplyButtonNew = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http:www.w3.org/2000/svg"
  >
    <path d="M16 0H0V16H16V0Z" fill="white" fillOpacity="0.01" />
    <path
      d="M5.33332 6L2.66666 8.66667L5.33332 11.3333"
      stroke="#333333"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.3333 3.33325V7.66659C13.3333 8.21889 12.8856 8.66659 12.3333 8.66659H2.66666"
      stroke="#333333"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default ReplyButtonNew;
